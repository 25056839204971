import React from "react";
import "../../../assets/css/owl.carousel.min.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/owl.carousel.js";
import "owl.carousel/dist/owl.carousel.min.js";

const options = {
  margin: 10,
  responsiveClass: true,
  nav: true,
  dots: false,
  responsive: {
    0: {
      items: 1,
      center: true,
    },
    568: {
      items: 3,
    },
    768: {
      items: 3,
    },
    992: {
      items: 3,
    },
    1200: {
      items: 3,
    },
  },
};

const MetricsCarousel = (props) => {
  return (
    <>
      <OwlCarousel
        className="owl-theme"
        {...options}
        key={props.statMatrixData?.length}
      >
        {props.statMatrixData && props.statMatrixData.length
          ? props.statMatrixData.map((item, index) => (
              <div className="item" key={index}>
                <div className="chValueBox">
                  <div className="iconBox">
                    {item.verified ? (
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.504"
                          height="16.255"
                          viewBox="0 0 16.504 16.255"
                        >
                          <defs>
                            <style></style>
                          </defs>
                          <g transform="translate(-2.057 -2.086)">
                            <path
                              className="a"
                              d="M17.5,9.578v.667a7.25,7.25,0,1,1-4.3-6.627"
                              transform="translate(0 0)"
                            />
                            <path
                              className="a"
                              d="M22.925,6l-7.25,7.257L13.5,11.082"
                              transform="translate(-5.425 -1.555)"
                            />
                          </g>
                        </svg>
                      </span>
                    ) : null}
                    <span>{item.result}</span>
                  </div>
                  <span className="label">{item.metricsslider}</span>
                </div>
              </div>
            ))
          : null}
      </OwlCarousel>
    </>
  );
};

export default MetricsCarousel;
