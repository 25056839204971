import React, { useEffect } from "react";
import { useState, useRef, useCallback } from "react";
import "./css/notification.css";
import { Menu, MenuItem, Button, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory, Prompt, useLocation } from "react-router-dom";
import closeGrayIcon from "./img/close-gray-icon.svg";
import trashCircleIcon from "./img/trash-circle-icon.svg";
import alertIcon from './img/alert.png';
import Dialog from "@material-ui/core/Dialog";
import messagePopup from "../../../utils/messagePopup";
import moment from "moment";
import ENV from '../../../config';
import { AdminHeader } from '../../header/adminHeader';
import BackdropLoader from "../../common/Loader";
import services from "../../../../src/services";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../../src/utils/constant";
import toastr from 'toastr';
import swal from "sweetalert";
import AdvisorHeader from "../../header/advisorHeader";

const ITEM_HEIGHT = 48;

const AdvisorReplyMessage = (props) => {
  const location = useLocation();
  let history = useHistory();
  let athleteSportName = "";
  let athleteGradeYear = "";
  let athletePrimaryPosition = "";
  let athleteSeccondaryPosition = "";
  let athleteRemoved = 0;
  let athleteActive = 1;

  if(history?.location?.state?.data?.messagemessagesendbymaprel?.Athletes?.length){
    let athleteData = history?.location?.state?.data?.messagemessagesendbymaprel?.Athletes;
    athleteSportName = athleteData[0]['athleteathletesportsmaprel']['Athletesports'].length ? athleteData[0]['athleteathletesportsmaprel']['Athletesports'][0]['sportname'] : "";
    athleteGradeYear = athleteData[0]['athleteathletegraduationyearmaprel']['Graduationyears'].length ? athleteData[0]['athleteathletegraduationyearmaprel']['Graduationyears'][0]['yearvalue'] : "";
    athletePrimaryPosition = athleteData[0]['onBoardingUserIdMap']['Onboardings'].length ? athleteData[0]['onBoardingUserIdMap']['Onboardings'][0]['onboardingprimarypositionmaprel']['Primarypositions'].length ? athleteData[0]['onBoardingUserIdMap']['Onboardings'][0]['onboardingprimarypositionmaprel']['Primarypositions'][0]['selectionfield'] : "" : "";
    athleteSeccondaryPosition = athleteData[0]['onBoardingUserIdMap']['Onboardings'].length ? athleteData[0]['onBoardingUserIdMap']['Onboardings'][0]['onboardingsecondarypositionmaprel']['Secondarypositions'].length ? athleteData[0]['onBoardingUserIdMap']['Onboardings'][0]['onboardingsecondarypositionmaprel']['Secondarypositions'][0]['selectionfield'] : "" : "";
    athleteRemoved = athleteData[0]['athleteuseridmaprel']['Userdata'].length ? athleteData[0]['athleteuseridmaprel']['Userdata'][0]['issoftdeleted'] : 0;
    athleteActive = athleteData[0]['athleteuseridmaprel']['Userdata'].length ? athleteData[0]['athleteuseridmaprel']['Userdata'][0]['active'] : 1;
  }
  else if(history?.location?.state?.data?.messagemessagesendtomaprel?.Athletes?.length){
    let athleteData = history?.location?.state?.data?.messagemessagesendtomaprel?.Athletes;
    athleteSportName = athleteData[0]['athleteathletesportsmaprel']['Athletesports'].length ? athleteData[0]['athleteathletesportsmaprel']['Athletesports'][0]['sportname'] : "";
    athleteGradeYear = athleteData[0]['athleteathletegraduationyearmaprel']['Graduationyears'].length ? athleteData[0]['athleteathletegraduationyearmaprel']['Graduationyears'][0]['yearvalue'] : "";
    athletePrimaryPosition = athleteData[0]['onBoardingUserIdMap']['Onboardings'].length ? athleteData[0]['onBoardingUserIdMap']['Onboardings'][0]['onboardingprimarypositionmaprel']['Primarypositions'].length ? athleteData[0]['onBoardingUserIdMap']['Onboardings'][0]['onboardingprimarypositionmaprel']['Primarypositions'][0]['selectionfield'] : "" : "";
    athleteSeccondaryPosition = athleteData[0]['onBoardingUserIdMap']['Onboardings'].length ? athleteData[0]['onBoardingUserIdMap']['Onboardings'][0]['onboardingsecondarypositionmaprel']['Secondarypositions'].length ? athleteData[0]['onBoardingUserIdMap']['Onboardings'][0]['onboardingsecondarypositionmaprel']['Secondarypositions'][0]['selectionfield'] : "" : "";
    athleteRemoved = athleteData[0]['athleteuseridmaprel']['Userdata'].length ? athleteData[0]['athleteuseridmaprel']['Userdata'][0]['issoftdeleted'] : 0;
    athleteActive = athleteData[0]['athleteuseridmaprel']['Userdata'].length ? athleteData[0]['athleteuseridmaprel']['Userdata'][0]['active'] : 1;
  }
  
  const [lastLocation, setLastLocation] = useState(location);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [anchorE1, setAnchorE1] = React.useState(null);
  const open = Boolean(anchorE1);
  const store = useSelector(store => store);
  const [message, setMessage] = useState(undefined),
    [showDeleteModal, setShowDeleteModal] = useState(false),
    [openPremiumModal, setOpenPremiumModal] = useState(false),
    [error, setError] = useState({
      message: ""
    }),
    [messageReplyData, setMessageReplyData] = useState([]),
    [loading, setLoading] = useState(true),
    [msgReplyId, setMsgReplyId] = useState(0),
    [isBlocking, setIsBlocking] = useState(false),
    [limit, setLimit] = useState(4),
    [hasMore, setHasMore] = useState(false),
    [checkForScroll, setCheckForScroll] = useState(true),
    [currentAdvisor, setCurrentAdvisor] = useState(athleteRemoved ? true : !athleteActive ? true : false),
    [athleteDetails, setAthleteDetails] = useState([athleteSportName, athleteGradeYear, athletePrimaryPosition, athleteSeccondaryPosition]),
    [placeHolderText, setPlaceholderText] = useState(athleteRemoved ? "Athlete has been removed by the SportsForce. You cannot send messages to the removed athlete" : 
        !athleteActive ? "This athlete has been deactivated by the SportsForce. You cannot send messages to deactivated athletes" : "Enter Your message here..."
    );

    const messagesEndRef = useRef(null)
    const nodeRef = useRef(null)
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }

    const showModal = async(nextLocation) => {
      const willSave = await swal({
          text: "Changes you made, may not be saved",
          icon: 'warning',
          buttons: {
              confirm: { text: "Stay" },
              danger: "Leave",
          },
      })
      if(willSave === "danger"){
          setConfirmedNavigation(true);
      }
      setLastLocation(nextLocation);
    };
  
    const handleBlockedRoute = (nextLocation) => {
      if (!confirmedNavigation && nextLocation.pathname !== history.location.pathname) {
        showModal(nextLocation);
        return false;
      }
      return true;
    };
  
    useEffect(() => {
      if (confirmedNavigation && lastLocation) {
        history.push(lastLocation.pathname);
      }
    }, [confirmedNavigation, lastLocation, history]);  

  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };
  const handleCoachMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/coach/composeMessage" });
  };
  const handleAthletetMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/advisor/composeMessage" });
  }
  const handleAdvisorMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/advisor/composeMessage" });
  }
  const handleSupportMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/support/composeMessage" });
  }
  const archiveMessageData = async (e) => {
    e.stopPropagation();
    await services.location.saveMessages({ obj: { id: history?.location?.state?.data?.id, active: 0, archivebyadvisor: 1 } }).then(async (res) => {
      await services.location.updateMessageReplies({ where:{msgid: history?.location?.state?.data?.id}, data: {active: 0, archivebyadvisor: 1} }).then(res => { 
        messagePopup("", "Chat archived successfully", "success");
        history.push('/message/advisor/athlete')
      });
    });
  }
  const deleteMessageData = () => {
    setShowDeleteModal(!showDeleteModal)
  }
  const deleteReplyMessageData = (e, id) => {
    setMsgReplyId(id);
    setShowDeleteModal(!showDeleteModal);
  }
  const cancelBtnClick = () => {
    setOpenPremiumModal(!openPremiumModal);
  }
  const createdTime = newDate => {
    let dateTime = null;
    if (moment(newDate).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')) {
      dateTime = moment(newDate).format('LT');
    }
    else {
      dateTime = moment(newDate).format('DD/MM/YYYY');
    }
    return dateTime;
  }
  const DeleteItem = ({ onDeleteClick, onCancelClick }) => {
    return (
      <div
        className="modal fade common-modal trash-modal show"
        id="MessageTrashModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={onCancelClick}>
                <img src={closeGrayIcon} alt="close Gray Icon" />
              </button>
              <div className="icon-box-first">
                <img src={trashCircleIcon} alt="trash Circle Icon" />
              </div>
              <div className="content-box">
                <h4>
                  Are you sure you want to
                  <br /> delete this message?
                </h4>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={onDeleteClick}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn gray"
                data-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onDeleteHandler = async (e) => {
    e.preventDefault();
    await services.location.saveMessages({ obj: { id: history?.location?.state?.data?.id, deletebyadvisor: 1, readbyadvisor: 1 } }).then(async (res) => {
      const _res = await services.location.updateMessageReplies({
        where: { msgid: parseFloat(history?.location?.state?.data?.id) },
        data: { deletebyadvisor: 1, readbyadvisor: 1 }
      });
      if (_res?.data?.data?.MessagereplyUpdateAll) {
        toastr.success("Message deleted successfully").css({"width":"auto"})
        history.push('/message/advisor/athlete')
      }
      else {
        messagePopup("", "Message not deleted try again", "error")
      }
    });
    setShowDeleteModal(!showDeleteModal)
  }

  const onReplyDeleteHandler = async (e) => {
    e.preventDefault();
    const _res = await services.location.saveMessagereply({
      obj: {
        id: parseFloat(msgReplyId),
        deletebyadvisor: 1,
        readbyadvisor: 1
      }
    });
    if (_res?.data?.data?.saveMessagereply?.id) {
      toastr.success("Message deleted successfully").css({"width":"auto"})
      history.push('/message/advisor/athlete')
    }
    else {
      messagePopup("", "Message not deleted try again", "error")
    }
    setMsgReplyId(0)
    setShowDeleteModal(!showDeleteModal)
  }

  const PremiumModal = ({ onActivateClick, onCancelClick }) => {
    return (
      <div
        className="modal fade common-modal trash-modal show"
        id="MessageTrashModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={onCancelClick}>
                <img src={closeGrayIcon} alt="close Gray Icon" />
              </button>
              <div className="icon-box-first">
                <img className='imgName' src={alertIcon} alt="Alert Circle Icon" />
              </div>
              <div className="content-box">
                <h4>
                  Sending message to admin is a premium feature
                  <br />Activate your plan to unlock messaging system.
                </h4>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={onActivateClick}
              >
                Activate
              </button>
              <button
                type="button"
                className="btn gray"
                data-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onActivateBtn = e => {
    history.push({ pathname: "/programAndPricing" });
    setOpenPremiumModal(!openPremiumModal);
  }

  const validateMessage = () => {
    //let errors = error;
    //errors.message = message ? '' : 'Please enter message';
    //setError(errors);
    return message ? true : false;
  }
  const sendMessageBtn = async () => {
    if (validateMessage()) {
      const userId = parseFloat(history?.location?.state?.data?.messagesendto ? history?.location?.state?.data?.messagesendto : history?.location?.state?.data?.messagesendby)
      await services.location.sendMessageToAnyRole({
        msgsenderrole: getUserInfo()?.role,
        msgrecieverrole: "ATHLETES",
        msgsenderid: parseFloat(getUserInfo()?.userid),
        msg: message.replace (/"/g, ""),
        userids: [userId],
        parentid: 0
      }).then(async (messageRes) => {
        if (messageRes?.data?.response?.status == 200) {
          toastr.success("Message has been sent successfully").css({"width":"auto"})
          /*
          await services.location.flameAPI({
            athleteId: userId,
            role: "ADMIN",
            title: "messagefromteam",
            userId: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id)
          }).then(async (flameRes) => { })
          */
          setIsBlocking(false);
          setMessage('');
          setLimit(4);
          getMessageReplyData(4);
          history.push({ pathname: "/message/advisor/replyMessage", state: { data: history?.location?.state?.data } });
        } else {
          messagePopup("", "Message has not been sent. Try agai!", "error");
        }
      })
      /*
      await services.location.saveMessages({
        obj: {
          id: parseFloat(history?.location?.state?.data?.id),
          deletebyotherrole: 0,
          archivebyotherrole: 0,
          deletebyathlete:0,
          archivebyathlete:0
        }
      }).then(async (res) => { 
          await services.location.saveMessagereply({
              obj: {
                msgid: parseFloat(history?.location?.state?.data?.id),
                msg: message,
                msgreceiverid: parseFloat(history?.location?.state?.data?.messagesendto ? history?.location?.state?.data?.messagesendto : history?.location?.state?.data?.messagesendby),
                msgsenderadminid: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id),
                subject: history?.location?.state?.data?.subject,
                active: 1,
                readbyotherrole:1
              }
            });

            const activityRes = await services.location.saveUserActivity({
              obj: {
                messageid: parseFloat(history?.location?.state?.data?.id),
                userid: parseFloat(history?.location?.state?.data?.msgsenderid),
                adminid: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id),
              }
            }).then(res => {
              if (res.status == 200) {
                messagePopup("", "Message has been sent successfully.", "success");
                setMessage('');
                getMessageReplyData();
                history.push({ pathname: "/message/athlete/replyMessage", state: { data: history?.location?.state?.data } });
              }
            }).catch(err => { });
      })*/
    }
    else {
      messagePopup("", "Please enter message", "warning")
    }
  }

  useEffect(async () => {
    if (history.location.state) {
      await services.location.getAdvisorAthleteMappings({ where: { advisorid: getUserInfo()?.id, active: 1 } }).then(res =>{
        if(res?.status == 200){
          if(res?.data?.data?.allAdvisorathletemappings?.Advisorathletemappings?.length){
            const checkCurrentAdvisior = res?.data?.data?.allAdvisorathletemappings?.Advisorathletemappings.some(item => {
              let itemAthleteId = item?.advisorathletemappingathleteidmaprel?.Athletes.length ? item?.advisorathletemappingathleteidmaprel?.Athletes[0]['id'] : 0
              if(itemAthleteId === history?.location?.state?.data?.messagesendby || itemAthleteId === history?.location?.state?.data?.messagesendto){
                return true
              }
            })
            if(!checkCurrentAdvisior){
              setCurrentAdvisor(true)
              setPlaceholderText("You cannot message to your old Athlete, If you have any associated query, you can send connect with SportsForce Support")
            }
          }
        }
      })  
      getMessageReplyData();
    }
    else {
      history.push('/message/advisor/athlete')
    }
  }, [history.location.state]);

  const replaceURLs = (message) => {
    if (!message) return;
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let emailRegex = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    message = message.replace(emailRegex, function (email) {
      return "<a href=mailto:'" + email + "'>" + email + "</a>"
    })
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return "<a href='" + hyperlink + "' target='_blank'>" + url + "</a>"
    });
  }

  const getMessageReplyData = async (count = limit) => {
    const _res = await services.location.getAllMessageReplies({
      where: {
        msgid: history.location.state.data.id,
        archivebyadvisor: history?.location?.state?.data?.archivebyadvisor ? history?.location?.state?.data?.archivebyadvisor : 0,
        deletebyadvisor: 0,
        order: "id desc"
      },
      first: count,
      last: count
    });
    let data = _res?.data?.data?.allMessagereplies?.totalCount > 0 ? _res?.data?.data?.allMessagereplies?.Messagereplies?.map(item => { 
      item['msg'] = replaceURLs(item?.msg)
      item['messagereplymsgsenderName'] = item?.messagereplymsgsenderadvisoridmaprel?.Userdata?.length > 0 ? item?.messagereplymsgsenderadvisoridmaprel?.Userdata[0]?.advisorUseridMap?.Advisors[0]['firstname'].trim()+" "+item?.messagereplymsgsenderadvisoridmaprel?.Userdata[0]?.advisorUseridMap?.Advisors[0]['lastname'].trim() : item?.messagereplymsgsenderidrel?.Athletes?.length ? item?.messagereplymsgsenderidrel?.Athletes[0]['athletefirstname'].trim()+" "+item?.messagereplymsgsenderidrel?.Athletes[0]['athletelastname'].trim() : "";
      item['messageSenderId'] = item?.messagereplymsgsenderadvisoridmaprel?.Userdata?.length > 0 ? item?.messagereplymsgsenderadvisoridmaprel?.Userdata[0]?.advisorUseridMap?.Advisors[0]['id'] : item?.messagereplymsgsenderidrel?.Athletes?.length ? item?.messagereplymsgsenderidrel?.Athletes[0]['id'] : "";
      item['messagereplymsgsenderEmail'] = item?.messagereplymsgsenderadvisoridmaprel?.Userdata?.length > 0 ? item?.messagereplymsgsenderadvisoridmaprel?.Userdata[0]?.email : item?.messagereplymsgsenderidrel?.Athletes?.length ? item?.messagereplymsgsenderidrel?.Athletes[0]['athleteemailid'] : "";
      item['messagereplymsgsenderImage'] = item?.messagereplymsgsenderadvisoridmaprel?.Athletes?.length > 0 ? item?.messagereplymsgsenderadvisoridmaprel?.Athletes[0]?.onBoardingUserIdMap?.Onboardings[0]['profileimages'] : item?.messagereplymsgsenderidrel?.Athletes?.length ? item?.messagereplymsgsenderidrel?.Athletes[0]['onBoardingUserIdMap']['Onboardings'][0]['profileimages'] : "";
      if (item?.parentid) {
        let parentDetails = item?.messagereplyparentidmaprel?.Userdata[0]?.athleteParentUserIdMap?.Athleteparents;
        let messagereplymsgsenderFirstName = parentDetails?.length ? parentDetails[0]?.parentfirstname : '';
        let messagereplymsgsenderLastName = parentDetails?.length ? parentDetails[0]?.parentlastname : '';
        item['messagereplymsgsenderInitials'] = messagereplymsgsenderFirstName && messagereplymsgsenderLastName ? messagereplymsgsenderFirstName[0].toUpperCase() + messagereplymsgsenderLastName[0].toUpperCase() : "";
        item['parentnamevalue'] = messagereplymsgsenderFirstName + " " + messagereplymsgsenderLastName;
      }
      else {
        item['messagereplymsgsenderInitials'] = item['messagereplymsgsenderName'].split(" ")[0][0].toUpperCase() + item['messagereplymsgsenderName'].split(" ")[1][0].toUpperCase();
      }
      item['messagereplymsgsenderadminName'] = item?.messagereplymsgsenderadminidmaprel?.Userdata?.length > 0 ? item?.messagereplymsgsenderadminidmaprel?.Userdata[0]?.userfullname : '';
      item['messagereplymsgsenderadminEmail'] = item?.messagereplymsgsenderadminidmaprel?.Userdata?.length > 0 ? item?.messagereplymsgsenderadminidmaprel?.Userdata[0]?.email : '';
      let messagereplymsgsenderadminFirstName = item?.messagereplymsgsenderadminidmaprel?.Userdata?.length > 0 && item?.messagereplymsgsenderadminidmaprel?.Userdata[0]?.fullname ? item?.messagereplymsgsenderadminidmaprel?.Userdata[0]?.fullname[0].split(/\s/)[0].slice(0, 1) : '';
      let messagereplymsgsenderadminLastName = item?.messagereplymsgsenderadminidmaprel?.Userdata?.length > 0 && item?.messagereplymsgsenderadminidmaprel?.Userdata[0]?.fullname ? item?.messagereplymsgsenderadminidmaprel?.Userdata[0]?.fullname.split(/\s/)[1].slice(0, 1) : '';
      item['messagereplymsgsenderadminInitials'] = messagereplymsgsenderadminFirstName && messagereplymsgsenderadminLastName ? messagereplymsgsenderadminFirstName[0] + messagereplymsgsenderadminLastName[0] : "";
      item['sysGenId'] = item?.messagereplymsgsenderidrel?.Athletes?.[0]?.athleteuseridmaprel?.Userdata?.[0]?.sysgenid ?? 0
      return item;
    }) : [];
    
    setMessageReplyData(data.sort((a,b) => a.id - b.id));
    setLoading(false);
    setLimit(limit + 4);
    setHasMore(_res?.data?.data?.allMessagereplies?.totalCount > _res?.data?.data?.allMessagereplies?.Messagereplies?.length ? true : false);
    checkForScroll && scrollToBottom();
    setCheckForScroll(false);
    hasMore && _res?.data?.data?.allMessagereplies?.Messagereplies?.length > 4 && nodeRef.current.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" })
  }

  let nameInitials = getUserInfo()?.firstname[0].toUpperCase() + getUserInfo()?.lastname[0].toUpperCase();
  
  const handleScroll = (e) => {
    if(e.currentTarget.scrollTop === 0) {
      hasMore && getMessageReplyData(limit)
    }
  } 

  return (
    <div className="pageContent">
      <BackdropLoader open={loading} />
      <div className="container">
        <div className="notification-with-sidebar">
          <nav className="notification-sidebar">
            <ul>
              <li className="button-link dropdown">
                <Button className="btn lightblue dropdown-toggle" onClick={(e) => handleClick(e)}>Compose</Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorE1}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '20ch',
                    },
                  }}
                >
                  <MenuItem>
                    <strong>Compose for</strong>
                  </MenuItem>
                  <MenuItem onClick={handleSupportMenu}>Support</MenuItem>
                  <MenuItem onClick={handleAthletetMenu}>Athlete</MenuItem>
                  {/* <MenuItem onClick={handleCoachMenu}>Coach</MenuItem>
                  <MenuItem onClick={handleAdvisorMenu}>Advisor</MenuItem> */}
                </Menu>
              </li>
              <li
                className={window.location.href.includes('/message/support/composeMessage') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/support/composeMessage" })}
              >
                <div className="link-item">
                  <span>Support</span> <span>{(parseInt(store?.adminMessages?.advisorSupportUnreadMessages)) ? (parseInt(store?.adminMessages?.advisorSupportUnreadMessages)) : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/message/advisor/athlete') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor/athlete" })}
              >
                <div className="link-item">
                  <span>Athlete</span> <span>{(parseInt(store?.adminMessages?.advisorAthleteUnreadMessages)) ? (parseInt(store?.adminMessages?.advisorAthleteUnreadMessages)) : ""}</span>
                </div>
              </li>
              {/* <li
                className={window.location.href.includes('/advisor') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor" })}
              >
                <div className="link-item">
                  <span>Advisor</span> <span>{(parseInt(store?.adminMessages?.advisiorUnreadMessages)) ? (parseInt(store?.adminMessages?.advisiorUnreadMessages)) : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/coach') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/coach" })}
              >
                <div className="link-item">
                  <span>Coaches</span> <span>{(parseInt(store?.adminMessages?.coachesUnreadMessages)) ? (parseInt(store?.adminMessages?.coachesUnreadMessages)) : ""}</span>
                </div>
              </li> */}
              <li
                className={window.location.href.includes('/archive') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor/archive" })}
              >
                <div className="link-item">
                  <span>Archive</span><span>{(parseInt(store?.adminMessages?.advisorArchiveUnreadMessages)) ? (parseInt(store?.adminMessages?.advisorArchiveUnreadMessages)) : ""}</span>
                </div>
              </li>
            </ul>
          </nav>
          <div className="notification-detail-box with-back">
            <div className="notification-search-box">
              <div className="search-box">
                <label>Athlete</label>
              </div>
              <div className="notification-pagination">
                <Link
                  to={{ pathname: "/message/advisor/athlete" }}
                >
                  <span>{"<"}</span>
                  Back to messages
                </Link>
              </div>
            </div>
            <div className="notification-messageDetail">
              <div className="message-detailInfo">
                <div className="subject-lineInfo">
                    {
                      messageReplyData?.length > 0 ? <div>
                        {
                          athleteRemoved ? <h2>{history?.location?.state?.data?.senderName}</h2> :
                          <Link style={{textDecoration:"none"}}
                          to={`/athlete/${history?.location?.state?.data?.senderName?.trim()?.toLowerCase()?.split(" ")?.join("-")}-${history?.location?.state?.data?.sysGenId}`}
                                // to={`/publicProfile/1/${+history?.location?.state?.data?.messageSenderId}/${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.id}`}
                                target="_blank"
                          >
                            <h2>{history?.location?.state?.data?.senderName}</h2>
                          </Link>
                        }
                        <h4>{athleteDetails.filter(el => el).join(', ')}</h4>
                      </div> : <h3>No new message</h3>
                    }
                    <div className="reply-msg-options">
                    {messageReplyData?.length > 0 ? <Tooltip placement="bottom" title={"Archive"} arrow>
                        <button className="trash-button" onClick={(e) => archiveMessageData(e)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="19.2" height="16" viewBox="0 0 29.981 29.982">
                              <path id="archive-30_30" data-name="archive-30*30" d="M2,10.547a3,3,0,0,1,.657-1.873L6.646,3.688A4.5,4.5,0,0,1,10.158,2H23.824a4.5,4.5,0,0,1,3.512,1.688l3.989,4.986a3,3,0,0,1,.657,1.873V27.484a4.5,4.5,0,0,1-4.5,4.5H6.5a4.5,4.5,0,0,1-4.5-4.5ZM10.158,5H23.824a1.5,1.5,0,0,1,1.171.563l2.661,3.326a.375.375,0,0,1-.293.609H6.619a.375.375,0,0,1-.293-.609L8.987,5.561A1.5,1.5,0,0,1,10.158,5Zm8.335,10.493a1.5,1.5,0,1,0-3,0v7.625L14.3,21.924a1.5,1.5,0,0,0-2.12,2.12l2.691,2.69a3,3,0,0,0,4.24,0L21.8,24.05a1.5,1.5,0,1,0-2.12-2.12l-1.186,1.186Z" transform="translate(-2 -2)" fill="#3e3e3e" fill-rule="evenodd" />
                          </svg>
                        </button>
                    </Tooltip> : ""}
                    {messageReplyData?.length > 0 ? <Tooltip placement="bottom" title={"Delete"} arrow><button className="trash-button" onClick={() => deleteMessageData()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                        <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z" fill="#3e3e3e"></path>
                      </svg>
                    </button></Tooltip> : ""}
                  </div>
                </div>
                <div className="replyMessageBlock" style={{maxHeight: "200px", overflowY: "auto"}} /*style={ messageReplyData?.length && messageReplyData?.length > 3 ? {height: "200px", overflowY: "auto"} : {}}*/ onScroll={(e)=>handleScroll(e)}>
                  {messageReplyData?.length > 0 ?
                    messageReplyData?.map((item, index) => {
                      if (index==4) {
                        return (
                          <div key={index} ref={nodeRef} >
                            <div className="message-infobox replyMessage">
                              { item?.sendmsgrolevalue === "Athlete" ?
                                athleteRemoved ? <div className="user-profile">
                                  {item?.messagereplymsgsenderImage && !item?.parentid ? <img src={ENV.DOWNLOAD_URL + `/${item?.messagereplymsgsenderImage}`} alt="" /> : <div className="initial">
                                    {item?.messagereplymsgsenderInitials}
                                  </div>}
                                </div> :
                                <Link style={{textDecoration:"none", color:"inherit"}}
                                    to={`/athlete/${item?.messagereplymsgsenderName?.trim()?.toLowerCase()?.split(" ")?.join("-")}-${item?.sysGenId}`}
                                    // to={`/publicProfile/1/${item?.messageSenderId}/${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.id}`}
                                      target="_blank">
                                  <div className="user-profile">
                                    {item?.messagereplymsgsenderImage && !item?.parentid ? <img src={ENV.DOWNLOAD_URL + `/${item?.messagereplymsgsenderImage}`} alt="" /> : <div className="initial">
                                      {item?.messagereplymsgsenderInitials}
                                    </div>}
                                  </div>
                                </Link>
                                : 
                                <div className="user-profile">
                                  {item?.messagereplymsgsenderImage && !item?.parentid ? <img src={ENV.DOWNLOAD_URL + `/${item?.messagereplymsgsenderImage}`} alt="" /> : <div className="initial">
                                    {item?.messagereplymsgsenderInitials}
                                  </div>}
                                </div>
                              }
                              <div className="user-detial-box">
                                <div className="item">
                                  <h4 className="name">{item?.parentid ? item?.parentnamevalue : item?.messagereplymsgsenderName} {item?.messagereplymsgsenderEmail ? <span>{item?.parentid ? `Parent / Guardian` : `Athlete`}</span> : ''}</h4>
                                  <div className="text-box">
                                    <p>
                                      <div
                                        dangerouslySetInnerHTML={{ __html: item?.msg }}
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="time-text">{createdTime(item?.createdon)}</div>
                                  <Tooltip placement="bottom" title={"Delete"} arrow>
                                  <button className="sub-message-trash-button" onClick={(e) => deleteReplyMessageData(e, item.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                                      <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z" fill="#3e3e3e"></path>
                                    </svg>
                                  </button>
                                  </Tooltip>
                                </div>
                              </div>
                            </div> 
                          </div>
                        )
                      }
                      else {
                        return (
                          <div key={index} >
                            <div className="message-infobox replyMessage">
                              { item?.sendmsgrolevalue === "Athlete" ? 
                                athleteRemoved ? <div className="user-profile">
                                  {item?.messagereplymsgsenderImage && !item?.parentid ? <img src={ENV.DOWNLOAD_URL + `/${item?.messagereplymsgsenderImage}`} alt="" /> : <div className="initial">
                                    {item?.messagereplymsgsenderInitials}
                                  </div>}
                                </div> :
                                <Link style={{textDecoration:"none", color:"inherit"}}
                                      to={`/athlete/${item?.messagereplymsgsenderName?.trim()?.toLowerCase()?.split(" ")?.join("-")}-${item?.sysGenId}`}
                                    // to={`/publicProfile/1/${item?.messageSenderId}/${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.id}`}
                                      target="_blank">
                                  <div className="user-profile">
                                    {item?.messagereplymsgsenderImage && !item?.parentid ? <img src={ENV.DOWNLOAD_URL + `/${item?.messagereplymsgsenderImage}`} alt="" /> : <div className="initial">
                                      {item?.messagereplymsgsenderInitials}
                                    </div>}
                                  </div>
                                </Link> 
                                : 
                                <div className="user-profile">
                                  {item?.messagereplymsgsenderImage && !item?.parentid ? <img src={ENV.DOWNLOAD_URL + `/${item?.messagereplymsgsenderImage}`} alt="" /> : <div className="initial">
                                    {item?.messagereplymsgsenderInitials}
                                  </div>}
                                </div>
                              }
                              <div className="user-detial-box">
                                <div className="item">
                                <h4 className="name">{item?.parentid ? item?.parentnamevalue : item?.messagereplymsgsenderName} {item?.sendmsgrolevalue ? <span>{item?.sendmsgrolevalue}</span> : ''}</h4>
                                  
                                  <div className="text-box">
                                    <p>
                                      <div
                                        dangerouslySetInnerHTML={{ __html: item?.msg }}
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="time-text">{createdTime(item?.createdon)}</div>
                                  <Tooltip placement="bottom" title={"Delete"} arrow>
                                  <button className="sub-message-trash-button" onClick={(e) => deleteReplyMessageData(e, item.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                                      <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z" fill="#3e3e3e"></path>
                                    </svg>
                                  </button>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    }) : ""}
                    { <div ref={messagesEndRef} />  }
                </div>
              </div>
              <div className="message-hereInfo">
                <div className="message-infobox">
                  <div className="user-profile">
                    <div className="initial">
                      {nameInitials}
                    </div>
                  </div>
                  <div className="user-detial-box">
                    <div className="item">
                      <h4 className="name">You <span>{getUserInfo()?.firstname+" "+getUserInfo()?.lastname}</span></h4>
                    </div>
                    <div className="item">
                      <div className="time-text">{createdTime(new Date())}</div>
                    </div>
                  </div>
                </div>
                <div className="message-replyInfo">
                  <form>
                    <Prompt
                      when={isBlocking}
                      // message={(location) => {
                      //   if (location.pathname !== history.location.pathname) {
                      //     return "Do you want to leave this page";
                      //   }
                      // }
                      // }
                      message={handleBlockedRoute}
                    />
                    <div className="form-group">
                      <label>Reply</label>
                      <textarea
                        placeholder={placeHolderText}
                        value={message ? message : ""}
                        onChange={(e) => { setMessage(e.target.value); e.target.value ? setIsBlocking(true) : setIsBlocking(false) }}
                      //disabled={ispremium === 1 ? false : ispremium === 0 ? true : ''}
                        disabled={currentAdvisor}
                      ></textarea>
                      {error && error.message ? (
                        <div className="error">
                          <p>{error.message}</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    { !currentAdvisor && <div className="form-group">
                      <button
                        type="button"
                        className="btn blue"
                        onClick={() => sendMessageBtn()}
                      //disabled={ispremium === 1 ? false : ispremium === 0 ? true : ''}
                      >Send</button>
                    </div> }
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={showDeleteModal}
              onClose={(event, reason)=>{
                if (reason == "backdropClick" || reason == "escapeKeyDown"){
                  deleteMessageData()
                }
              }}
      >
        {msgReplyId ? <DeleteItem
          onDeleteClick={(e) => onReplyDeleteHandler(e)}
          onCancelClick={deleteMessageData}
        /> : <DeleteItem
          onDeleteClick={(e) => onDeleteHandler(e)}
          onCancelClick={deleteMessageData}
        />}
      </Dialog>
      <Dialog open={openPremiumModal}
              onClose={(event, reason)=>{
                if (reason == "backdropClick" || reason == "escapeKeyDown"){
                  cancelBtnClick()
                }
              }}
      >
        <PremiumModal
          onActivateClick={(e) => onActivateBtn(e)}
          onCancelClick={cancelBtnClick}
        />
      </Dialog>
    </div>
  );
};

export default AdvisorReplyMessage;