const ENV = {
  WEB_URI: "https://devapi.sfrglobal.com",
  WEB_URI_GRAPHQL: "https://devapi.sfrglobal.com/graphql",
  DOWNLOAD_URL: "https://cdn.sfrglobal.com",
  UPLOAD_URL: "https://devapi.sfrglobal.com/api/S3Containers/staging.storage.sportsforce/upload",
  apiKey: process.env.REACT_APP_SECRET_KEY,
  mapApiJs: "https://maps.googleapis.com/maps/api/js",
 WEB_ATHLETE_URL : "https://dev.sfrglobal.com"
};

export default ENV;
