import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import { Provider } from "react-redux";
import {createStore} from 'redux'
import {masterReducer} from './redux/rootReducers'
import history from './history'
import {Router} from 'react-router-dom'
const store = createStore(masterReducer)
ReactDOM.render(
  <Provider store={store}>
  <React.StrictMode>
    <Router history={history}>
    <App />
    </Router>
  </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
