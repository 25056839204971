import React from "react";
import { getUserInfo } from "../../../utils/constant";
const AccountSettingLeftNavigation = () => {
  return (
    <div className="account-sidebar">
      <ul>
        {getUserInfo() && getUserInfo().role == "COLLEGECOUCHES" ? (
        <li  className={`${
          window.location.hash == "#/myAccount" ? "active " : ""
        }`}>
          <a href="#/myAccount">
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="19"
                height="17"
                viewBox="0 0 19 17"
              >
                <g
                  id="Mask_Group_32"
                  data-name="Mask Group 32"
                  transform="translate(-118.154 -72.924)"
                  clipPath="url(#clip-path)"
                >
                  <g
                    id="person-add-outline"
                    transform="translate(119.504 73.578)"
                  >
                    <path
                      id="Path_375"
                      data-name="Path 375"
                      d="M98.978,27.333c.143,1.954,1.607,3.547,3.213,3.547s3.073-1.593,3.213-3.547a3.229,3.229,0,1,0-6.426,0Z"
                      transform="translate(-95.18 -23.786)"
                      fill="none"
                      stroke="#333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_376"
                      data-name="Path 376"
                      d="M54.577,150.643c3.177,0,6.4,1.774,7,5.121a.619.619,0,0,1-.571.791H48.151a.619.619,0,0,1-.571-.791C48.176,152.416,51.4,150.643,54.577,150.643Z"
                      transform="translate(-47.566 -141.184)"
                      fill="none"
                      stroke="#333"
                      strokeMiterlimit="10"
                      strokeWidth="1"
                    />
                  </g>
                </g>
              </svg>
            </span>
            My Account
          </a>
        </li>
        ) : null}
        <li
          className={`${
            window.location.hash == "/notification-settings" ? "active " : ""
          }`}
        >
          <a 
          href="/notification-settings"
          >
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.678"
                height="19.469"
                viewBox="0 0 16.678 19.469"
              >
                <path
                  id="notifications-outline"
                  d="M95.282,61.472c-1.14-1.4-1.945-2.106-1.945-5.953,0-3.523-1.8-4.778-3.28-5.388a.791.791,0,0,1-.442-.468,1.98,1.98,0,0,0-3.909,0,.783.783,0,0,1-.442.468c-1.482.61-3.28,1.861-3.28,5.388,0,3.847-.807,4.557-1.947,5.953a.9.9,0,0,0,.768,1.446H94.519A.9.9,0,0,0,95.282,61.472ZM90.5,62.918v.71a2.841,2.841,0,1,1-5.683,0v-.71"
                  transform="translate(-79.32 -47.5)"
                  fill="none"
                  stroke="#333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </svg>
            </span>
            Notification Settings
          </a>
        </li>
        <li
          className={`${
            window.location.hash == "/passwordChange" ? "active " : ""
          }`}
        >
          <a href="/passwordChange">
            <span className="icon lock">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.961"
                height="18.773"
                viewBox="0 0 14.961 18.773"
              >
                <g
                  id="padlock_1_"
                  data-name="padlock (1)"
                  transform="translate(-433.592 -454.859)"
                >
                  <g
                    id="Group_691"
                    data-name="Group 691"
                    transform="translate(427.639 471.39)"
                  >
                    <g id="Group_690" data-name="Group 690">
                      <path
                        id="Path_373"
                        data-name="Path 373"
                        d="M64.761,6.894H63.439V3.878a3.961,3.961,0,0,0-7.92,0V6.894H54.2a2.2,2.2,0,0,0-2.2,2.2v7.48a2.2,2.2,0,0,0,2.2,2.2H64.761a2.2,2.2,0,0,0,2.2-2.2V9.094A2.2,2.2,0,0,0,64.761,6.894ZM56.986,3.878a2.495,2.495,0,0,1,4.987,0V6.894H56.986Zm8.508,12.7a.734.734,0,0,1-.733.733H54.2a.734.734,0,0,1-.733-.733V9.094A.734.734,0,0,1,54.2,8.36H64.761a.734.734,0,0,1,.733.733Z"
                        transform="translate(-46.047 -16.529)"
                        fill="#333"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_693"
                    data-name="Group 693"
                    transform="translate(435.859 485.467)"
                  >
                    <g id="Group_692" data-name="Group 692">
                      <path
                        id="Path_374"
                        data-name="Path 374"
                        d="M220.357,286a1.357,1.357,0,0,0-.735,2.5v1.61a.733.733,0,1,0,1.467,0V288.5a1.357,1.357,0,0,0-.732-2.5Z"
                        transform="translate(-215.143 -306.12)"
                        fill="#333"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            Change Password
          </a>
        </li>
      </ul>
    </div>
  );
};

export default AccountSettingLeftNavigation;