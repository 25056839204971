const initialState = {
    navState: true,
    navSelected: "",
    isReqCount: 0
}
const initialSettingState = {
    navSettingState: false,
    navSelected: ""
}
export const navStatus = (state= initialState, action) =>{
    switch (action.type){
        case "nav_change":
            return {
                navState: action?.payload?.status,
                navSelected: action?.payload?.selected,
                isReqCount: action?.payload?.count
            }
        default:
            return state
    }
}

export const navSettingStatus = (state= initialSettingState, action) =>{
    switch (action.type){
        case "nav_setting_change":
            return {
                navSettingState: action?.payload?.status,
                navSettingSelected: action?.payload?.selected
            }
        default:
            return state
    }
}