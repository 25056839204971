export const getAdvisorDetailsQuery = `
query allAdvisors($obj:JSON)
{
  allAdvisors(where:$obj)
  {
    Advisors
    {
      id
      acive
      emailid
      firstname
      lastname
      phonenumber
      countrycodeid
    }
  }
}
`;

export const saveAdvisorDetailQuery = `
mutation saveAdvisor($obj:AdvisorInput!){
  saveAdvisor(obj:$obj){
    id
  }
}
`;
export const getAdvisorAthleteDetailsQuery = `
query allAdvisorathletemappings($where:JSON, $first: Int, $last:Int)
{
  allAdvisorathletemappings(where:$where, first: $first , last: $last)
  {
  totalCount
    Advisorathletemappings
    {
       active
      advisorathletemappingathleteidmaprel{
      Athletes{
       active
        athletefirstname
        athletelastname
        graduationmonthvalue
        athleteathletesportsmaprel{
          Athletesports{
            sportname
          }
        }
        athleteathletegraduationyearmaprel{
          Graduationyears{
            yearvalue
          }
        }
      }
    }
    }
  }
}
`;