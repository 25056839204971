import React, { useEffect, useState } from 'react'
import './homepage.css';
import { AdminHeader } from '../header/adminHeader';
import '../header/assets/css/stylesheet.css'
import '../header/assets/css/dashboard.scss'
import {useDispatch, useSelector} from "react-redux";
import { Dialog } from "@material-ui/core";
import Pagination from 'react-js-pagination'
import toastr from 'toastr';
import services from "../../services";
import { getUserInfo } from "../../../src/utils/constant"
import { AdminFooter } from "../header/adminFooter";

const HomePage = (props) => {
  const store = useSelector(store => store),
        dispatch = useDispatch()

  const [fields, setFields] = useState({
    //visitorCount:0,
    athleteCount: 0,
    advisorCount: 0,
    collegeCount: 0,
    coachCount: 0,
    athletePercentage: "",
    advisorPercentage: "",
    collegePercentage: "",
    coachPercentage: "",
    visitorPercentage: "",
    totalVisitorsCount: 0
  })
  //[totalCount, setTotalCount] = useState(0)
  useEffect(() => {
    dashboardDataCount()
    onPercentage()
    dispatch({type:"nav_change", payload:{ status: true}})
  }, [])

  const dashboardDataCount = async () => {
    const res = await services.location.dashboardData()
    if (res?.status == 200 && res?.data?.data) {
      let fieldsValue = fields;
      //fieldsValue["visitorCount"] = res?.data?.data?.allVisitors?.Visitors?.[0]?.totalvisitorcount || 0;
      //fieldsValue["athleteCount"] = res?.data?.data?.allAthletes?.totalCount || 0;
      fieldsValue["advisorCount"] = res?.data?.data?.allAdvisors?.totalCount || 0;
      fieldsValue["collegeCount"] = res?.data?.data?.allColleges?.totalCount || 0;
      fieldsValue["coachCount"] = res?.data?.data?.allCoachandteamdetails?.totalCount || 0;
      setFields({ ...fieldsValue })
    }
    else {
      let fieldsValue = fields;
      //fieldsValue["visitorCount"] = 0;
      //fieldsValue["athleteCount"] = 0;
      fieldsValue["advisorCount"] = 0;
      fieldsValue["collegeCount"] = 0;
      fieldsValue["coachCount"] = 0;
      setFields({ ...fieldsValue })
    }
  }


  const onPercentage = async () => {
    const res = await services.location.getPercentage();
    if (res?.status == 200 && res?.data?.response) {
      let fieldsValue = fields;
      fieldsValue["athletePercentage"] = res.data.response.data.athletePercent || "";
      fieldsValue["advisorPercentage"] = res.data.response.data.advisorPercent || "";
      fieldsValue["collegePercentage"] = res.data.response.data.collegePercent || "";
      fieldsValue["coachPercentage"] = res.data.response.data.coachPercent || "";
      fieldsValue["visitorPercentage"] = res.data.response.data.visitorPercent || "";
      fieldsValue["totalVisitorsCount"] = res.data.response.data.totalVisitors || 0;
      fieldsValue["athleteCount"] = res.data.response.data.TotalAthletes || 0;
      setFields({ ...fieldsValue })
    }
    else {
      let fieldsValue = fields;
      fieldsValue["athletePercentage"] = "";
      fieldsValue["advisorPercentage"] = "";
      fieldsValue["collegePercentage"] = "";
      fieldsValue["coachPercentage"] = "";
      fieldsValue["visitorPercentage"] = "";
      fieldsValue["totalVisitorsCount"] = 0;
      fieldsValue["athleteCount"] = 0;
      setFields({ ...fieldsValue })
    }
  }
  return (
    <>
      {/*<div className={store?.navStatus?.navState ?  'main' : "main sidebar-hidden"}>*/}
      <div className={'content'}>
        <AdminHeader
          title={"Dashboard"}
        />
        <div className="dashboard" style={{ height: "100%" }}>
          <div className="card-listing">
            <div className={(fields?.visitorPercentage < 0) ? "cart-item red" : "cart-item"}>
              <div className="cart-box">
                <div className="info-box">
                  <div className="detail-box">
                    <div className="icon-box">
                      <svg xmlns="http://www.w3.org/2000/svg" width="47.931" height="40.742" viewBox="0 0 47.931 40.742">
                        <g id="user-group" transform="translate(0 -29.114)">
                          <path id="Path_2329" data-name="Path 2329" d="M135.878,49.04c4.53,0,8.2-4.461,8.2-9.963s-1.206-9.963-8.2-9.963-8.2,4.461-8.2,9.963S131.348,49.04,135.878,49.04Z" transform="translate(-111.912 0)" fill="#3e3e3e" />
                          <path id="Path_2330" data-name="Path 2330" d="M99.6,210.742c-.152-9.585-1.4-12.317-10.983-14.046a6.73,6.73,0,0,1-8.983,0c-9.475,1.71-10.8,4.4-10.978,13.734-.014.762-.021.8-.023.714,0,.166,0,.472,0,1.007,0,0,2.281,4.6,15.491,4.6s15.492-4.6,15.492-4.6c0-.343,0-.583,0-.745A6.03,6.03,0,0,1,99.6,210.742Z" transform="translate(-60.158 -146.892)" fill="#3e3e3e" />
                          <path id="Path_2331" data-name="Path 2331" d="M264.733,61.2c3.679,0,6.662-3.623,6.662-8.092s-.979-8.092-6.662-8.092a9.5,9.5,0,0,0-2.486.295,16.33,16.33,0,0,1,1.492,7.7,13.662,13.662,0,0,1-2.046,7.292A5.706,5.706,0,0,0,264.733,61.2Z" transform="translate(-229.385 -13.94)" fill="#3e3e3e" />
                          <path id="Path_2332" data-name="Path 2332" d="M296.689,192.532c-.123-7.785-1.14-10-8.92-11.407a4.664,4.664,0,0,1-3.648,1.4c-.106,0-.208,0-.309-.008A10.235,10.235,0,0,1,288,185.752c1.878,2.576,2.31,6.036,2.4,11.119,5.234-1.034,6.307-3.194,6.307-3.194,0-.281,0-.475,0-.607A4.642,4.642,0,0,1,296.689,192.532Z" transform="translate(-248.772 -133.243)" fill="#3e3e3e" />
                          <path id="Path_2333" data-name="Path 2333" d="M54.617,61.2a5.709,5.709,0,0,0,3.04-.892,13.662,13.662,0,0,1-2.046-7.292,16.333,16.333,0,0,1,1.492-7.7,9.5,9.5,0,0,0-2.486-.295c-5.683,0-6.662,3.623-6.662,8.092S50.938,61.2,54.617,61.2Z" transform="translate(-42.034 -13.941)" fill="#3e3e3e" />
                          <path id="Path_2334" data-name="Path 2334" d="M12.891,182.512c-.1,0-.2.008-.309.008a4.664,4.664,0,0,1-3.648-1.4c-7.78,1.4-8.8,3.622-8.92,11.407A4.723,4.723,0,0,1,0,193.07c0,.132,0,.325,0,.607,0,0,1.073,2.16,6.307,3.194.09-5.083.521-8.543,2.4-11.119A10.235,10.235,0,0,1,12.891,182.512Z" transform="translate(0 -133.242)" fill="#3e3e3e" />
                        </g>
                      </svg>
                    </div>
                    <div className="detail-text">
                      <h3 className="title">Visitors</h3>
                      <h4 className="count-text">{fields?.totalVisitorsCount.toLocaleString()}</h4>
                    </div>
                  </div>
                  <div className="percentage-text">
                    {/*{`${fields?.visitorPercentage < 0 ? "" : fields?.visitorPercentage == 0 ? "" : "+"} ${fields?.visitorPercentage}`}*/}
                  </div>
                </div>
                <div className="card-link">
                  {/*<span className="link-text">View more</span>*/}
                </div>
              </div>
            </div>
            <div className={((fields?.athletePercentage) < 0) ? "cart-item red" : "cart-item"}>
              <div className="cart-box">
                <div className="info-box">
                  <div className="detail-box">
                    <div className="icon-box">
                      <svg xmlns="http://www.w3.org/2000/svg" width="48.435" height="47.139" viewBox="0 0 48.435 47.139">
                        <g id="athlete" transform="translate(0 -3.976)">
                          <path id="Path_2339" data-name="Path 2339" d="M75.97,66.021H98.134a.892.892,0,0,0,.892-.892V63.392a.892.892,0,0,0-.892-.892H75.97a.892.892,0,0,0-.892.892v1.737A.892.892,0,0,0,75.97,66.021Z" transform="translate(-62.834 -48.98)" fill="#3e3e3e" />
                          <path id="Path_2340" data-name="Path 2340" d="M105.734,174.232l3.766,1.255,3.74-1.247a5.583,5.583,0,0,0,3.822-5.3v-4.983a2.68,2.68,0,0,1-.77-1.876v-3.331c0-.023,0-.045-.005-.068a12.473,12.473,0,0,1-1.432,1.113l-1.6,1.068a6.753,6.753,0,0,1-7.51,0l-1.6-1.068a12.488,12.488,0,0,1-1.432-1.113c0,.023-.005.045-.005.068v3.331a2.68,2.68,0,0,1-.806,1.911l.011,4.973A5.587,5.587,0,0,0,105.734,174.232Z" transform="translate(-85.282 -129.474)" fill="#3e3e3e" />
                          <path id="Path_2341" data-name="Path 2341" d="M92.187,99.265a10.269,10.269,0,0,0,4.4,6.574l1.6,1.068a4.788,4.788,0,0,0,5.325,0l1.6-1.068a10.269,10.269,0,0,0,4.4-6.574l.619-3.094H91.568Z" transform="translate(-76.635 -77.16)" fill="#3e3e3e" />
                          <path id="Path_2342" data-name="Path 2342" d="M93.253,197.459l-3.51-.7v4.049a7.879,7.879,0,0,1-5.394,7.484l-4.1,1.368a1.149,1.149,0,0,1-.727,0l-4.129-1.377A7.885,7.885,0,0,1,70,200.838l-.009-4.074-3.475.695v15H93.253Z" transform="translate(-55.664 -161.342)" fill="#3e3e3e" />
                          <path id="Path_2343" data-name="Path 2343" d="M99.026,11.55A3.338,3.338,0,0,0,95.694,8.3a3.373,3.373,0,0,0-.372.021.983.983,0,0,1-1-.564c-1.05-2.26-3.971-3.778-7.267-3.778s-6.217,1.518-7.267,3.778a.983.983,0,0,1-1,.564A3.374,3.374,0,0,0,78.41,8.3a3.338,3.338,0,0,0-3.332,3.253Z" transform="translate(-62.834)" fill="#3e3e3e" />
                          <path id="Path_2344" data-name="Path 2344" d="M253.1,218.174l-1.2-4.813a12.13,12.13,0,0,0-7.315-8.309V219.4h7.563a.985.985,0,0,0,.956-1.224Z" transform="translate(-204.697 -168.283)" fill="#3e3e3e" />
                          <path id="Path_2345" data-name="Path 2345" d="M8.548,205.051a12.13,12.13,0,0,0-7.315,8.309l-1.2,4.813A.985.985,0,0,0,.985,219.4H8.548Z" transform="translate(0 -168.283)" fill="#3e3e3e" />
                        </g>
                      </svg>


                    </div>
                    <div className="detail-text">
                      <h3 className="title">Athlete</h3>
                      <h4 className="count-text">{fields?.athleteCount.toLocaleString()}</h4>
                    </div>
                  </div>
                  <div className="percentage-text">
                    {/*{`${fields?.athletePercentage < 0 ? "" : fields?.athletePercentage == 0 ? "" : "+"} ${fields?.athletePercentage}`}*/}
                  </div>
                </div>
                <div className="card-link">
                  {/*<span className="link-text">View more</span>*/}
                </div>
              </div>
            </div>
            <div className={(fields?.advisorPercentage < 0) ? "cart-item red" : "cart-item"}>
              <div className="cart-box">
                <div className="info-box">
                  <div className="detail-box">
                    <div className="icon-box">
                      <svg id="consultant" xmlns="http://www.w3.org/2000/svg" width="48.095" height="48.095" viewBox="0 0 48.095 48.095">
                        <ellipse id="Ellipse_11" data-name="Ellipse 11" cx="6" cy="5" rx="6" ry="5" transform="translate(7.047 26.047)" fill="#3e3e3e" />
                        <path id="Path_2318" data-name="Path 2318" d="M31,401.863a1.408,1.408,0,0,0,1.409,1.409H49.317a1.408,1.408,0,0,0,1.409-1.409,9.863,9.863,0,1,0-19.726,0Z" transform="translate(-28.088 -355.177)" fill="#3e3e3e" />
                        <ellipse id="Ellipse_12" data-name="Ellipse 12" cx="6" cy="5" rx="6" ry="5" transform="translate(29.047 26.047)" fill="#3e3e3e" />
                        <path id="Path_2319" data-name="Path 2319" d="M271,401.863a1.408,1.408,0,0,0,1.409,1.409h16.908a1.408,1.408,0,0,0,1.409-1.409,9.863,9.863,0,1,0-19.726,0Z" transform="translate(-245.544 -355.177)" fill="#3e3e3e" />
                        <path id="Path_2320" data-name="Path 2320" d="M43.868,0H4.227A4.232,4.232,0,0,0,0,4.227V15.5a4.232,4.232,0,0,0,4.227,4.227H9.28l2.405,2.405a1.409,1.409,0,0,0,1.992,0l2.405-2.405H32.012l2.405,2.405a1.408,1.408,0,0,0,1.992,0l2.405-2.405h5.053A4.232,4.232,0,0,0,48.095,15.5V4.227A4.232,4.232,0,0,0,43.868,0ZM18.317,14.09H7.045a1.409,1.409,0,0,1,0-2.818H18.317a1.409,1.409,0,1,1,0,2.818Zm0-5.636H7.045a1.409,1.409,0,0,1,0-2.818H18.317a1.409,1.409,0,0,1,0,2.818ZM41.05,14.09H29.777a1.409,1.409,0,0,1,0-2.818H41.05a1.409,1.409,0,1,1,0,2.818Zm0-5.636H29.777a1.409,1.409,0,0,1,0-2.818H41.05a1.409,1.409,0,1,1,0,2.818Z" fill="#3e3e3e" />
                      </svg>
                    </div>
                    <div className="detail-text">
                      <h3 className="title">Advisor</h3>
                      <h4 className="count-text">{fields?.advisorCount.toLocaleString()}</h4>
                    </div>
                  </div>
                  <div className="percentage-text">
                    {/*{`${fields?.advisorPercentage < 0 ? "" : fields?.advisorPercentage == 0 ? "" : "+"} ${fields?.advisorPercentage}`}*/}
                  </div>
                </div>
                <div className="card-link">
                  {/*<span className="link-text">View more</span>*/}
                </div>
              </div>
            </div>
            <div className={(fields?.collegePercentage < 0) ? "cart-item red" : "cart-item"}>
              <div className="cart-box">
                <div className="info-box">
                  <div className="detail-box">
                    <div className="icon-box">
                      <svg xmlns="http://www.w3.org/2000/svg" width="47.869" height="47.869" viewBox="0 0 47.869 47.869">
                        <g id="Layer_2" data-name="Layer 2" transform="translate(-2 -2)">
                          <path id="Path_2335" data-name="Path 2335" d="M27.837,16h-1.71V38.225a4.739,4.739,0,0,1-.308,1.71h3.727a3.429,3.429,0,0,0,3.419-3.419V21.129A5.144,5.144,0,0,0,27.837,16Z" transform="translate(16.903 9.935)" fill="#3e3e3e" />
                          <path id="Path_2336" data-name="Path 2336" d="M8.838,16H7.129A5.144,5.144,0,0,0,2,21.129V36.515a3.429,3.429,0,0,0,3.419,3.419H9.146a4.739,4.739,0,0,1-.308-1.71Z" transform="translate(0 9.935)" fill="#3e3e3e" />
                          <path id="Path_2337" data-name="Path 2337" d="M17.419,21A3.419,3.419,0,0,0,14,24.419V36.387h6.838V24.419A3.419,3.419,0,0,0,17.419,21Z" transform="translate(8.515 13.483)" fill="#3e3e3e" />
                          <path id="Path_2338" data-name="Path 2338" d="M23.387,8.052V5.419H25.1A1.71,1.71,0,0,0,25.1,2H21.677a1.71,1.71,0,0,0-1.71,1.71V8.052L9.792,16.771A5.129,5.129,0,0,0,8,20.669V48.16a1.71,1.71,0,0,0,1.71,1.71h5.129V38.206A7.044,7.044,0,0,1,20.993,31.1a6.838,6.838,0,0,1,7.522,6.8V49.869h5.129a1.71,1.71,0,0,0,1.71-1.71V20.669a5.129,5.129,0,0,0-1.792-3.894Zm0,14.463H19.967a1.71,1.71,0,0,1,0-3.419h3.419a1.71,1.71,0,1,1,0,3.419Z" transform="translate(4.258)" fill="#3e3e3e" />
                        </g>
                      </svg>
                    </div>
                    <div className="detail-text">
                      <h3 className="title">College</h3>
                      <h4 className="count-text">{fields?.collegeCount.toLocaleString()}</h4>
                    </div>
                  </div>
                  <div className="percentage-text">
                    {/*{`${fields?.collegePercentage < 0 ? "" : fields?.collegePercentage == 0 ? "" : "+"} ${fields?.collegePercentage}`}*/}
                  </div>
                </div>
                <div className="card-link">
                  {/*<span className="link-text">View more</span>*/}
                </div>
              </div>
            </div>
            <div className={(fields?.coachPercentage < 0) ? "cart-item red" : "cart-item"}>
              <div className="cart-box">
                <div className="info-box">
                  <div className="detail-box">
                    <div className="icon-box">
                      <svg xmlns="http://www.w3.org/2000/svg" width="42.272" height="48.096" viewBox="0 0 42.272 48.096">
                        <g id="trainer" transform="translate(-31)">
                          <path id="Path_2321" data-name="Path 2321" d="M63.408,271H60.59v1.393a7.069,7.069,0,0,1-7.061,7.061H52.136a4.232,4.232,0,0,1,4.227,4.227v5.636a1.409,1.409,0,1,1-2.818,0v-1.651a4.227,4.227,0,1,1-1.409-8.212H50.742a7.069,7.069,0,0,1-7.061-7.061V271H40.863A9.875,9.875,0,0,0,31,280.863v4.227h9.863a1.409,1.409,0,0,1,1.409,1.409v7.139H62V286.5a1.409,1.409,0,0,1,1.409-1.409h9.863v-4.227A9.875,9.875,0,0,0,63.408,271Z" transform="translate(0 -245.543)" fill="#3e3e3e" />
                          <path id="Path_2322" data-name="Path 2322" d="M391,456.73h7.045a1.409,1.409,0,0,0,1.409-1.409V451H391Z" transform="translate(-326.183 -408.634)" fill="#3e3e3e" />
                          <path id="Path_2323" data-name="Path 2323" d="M31,455.321a1.409,1.409,0,0,0,1.409,1.409h7.045V451H31Z" transform="translate(0 -408.634)" fill="#3e3e3e" />
                          <path id="Path_2324" data-name="Path 2324" d="M242.409,393.818A1.409,1.409,0,1,0,241,392.409,1.411,1.411,0,0,0,242.409,393.818Z" transform="translate(-190.273 -354.271)" fill="#3e3e3e" />
                          <path id="Path_2325" data-name="Path 2325" d="M196.028,286.923a4.247,4.247,0,0,0,4.24,4.14h2.787a4.247,4.247,0,0,0,4.24-4.14A12.656,12.656,0,0,1,196.028,286.923Z" transform="translate(-149.526 -259.97)" fill="#3e3e3e" />
                          <path id="Path_2326" data-name="Path 2326" d="M209.1,123.568,210.728,121H200.255l1.626,2.568A14.322,14.322,0,0,1,209.1,123.568Z" transform="translate(-153.356 -109.634)" fill="#3e3e3e" />
                          <path id="Path_2327" data-name="Path 2327" d="M170.727,7.045A7.053,7.053,0,0,0,163.681,0h-5.636A7.053,7.053,0,0,0,151,7.045v1.5h19.727Z" transform="translate(-108.728)" fill="#3e3e3e" />
                          <path id="Path_2328" data-name="Path 2328" d="M160.863,135.091a9.875,9.875,0,0,0,9.863-9.863V121h-1.291l-3.155,4.981a1.409,1.409,0,0,1-1.636.583,11.469,11.469,0,0,0-7.563,0,1.409,1.409,0,0,1-1.636-.583L152.291,121H151v4.227A9.875,9.875,0,0,0,160.863,135.091Z" transform="translate(-108.728 -109.634)" fill="#3e3e3e" />
                        </g>
                      </svg>

                    </div>
                    <div className="detail-text">
                      <h3 className="title">College Coach</h3>
                      <h4 className="count-text">{fields?.coachCount.toLocaleString()}</h4>
                    </div>
                  </div>
                  <div className="percentage-text">
                    {/*{`${fields?.coachPercentage < 0 ? "" : fields?.coachPercentage == 0 ? "" : "+"} ${fields?.coachPercentage}`}*/}
                  </div>
                </div>
                <div className="card-link">
                  {/*<span className="link-text">View more</span>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
      {/*</div>*/}
    </>
  );
};

export default HomePage;
