import React from "react";
import { useState, useEffect } from "react";
import "./css/notification.css";
import { Menu, MenuItem, Button } from "@material-ui/core";
import { useHistory, Prompt, useLocation } from "react-router-dom";
import messagePopup from "../../../utils/messagePopup";
import { AdminHeader } from '../../header/adminHeader';
import BackdropLoader from "../../common/Loader";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import services from "../../../../src/services";
import toastr from 'toastr';
import swal from "sweetalert";
const ITEM_HEIGHT = 48;

const SupportAdvisorComposeMessage = (props) => {
  const location = useLocation();
  let history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [anchorE1, setAnchorE1] = React.useState(null);
  const store = useSelector(store => store);
  const open = Boolean(anchorE1),
    [message, setMessage] = useState(undefined),
    [sendMsg, setMsg] = useState(true),
    [loading, setLoading] = useState(false),
    [isBlocking, setIsBlocking] = useState(false),
    [allAdvisors, setAllAdvisors] = useState([]),
    [selectedAdvisors, setSelectedAdvisors] = useState([]);

    const showModal = async(nextLocation) => {
      const willSave = await swal({
          text: "Changes you made, may not be saved",
          icon: 'warning',
          buttons: {
              confirm: { text: "Stay" },
              danger: "Leave",
          },
      })
      if(willSave === "danger"){
          setConfirmedNavigation(true);
      }
      setLastLocation(nextLocation);
    };
  
    const handleBlockedRoute = (nextLocation) => {
      if (!confirmedNavigation && nextLocation.pathname !== history.location.pathname) {
        showModal(nextLocation);
        return false;
      }
      return true;
    };
  
    useEffect(() => {
      if (confirmedNavigation && lastLocation) {
        history.push(lastLocation.pathname);
      }
    }, [confirmedNavigation, lastLocation, history]);  

  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };

  const handleAtheleteMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/athlete/composeMessage" });
  }
  const handleAdvisorMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/support/advisor/composeMessage" });
  }

  const sendClickBtn = async (e) => {
    e.preventDefault();
    setIsBlocking(true);
    let messageId = "";
    if (selectedAdvisors.length) {
      if (message) {
        let userIds = [];
        let advisorIds = [];
        selectedAdvisors.map(item => {
          userIds.push(item.userid)
          advisorIds.push(item.id)
        })

        await services.location.sendMessageToAnyRole({
          msgsenderrole: "ADMIN",
          msgrecieverrole: "ADVISORS",
          msgsenderid: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id),
          msg: message.replace(/"/g, ""),
          userids: userIds,
          parentid: 0
        }).then(async (messageRes) => {
          setIsBlocking(false);
          if (messageRes?.data?.response?.status == 200) {
            await services.location.sendNotificationToAdvisor({
              userId: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id),
              title: "messagefromteam",
              role: JSON.parse(localStorage.getItem('user_Admin_Advisor')).role,
              msgrecieverids: advisorIds
            }).then(async (notificationRes) => {})

            toastr.success("Message has been sent successfully").css({"width":"auto"})
            history.push('/message/advisor');
          } else {
            messagePopup("", "Message has not been sent. Try agai!", "error");
          }
        })
      }
      else {
        messagePopup("", "Please enter message", "warning")
      }
    }
    else {
      messagePopup("", "Please specify recipient", "warning")
    }
  }

  const handleAllAthleteChange = async (values) => {
    let searchText = values.target.value;
    if (searchText) {
      searchText = "%" + `${searchText}` + "%";
      const variables = {
        where: {
          active: 1,
          and: [
            {
              or: [
                { firstname: { like: searchText, options: "i" } },
                { lastname: { like: searchText, options: "i" } },
              ],
            },
          ],
        },
      };
      await services.location.getAllAdvisor(variables).then(res => {
        if (res?.status == 200) {
          setAllAdvisors(res?.data?.data?.allAdvisors?.Advisors)
        }
      })
    }
  }

  const changeSelectedAthlete = async (values) => {
    let selVal = values?.target?.value?.length ? values?.target?.value : [];
    setSelectedAdvisors(selVal)
  }

  return (
    <>
      <BackdropLoader open={loading} />
      <div className="content">
        <AdminHeader
          title={"Message"}
        />
        <div className="notification-with-sidebar">
          <nav className="notification-sidebar">
            <ul>
              <li className="button-link dropdown">
                <Button
                  className="btn lightblue dropdown-toggle"
                  onClick={e => handleClick(e)}
                >
                  Compose
                </Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button"
                  }}
                  anchorEl={anchorE1}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch"
                    }
                  }}
                >
                  <MenuItem>
                    <strong>Compose for</strong>
                  </MenuItem>
                  <MenuItem onClick={handleAtheleteMenu}>Athlete</MenuItem>
                  <MenuItem onClick={handleAdvisorMenu}>Advisor</MenuItem>
                  {/* <MenuItem onClick={handleCoachMenu}>Coach</MenuItem> */}
                </Menu>
              </li>
              <li
                className={window.location.href.includes('/athlete') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/athlete" })}
              >
                <div className="link-item">
                  <span>Athlete</span> <span>{(parseInt(store?.adminMessages?.athleteUnreadMessages)) ? (parseInt(store?.adminMessages?.athleteUnreadMessages)) : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/advisor') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor" })}
              >
                <div className="link-item">
                  <span>Advisor</span><span>{(parseInt(store?.adminMessages?.advisiorUnreadMessages)) ? (parseInt(store?.adminMessages?.advisiorUnreadMessages)) : ""}</span>
                </div>
              </li>
              {/* <li onClick={() => history.push({ pathname: "/message/coach" })}>
                <div className="link-item">
                  <span>Coaches</span> <span>{(parseInt(store?.adminMessages?.coachesUnreadMessages)) ? (parseInt(store?.adminMessages?.coachesUnreadMessages)) : ""}</span>
                </div>
              </li> */}
              <li
                className={window.location.href.includes('/archive') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/archive" })}
              >
                <div className="link-item">
                  <span>Archive</span><span>{(parseInt(store?.adminMessages?.archiveUnreadMessages)) ? (parseInt(store?.adminMessages?.archiveUnreadMessages)) : ""}</span>
                </div>
              </li>
            </ul>
          </nav>
          <div className="notification-detail-box">
            <div className="notification-search-box">
              <div className="search-box">
                <h1>New Message</h1>
              </div>
            </div>
            <div className="notification-messageDetail">
              <div className="message-hereInfo newMessage">
                <div className="message-replyInfo">
                  <form>
                    <Prompt
                      when={isBlocking}
                      // message={(location) => {
                      //   if (location.pathname !== history.location.pathname) {
                      //     return "Do you want to leave this page";
                      //   }
                      // }
                      // }
                      message={handleBlockedRoute}
                    />
                    <div className="form-group flexbox d-block">
                      <label>
                        Select Advisor
                      </label>
                      <div className="autocomplete-class">
                        <Autocomplete
                          id="combo-box-demo"
                          multiple={true}
                          freeSolo={true}
                          options={allAdvisors}
                          getOptionLabel={(option) => {
                            return option.firstname ? option.firstname + " " + option.lastname : option.toString()
                          }
                          }
                          onChange={(e, data) => {
                            changeSelectedAthlete({
                              target: { value: data ? data : "" },
                            });
                          }}
                          value={selectedAdvisors || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Enter Advisor Name"
                              value={selectedAdvisors || ""}
                              onChange={(e) => {
                                handleAllAthleteChange({
                                  target: {
                                    value: e.target.value ? e.target.value : "",
                                  },
                                });
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {/*
                    <div className="form-group flexbox">
                      <label>To:</label>
                      {selectedAdvisors.map(item => <span className="toName">{item.emailid}</span>)}
                    </div>
                    <div className="form-group flexbox">
                      <label>Subject:</label>
                      <input
                        type="text"
                        value={subject ? subject : ""}
                        disabled={!sendMsg ? true : false}
                        onChange={(e) => { setSubject(e.target.value); setIsBlocking(true); }} />
                    </div> */}
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        placeholder="Enter Your message here..."
                        value={message ? message : ""}
                        disabled={!sendMsg ? true : false}
                        onChange={(e) => { setMessage(e.target.value); setIsBlocking(true); }}>
                      </textarea>
                    </div>
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn blue"
                        disabled={!sendMsg ? true : false}
                        onClick={(e) => { sendClickBtn(e) }}>Send</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportAdvisorComposeMessage;