import React from "react";
import { compose, withProps, withHandlers } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import ENV from "../../../config";

const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL: `${ENV.mapApiJs}?key=${ENV.apiKey}&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `200px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withHandlers({
    onMarkerClustererClick: () => (markerClusterer) => {
      const clickedMarkers = markerClusterer.getMarkers();
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={12}
    // defaultCenter={{ lat: props.markers[0].latitude, lng: props.markers[0].longitude }}
    // defaultCenter={{ lat: 41.850033, lng: -87.6500523 }}
    center={{
      lat: props.markers[0] ? props.markers[0].latitude : 41.850033,
      lng: props.markers[0] ? props.markers[0].longitude : -87.6500523,
    }}
  >
    <MarkerClusterer
      onClick={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      // gridSize={60}
    >
      {React.Children.toArray(
        props.markers.map((marker) => (
          <Marker
            key={marker.photo_id}
            position={{ lat: marker.latitude, lng: marker.longitude }}
          />
        ))
      )}
    </MarkerClusterer>
  </GoogleMap>
));

export default function Map(props) {
  const [markers, setMarkers] = React.useState([]);
  React.useEffect(() => {
    if (props.location && props.location.search) {
      const query = new URLSearchParams(props.location.search);
      const latitude = query.get("lat");
      const longitude = query.get("long");
      setMarkers([{ latitude: +latitude, longitude: +longitude }]);
    }
    if (props.markers) {
      setMarkers(props.markers);
    }
  }, []);
  return <MapWithAMarkerClusterer height={props.height} markers={markers} />;
}

// export default class Map extends React.PureComponent {

//   componentWillMount() {
//     this.setState({ markers: [] });
//   }

//   componentDidMount() {

//     // this.setState({
//     //   markers: [
//     //     { latitude: 29.6436, longitude: -82.3549 },
//     //     { latitude: 42.278, longitude: -83.7382 },
//     //     { latitude: 40.0067, longitude: -83.0305 },
//     //   ],
//     // });
//   }
//   render() {
//     return <MapWithAMarkerClusterer markers={this.state.markers} />;
//   }
// }
