import React, {useEffect, useState, useRef} from "react";
import '../header/assets/css/header.scss'
import collageBtn from "./assets/img/collapse.svg";
import notification from '../header/assets/img/notification.png'
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, withRouter} from "react-router-dom";
import services from "../../services";
import ENV from "../../config";
import userImg from "./Img/user.svg";
import { useLocation } from "react-router-dom";
import { getUserInfo } from "../../../src/utils/constant"
import { getInitial } from '../common/getInitial'
export const AdminHeader = withRouter(({title, isCommitReq, commitCount}) =>{
const location = useLocation();
const history = useHistory()
const { pathname } = location;
const splitRoute = pathname.split("/")
const splitPath = splitRoute?.[splitRoute?.length - 1];
const [totalCount, setTotalCount] = useState(0)
const dispatch = useDispatch(),
      store = useSelector(store => store),
      [isSearch, setIsSearch] = useState(false),
      [isMobSearch, setIsMobSearch] = useState(false),
      [searchAthleteData, setSearchAthleteData] = useState([]),
      [searchByAthleteName, setSearchByAthleteName] = useState(null),
      [searchMobByAthleteName, setSearchMobByAthleteName] = useState(null),
      [searchMobAthleteData, setSearchMobAthleteData] = useState([]),
      [isLog, setIsLog] = useState(false)
      const [DropDown, setDropDown] = useState(false);
      const [openModal, setopenModal] = React.useState(false);
      const handleOpenModal = () => setopenModal(true);
      const handleCloseModal = () => setopenModal(false);
      const domRef = useRef();
      const modalRef = useRef()
      const setOpenCloseDropDown = (e) => {
        setSearchByAthleteName(e.target.value);
        if (e.target.value.length > 2) {
          getAthleteList(e?.target?.value?.toUpperCase() || "");
          setDropDown(true);
        } else {
          setSearchAthleteData([])
          setDropDown(false);
        }
      };
    useEffect(()=>{
        checkNotification();
        getAllUnreadMessages();
    },[])
    const checkNotification = async() => {
        const body ={
            adminid: getUserInfo()?.id ?? 0
        }
        var res = await services.location.checkNewNotificationApi(body);
        if(res?.status == 200 && res?.data?.data){
            setTotalCount(res.data.data.allAdminnotifications.totalCount)
        }
        else{
        }
    }
    const getAllUnreadMessages = async() => {
        await services.location.getMessagesReplyTotalCount({where:{
            readbyotherrole:0,
            deletebyotherrole:0,
            archivebyotherrole:0,
            msgreceiveradminid:{neq:null},
            and:[{or:[{msgsenderid:{neq:null}}, {msgsendercoachid:{neq:null}}, {msgsenderadvisorid:{neq:null}}]}]
            //or:[{msgsenderid:{neq:null}}, {msgsendercoachid:{neq:null}}, {msgsenderadvisorid:{neq:null}}]
        }}).then(res => {
            if(res.status == "200"){
                dispatch({type:"All_UNREAD_MESSAGES", payload:{unreadCount: res?.data?.data?.allMessagereplies?.totalCount}})
            }
        });

        await services.location.getMessagesReplyTotalCount({
            where:{
                readbyotherrole:0,
                deletebyotherrole:0,
                archivebyotherrole:0,
                msgsenderadvisorid:{neq:null},
                msgreceiveradminid:{neq:null}
            }
        }).then(advisorRes => {
            if(advisorRes.status == "200"){
                dispatch({type:"ADVISOR_UNREAD_MESSAGES", payload:{unreadCount: advisorRes?.data?.data?.allMessagereplies?.totalCount}})
            }
        });
        
        await services.location.getMessagesReplyTotalCount({
            where:{
                readbyotherrole:0,
                deletebyotherrole:0,
                archivebyotherrole:0,
                msgsenderid:{neq:null},
                msgreceiveradminid:{neq:null}
            }
        }).then(athleteRes => {
            if(athleteRes.status == "200"){ 
                dispatch({type:"ATHLETE_UNREAD_MESSAGES", payload:{unreadCount: athleteRes?.data?.data?.allMessagereplies?.totalCount}})
            }
        });
        
        await services.location.getMessagesReplyTotalCount({
            where:{
                active:1,
                isread:0,
                istrash:1,
                msgsendercoachid:{neq:null},
                msgreceiveradminid:{neq:null}
            }
        }).then(coachesRes => {
            if(coachesRes.status == "200"){
                dispatch({type:"COACHES_UNREAD_MESSAGES", payload:{unreadCount: coachesRes?.data?.data?.allMessagereplies?.totalCount}})
            }
        });

        await services.location.getMessagesReplyTotalCount({
            where:{
                readbyotherrole:0,
                deletebyotherrole:0,
                archivebyotherrole:1,
                msgreceiveradminid:{neq:null},
                and:[{or:[{msgsenderid:{neq:null}}, {msgsenderadvisorid:{neq:null}}]}]
            }
        }).then(coachesRes => { 
            if(coachesRes.status == "200"){
                dispatch({type:"ARCHIVE_UNREAD_MESSAGES", payload:{unreadCount: coachesRes?.data?.data?.allMessagereplies?.totalCount}})
            }
        });
    }
    const markNotification = async() => {
        const body ={
            adminid: getUserInfo()?.id ?? 0
        }
        var res = await services.location.markNotificationApi(body);
        if(res?.status == 200 && res?.data?.data){
        }
        else{
        }
    }

    const getAthleteList = async (name) => {
        if (name.length > 0) {
            const _res = await services.location.searchAthlete({ athleteName: name, role: getUserInfo()?.role ?? "" });
            if (parseFloat(_res?.data?.response?.status) === 200) {
                setSearchAthleteData(_res?.data?.response?.data);
                setSearchMobAthleteData(_res?.data?.response?.data)
            } else {
                setSearchAthleteData([]);
                setSearchMobAthleteData([])
            }
        }
    },
    handalCloseDropDown = async () => {
        await setIsSearch(false);
        await setIsMobSearch(false)
        await setSearchByAthleteName("");
        await setSearchMobByAthleteName("")
    },
    handleSearch = (e) => {
        setSearchByAthleteName(e.target.value);
        setSearchMobByAthleteName(e.target.value)
        if (e.target.value.length > 2) {
            getAthleteList(e?.target?.value?.toUpperCase() || "");
            setIsSearch(true);
            setIsMobSearch(true)
        } else {
            setSearchAthleteData([])
            setSearchMobAthleteData([])
            setIsSearch(false);
            setIsMobSearch(false)
        }
    },
    profileViewApi = async (athlete) => {
        localStorage.setItem("pubViewID", athlete.id || 0);
        const _res = await services.location.userProfileApi(
            {
                obj: {
                    userrole: getUserInfo()?.role ?? "Admin",
                    profileviewedbyadvisorid: JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.id ?? 0,
                    profileviewto: +athlete?.id || 0,
                },
            });
    },
    getNameInitial = (data) => {
            const Name = data?.athleteFullName?.trim()?.split(" ")
            return `${Name?.[0]?.split("")?.[0]?.toUpperCase() ?? ""}${Name?.[Name?.length - 1]?.split("")?.[0]?.toUpperCase() ?? ""}`
        },
    logOut = () =>{
    const isRememeber = localStorage?.getItem('rememberMe')
        if (isRememeber){
            localStorage?.removeItem('user_Admin_Advisor')
            localStorage?.removeItem('loginType')
            localStorage?.removeItem('authToken_Admin_Advisor')
            history?.push('/')
        }else {
            history?.push('/')
            localStorage?.clear()
        }
    }
    useEffect(()=>{
        var w = window?.innerWidth
        if (w > 992){
            var handler = event =>{
                if (!domRef?.current?.contains(event?.target)){
                    setSearchAthleteData([])
                    setSearchByAthleteName("")
                    setIsSearch(false)
                }
            }
        }
        if (w <= 992){
            var handler = event =>{
                if (!modalRef?.current?.contains(event?.target)){
                    setSearchMobAthleteData([])
                    setSearchMobByAthleteName("")
                    setIsMobSearch(false)
                    setopenModal(false)
                }
            }
        }
        document?.addEventListener("mousedown", handler)
        return () =>{
            document?.removeEventListener("mousedown", handler)
        }
    })
    return(
        <>
        <nav className="navbar">
            <div className="pagetitle">
                <button
                    id="sidebarCollapse"
                    type={'button'}
                onClick={()=>dispatch({type:"nav_change", payload:{status: !store?.navStatus?.navState}})}
                >
                    <img src={collageBtn} alt="Collapse Icon"/>
                </button>
                <h1>{!!title ? title : "Title"}
                    {isCommitReq &&
                    <span className="count">
                                {commitCount}</span>
                    }
                </h1>
            </div>
            <ul className="navbar-menu">
                <li className="searchbox">
                    <input type="text"
                           onChange={(e)=>handleSearch(e)}
                           placeholder="Search Athletes"
                           value={searchByAthleteName || ""}
                    />
                    <div ref={domRef} className="dropdown-menu dropdownopen"style={isSearch ? {display:"block"} : {display:"none"}}>
                        {searchAthleteData.length > 0 && <div className="title">Search Results</div> }
                        <ul className="search-list">
                            {searchAthleteData.length > 0 ?
                            searchAthleteData.map((athlete) => (
                                
                                    <li id={athlete.id} key={athlete.id.toString()}>
                                        {console.log(" search ====>>>>", athlete)}
                                        <Link
                                            target={'_blank'}
                                    to={`/athlete/${athlete?.athleteFullName?.trim()?.toLowerCase()?.toString()?.split(" ")?.join("-")}-${athlete.sysGenId}`}
                                    onClick={() => {
                                        handalCloseDropDown();
                                        profileViewApi(athlete);
                                        localStorage.setItem(`report_userid_${athlete?.sysGenId}`, JSON.stringify(athlete?.id))
                                    }}
                                    >
                                
                                        <div className="pic">
                                            {!!athlete.profileImages ?
                                                <img
                                                    src={
                                                        athlete.profileImages != null
                                                            ? ENV.DOWNLOAD_URL +
                                                            "/" +
                                                            athlete.profileImages
                                                            : userImg
                                                    }
                                                    alt=""
                                                />
                                                :
                                                !!athlete?.athleteFullName ?
                                                    <span style={{ textAlign: "center", display: "block" }}>{getNameInitial(athlete)}</span>
                                                    : ""}
                                        </div>
                                        <div className="desgination">
                                            <p>
                                                <strong>{athlete.athleteFullName}</strong>
                                                {athlete.sportName}-{athlete.yearValue}
                                                <br />
                                                {athlete.city} {(!!athlete.city && !!athlete.countryShortName) ? "," : ""} {athlete.countryShortName}
                                            </p>
                                        </div>
                                        </Link>
                                    </li>
                            )):"No Record Found"}
                        </ul>
                    </div>
                </li>
                <li className="mobileSearchIcon icon">
                    <button
                        onClick={()=>{
                            handleOpenModal()
                            setSearchMobAthleteData([])
                            setSearchMobByAthleteName("")
                        }}
                        type="button"
                        className="btn searchBtn"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.019" height="24"
                                viewBox="0 0 24.019 24">
                            <g id="search" transform="translate(0 -0.05)">
                                <path id="Path_2164" data-name="Path 2164"
                                        d="M9.837,19.725A9.8,9.8,0,0,0,15.3,18.066l5.652,5.652a1.176,1.176,0,0,0,1.64,0l1.1-1.1a1.176,1.176,0,0,0,0-1.64l-5.671-5.632a9.834,9.834,0,1,0-8.178,4.379Zm0-15.817a5.98,5.98,0,1,1-5.98,5.98A5.985,5.985,0,0,1,9.837,3.908Z"
                                        fill="#fff"/>
                            </g>
                        </svg>

                    </button>
                    </li>
                <li className={ pathname.includes("message") ? "emailLink active" : "emailLink"}>
                    <Link to={'/message/athlete'} onClick={() => markNotification()}>
                    <span className="count" style={splitPath == 'message/athlete' ? {display: "none"} : {display: (parseInt(store?.adminMessages?.allUnreadMessages) > 0 ? "flex":"none")}}>{store?.adminMessages?.allUnreadMessages}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
                        <g id="email" transform="translate(-1 -4)">
                            <path id="Path_369" data-name="Path 369" d="M30.944,7.139A3.684,3.684,0,0,0,27.311,4H4.689A3.684,3.684,0,0,0,1.056,7.139L16,16.809Z" fill="#fff"/>
                            <path id="Path_370" data-name="Path 370" d="M16.543,18.84a1,1,0,0,1-1.086,0L1,9.486V24.311A3.693,3.693,0,0,0,4.689,28H27.311A3.693,3.693,0,0,0,31,24.311V9.485Z" fill="#fff"/>
                        </g>
                    </svg>
                    </Link>                
                </li>
                <li className={splitPath == 'notification' ? "notifyLink active" : "notifyLink"}>
                <Link to={'/notification'} onClick={() => markNotification()}>
                <span className="count" style={splitPath == 'notification' ? {display: "none"} : {display: (parseInt(totalCount) > 0 ? "flex":"none")}}>{totalCount}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22.063" height="24" viewBox="0 0 22.063 24">
                        <g transform="translate(-20.66)">
                            <g transform="translate(20.66)">
                                <path class="a" d="M191.132,469.361a3.313,3.313,0,0,0,6.083,0c-.962.047-1.962.079-3.041.079S192.094,469.408,191.132,469.361Z" transform="translate(-183.141 -447.36)" fill="#fff"></path>
                                <path class="a" d="M42.28,16.92c-1.265-1.6-3.389-4.732-3.389-7.8a7.237,7.237,0,0,0-4.8-6.785,2.4,2.4,0,0,0-4.8,0,7.237,7.237,0,0,0-4.8,6.785c0,3.069-2.123,6.2-3.389,7.8a2.037,2.037,0,0,0-.348,1.883,1.981,1.981,0,0,0,1.331,1.306,27.1,27.1,0,0,0,5.7.884c1.2.081,2.492.128,3.9.128s2.7-.047,3.9-.128a27.116,27.116,0,0,0,5.7-.884,1.98,1.98,0,0,0,1.33-1.306A2.04,2.04,0,0,0,42.28,16.92Z" transform="translate(-20.66)" fill="#fff"></path>
                            </g>
                        </g>
                    </svg>
                </Link>                
                </li>
                <li className="profileLink">
                    <a onMouseOver={()=>setIsLog(!isLog)} href={void 0} className="avtaar">{getInitial(localStorage.getItem('user_Admin_Advisor'), true)}
                        <ul className="profile-dropdwon">
                            {/* <li onClick={()=>history.push('/admin/notificationSetting')}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16.678"
                                    height="19.469"
                                    viewBox="0 0 16.678 19.469"
                                >
                                    <path
                                        id="notifications-outline"
                                        d="M95.282,61.472c-1.14-1.4-1.945-2.106-1.945-5.953,0-3.523-1.8-4.778-3.28-5.388a.791.791,0,0,1-.442-.468,1.98,1.98,0,0,0-3.909,0,.783.783,0,0,1-.442.468c-1.482.61-3.28,1.861-3.28,5.388,0,3.847-.807,4.557-1.947,5.953a.9.9,0,0,0,.768,1.446H94.519A.9.9,0,0,0,95.282,61.472ZM90.5,62.918v.71a2.841,2.841,0,1,1-5.683,0v-.71"
                                        transform="translate(-79.32 -47.5)"
                                        fill="none"
                                        stroke="#333"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                    />
                                </svg>
                                Notification Settings  
                            </li> */}
                           <li onClick={()=>history.push('/admin/passwordChange')}>
                           <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14.961"
                                    height="18.773"
                                    viewBox="0 0 14.961 18.773"
                                >
                                    <g
                                    id="padlock_1_"
                                    data-name="padlock (1)"
                                    transform="translate(-433.592 -454.859)"
                                    >
                                    <g
                                        id="Group_691"
                                        data-name="Group 691"
                                        transform="translate(427.639 471.39)"
                                    >
                                        <g id="Group_690" data-name="Group 690">
                                        <path
                                            id="Path_373"
                                            data-name="Path 373"
                                            d="M64.761,6.894H63.439V3.878a3.961,3.961,0,0,0-7.92,0V6.894H54.2a2.2,2.2,0,0,0-2.2,2.2v7.48a2.2,2.2,0,0,0,2.2,2.2H64.761a2.2,2.2,0,0,0,2.2-2.2V9.094A2.2,2.2,0,0,0,64.761,6.894ZM56.986,3.878a2.495,2.495,0,0,1,4.987,0V6.894H56.986Zm8.508,12.7a.734.734,0,0,1-.733.733H54.2a.734.734,0,0,1-.733-.733V9.094A.734.734,0,0,1,54.2,8.36H64.761a.734.734,0,0,1,.733.733Z"
                                            transform="translate(-46.047 -16.529)"
                                            fill="#333"
                                        />
                                        </g>
                                    </g>
                                    <g
                                        id="Group_693"
                                        data-name="Group 693"
                                        transform="translate(435.859 485.467)"
                                    >
                                        <g id="Group_692" data-name="Group 692">
                                        <path
                                            id="Path_374"
                                            data-name="Path 374"
                                            d="M220.357,286a1.357,1.357,0,0,0-.735,2.5v1.61a.733.733,0,1,0,1.467,0V288.5a1.357,1.357,0,0,0-.732-2.5Z"
                                            transform="translate(-215.143 -306.12)"
                                            fill="#333"
                                        />
                                        </g>
                                    </g>
                                    </g>
                                </svg>
                               Change Password
                            </li>
                            <li onClick={() =>logOut()} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17"
                                    viewBox="0 0 15.269 16.905">
                                    <g id="XMLID_15_" transform="translate(-15)" opacity="0.6">
                                        <path id="XMLID_16_"
                                            d="M26.274,45.183a.818.818,0,0,0-.781,1.438,6,6,0,1,1-5.737.011.818.818,0,1,0-.786-1.435,7.634,7.634,0,1,0,7.3-.014Z"
                                            transform="translate(0 -42.626)"/>
                                        <path id="XMLID_17_"
                                            d="M140.818,7.089a.818.818,0,0,0,.818-.818V.818a.818.818,0,0,0-1.636,0V6.271A.818.818,0,0,0,140.818,7.089Z"
                                            transform="translate(-118.184)"/>
                                    </g>
                                </svg>
                                Sign Out
                            </li>
                        </ul>
                    </a>
                </li>
            </ul>
        </nav>
        <div
        ref={modalRef}
        className={openModal ? "modal fade show searchModal" : "modal fade"}
        id="searchModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="searchModal"
        aria-hidden="true"
        style={searchMobAthleteData?.length === 0 ? { bottom: "auto" } : { bottom: 0 }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <a type="button" className="close" href={void 0} onClick={() => {
                handleCloseModal()
                setSearchByAthleteName("")
                setSearchAthleteData([])
                setSearchMobAthleteData([])
                setSearchMobByAthleteName("")
              }}>
                <span aria-hidden="true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.001"
                    height="7.001"
                    viewBox="0 0 8.001 7.001"
                  >
                    <path
                      id="arrow-left-short"
                      d="M12,8a.5.5,0,0,1-.5.5H5.707l2.147,2.146a.5.5,0,1,1-.708.708l-3-3a.5.5,0,0,1,0-.708l3-3a.5.5,0,0,1,.708.708L5.707,7.5H11.5A.5.5,0,0,1,12,8Z"
                      transform="translate(-3.999 -4.499)"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </svg>
                </span>
              </a>
              <input
                type="search"
                name=""
                value={searchMobByAthleteName || ""}
                placeholder="Search Athletes"
                onChange={(e) => {
                    handleSearch(e);
                }}
              />
                <div className="dropdown-menu dropdownopen"style={isMobSearch ? {display:"block"} : {display:"none"}}>
                    {searchMobAthleteData?.length > 0 && <div className="title">Search Results</div> }
                    <ul className="search-list">
                        {searchMobAthleteData.length > 0 ?
                            searchMobAthleteData.map((athlete) => (

                                <li id={athlete.id} key={athlete.id.toString()}>
                                    <Link
                                        target={'_blank'}
                                        to={`/athlete/${athlete?.athleteFullName?.trim()?.toLowerCase()?.toString()?.split(" ")?.join("-")}-${athlete?.sysGenId}`
                                            // `/publicProfile/1/${+athlete?.id}/${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.id}`
                                        }
                                        onClick={() => {
                                            handalCloseDropDown();
                                            profileViewApi(athlete);
                                            setopenModal(false)
                                            localStorage.setItem(`report_userid_${athlete?.sysGenId}`, JSON.stringify(athlete?.id))
                                        }}
                                    >

                                        <div className="pic">
                                            {!!athlete.profileImages ?
                                                <img
                                                    src={
                                                        athlete.profileImages != null
                                                            ? ENV.DOWNLOAD_URL +
                                                            "/" +
                                                            athlete.profileImages
                                                            : userImg
                                                    }
                                                    alt=""
                                                />
                                                :
                                                !!athlete?.athleteFullName ?
                                                    <span style={{ textAlign: "center", display: "block" }}>{getNameInitial(athlete)}</span>
                                                    : ""}
                                        </div>
                                        <div className="desgination">
                                            <p>
                                                <strong>{athlete.athleteFullName}</strong>
                                                {athlete.sportName}-{athlete.yearValue}
                                                <br />
                                                {athlete.city} {(!!athlete.city && !!athlete.countryShortName) ? "," : ""} {athlete.countryShortName}
                                            </p>
                                        </div>
                                    </Link>
                                </li>
                            )):"No Record Found"}
                    </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
})