export function getAuthToken() {
    return localStorage.getItem("authToken_Admin_Advisor");
  }
  
export function getUserInfo() {
    return JSON.parse(localStorage.getItem("user_Admin_Advisor"));
  }

export function logOut() {
  const isRememeber = localStorage?.getItem('rememberMe')
  if (isRememeber){
    localStorage?.removeItem('user_Admin_Advisor')
    localStorage?.removeItem('loginType')
    localStorage?.removeItem('authToken_Admin_Advisor')
  }else {
    localStorage?.clear()
  }
}  