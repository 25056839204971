import { React, useEffect, useRef, useState } from "react";
import {TwitterShareButton, FacebookShareButton} from 'react-share'
import ENV from "../../../config";
import swal from 'sweetalert'
import {IsValidEmail, IsValidName} from "../../../utils/validationUtils";
import {CopyToClipboard} from 'react-copy-to-clipboard'
import moment from "moment";
import services from "../../../services";
import "../assets/css/suggestCollege.scss";
import PhoneNumber from "../../common/PhoneNumber";
import { checkPhoneNumberValidation } from "../../../utils/validationUtils";
import { formatPhoneNumber } from "../../../utils/validationUtils";
import {typeConvert} from "../../common/getThumbnail";
export default function SharePost({handleModal, modalData, pageType, userID, pubID}) {
  const  [copied, setCopied] = useState(false);
   const  [isMailText, setIsMailText] = useState(false)
   const  [isText, setIsText] = useState(false)
   const  [apiType, setApiType] = useState(null)
   const  [inputMail, setInputMail] = useState({})
   const [allCountry, setAllCounty] = useState([])
   const [renderCount, setRenderCount] = useState(0)
   const [permalinkText, setpermalinkText] = useState("")
   const [isHide, setIsHide] = useState(false)
   const  copyToClipboard = () => {
     setCopied(true);
  }


  useEffect(()=>{
    getAllCountry()
  },[renderCount])

  const getAllCountry = async () =>{
    const response = await services.location.getCountriesData();
    if (response?.status == 200){
      const allCountries = response.data.data?.length > 0
          ? response.data.data.map((item) => {
            if (item.countryFlagImage) {
              item.countryFlagImage = `${ENV.DOWNLOAD_URL}/${item.countryFlagImage}`;
            }
            return item;
          }) : []
      setAllCounty(allCountries)
      const cloneState = {...inputMail}
      if ( allCountries?.length > 0){
        cloneState['countryDetails'] = allCountries.filter((item) => +item.id == 1)
            .map((item1) => ({
              value: item1 ? item1.id : "",
              label: item1 ? item1.countryName + `(+${item1.countryCode})` : "",
              flag: item1 ? item1.countryFlagImage : "",
              countryCode: item1.countryCode ? item1.countryCode : 1,
              //earlier this key is not added due this bugs is created
              countryShortName: !!item1?.countryShortName ? item1?.countryShortName : "US"
            }))[0];
        setInputMail(cloneState)
      }
    }
  }
// get permalink
  useEffect(async () => {
    if (pubID) {
      const variable = {
        obj: {
          userid: modalData.userId,
          // active: 1
        }
      }
      const premalinkUrlData = await services.location.getPermalinkUrl(variable);
      if (premalinkUrlData?.status === 200) {
        let permalinkTextUrl = premalinkUrlData?.data?.data?.allAthletes?.Athletes?.[0]?.permalinkurl;
        setpermalinkText(permalinkTextUrl ? permalinkTextUrl : "")
      } else {

      }
    }
  },[])
  //
  const shareTextQuote = (pageType, isMail) =>{
    let quote = modalData?.postdescription ?? ""
    if(pageType == "Home"){
      if(modalData?.allVideoAudioPost?.length > 0){
        modalData?.allVideoAudioPost?.map((el, indx) =>{
          if(el?.typeofpost == 1 || el?.typeofpost == 2){
            quote = !!isMail ? quote.concat(`\n ${indx > 0 ? `,  ` : ""}`,` <a href=${ENV.DOWNLOAD_URL}/${typeConvert(el?.postimagevideoandlinkurl ?? "", "Share_Pop_Text_Mail")}>
            ${typeConvert(el?.postimagevideoandlinkurl ?? "", "Share_Pop_Text_Mail")}
          </a> `) :
                quote.concat("\n", typeConvert(ENV.DOWNLOAD_URL+"/"+ el?.postimagevideoandlinkurl ?? "", "Share_Pop_Text_Mail"))
          }
        })
      }
      if(modalData?.allVideoAudioPost?.length > 0){
        modalData?.allVideoAudioPost?.map((el, indx) =>{
          if(el?.typeofpost == 3 || el?.typeofpost == 4){
            quote = !!isMail ? quote.concat(`\n ${indx > 0 ? "," : ""}`,`<a href=${el?.postimagevideoandlinkurl ?? ""}>
            ${el?.postimagevideoandlinkurl ?? ""}
          </a>`) :
                quote.concat("\n", el?.postimagevideoandlinkurl ?? "")
          }
        })
      }
      else if(modalData?.postType == "favorite"){
        quote = `Shared ${modalData?.postType} College ${modalData?.collegename || ""}`
      }
      else if(modalData?.postType == "eventNew" || modalData?.postType == "event"){
        quote = `${modalData?.eventname ?? ""} scheduled from ${!!modalData?.startdate ? moment(modalData?.startdate).format("MMM DD, YYYY") : ""} to 
    ${!!modalData?.enddate ? moment(modalData?.enddate).format("MMM DD, YYYY") : ""}`
      }
      else if (modalData?.postType == "photosVideos"){
        if(!!modalData?.videolink){
          quote = !!isMail ? `<p><a href=${modalData?.videolink ?? ""}>${modalData?.videolink ?? ""}</a></p>`
              :  `${modalData?.videolink ?? ""}`
        }
        else if(!!modalData?.photosname){
          quote = !!isMail ? `<a href=${ENV.DOWNLOAD_URL}/${modalData?.photosname ?? ""}>${modalData?.photosname ?? ""}</a>`
              : `${ENV.DOWNLOAD_URL}/${modalData?.photosname ?? ""}`
        }
        else if (!!modalData?.videoname){
          quote = !!isMail ? `<a href=${ENV.DOWNLOAD_URL}/${typeConvert(modalData?.videoname ?? "", "Share_Pop_Text_Mail")}>${typeConvert(modalData?.videoname ?? "", "Share_Pop_Text_Mail")}</a>`
              : `${ENV.DOWNLOAD_URL}/${typeConvert(modalData?.videoname ?? "", "Share_Pop_Text_Mail")}`
        }else{
          quote = "http://sfrglobal.com/#/"
        }
      }
      else{
        quote = `Shared ${modalData?.postType || ""} by ${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.FirstName || ""} ${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.lastName || ""}\n
         ${modalData?.postdescription ?? ""}`
      }
    }
    else if (pageType == "Public"){
      quote = !!isMail ? `Sharing ${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.FirstName || ""} ${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.lastName || ""} public profile`+
            `<a href=${!!pubID && permalinkText ? permalinkText : ""}>${!!pubID && permalinkText ? permalinkText : ""}</a>`
          : `Sharing ${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.FirstName || ""} ${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.lastName || ""} public profile 
      ${!!pubID && permalinkText ? permalinkText : ""}`
    }
    else if (pageType == "Event"){
      if(!!isMail){
        quote = `
      Event Name: ${modalData?.eventmappingeventidrel?.Events[0]?.eventname ?? ""}\n
      Start Date: ${modalData?.eventmappingeventidrel?.Events[0]?.startdate ?? ""}\n
      End Date: ${modalData?.eventmappingeventidrel?.Events[0]?.enddate ?? ""}\n
      Category: ${modalData?.eventmappingeventidrel?.Events[0]?.eventcategoryidrel?.Eventcategories[0]?.categoryname ?? ""}\n
      Location: ${modalData?.eventmappingeventidrel?.Events[0]?.location ?? ""}\n
      City, State/Province, Country:${!!modalData?.eventmappingeventidrel?.Events[0]?.city ?
            modalData?.eventmappingeventidrel?.Events[0]?.city + "," : ""} ${!!modalData?.eventmappingeventidrel?.Events[0]?.eventstateidrel?.States[0]?.statename ?
            modalData?.eventmappingeventidrel?.Events[0]?.eventstateidrel?.States[0]?.statename + "," : ""} 
      ${!!modalData?.eventmappingeventidrel?.Events[0]?.eventcountryidrel?.Countries?.[0]?.countryname ? modalData?.eventmappingeventidrel?.Events[0]?.eventcountryidrel?.Countries?.[0]?.countryname : ""}\n
      Website:` + `<p><a href=${modalData?.eventmappingeventidrel?.Events[0]?.eventwebsiteurl ?? ""}>${modalData?.eventmappingeventidrel?.Events[0]?.eventwebsiteurl ?? ""}</a></p>` + `
      Event Notes: ${modalData.eventmappingeventidrel.Events[0].eventnotes}\n
      `
      }else {
        quote = `
      Event Name: ${modalData?.eventmappingeventidrel?.Events[0]?.eventname ?? ""}\n
      Start Date: ${modalData?.eventmappingeventidrel?.Events[0]?.startdate ?? ""}\n
      End Date: ${modalData?.eventmappingeventidrel?.Events[0]?.enddate ?? ""}\n
      Category: ${modalData?.eventmappingeventidrel?.Events[0]?.eventcategoryidrel?.Eventcategories[0]?.categoryname ?? ""}\n
      Location: ${modalData?.eventmappingeventidrel?.Events[0]?.location ?? ""}\n
      City, State/Province, Country:${!!modalData?.eventmappingeventidrel?.Events[0]?.city ?
            modalData?.eventmappingeventidrel?.Events[0]?.city + "," : ""} ${!!modalData?.eventmappingeventidrel?.Events[0]?.eventstateidrel?.States[0]?.statename ?
            modalData?.eventmappingeventidrel?.Events[0]?.eventstateidrel?.States[0]?.statename + "," : ""} 
      ${!!modalData?.eventmappingeventidrel?.Events[0]?.eventcountryidrel?.Countries?.[0]?.countryname ? modalData?.eventmappingeventidrel?.Events[0]?.eventcountryidrel?.Countries?.[0]?.countryname : ""}\n
      Website: ${modalData?.eventmappingeventidrel?.Events[0]?.eventwebsiteurl ?? ""}\n
      Event Notes: ${modalData.eventmappingeventidrel.Events[0].eventnotes}\n
      `
      }
    }
    else if (pageType == "AddCoach"){
      quote = ` Sharing Coach Detail 
           Coach Name:  ${modalData?.coachName || ""} 
                Email:  ${modalData?.coachEmail || ""}
                ${!!modalData?.coachNumber ? 'Phone Number: ' + modalData?.coachNumber : "" }
                `
    }
    else if (pageType == "Vid/Photo"){
      if(modalData?.isphotosorvideoslik == 0 || modalData?.isphotosorvideoslik == 1) {
        quote = !!isMail ?
            `Sharing Link
     ${modalData?.isphotosorvideoslik == 1 ? `<a href=${ENV.DOWNLOAD_URL + "/" + typeConvert(modalData?.videoname ?? "", "Share_Pop_Text_Mail")}>
            ${ENV.DOWNLOAD_URL + "/" + typeConvert(modalData?.videoname ?? "", "Share_Pop_Text_Mail")}
                </a>` :
                `<a href=${ENV.DOWNLOAD_URL + "/" + modalData?.photosname}>${ENV.DOWNLOAD_URL + "/" + modalData?.photosname}</a>`
            }`
            : `Sharing Link
     ${modalData?.isphotosorvideoslik == 1 ? (ENV.DOWNLOAD_URL + "/" + typeConvert(modalData?.videoname ?? "", "Share_Pop_Text_Mail")) :
                (ENV.DOWNLOAD_URL + "/" + modalData?.photosname)
            }`
      }
      else if(modalData?.isphotosorvideoslik == 2 || modalData?.isphotosorvideoslik == 3){
        quote = !!isMail ?
            `Sharing Link <a href=${modalData?.videolink ?? ""}>${modalData?.videolink ?? ""}</a>`
            : `Sharing Link ${modalData?.videolink}`
      }
    }
    else if (pageType === "Reports"){
      quote = ` Sharing ${modalData?.coachName || ""} ${modalData?.coachEmail || ""} for verification`
    }
    else if(pageType === "Timeline"){
      quote = `Sharing ${modalData?.typeofPost == 1 ? "photo" : "video"}
      ${modalData?.typeofPost == 1 ? ENV.DOWNLOAD_URL+"/"+modalData?.postimagevideoandlinkurl : ""}`
    }
    return quote
  }
  const shareTextTitle = pageType =>{
    let title
    if(pageType == "Home"){
      // title = `Shared ${modalData?.postType || ""}`
    }
    else if(pageType == "Event"){
      title = `Shared Event`
    }
    else if(pageType == "AddCoach"){
      title = "Sharing Coach Detail"
    }
    else if(pageType == "Vid/Photo"){
      title =``
    }
    else if(pageType === "Reports"){
      title = "Share Reports"
    }
    else if(pageType === "Timeline"){
      title =`Sharing ${modalData?.typeofPost == 1 ? "photo" : "video"}`
    }
    return title
  }
  const shareTextURL = pageType =>{
    let url
    if((pageType == "Home" || modalData?.postType == "post") && (modalData?.allVideoAudioPost?.length > 0)){
      url = modalData?.allVideoAudioPost?.[0]?.postimagevideoandlinkurl &&
      (modalData?.allVideoAudioPost?.[0]?.typeofpost == 1 || modalData?.allVideoAudioPost?.[0]?.typeofpost == 2)  ?
          typeConvert(`${ENV.DOWNLOAD_URL}/${modalData?.allVideoAudioPost?.[0]?.postimagevideoandlinkurl ?? ""}`, "Share_Pop_URL") :
          modalData?.allVideoAudioPost?.[0]?.typeofpost == 3 || modalData?.allVideoAudioPost?.[0]?.typeofpost == 4 ?
              modalData?.allVideoAudioPost?.[0]?.postimagevideoandlinkurl
              :
           ENV.WEB_URL
    }
    else if (pageType == "Home" || modalData?.postType == "photosVideos"){
      if(!!modalData?.videolink){
        url = `${!!modalData?.videolink ? modalData?.videolink : ENV.WEB_URL }`
      }
      else if(!!modalData?.photosname){
        url = `${ENV.DOWNLOAD_URL}/${!!modalData?.photosname ? modalData?.photosname : ENV.WEB_URL}`
      }
      else if(!!modalData?.videoname){
        url = `${ENV.DOWNLOAD_URL}/${!!modalData?.videoname ? typeConvert(modalData?.videoname, "Share_Pop") : ENV.WEB_URL}`
      }
      else{
        url = ENV.WEB_URL
      }
    }
    else if (pageType == "Public"){
      url = !!pubID ? permalinkText ? permalinkText : "" : `${ENV.WEB_URL}/#/publicView/0/0`
    }
    else if (pageType == "Vid/Photo"){
      if(modalData?.isphotosorvideoslik == 0 || modalData?.isphotosorvideoslik == 1){
        url = !!modalData ?
            `${ENV.DOWNLOAD_URL}/${modalData?.isphotosorvideoslik == 0 ? modalData?.photosname ?? "" : typeConvert(modalData?.videoname ?? "", "Share_Pop")}` :
            ENV.WEB_URL
      }
      else if(modalData?.isphotosorvideoslik == 2 || modalData?.isphotosorvideoslik == 3){
        url = !!modalData ?
            `${modalData?.videolink ?? ""}` :
            ENV.WEB_URL
      }
    }
    else if(pageType == "Timeline"){
      url = !!modalData ?
      `${ENV.DOWNLOAD_URL}/${modalData?.postimagevideoandlinkurl ?? ""}` :
      ENV.WEB_URL
    }
    else{
      url = ENV.WEB_URL
    }
    return url
  }
  const shareTextTweeter = pageType =>{
     let url = modalData?.postdescription ?? ""
    if(pageType == "Event"){
      url = `
Event Name: ${modalData?.eventmappingeventidrel?.Events[0]?.eventname ?? ""}
Start Date: ${modalData?.eventmappingeventidrel?.Events[0]?.startdate ?? ""}
End Date: ${modalData?.eventmappingeventidrel?.Events[0]?.enddate ?? ""}
Category: ${modalData?.eventmappingeventidrel?.Events[0]?.eventcategoryidrel?.Eventcategories[0]?.categoryname ?? ""}
Location: ${modalData?.eventmappingeventidrel?.Events[0]?.location ?? ""}
City, State/Province, Country: ${!!modalData?.eventmappingeventidrel?.Events[0]?.city ?
modalData?.eventmappingeventidrel?.Events[0]?.city +"," : ""} ${!!modalData?.eventmappingeventidrel?.Events[0]?.eventstateidrel?.States[0]?.statename ?
modalData?.eventmappingeventidrel?.Events[0]?.eventstateidrel?.States[0]?.statename + "," : ""} ${!!modalData?.eventmappingeventidrel?.Events[0]?.eventcountryidrel?.Countries?.[0]?.countryname ? modalData?.eventmappingeventidrel?.Events[0]?.eventcountryidrel?.Countries?.[0]?.countryname  : ""}
Website: ${modalData?.eventmappingeventidrel?.Events[0]?.eventwebsiteurl ?? ""}
Event Notes: ${modalData.eventmappingeventidrel.Events[0].eventnotes}
      `
    }
    else if (pageType == "AddCoach"){
      url = `
     Coach Name: ${modalData?.coachName || ""} 
     Email: ${modalData?.coachEmail || ""}
     ${!!modalData?.coachNumber ? 'Phone Number: ' + modalData?.coachNumber : "" }
     `
    }
    else if(pageType == "Home"){
      if(modalData?.allVideoAudioPost?.length > 0){
        modalData?.allVideoAudioPost?.map((el, indx) =>{
          if(el?.typeofpost == 1 || el?.typeofpost == 2){
            url = url.concat(`\n`, ENV.DOWNLOAD_URL+"/"+ el?.postimagevideoandlinkurl ?? "")
          }
        })
      }
      if(modalData?.allVideoAudioPost?.length > 0){
        modalData?.allVideoAudioPost?.map((el, indx) =>{
          if(el?.typeofpost == 3 || el?.typeofpost == 4){
            url = url.concat(`\n`, el?.postimagevideoandlinkurl ?? "")
          }
        })
      }
      // else if(modalData?.allVideoAudioPost?.[0]?.typeofpost == 3 || modalData?.allVideoAudioPost?.[0]?.typeofpost == 4){
      //   url = modalData?.allVideoAudioPost?.[0]?.postimagevideoandlinkurl ?? ""
      // }
      else if(modalData?.postType == "favorite"){
        url = `Shared ${modalData?.postType} College ${modalData?.collegename || ""}`
      }
      else if(modalData?.postType == "eventNew" || modalData?.postType == "event"){
      url = `${modalData?.eventname ?? ""} scheduled from ${!!modalData?.startdate ? moment(modalData?.startdate).format("MMM DD, YYYY") : ""} to 
    ${!!modalData?.enddate ? moment(modalData?.enddate).format("MMM DD, YYYY") : ""}`
      }
      else if (modalData?.postType == "photosVideos"){
        url =  modalData?.postType == "photosVideos" ? !!modalData?.videolink ? modalData?.videolink ?? "" :
            `${ENV.DOWNLOAD_URL}/${!!modalData?.photosname ?
            modalData?.photosname ?? "" : modalData?.videoname ?? ""}` : "http://sfrglobal.com/#/"
      }
      else{
    url = `Shared ${modalData?.postType || ""} by ${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.FirstName || ""}
${modalData?.postdescription ?? ""}`
      }
    }
    else if (pageType == "Public"){
      url = `Sharing ${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.FirstName || ""} ${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.lastName || ""} public profile 
      ${!!pubID && permalinkText ? permalinkText : ""}`
    }
    return url
  }
  const getShareHeader = pageType =>{
     let text = "Share"
     if(pageType == "AddCoach"){
       text = "Invite Your Coach"
     }
     return text
  }
  const onHandleChange = (e, fieldName) =>{
     const {name, value} = e.target
    if(fieldName == "PhoneNumber"){
      if(value?.length > 10) return
      const clone = {...inputMail}
      clone[fieldName] = formatPhoneNumber(value)
      setInputMail(clone)
    }else{
      const clone = {...inputMail}
      clone[e.target.name] = e.target.value
      setInputMail(clone)
    }
  }
  const isValidPhoneNumbers = (value) =>{
    return new RegExp("^[0-9]{10}$").test(value);
   }
  const checkValidation = (input, type)  => {
    let valInput = {}
    if (type == "mail") {
      valInput["firstName"] = IsValidName(input?.firstName) ? true : false
      valInput["lastName"] = IsValidName(input?.lastName) ? true : false
      valInput["email"] = IsValidEmail(input?.emailId.toLowerCase())
      valInput["status"] = (!!valInput?.firstName && !!valInput?.lastName && !!valInput?.email) ? true : false
    } else if (type == "text") {
      valInput["firstNameText"] = IsValidName(input?.firstName) ? true : false
      valInput["lastNameText"] = IsValidName(input?.lastName) ? true : false
      // valInput["phoneNumberText"] = isValidPhoneNumbers(parseInt(input?.PhoneNumber)) ? true : false
      valInput["phoneNumberText"] = checkPhoneNumberValidation(input?.PhoneNumber, input?.countryDetails?.countryShortName ?? "US")
      valInput["statusText"] = (!!valInput?.firstNameText && !!valInput?.lastNameText && !!valInput?.phoneNumberText) ? true : false
    }
    return valInput
  }
  const onSubmit = async () =>{
    if(apiType == "mail"){
      if(!!inputMail?.emailId && !!inputMail?.firstName && inputMail?.lastName){
        let { status, firstName, lastName, email } = checkValidation(inputMail, "mail")
        if(status == false) {
          swal({
            text:
                `${firstName ? "" : "First Name Should Be Only Character,"}${lastName ? "" : "Last Name Should Be Only Character,"}${email ? "" : "Email Id Is not Valid."}`,
            icon: "error"
          })
        }else{
          const obj = {
            emailId: inputMail?.emailId,
            firstName: inputMail?.firstName,
            lastName: inputMail?.lastName,
            sharedData: `${shareTextQuote(pageType, true)}`
          }
          const res = await services.location.sendPostOnEmail(obj)
          if (res && res.status === 200) {
            swal({text: "Mail sent successfully", icon: "success"})
            handleModal()
          } else {
            swal("", "mail didn't send", "")
          }
        }
      }else{
        swal("","All Field Are Mandatory","")
      }
    }
    else if(apiType == "text"){
      if(!!inputMail?.PhoneNumber && !!inputMail?.firstName && inputMail?.lastName) {
        let {firstNameText, lastNameText, phoneNumberText, statusText} = checkValidation(inputMail, "text")
        if (statusText == false) {
          swal({
            text:
                `${firstNameText ? "" : "First Name Should Be Only Character,"}${lastNameText ? "" : "Last Name Should Be Only Character,"}${phoneNumberText ? "" : "Mobile Number not Valid."}`
            , icon: "error"
          })
        } else {
          const obj = {
            PhoneNumber: inputMail?.PhoneNumber ?? 0,
            firstName: inputMail?.firstName ?? "",
            lastName: inputMail?.lastName ?? "",
            sharedData: `${shareTextQuote(pageType, true)}`
          }
          const res = await services.location.sendPostOnText(obj)
          if (res && res.status === 200) {
            swal("", "Text sent successfully", "success")
            handleModal()
          } else {
            swal("", "text didn't send", "error")
          }
        }
      }else{
        swal("","All Field Are Mandatory","")
      }
    }
  }

  const handleCountryChange = (option) => {
    const fieldsT = { ...inputMail };
    // setError({})
    fieldsT["countryDetails"] = option;
    setInputMail({ ...fieldsT, PhoneNumber: "" });
  };
  
  return (
    <>
      <div className={`modal fade shareUpdateModal show`} id="shareUpdateModal"
      style={{display:"block", position:"initial", overflowY:"auto"}}
      >
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <div className="modal-header">
              <button
                className="close"
                // onClick={handleModal}
                onClick={isMailText ? () => {
                  setIsMailText(false) ||
                    setInputMail({}) ||
                  setRenderCount(renderCount + 1)
                  // setIsText(false)
                } : handleModal}
              >
                <span aria-hidden="true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.804"
                    height="15.803"
                    viewBox="0 0 15.804 15.803"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g className="a" transform="translate(-6.776 -6.776)">
                      <path
                        d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </div>
            <div className="modal-body">
              <h1 className="heading">{getShareHeader(pageType)}</h1>
              <div className="linkBox">
                <p style={{overflowX: "hidden"}}>{shareTextURL(pageType)}</p>
                <CopyToClipboard
                    text={shareTextURL(pageType)}
                    onCopy={() => copyToClipboard()}
                >
                  {/*<button>Copy to clipboard with button</button>*/}
                  <button type={'button'} className="copyBtn">
                    {!copied ? "Copy link" : "Copied!" }
                  </button>
                </CopyToClipboard>
              </div>
              <ul className="shareupdatelinks">
                <li>
                  <a href={void 0}>
                        <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.995" viewBox="0 0 24 19.995">
                                <path id="Icon_ionic-ios-share-alt" data-name="Icon ionic-ios-share-alt"
                                      d="M28.289,15.148,19.472,6.9a.451.451,0,0,0-.35-.15c-.275.012-.625.206-.625.5v4.137a.267.267,0,0,1-.225.256C9.5,12.986,5.812,19.543,4.506,26.359c-.05.269.312.519.481.306,3.2-4.03,7.092-6.661,13.247-6.711a.309.309,0,0,1,.262.3v4.062a.536.536,0,0,0,.906.331l8.88-8.392a.69.69,0,0,0,.219-.525A.837.837,0,0,0,28.289,15.148Z"
                                      transform="translate(-4.501 -6.75)" fill="#d58228"/>
                            </svg>
                        </span>
                    Share this link with coaches
                  </a>
                </li>
                <li>
                  <a href={void 0}>
                        <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path id="pin"
                                      d="M.146,23.855a.5.5,0,0,0,.707,0L9.5,15.207l4.146,4.147a.5.5,0,0,0,.708,0l1.5-1.5A.5.5,0,0,0,16,17.5V13.707l4.5-4.5.646.647a.5.5,0,0,0,.707,0l2-2a.5.5,0,0,0,0-.708l-7-7a.5.5,0,0,0-.707,0l-2,2a.5.5,0,0,0,0,.708l.647.646L10.293,8H6.5a.505.505,0,0,0-.354.146l-1.5,1.5a.5.5,0,0,0,0,.708L8.793,14.5.147,23.147A.5.5,0,0,0,.146,23.855Z"
                                      transform="translate(0 0)" fill="#c0321f"/>
                            </svg>
                        </span>
                    Post on social
                  </a>
                </li>
                <li>
                  <a href={void 0}>
                        <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="14.4" viewBox="0 0 23 14.4">
                                <path id="users"
                                      d="M10.2,17.7a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,10.2,17.7Zm8.6,0a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,18.8,17.7Zm-8.6,2.4a9.576,9.576,0,0,1,7.2,3.25V24.9H3V23.35a9.576,9.576,0,0,1,7.2-3.25Zm8.6,4.8V22.442l-.6-.68a12.068,12.068,0,0,0-1.5-1.432A9.618,9.618,0,0,1,26,23.35V24.9Z"
                                      transform="translate(-3 -10.5)" fill="#00c8f8" fill-rule="evenodd"/>
                            </svg>
                        </span>
                    Text or DM your teammates
                  </a>
                </li>
              </ul>
              {isHide &&
              <>
                <p className="info">copy the link above or click below to share directly.</p>
                {isMailText &&
                <div className={'col-md-12'}>
                  {/*  <div className={'row'} style={{marginBottom:"5px"}}>*/}
                  {/*    <div className={'col-md-5'}>Recipent Name</div>*/}
                  {/*    <div className={'col-md-7'}><input*/}
                  {/*        value={inputMail?.recpName || ""}*/}
                  {/*        name={"recpName"}*/}
                  {/*        placeholder={"Recipient Name"}*/}
                  {/*        onChange={(e)=>setInputMail({[e.target.name] : e.target.value})}*/}
                  {/*    />*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  <div className={'row'} style={{marginBottom: "5px"}}>
                    <div className={'col-md-5'}><p style={{fontSize: "14px"}}>Recipient First Name<span
                        style={{marginLeft: "5px", color: "red"}}>*</span></p></div>
                    <div className={'col-md-7'}><input
                        value={inputMail?.firstName || ""}
                        name={"firstName"}
                        placeholder={"First Name"}
                        onChange={(e) => onHandleChange(e)}
                    />
                    </div>
                  </div>
                  <div className={'row'} style={{marginBottom: "5px"}}>
                    <div className={'col-md-5'}><p style={{fontSize: "14px"}}>Recipient Last Name<span
                        style={{marginLeft: "5px", color: "red"}}>*</span></p></div>
                    <div className={'col-md-7'}><input
                        value={inputMail?.lastName || ""}
                        name={"lastName"}
                        placeholder={"Last Name"}
                        onChange={(e) => onHandleChange(e)}
                    />
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-md-5'}><p>{isText ? "Recipient Phone Number" : "Recipient Email"}<span
                        style={{marginLeft: "5px", color: "red"}}>*</span></p></div>
                    <div className={'col-md-7'}>
                      {isText ?
                          <>
                            <PhoneNumber
                                dropdownName="countryCode"
                                dropdownOnChange={handleCountryChange}
                                dropdownValue={inputMail?.countryDetails ?? 1}
                                dropDownOptions={
                                  allCountry?.map((item) => ({
                                    value: item.id,
                                    label: item.countryName + `(+${item.countryCode})`,
                                    flag: item.countryFlagImage,
                                    countryCode: item.countryCode,
                                    countryShortName: item.countryShortName,
                                  }))
                                }
                                formatOptionLabel={(option, {context, selectValue}) => {
                                  return context === "menu" ? (
                                      <>
                                        <img
                                            src={option.flag}
                                            alt=""
                                            style={{width: "40px", marginRight: "15px"}}
                                        />
                                        <span>{option.label}</span>
                                      </>
                                  ) : (
                                      <>
                                        <img
                                            src={option.flag}
                                            alt=""
                                            style={{width: "40px", marginRight: "15px"}}
                                        />
                                        <span>+{option.countryCode}</span>
                                      </>
                                  );
                                }}
                                inputPlaceholder="Phone Number"
                                inputType="phonenumber"
                                inputValue={inputMail?.PhoneNumber ?? ""}
                                inputOnChange={(e) => {
                                  onHandleChange({
                                    target: {
                                      value: e.target.value.replace(/[^0-9]/gi, ""),
                                    },
                                  }, "PhoneNumber");
                                }}
                            />
                            <div style={{color: "red"}}>
                              {/*{error?.["athleteNumber"] ?? ""}*/}
                            </div>
                          </>
                          :
                          // <input
                          //     type={isText ? "number" : "text"}
                          //     value={isText ? inputMail?.PhoneNumber ?? "" : inputMail?.emailId ?? ""}
                          //     name={isText ? "PhoneNumber" : "emailId"}
                          //     placeholder={isText ? "Phone Number" : "Email Id"}
                          //     onChange={(e) => onHandleChange(e)}
                          // />
                          <input
                              type={"text"}
                              value={inputMail?.emailId ?? ""}
                              name={"emailId"}
                              placeholder={"Email Id"}
                              onChange={(e) => onHandleChange(e)}
                          />
                      }
                    </div>
                  </div>
                  <div className={"row"} style={{textAlign: "center", display: "block",}}>
                    <button type={'button'} className={"btn btn-primary"} onClick={() => onSubmit()}>Send</button>
                  </div>
                </div>
                }
                {!isMailText &&
                <div className="socialFlexBox">
                  <div className="item twitter">
                    <a>
                      <div>
                      <span className="icon">
                        <TwitterShareButton
                            // quote={shareTextQuote(pageType)}
                            title={shareTextTitle(pageType)}
                            style={{height: "25px"}}
                            url={(!!pageType && (pageType == "Event")
                                || (pageType == "AddCoach")
                                || (pageType == "Home")
                            ) ? shareTextTweeter(pageType) : shareTextURL(pageType)}
                            beforeOnClick={() => handleModal()}
                        >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            id="Capa_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            //   style="enable-background:new 0 0 512 512;"
                            space="preserve"
                        >
                          <g>
                            <g>
                              <path
                                  d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016    c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992    c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056    c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152    c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792    c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44    C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568    C480.224,136.96,497.728,118.496,512,97.248z"/>
                            </g>
                          </g>
                        </svg>
                          </TwitterShareButton>
                      </span>
                        <span onClick={() => handleModal}>Twitter</span>
                      </div>
                    </a>
                  </div>

                  <div className="item fbook">
                    <a>
                      <div>
                      <span className="icon">
                        <FacebookShareButton
                            quote={shareTextQuote(pageType)}
                            // title={shareTextTitle(pageType)}
                            style={{height: "25px"}}
                            // url={((!!pageType && (pageType == "Event") || (pageType == "Home")
                            // ? getUrl(pageType) : "http://sfrglobal.com/#/")
                            // )}
                            url={"http://sfrglobal.com/#/"}
                            beforeOnClick={() => handleModal()}
                        >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            //   xmlnsSvgjs="http://svgjs.com/svgjs"
                            version="1.1"
                            width="512"
                            height="512"
                            x="0"
                            y="0"
                            viewBox="0 0 24 24"
                            //style="enable-background:new 0 0 512 512"
                            space="preserve"
                        >
                          <g>
                            <path
                                xmlns="http://www.w3.org/2000/svg"
                                d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                                fill="#000000"
                                data-original="#000000"
                                //   style=""
                            />
                          </g>
                        </svg>
                          </FacebookShareButton>
                      </span>
                        <span onClick={() => handleModal}>Facebook</span>
                      </div>
                    </a>
                  </div>
                  <div className="item text">
                    <a>
                      <div>
                      <span className="icon">
                      <svg
                          onClick={() => {
                            setIsMailText(true);
                            setIsText(true)
                            setApiType('text')
                          }}
                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path
                          d="M17,9H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2Zm-4,4H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2ZM12,2A10,10,0,0,0,2,12a9.89,9.89,0,0,0,2.26,6.33l-2,2a1,1,0,0,0-.21,1.09A1,1,0,0,0,3,22h9A10,10,0,0,0,12,2Zm0,18H5.41l.93-.93a1,1,0,0,0,0-1.41A8,8,0,1,1,12,20Z"/>
                      </svg>
                      </span>
                        <span onClick={handleModal}>Text</span>
                      </div>
                    </a>
                  </div>

                  <div className="item mail">
                    <a>
                      <div>
                      <span className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24"
                             onClick={() => {
                               setIsMailText(true)
                               setIsText(false)
                               setApiType('mail')
                             }}
                        >
                          <g id="email" transform="translate(-1 -4)">
                            <path id="Path_369" data-name="Path 369"
                                  d="M30.944,7.139A3.684,3.684,0,0,0,27.311,4H4.689A3.684,3.684,0,0,0,1.056,7.139L16,16.809Z"
                                  fill="#fff"/>
                            <path id="Path_370" data-name="Path 370"
                                  d="M16.543,18.84a1,1,0,0,1-1.086,0L1,9.486V24.311A3.693,3.693,0,0,0,4.689,28H27.311A3.693,3.693,0,0,0,31,24.311V9.485Z"
                                  fill="#fff"/>
                          </g>
                        </svg>
                      </span>
                        <span>Mail</span>
                      </div>
                    </a>
                  </div>
                </div>
                }
              </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
