export const commitDetail = `
query allCommitsforverifications($where: JSON){
  allCommitsforverifications(where: $where){
    Commitsforverifications{
      active
      adminstatus
      firstname
      lastname
      email
      id
      userid
      countryid
      phone
      role
    }
  }
}
`
export const saveCommitsforverification = `
mutation saveCommitsforverification($obj:CommitsforverificationInput!){
  saveCommitsforverification(obj:$obj){
      id
    }
  }
`;