import {useState} from 'react'
export const TimeDate = (data) => {
    const signedCommitmentDate = new Date(data?.TimeDate);
    const currentDate = new Date();
    const [days, setDays] = useState();

    function timeDiffCalc(signedCommitmentDate, currentDate) {
        let diffInMilliSeconds =
            Math.abs(currentDate - signedCommitmentDate) / 1000;

        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;
        // calculate hours
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;
        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;

        const months = Math.floor(days % 365 / 30);

        const years = Math.floor(days / 365)
        return { days, hours, minutes, months, years };
    }

    let signedSignature = "";
    if (data?.TimeDate != null) {
        let abc = timeDiffCalc(signedCommitmentDate, currentDate);
        signedSignature =
            abc.years > 1
                ? abc.years + "years"
                : abc.years == 1
                ? abc.years + "year"
                : abc.months > 1
                    ? abc.months + " months"
                    : abc.months == 1
                        ? `${abc.months} month`
                        : abc.days == 1
                            ? abc.days + " day"
                            : abc.days > 0
                                ? abc.days + " days "
                                : abc.hours > 0
                                    ? abc.hours + " hours "
                                    : abc.minutes > 0
                                        ? abc.minutes + " mins "
                                        : abc.minutes == 1
                                            ? abc.minutes + "min"
                                            : "just now";

    } else {
        return (
            <>
                <div className="note"></div>
            </>
        );
    }

    return (
        <>
            {/*<div className="note" style={{ width: "95%" }}>*/}
            <span>{signedSignature !== "just now" ? `${signedSignature} ago` : signedSignature}</span>
            {/*</div>*/}
        </>
    );
};
