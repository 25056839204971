export const savePostLikeQuery = `
  mutation saveUserpostlike($obj: UserpostlikeInput!){
    saveUserpostlike(obj: $obj){
      active
      id
      postid
      userid
    }
  } 
  `;

  export const getAllCollegeQuery = `
  query allColleges($obj:JSON){
    allColleges(where:$obj){
      Colleges{
        id
        collegename
        active
      }
    }
  }
  `;

  export const coachApiData = `
  query allCoachandteamdetails($where: JSON){
   allCoachandteamdetails(where: $where){
     Coachandteamdetails{
           id
           coachfirstname
           coachlastname
           userid
           coachemail
           coachuserid
         }
       }
   }
  `;