import React, { useEffect, useState, useRef, useCallback } from 'react'
import ENV from '../../config.js';
import './homepage.css';
import { AdminHeader } from '../header/adminHeader';
import GoTopButton from '../profile/ProfileEdit/GoTopButton';
import '../profile/assets/css/athleteProfileFinalDark.css'
import '../profile/assets/css/athleteProfileFinalDark.scss'
import '../profile/assets/css/athleteDashUnverified.scss'
import '../header/assets/css/stylesheet.scss'
import '../header/assets/css/dashboard.scss'
import { useSelector } from "react-redux";
import services from "../../services";
import Moment from 'react-moment';
import 'moment-timezone';
import { getUserInfo } from '../../utils/constant';
import BackdropLoader from "../common/Loader";
import {TimeDate} from "../common/timeDate";
import bell from '../../assets/img/notification.jpg'
import { Link , useHistory} from "react-router-dom";

const Notification = (props) => {
  const store = useSelector(store => store),
        history = useHistory(),
    [fields, setFields] = useState([]),
    [item, setitem] = useState(20),
    [updatedData, setupdatedData] = useState([]),
    [currentPage, setCurrentPage] = useState(1),
    [totalCount, setTotalCount] = useState(0),
    [profileImage, setprofileImage] = useState(0),
    [isVisible, setIsVisible] = useState(false),
    [limit, setLimit] = useState(10),
    [perPage, setPerPage] = useState(1),
    [isSelect, setisSelect] = useState(1),
    [goNum, setGoNum] = useState(1),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(false),
    [hasMore, setHasMore] = useState(false),
    fakeInput = useRef(null),
    observer = useRef(),
    lastBookElementRef = useCallback(node => {
      if (loading) return
      if (observer?.current) observer?.current?.disconnect()
      observer.current = new IntersectionObserver(entires => {
        if (entires[0].isIntersecting && hasMore) {
          getScrollData(isSelect)
        }
        // else if(hasMore == false){
        //     setLoading(false)
        // }
      })
      if (node) observer.current.observe(node)
    }, [loading, hasMore, isSelect]);
  useEffect(() => {
    // const offset_page = perPage*limit
    notificationContent(perPage, limit)
  }, [])

  const notificationContent = async (offset_page, limit_page) => {
    setLoading(true)
    setError(false)
    const body = getUserInfo()?.role == "ADMIN" ?
    {
      first: limit_page * 1,
      last: limit_page,
      adminid: getUserInfo()?.id
    }
    :
    {
      first: limit_page * 1,
      last: limit_page,
      advisorid: getUserInfo()?.id
    }
    let res = getUserInfo()?.role == "ADMIN" ?
        await services.location.notificationData(body) : await services.location.notificationAdvisorData(body);
    if (res?.status == 200 && res?.data?.data) {
      const fieldsValue = getUserInfo()?.role == "ADMIN" ?  (res?.data?.data?.allAdminnotifications?.Adminnotifications?.length > 0 ?
        res?.data?.data?.allAdminnotifications?.Adminnotifications : [])
          :
          (res?.data?.data?.allAdvisornotifications?.Advisornotifications?.length > 0 ?
              res?.data?.data?.allAdvisornotifications?.Advisornotifications : [])
      setFields(fieldsValue)
      fieldsValue.length > 0 && setupdatedData(fieldsValue)
      setLoading(false)
      if (getUserInfo()?.role == "ADMIN"){
        setHasMore(res?.data?.data?.allAdminnotifications?.Adminnotifications?.length > 0 &&
            res?.data?.data?.allAdminnotifications?.Adminnotifications?.length < res?.data?.data?.allAdminnotifications?.totalCount)
      }
    else if (getUserInfo()?.role == "ADVISORS"){
        setHasMore(res?.data?.data?.allAdvisornotifications?.Advisornotifications?.length > 0 &&
            res?.data?.data?.allAdvisornotifications?.Advisornotifications?.length < res?.data?.data?.allAdvisornotifications?.totalCount)
      }
      setTotalCount(getUserInfo?.role == "ADMIN" ?
          res?.data?.data?.allAdminnotifications?.totalCount
      :
          res?.data?.data?.allAdvisornotifications?.totalCount
      )
    }
    else {
      setHasMore(false)
      setLoading(false)
      setError(true)
      setupdatedData([])
      setTotalCount(0)
    }
  }

  const getScrollData = (value) => {
    const clonePerPage = 1,
      cloneLimit = limit + 10
    setPerPage(clonePerPage)
    setLimit(cloneLimit)
    if (value == 1) {
      notificationContent(clonePerPage, cloneLimit)
    }
    else if (value == 2) {
      unreadApiCall(clonePerPage, cloneLimit)
    }
  }

  const readValue = async (value) => {
    const body = {
      id: value ?? 0
    }
    const res = getUserInfo()?.role == "ADMIN" ?
        await services.location.readValueApi(body)
        :
        await services.location.advisorReadValueApi(body)
    if (res?.status == 200) {
      //window.location.reload(false)
      if (isSelect == 1) {
        notificationContent(perPage, limit)
      }
      else if (isSelect == 2) {
        unreadApiCall(1, 10)
      }
      // setCurrentPage(1)
    } else {
    }
  }

  const markAllRead = async () => {
    const body = getUserInfo()?.role == "ADMIN" ?{
      adminid: getUserInfo()?.id ?? 0
    }
    :
    {
      advisorid: getUserInfo()?.id ?? 0
    }
    const res = getUserInfo()?.role == "ADMIN" ?
        await services.location.markReadForAllApi(body)
        :
        await services.location.advisorMarkReadForAll(body)
    if (res?.status == 200) {
      notificationContent(1, 10)
      setCurrentPage(1)
      setGoNum(1)
      setLimit(10)
      setPerPage(1)
      setisSelect(1)
    } else {
    }
  },

    handlePagination = page_num => {
      setCurrentPage(page_num)
      setPerPage(page_num)
      if (isSelect == 1) {
        notificationContent(page_num, limit)
      } else {
        unreadApiCall(page_num, limit)
      }
    },
    onHandlePerPage = limit_page => {
      setLimit(limit_page)
      if (isSelect == 1) {
        notificationContent(perPage, limit_page)
      } else {
        unreadApiCall(perPage, limit_page)
      }
    },
    unreadApiCall = async (per_Page, limit_page) => {
      setLoading(true)
      const body = getUserInfo()?.role == "ADMIN" ?
      {
        adminid: getUserInfo()?.id ?? 0,
        isread: 0,
        first: (per_Page * limit_page),
        last: limit_page
      }
      :
          {
            advisorid: getUserInfo()?.id ?? 0,
            isread: 0,
            first: (per_Page * limit_page),
            last: limit_page
          }
      const res = getUserInfo()?.role == "ADMIN" ?
          await services?.location?.notificationDataDyRead(body)
          :
          await services?.location?.advisorNotificationDataUnRead(body)
      if (res?.status == 200) {
        const fieldsValue = getUserInfo()?.role == "ADMIN" ?  (res?.data?.data?.allAdminnotifications?.Adminnotifications?.length > 0 ?
          res?.data?.data?.allAdminnotifications?.Adminnotifications : []) :
            (res?.data?.data?.allAdvisornotifications?.Advisornotifications?.length > 0 ?
                res?.data?.data?.allAdvisornotifications?.Advisornotifications : [])
        // setFields(fieldsValue)
        setupdatedData(fieldsValue)
        //fieldsValue?.length > 0 && setupdatedData(fieldsValue)
        if (getUserInfo()?.role == "ADMIN"){
          setHasMore(res?.data?.data?.allAdminnotifications?.Adminnotifications?.length > 0 &&
              res?.data?.data?.allAdminnotifications?.Adminnotifications?.length < res?.data?.data?.allAdminnotifications?.totalCount)
        }
        else if (getUserInfo()?.role == "ADVISORS"){
          setHasMore(res?.data?.data?.allAdvisornotifications?.Advisornotifications?.length > 0 &&
              res?.data?.data?.allAdvisornotifications?.Advisornotifications?.length < res?.data?.data?.allAdvisornotifications?.totalCount)
        }
        setTotalCount(getUserInfo?.role == "ADMIN" ?
            res?.data?.data?.allAdminnotifications?.totalCount
        :
            res?.data?.data?.allAdvisornotifications?.totalCount
        )
        setLoading(false)
      } else {
        setupdatedData([])
        setTotalCount(0)
        setLoading(false)
      }
    };
  const goTop = () => {
    fakeInput?.current?.focus();
    fakeInput?.current?.select();
  };
  const getAdminInitial = (data = "") =>{
    const initial = data?.split(" "),
          finalText = `${initial?.[0]?.split("")?.[0]}${initial?.[1]?.split("")?.[0]}`
      return finalText
  }
  return (
    <>
      {/*<div className={store?.navStatus?.navState ?  'main' : "main sidebar-hidden"}>*/}
      <BackdropLoader open={loading} />
      <div className={'content'}
           // style={{height:"auto"}}
      >
        {getUserInfo()?.role == "ADMIN" &&
        <AdminHeader
          title={"Notification"}
        />
        }
        {getUserInfo()?.role == "ADVISORS" &&
        <h1
            style={{
              fontWeight: 800, textAlign: "center",
              marginTop: "60px", padding: "10px", background: "#fff",
            }}>
          Notifications
        </h1>
        }
        <div className="dashboard scrollbar-wrap" style={getUserInfo()?.role == "ADVISORS" ?
            {height: "100vh"} : {}}>
          {/*//scrollbar-wrap*/}
          <input className="fakeInput" type="text" ref={fakeInput} />
          <div className="tab-wrap">
            <ul className="tabs">
              <li className={isSelect == 1 ? "current" : ""} data-tab="tab1" onClick={() => {
                notificationContent(1, 10)
                setisSelect(1)
                setPerPage(1)
                setLimit(10)
                setCurrentPage(1)
              }}>All</li>
              <li className={isSelect == 2 ? "current" : ""} data-tab="tab2" onClick={() => {
                unreadApiCall(1, 10)
                setisSelect(2)
                setPerPage(1)
                setLimit(10)
                setCurrentPage(1)
              }}>Unread</li>
              <li className={""} onClick={() => {
                markAllRead()
              }}>Mark all Read</li>
            </ul>
          </div>
          <div className="tab-contents">
            <div id="tab1" className="tab-content current">
              {console.log("updatedData ====>>>", updatedData)}
              <div className="notification-wrap">
                {updatedData?.length > 0 ?
                  updatedData.map((user, idx) => {
                    if (updatedData?.length == idx + 1) {
                      return <div className={user.isread == 0 ? "item active" : "item"} ref={lastBookElementRef} key={idx + 1}>
                        <div className="notification-date-item">
                          <div className="date-text"><span><Moment format="MMMM DD, YYYY" order="descending">
                            {user.createdon}
                          </Moment></span>
                            <span><Moment format="hh:mm a">{user.createdon}</Moment></span>
                          </div>
                        </div>
                        <div className={user.isread == 0 ? "notification-item" : "notification-item"} onClick={() => readValue(user.id)} style={user.isread ? { backgroundColor: "white" } : { backgroundColor: "lightgray" }}>
                          {getUserInfo()?.role == "ADMIN" ?
                              <div className="user-profile">
                                {!!user.athleteid ?
                                    <Link
                                        to={`/athlete/${user?.adminnotificationathleteidmaprel?.Athletes?.[0]?.athletefirstname?.trim()?.toLowerCase()?.split(" ")?.join('-')}-${user?.adminnotificationathleteidmaprel?.Athletes?.[0]?.athletelastname?.trim()?.toLowerCase()?.split(" ")?.join('-')}-${user?.adminnotificationathleteidmaprel?.Athletes[0]?.athleteuseridmaprel?.Userdata?.[0]?.sysgenid ?? 0}`}
                                        // to={`/publicProfile/1/${+user.athleteid}/${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.id}`}
                                          target="_blank">
                                {
                                  user?.adminnotificationathleteidmaprel?.Athletes[0]?.onBoardingUserIdMap?.Onboardings?.[0]?.profileimages == ("" || null || undefined) ? user?.adminnotificationathleteidmaprel?.Athletes?.[0]?.athletefirstname[0] + user?.adminnotificationathleteidmaprel?.Athletes?.[0]?.athletelastname?.[0]
                                  : <img
                                      src={`${ENV.DOWNLOAD_URL}/${user?.adminnotificationathleteidmaprel?.Athletes?.[0].onBoardingUserIdMap?.Onboardings?.[0]?.profileimages}`}/>
                                }
                                    </Link>
                                    :
                                    <span>
                                      {
                                        user?.adminnotificationathleteidmaprel?.Athletes[0]?.onBoardingUserIdMap?.Onboardings?.[0]?.profileimages == ("" || null || undefined) ? user?.adminnotificationathleteidmaprel?.Athletes?.[0]?.athletefirstname[0] + user?.adminnotificationathleteidmaprel?.Athletes?.[0]?.athletelastname?.[0]
                                            : <img
                                                src={`${ENV.DOWNLOAD_URL}/${user?.adminnotificationathleteidmaprel?.Athletes?.[0].onBoardingUserIdMap?.Onboardings?.[0]?.profileimages}`}/>
                                      }
                                    </span>
                                }
                              </div>
                              :
                              <div className="user-profile">
                                {!!user.athleteid ?
                                    <Link
                                        to={`/athlete/${user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.athletefirstname?.trim()?.toLowerCase()?.split(" ")?.join('-')}-${user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.athletelastname?.trim()?.toLowerCase()?.split(" ")?.join('-')}-${user?.advisornotificationathleteidmaprel?.Athletes[0]?.athleteuseridmaprel?.Userdata?.[0]?.sysgenid ?? 0}`}
                                        // to={`/publicProfile/1/${+user.athleteid}/${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.id}`}
                                        target="_blank">
                                      {user?.advisornotificationathleteidmaprel?.Athletes?.length > 0 ?
                                          (user?.advisornotificationathleteidmaprel?.Athletes[0]?.onBoardingUserIdMap?.Onboardings?.[0]?.profileimages == ("" || null || undefined) ?
                                                  user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.athletefirstname[0] + user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.athletelastname?.[0]
                                                  :
                                                  <img
                                                      src={`${ENV.DOWNLOAD_URL}/${user?.advisornotificationathleteidmaprel?.Athletes?.[0].onBoardingUserIdMap?.Onboardings?.[0]?.profileimages}`}
                                                  />
                                          )
                                          :
                                          user?.advisornotificationadminidmaprel?.Userdata?.length > 0 ?
                                              getAdminInitial(user?.advisornotificationadminidmaprel?.Userdata?.[0]?.fullname ?? "")
                                              :
                                              "ER"
                                      }
                                    </Link>
                                    :
                                    <span>
                                      {user?.advisornotificationathleteidmaprel?.Athletes?.length > 0 ?
                                          (user?.advisornotificationathleteidmaprel?.Athletes[0]?.onBoardingUserIdMap?.Onboardings?.[0]?.profileimages == ("" || null || undefined) ?
                                                  user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.athletefirstname[0] + user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.athletelastname?.[0]
                                                  :
                                                  <img
                                                      src={`${ENV.DOWNLOAD_URL}/${user?.advisornotificationathleteidmaprel?.Athletes?.[0].onBoardingUserIdMap?.Onboardings?.[0]?.profileimages}`}
                                                  />
                                          )
                                          :
                                          user?.advisornotificationadminidmaprel?.Userdata?.length > 0 ?
                                              getAdminInitial(user?.advisornotificationadminidmaprel?.Userdata?.[0]?.fullname ?? "")
                                              :
                                              "ER"
                                      }
                                    </span>}
                              </div>
                          }
                          <div className="user-detial-box">
                            <div className="item">
                              <h4 className="name" style={user.isread == 0 ? { fontWeight: "700" } : { fontWeight: 400 }}>{user.notificationcontent}</h4>
                            </div>
                            <div className="item">
                              <div className="time-text">
                                <TimeDate TimeDate = {user.createdon}/>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    } else {
                      return <div className={user.isread == 0 ? "item active" : "item"}>
                        <div className="notification-date-item">
                          <div className="date-text"><span><Moment format="MMMM DD, YYYY" order="descending">
                            {user.createdon}
                          </Moment></span>
                            <span><Moment format="hh:mm a">{user.createdon}</Moment>
                            </span>
                          </div>
                        </div>
                        <div className={user.isread == 0 ? "notification-item" : "notification-item"} style={user.isread ? { backgroundColor: "white" } : { backgroundColor: "lightgray" }}>
                          {getUserInfo()?.role == "ADMIN" ?
                              <div className="user-profile">
                                {!!user.athleteid ?
                                <Link
                                    to={`/athlete/${user?.adminnotificationathleteidmaprel?.Athletes?.[0]?.athletefirstname?.trim()?.toLowerCase()?.split(" ")?.join('-')}-${user?.adminnotificationathleteidmaprel?.Athletes?.[0]?.athletelastname?.trim()?.toLowerCase()?.split(" ")?.join('-')}-${user?.adminnotificationathleteidmaprel?.Athletes[0]?.athleteuseridmaprel?.Userdata?.[0]?.sysgenid ?? 0}`}
                                    // to={`/publicProfile/1/${+user.athleteid}/${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.id}`}
                                    target="_blank"
                                >
                                {user?.adminnotificationathleteidmaprel?.Athletes[0]?.onBoardingUserIdMap?.Onboardings?.[0]?.profileimages == ("" || null || undefined) ? user?.adminnotificationathleteidmaprel?.Athletes?.[0]?.athletefirstname[0] + user?.adminnotificationathleteidmaprel?.Athletes?.[0]?.athletelastname?.[0]
                                  : <img src={`${ENV.DOWNLOAD_URL}/${user?.adminnotificationathleteidmaprel?.Athletes?.[0].onBoardingUserIdMap?.Onboardings?.[0]?.profileimages}`}/>
                                }
                                </Link>
                                 :
                                    <span>
                                      {user?.adminnotificationathleteidmaprel?.Athletes[0]?.onBoardingUserIdMap?.Onboardings?.[0]?.profileimages == ("" || null || undefined) ? user?.adminnotificationathleteidmaprel?.Athletes?.[0]?.athletefirstname[0] + user?.adminnotificationathleteidmaprel?.Athletes?.[0]?.athletelastname?.[0]
                                          : <img src={`${ENV.DOWNLOAD_URL}/${user?.adminnotificationathleteidmaprel?.Athletes?.[0].onBoardingUserIdMap?.Onboardings?.[0]?.profileimages}`}/>
                                      }
                                    </span>
                                }
                              </div>
                              :
                              <div className="user-profile">
                                {!!user.athleteid ?
                                    <Link
                                        to={`/athlete/${user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.athletefirstname?.trim()?.toLowerCase()?.split(" ")?.join('-')}-${user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.athletelastname?.trim()?.toLowerCase()?.split(" ")?.join('-')}-${user?.advisornotificationathleteidmaprel?.Athletes[0]?.athleteuseridmaprel?.Userdata?.[0]?.sysgenid ?? 0}`}
                                        // to={`/publicProfile/1/${+user.athleteid}/${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.id}`}
                                          target="_blank">
                                      {user?.advisornotificationathleteidmaprel?.Athletes?.length > 0 ?
                                          (user?.advisornotificationathleteidmaprel?.Athletes[0]?.onBoardingUserIdMap?.Onboardings?.[0]?.profileimages == ("" || null || undefined) ?
                                                  user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.athletefirstname[0] + user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.athletelastname?.[0]
                                                  :
                                                  // <div>TT</div>
                                                  <img src={`${ENV.DOWNLOAD_URL}/${user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.onBoardingUserIdMap?.Onboardings?.[0]?.profileimages}`}/>

                                          )
                                          :
                                          user?.advisornotificationadminidmaprel?.Userdata?.length > 0 ?
                                              getAdminInitial(user?.advisornotificationadminidmaprel?.Userdata?.[0]?.fullname ?? "")
                                              :
                                              "ER"
                                      }
                                    </Link>
                                    :
                                    <span>
                                      {user?.advisornotificationathleteidmaprel?.Athletes?.length > 0 ?
                                          (user?.advisornotificationathleteidmaprel?.Athletes[0]?.onBoardingUserIdMap?.Onboardings?.[0]?.profileimages == ("" || null || undefined) ?
                                                  user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.athletefirstname[0] + user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.athletelastname?.[0]
                                                  :
                                                  // <div>TT</div>
                                                  <img src={`${ENV.DOWNLOAD_URL}/${user?.advisornotificationathleteidmaprel?.Athletes?.[0]?.onBoardingUserIdMap?.Onboardings?.[0]?.profileimages}`}/>
                                          )
                                          :
                                          user?.advisornotificationadminidmaprel?.Userdata?.length > 0 ?
                                              getAdminInitial(user?.advisornotificationadminidmaprel?.Userdata?.[0]?.fullname ?? "")
                                              :
                                              "ER"
                                      }
                                    </span>
                                }
                              </div>
                          }
                          <div className="user-detial-box" onClick={() => readValue(user.id)}>
                            <div className="item">
                              <h4 className="name" style={user.isread == 0 ? { fontWeight: "700" } : { fontWeight: 400 }}>{user.notificationcontent}</h4>
                            </div>
                            <div className="item">
                              <div className="time-text">
                                <TimeDate TimeDate = {user.createdon}/>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    }
                  }) : <p style={{ textAlign: "center" }}>
                      <img src={bell}/>
                      {/*No Record Found*/}
                </p>}

              </div>

            </div>
          </div>

        </div>
        {/*<GoTopButton*/}
        {/*  goTop={() => {*/}
        {/*    goTop();*/}
        {/*  }}*/}
        {/*/>*/}
      </div>
      {/*</div>*/}
    </>
  );
};

export default Notification;