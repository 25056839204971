import React from "react";
import { HashRouter, Redirect, Route, Switch, BrowserRouter, Router } from "react-router-dom";
import { Login } from "../components/auth/Login";
import Homepage from "../components/Dashboard/Homepage";
import { StatsApproval } from "../components/Dashboard/Athlete/statsApproval";
import DashboardHeader from "../components/header/DashboardHeader";
import DarkEditProfile from "../components/profile/ProfileEdit/DarkEditProfile";
import DashboardFooter from "../components/footer/DashboardFooter";
import Notification from "../components/Dashboard/notifyHomepage.js";
import Message from "../components/Dashboard/Message/Message.js";
import SupportAdvisorMessage from "../components/Dashboard/Message/SupportAdvisorMessage";
import AdvisorMessage from "../components/Dashboard/Message/AdvisorMessage";
import CoachMessage from "../components/Dashboard/Message/CoachMessage";
import TrashMessage from "../components/Dashboard/Message/TrashMessage";
import AdvisorArchiveMessage from "../components/Dashboard/Message/AdvisorArchiveMessage";
import ComposeMessage from "../components/Dashboard/Message/ComposeMessage";
import AthleteComposeMessage from "../components/Dashboard/Message/AthleteComposeMessage";
import SupportAdvisorComposeMessage from "../components/Dashboard/Message/SupportAdvisorComposeMessage";
import AdvisorComposeMessage from "../components/Dashboard/Message/AdvisorComposeMessage";
import SupportComposeMessage from "../components/Dashboard/Message/SupportComposeMessage";
import AdvisorReplyMessage from "../components/Dashboard/Message/AdvisorReplyMessage";
import AthleteReplyMessage from "../components/Dashboard/Message/AthleteReplyMessage";
import AdvisorSupportReplyMessage from "../components/Dashboard/Message/AdvisorSupportReplyMessage";
import AdminHomepage from "../components/Dashboard/AdminHomepage";
import AdvisorHomePage from "../components/Dashboard/AdvisorHomepage";
import { SideBar } from "../components/header/adminSideBar";
import { AdminRoutes, AdvisorRoutes } from "./manageRoutes";
import { AthleteInfo } from "../components/Dashboard/Athlete/athleteInfo";
import { ForgotPassword } from "../components/auth/forgotPassword";
import { ChangePassword } from "../components/auth/changePassword";
import { TagSchlClg } from "../components/Dashboard/TagSchool";
import { AddNewAthlete } from "../components/Dashboard/AddNewAthlete";
import { CommitmentStory } from "../components/Dashboard/commitmentStory";
import PasswordChange from "../components/Dashboard/AccountSettings/PasswordChange";
import NotificationSetting from "../components/Dashboard/AccountSettings/NotificationSetting";
//import AdminNotificationSetting from "../components/Dashboard/AccountSettings/AdminNotificationSetting";
import NewClub from "../components/landingPage/newClub";
import {AdvisorInfo} from "../components/Dashboard/Advisor/advisor";
import {AthleteConnect} from "../components/Dashboard/Advisor/athleteListing";
import {getUserInfo} from '../utils/constant'
export default function Routes() {
  return (
      <Switch>
        <Route
          exact={true}
          path="/"
          render={(e, props) => (
            <>
              <Login {...e} data={props} />
            </>
          )}
        />
        <Route
          exact={true}
          path="/forgotPassword"
          render={(e, props) => (
            <>
              <ForgotPassword {...e} data={props} />
            </>
          )}
        />
        <Route
          exact={true}
          path="/changePassword/:email"
          render={(e, props) => <ChangePassword {...e} data={props} />}
        />
        {/*admin routes*/}
        <AdminRoutes exact={true} path="/dashboard" components={Homepage} />
        <AdminRoutes
          exact={true}
          path="/statsApproval"
          components={StatsApproval}
        />
        <AdminRoutes
          exact={true}
          path="/athleteInfo"
          components={AthleteInfo}
        />
        <AdminRoutes
          exact={true}
          path="/notification"
          components={Notification}
        />
        <AdminRoutes
          exact={true}
          path="/tagSchoolCollege/:athName/:clgID"
          components={TagSchlClg}
        />
        <AdminRoutes
          exact={true}
          path="/commitmentStory"
          components={CommitmentStory}
        />
        <AdminRoutes
          exact={true}
          path="/message/athlete"
          components={Message}
        />
        <AdminRoutes
          exact={true}
          path="/message/advisor"
          components={SupportAdvisorMessage}
        />
        <AdminRoutes
          exact={true}
          path="/message/coach"
          components={CoachMessage}
        />
        <AdminRoutes
          exact={true}
          path="/message/archive"
          components={TrashMessage}
        />
        <AdminRoutes
          exact={true}
          path="/message/coach/composeMessage"
          components={ComposeMessage}
        />
        <AdminRoutes
          exact={true}
          path="/message/athlete/composeMessage"
          components={AthleteComposeMessage}
        />
        <AdminRoutes
          exact={true}
          path="/message/athlete/replyMessage"
          components={AthleteReplyMessage}
        />
        <AdminRoutes
          exact={true}
          path="/message/advisor/supportReplyMessage"
          components={AdvisorSupportReplyMessage}
        />
        <AdminRoutes
          exact={true}
          path="/message/support/advisor/composeMessage"
          components={SupportAdvisorComposeMessage}
        />
        <AdminRoutes
          exact={true}
          path="/admin/passwordChange"
          components={PasswordChange}
        />
        <AdminRoutes
          exact={true}
          path="/addNewAthlete"
          components={AddNewAthlete}
        />
          <AdminRoutes
              exact={true}
              path="/editNewAthlete/:id"
              components={AddNewAthlete}
          />
          <AdminRoutes
              exact={true}
              path="/advisorinfo"
              components={AdvisorInfo}
          />
          <AdminRoutes
              exact={true}
              path="/advisor/athletelisting/:id"
              components={AthleteConnect}
          />
        {/* <AdminRoutes
          exact={true}
          path="/admin/notificationSetting"
          components={AdminNotificationSetting}
        /> */}
        {/*//end admin routs*/}
        {/*//advisor routes*/}
        <AdvisorRoutes
          exact={true}
          path="/advisor/dashboard"
          components={AdvisorHomePage}
        />
        <AdvisorRoutes
          exact={true}
          path="/advisor/myAthlete"
          components={AthleteInfo}
        />
        <AdvisorRoutes
          exact={true}
          path="/advisor/tagSchoolCollege/:athName/:clgID"
          components={TagSchlClg}
        />
        <Route
          exact={true}
          path="/advisor/notification"
          render={(e, props) =>
            JSON.parse(localStorage.getItem("user_Admin_Advisor")).role ===
            "ADVISORS" ? (
              <>
                <DashboardHeader />
                <Notification />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          }
        />
        <Route
          exact={true}
          path="/message/advisor/athlete"
          render={(e, props) =>
            JSON.parse(localStorage.getItem("user_Admin_Advisor")).role ===
            "ADVISORS" ? (
              <>
                <DashboardHeader />
                <AdvisorMessage />
                <DashboardFooter />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          }
        />

        <Route
          exact={true}
          path="/message/support/composeMessage"
          render={(e, props) =>
            JSON.parse(localStorage.getItem("user_Admin_Advisor")).role ===
            "ADVISORS" ? (
              <>
                <DashboardHeader />
                <SupportComposeMessage />
                <DashboardFooter />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          }
        />

        <Route
          exact={true}
          path="/message/advisor/archive"
          render={(e, props) =>
            JSON.parse(localStorage.getItem("user_Admin_Advisor")).role ===
            "ADVISORS" ? (
              <>
                <DashboardHeader />
                <AdvisorArchiveMessage />
                <DashboardFooter />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          }
        />

        <Route
          exact={true}
          path="/message/advisor/composeMessage"
          render={(e, props) =>
            JSON.parse(localStorage.getItem("user_Admin_Advisor")).role ===
            "ADVISORS" ? (
              <>
                <DashboardHeader />
                <AdvisorComposeMessage />
                <DashboardFooter />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          }
        />

        <Route
          exact={true}
          path="/message/advisor/replyMessage"
          render={(e, props) =>
            JSON.parse(localStorage.getItem("user_Admin_Advisor")).role ===
            "ADVISORS" ? (
              <>
                <DashboardHeader />
                <AdvisorReplyMessage />
                <DashboardFooter />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          }
        />

        <Route
          exact={true}
          path="/passwordChange"
          render={(e, props) =>
            JSON.parse(localStorage.getItem("user_Admin_Advisor")).role ===
            "ADVISORS" ? (
              <>
                <DashboardHeader />
                <PasswordChange />
                <DashboardFooter />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          }
        />

        <Route
          exact={true}
          path="/notification-settings"
          render={(e, props) =>
            JSON.parse(localStorage.getItem("user_Admin_Advisor")).role ===
            "ADVISORS" ? (
              <>
                <DashboardHeader />
                <NotificationSetting />
                <DashboardFooter />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          }
        />

        {/*//end advisor routes*/}

        <Route
          exact={true}
          path="/publicProfile/:isToken/:id/:advisorId"
          render={(e, props) =>
            localStorage.getItem("user_Admin_Advisor") ? (
              <>
                <DashboardHeader />
                <DarkEditProfile
                  {...e}
                  publicProfile={true}
                  isPublic={true}
                  data={props}
                />
                <DashboardFooter />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          }
        />

<Route
          exact={true}
          path="/Club"
          render={(e, props) =>
            <>
              <DashboardHeader />
              <NewClub {...e} data={props} />
              <DashboardFooter />
            </>
          }
        />

        <Route
          exact={true}
          path="/advisor/publicProfile/:isToken/:id/:advisorId"
          render={(e, props) =>
            localStorage.getItem("authToken_Admin_Advisor") ? (
              <>
                <DashboardHeader />
                <DarkEditProfile
                  {...e}
                  publicProfile={true}
                  isPublic={true}
                  data={props}
                />
                <DashboardFooter />
              </>
            ) : (
              <Redirect to="/"> </Redirect>
            )
          }
        />
          <Route
              exact={true}
              path="/athlete/:athleteFullName"
              render={(e, props) =>
                  localStorage.getItem("authToken_Admin_Advisor") ? (
                      <>
                          <DashboardHeader />
                          <DarkEditProfile
                              connectID = { getUserInfo()?.id ?? 0 }
                              {...e}
                              publicProfile={true}
                              isPublic={true}
                              data={props}
                          />
                          <DashboardFooter />
                      </>
                  ) : (
                      <Redirect to="/"> </Redirect>
                  )
              }
          />
      </Switch>
  );
}
