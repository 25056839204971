import services from "../../services";
import swal from 'sweetalert'
import {getUserInfo} from "../../utils/constant";
import toastr from 'toastr'

export const flameAPI = async (title, athleteID, userID,isPopShow = false, role, isTag = false, data = undefined) =>{
    const body = {
        title: !!title ? title : "flamefromprofile",
        athleteId: !!athleteID ? athleteID : 0,   // jisse hum flames kr rhe hai
        userId: userID,   // jo flame kr raha hai,
        role: role,
        ...(isTag &&  {collegeid : data?.collegeId ?? 0})
    }
    const res = await services.location.flameAPI(body)
    if (res?.data?.response?.status == 200 && isPopShow){
        swal({text: "Notfication send", icon: "success"})
    }else{
    }
}
export const UpdateUserCollegeTable = async (dataObj) =>{
    const _res = await services.location.updateUserClg(dataObj)
    if (_res?.status == 200){
        return true
    }else{
        return false
    }
}

export const vidCountIncre = async (data, isAdmin = false) =>{
    //this check is admin cannot increase the count of the video
    if (!isAdmin){
         const body = {
             "videoid": data?.id ?? 0,
             "userid": isAdmin ? getUserInfo()?.id : 0,
             "adminorAdvisorid": !isAdmin ? getUserInfo()?.id : 0
         }
         const _res = await services.location.vidCount(body)
         if (_res?.status == 200){
         }else {
             toastr.error('Failed to increase video count')
         }
     }
}