import React, { useEffect, useRef, useState } from "react";
import '../header/assets/css/dashboard.scss'
import '../header/assets/css/stylesheet.scss'
import { AdminHeader } from "../header/adminHeader";
import { AdminFooter } from "../header/adminFooter";
import PhoneNumber from "../common/PhoneNumber";
import { IsValidEmail, numericValidation } from "../common/validation";
import {IsValidName, formatPhoneNumber, checkPhoneNumberValidation, NewValidName} from "../../utils/validationUtils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import BackdropLoader from "../common/Loader";
import { Dialog } from "@material-ui/core";
import services from "../../services";
import ENV from "../../config";
import toastr from 'toastr';
import { useHistory } from "react-router-dom";
import {RemoveAdvisorEditAth} from "../common/statsPopup";
export const curDate = (value) =>{
    const status =  moment(value).isAfter(moment(new Date()).format('YYYY-MM-DD'))
    if(status) return false
    return true
}

export const AddNewAthlete = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(1);
    const [allCountryList, setAllCountryList] = useState([]);
    const [statsSports, setStatsSports] = useState([]);
    const [statsGradyear, setStatsGradyear] = useState([]);
    const [newAthField, setNewAthField] = useState({ gender: "Male", mobileNumber: ""});
    const [newAthError, setNewAthError] = useState({});
    const [advisorData, setAdvisorData] = useState([]);
    const [athletePlans, setAthletePlans] = useState([]);
    const [athletePlanType, setAthletePlanType] = useState([]);
    const [isPlanOpen, setIsPlanOpen] = useState(false);
    const [isAdvisorOpen, setIsAdvisorOpen] = useState(false);
    const [isRemovePlanModal, setIsRemovePlanModal] = useState(false);
    const [isRemoveAdvisorModal, setIsRemoveAdvisorModal] = useState(false);
    const [formSequence, setFormSequence] = useState([]);
    const [isChangePop, setIsChangePop] = useState(false)
    const [isSubCancelled, setIsSubCancelled] = useState(false)
    const [isPlanChange, setIsPlanChange] = useState(false)
    const [clickCancelCount, setClickCancelCount] = useState(1)
    const [isEdit, setIsEdit] = useState(false)
    const [isAthPlan, setIsAthPlan] = useState(false)
    const [athCancelPlanPop, setAthCancelPlanPop] = useState(false)
    const [isRemoveAdvisorPop, setIsRemoveAdvisorPop] = useState(false)
    const [isRemoveAdvisorCheck, setIsRemoveAdvisorCheck] = useState(false)
    const [advisorRemoveData, setAdvisorRemoveData] = useState()
    const [isUserDeActivate, setIsUserDeActivate] = useState(false)
    const [allLeadSource, setAllLeadSource] = useState([])
    const [allAppointSetter, setAllAppointment] = useState([])
    const history = useHistory();

    useEffect(async () => {
        if(props?.match?.params?.id
            // history?.location?.state?.data?.athleteId
        ){
            setIsLoading(true)
            athleteData()
        }
        const response = await services.location.getCountriesData();
        if (response && response.status === 200 && response?.data?.data?.length > 0) {
            const allCountries = response.data.data ? response.data.data.map((item) => {
                if (item.countryFlagImage) {
                    item.countryFlagImage = `${ENV.DOWNLOAD_URL}/${item.countryFlagImage}`;
                }
                return item;
            }) : null;
            setAllCountryList(allCountries)
        }
        else {
            setAllCountryList([]);
        }

        const _res = await services?.location.getStatsFilterSports();
        if (_res?.status == 200) {
            const sportData = _res?.data?.data?.allAthletesports?.Athletesports?.length > 0 ? _res?.data?.data?.allAthletesports?.Athletesports : [];
            setStatsSports(sportData);
        } else {
            setStatsSports([]);
        }

        const _resGY = await services?.location.getStatsFilterGYear();
        if (_resGY?.status == 200) {
            const gData = _resGY?.data?.data?.allGraduationyears?.Graduationyears?.length > 0 ? _resGY?.data?.data?.allGraduationyears?.Graduationyears : [];
            setStatsGradyear(gData);
        } else {
            setStatsGradyear([]);
        }

        const _resAP = await services?.location.getAllSubscriptionPlan();
        if (_resAP?.status == 200) {
            const spData = _resAP?.data?.data?.allSubscriptionplans?.Subscriptionplans?.length > 0 ? _resAP?.data?.data?.allSubscriptionplans?.Subscriptionplans : [];
            setAthletePlans(spData);
        } else {
            setAthletePlans([]);
        }

        const _resAPT = await services?.location.getAllSubscriptionPlanType();
        if (_resAPT?.status == 200) {
            const sptData = _resAPT?.data?.data?.allSubscriptionplantypes?.Subscriptionplantypes?.length > 0 ? _resAPT?.data?.data?.allSubscriptionplantypes?.Subscriptionplantypes : [];
            setAthletePlanType(sptData);
        } else {
            setAthletePlanType([]);
        }
        const _resLead = await services?.location.getAllLeadSSource();
        if (_resLead?.status == 200) {
            const lsData = _resLead?.data?.data?.allLeadsources?.Leadsources?.length > 0 ? _resLead?.data?.data?.allLeadsources?.Leadsources : [];
            setAllLeadSource(lsData)
        } else {
            setAllLeadSource([]);
        }
        const _resAppointSetter = await services?.location.getAllAppointmentSetter();
        if (_resLead?.status == 200) {
            const asData = _resAppointSetter?.data?.data?.allAppointmentsetters?.Appointmentsetters?.length > 0 ? _resAppointSetter?.data?.data?.allAppointmentsetters?.Appointmentsetters : [];
            setAllAppointment(asData)
        } else {
            setAllAppointment([]);
        }

    }, []);

    const athleteData = async () =>{
        const athleteInfo = await services.location.getAthleteEditInfo({obj: {id:props?.match?.params?.id ?? 0}});
                // history?.location?.state?.data?.athleteId
        if (athleteInfo && athleteInfo.status === 200 && athleteInfo?.data?.data?.allAthletes?.Athletes?.length > 0) {
            setIsUserDeActivate(athleteInfo?.data?.data?.allAthletes?.Athletes?.[0]?.active == 0 ? true : false)
            let editAthField = {...newAthField}
            let athleteDetails = athleteInfo?.data?.data?.allAthletes?.Athletes?.[0]
            editAthField['firstName'] = athleteDetails?.athletefirstname
            editAthField['lastName'] = athleteDetails?.athletelastname
            editAthField['emailId'] = athleteDetails?.athleteemailid
            editAthField['gender'] = athleteDetails?.gender
            editAthField['sportId'] = athleteDetails?.athletesports
            editAthField['mobileNumber'] = athleteDetails?.athletephonenumber
            editAthField['gradeYearId'] = athleteDetails?.athletegraduationyear
            editAthField['referralpartner'] = athleteDetails?.referralpartner ?? ""
            editAthField['appointmentsetter'] = athleteDetails?.appointmentsetter ?? ""
            editAthField['frontendadvisor'] = athleteDetails?.frontendadvisor ?? ""
            editAthField['leadsource'] = athleteDetails?.leadsource ?? ""
            if(athleteDetails?.onBoardingUserIdMap?.Onboardings?.[0]?.dateofbirth){
                editAthField['dateOfBirth'] = athleteDetails?.onBoardingUserIdMap?.Onboardings?.[0]?.dateofbirth.replace('T00:00:00.000Z','');
            }
            editAthField['countryId'] = athleteDetails?.athletecountrycodeid
            if(checkMembershipData(athleteDetails?.membershipuseridMap?.Memberships ?? [])){
                const getIndex = getMembershipIdx(athleteDetails?.membershipuseridMap?.Memberships)
                editAthField['athletePlan'] = athleteDetails?.membershipuseridMap?.Memberships?.[getIndex]?.membershipsubscriptionplanidmaprel?.Subscriptionplans?.[0]?.id
                editAthField['planStartDate'] = athleteDetails?.membershipuseridMap?.Memberships?.[getIndex]?.planstartdate
                editAthField['athletePlanType'] = athleteDetails?.membershipuseridMap?.Memberships?.[getIndex]?.membershipsubscriptionplantypemaprel?.Subscriptionplantypes?.[0]?.id
                editAthField['installmentDueDate'] = athleteDetails?.membershipuseridMap?.Memberships?.[getIndex]?.planrenewaldate
                editAthField['installmentNumber'] = athleteDetails?.membershipuseridMap?.Memberships?.[getIndex]?.installmentnumber
                editAthField['lastPaymentDate'] = athleteDetails?.membershipuseridMap?.Memberships?.[getIndex]?.lastpaymentdate
                editAthField['issubscriptioncancelled'] = athleteDetails?.membershipuseridMap?.Memberships
                    ?.[0]?.issubscriptioncancelled
            }
            if(athleteDetails?.advisorathletemappingAthleteidMap?.Advisorathletemappings?.length && athleteDetails?.advisorathletemappingAthleteidMap?.Advisorathletemappings[0]?.advisorid){
                let advisorDetails = athleteDetails?.advisorathletemappingAthleteidMap?.Advisorathletemappings[0]?.advisorathletemappingadvisoridmaprel?.Advisors
                if(advisorDetails?.length){
                    editAthField['advisorFirstName'] = advisorDetails[0]['firstname']
                    editAthField['advisorLastName'] = advisorDetails[0]['lastname']
                    editAthField['advisorEmailId'] = {...advisorDetails[0]}
                }
                setAdvisorRemoveData(athleteDetails?.advisorathletemappingAthleteidMap?.Advisorathletemappings ?? 0)
            }
            const getIndex = athleteDetails?.membershipuseridMap?.Memberships?.length > 0 ?
                getMembershipIdx(athleteDetails?.membershipuseridMap?.Memberships ?? 0) : 0
            setIsSubCancelled(athleteDetails?.membershipuseridMap?.Memberships
                ?.[getIndex]?.issubscriptioncancelled == 0 ? false : true)
            // setIsAthPlan(athleteDetails?.membershipuseridMap?.Memberships?.length > 0 ? true : false)
            setIsRemoveAdvisorCheck(athleteDetails?.advisorathletemappingAthleteidMap?.Advisorathletemappings?.length > 0 ?
            true : false)
            setNewAthField({...editAthField})
            setIsEdit(true)
            setIsLoading(false)
        }else{
            setIsLoading(false)
        }
    }
    const checkMembershipData = (data) =>{
        let status = false
        if (!!data && data?.length > 0){
             data?.map((el) => {
                if (el?.active == 1) return status = true
             })
        }
        if (status){
            setIsAthPlan(true)
        }else{
            setIsAthPlan(false)
        }
        return status
    }
    const getMembershipIdx = (arr) =>{
        let memIdx = 0
        if (arr?.length > 0){
             arr?.map((el,idx)=>{
                if (el?.active == 1) memIdx = idx
             })
        }
        return memIdx
    }

    const handleCountryChange = (option) => {
        const cloneState = newAthField;
        cloneState["countryDetails"] = option;
        setNewAthField({ ...cloneState })
        setNewAthError({})
    };

    const onHandleChange = (e) => {
        const cloneState = newAthField;
        if (e?.target?.name == "firstName" || e?.target?.name == "lastName") {
            const regTest = /^[A-Za-z]+$/,
                testValue = e?.target?.value?.split("")[0]
            if (!regTest.test(testValue)) return
            cloneState[e?.target?.name] = e?.target?.value
        }
        else if (e?.target?.name == "mobileNumber") {
            if (e.target.value?.length > 10) return
            cloneState[e?.target?.name] = formatPhoneNumber(e?.target?.value)
        }
        else if (e?.target?.name == "Male" || e?.target?.name == "Female") {
            cloneState['gender'] = e?.target?.name
        }
        else if (e?.target?.name == "installmentNumber") {
            if (!numericValidation(e?.target?.value)){
                cloneState[e?.target?.name] = ""
            }
            else{
                cloneState[e?.target?.name] = e?.target?.value
            }
        }
        else if (e?.target?.name == "athletePlanType") {
            if (e?.target?.value == 1){
                cloneState["installmentDueDate"] = "";
                cloneState["installmentNumber"] = "";
                cloneState["lastPaymentDate"] = "";
                cloneState[e?.target?.name] = e?.target?.value
            }
            else{
                cloneState[e?.target?.name] = e?.target?.value
            }
        }
        else if (e?.target?.name === "advisorEmailId" && typeof (e?.target?.value) === "object") {
            cloneState['advisorFirstName'] = e?.target?.value?.firstname
            cloneState['advisorLastName'] = e?.target?.value?.lastname
            cloneState['advisorEmailId'] = e?.target?.value
        }
        else if ((e?.target?.name === "advisorFirstName" && typeof (cloneState["advisorEmailId"]) === "object" && cloneState?.advisorEmailId?.firstname != e?.target?.value)) {
            cloneState['advisorEmailId'] = "";
        }
        else if ((e?.target?.name === "advisorLastName" && typeof (cloneState["advisorEmailId"]) === "object" && cloneState?.advisorEmailId?.lastname != e?.target?.value)) {
            cloneState['advisorEmailId'] = "";
        }
        else if(e?.target?.name == "athletePlan" && isEdit){
            setIsSubCancelled(true)
            setIsPlanChange(true)
            cloneState[e?.target?.name] = e?.target?.value
        }
        else {
            cloneState[e?.target?.name] = e?.target?.value
        }
        setNewAthField({ ...cloneState })
        setNewAthError({})
    }

    const validateAthleteForm = () => {
        let status = true,
            cloneError = newAthError;
        if (!newAthField?.firstName) {
            cloneError['firstName'] = true
            cloneError['errorFirstName'] = "Please enter your First Name."

            setNewAthError({ ...cloneError })
            return status = false
        }
        if (!!newAthField?.firstName) {
            if (!NewValidName(newAthField?.firstName)) {
                cloneError['firstName'] = true
                cloneError['errorFirstName'] = "Please enter a valid First Name"
                setNewAthError({ ...cloneError })
                return status = false
            }
        }

        if (!newAthField?.lastName) {
            cloneError['lastName'] = true
            cloneError['errorLastName'] = "Please enter your Last Name."
            setNewAthError({ ...cloneError })
            return status = false
        }
        if (!!newAthField?.lastName) {
            if (!NewValidName(newAthField?.lastName)) {
                cloneError['lastName'] = true
                cloneError['errorLastName'] = "Please enter a valid Last Name"
                setNewAthError({ ...cloneError })
                return status = false
            }
        }

        if (!newAthField?.emailId) {
            cloneError['emailId'] = true
            cloneError['errorEmailId'] = "Please enter athlete Email ID"
            setNewAthError({ ...cloneError })
            return status == false
        }
        if (!!newAthField?.emailId) {
            if (!IsValidEmail(!!newAthField?.emailId ? newAthField?.emailId : "")) {
                cloneError['emailId'] = true
                cloneError['errorEmailId'] = "Please enter a valid Email Id"
                setNewAthError({ ...cloneError })
                return status == false
            }
        }

        // if(!newAthField?.gender){
        //     cloneError['gender'] = true
        //     cloneError['errorGender'] = "Please enter athlete gender"
        //     setNewAthError({ ...cloneError })
        //     return status == false
        // }

        if (!newAthField?.sportId) {
            cloneError['sportId'] = true
            cloneError['errorSportId'] = "Please select athlete sport"
            setNewAthError({ ...cloneError })
            return status == false
        }
        if ('mobileNumber' in newAthField) {
            if (!!newAthField?.mobileNumber){
                if (!checkPhoneNumberValidation(newAthField?.mobileNumber, !!newAthField?.countryDetails?.countryShortName ?
                    newAthField?.countryDetails?.countryShortName : "US")){
                    cloneError['mobileNumber'] = true
                    cloneError['errorMobileNumber'] = "Please enter valid athlete mobile number"
                    setNewAthError({ ...cloneError })
                    return status == false
                }
            }else{
                cloneError['mobileNumber'] = true
                cloneError['errorMobileNumber'] = "Please enter athlete mobile number"
                setNewAthError({ ...cloneError })
                return status == false
            }
        }

        if (!newAthField?.gradeYearId) {
            cloneError['gradeYearId'] = true
            cloneError['errorGradeYearId'] = "Please select athlete grad year"
            setNewAthError({ ...cloneError })
            return status == false
        }

        if (!newAthField?.dateOfBirth) {
            cloneError['dateOfBirth'] = true
            cloneError['errorDateOfBirth'] = "Please enter athlete date of birth"
            setNewAthError({ ...cloneError })
            return status == false
        }

        if (!!newAthField?.referralpartner) {
            if (!NewValidName(newAthField?.referralpartner)) {
                cloneError['referralpartner'] = true
                cloneError['errorReferralpartner'] = "Please enter a valid referral partner."
                setNewAthError({ ...cloneError })
                return status = false
            }
        }

        if (!!newAthField?.frontendadvisor) {
            if (!NewValidName(newAthField?.frontendadvisor)) {
                cloneError['frontendadvisor'] = true
                cloneError['errorFrontendadvisor'] = "Please enter a valid front-end advisor."
                setNewAthError({ ...cloneError })
                return status = false
            }
        }

        if (isPlanOpen) {
            if (!newAthField?.athletePlan) {
                cloneError['athletePlan'] = true
                cloneError['errorAthletePlan'] = "Please selelct athlete plan"
                setNewAthError({ ...cloneError })
                return status == false
            }
            if (!newAthField?.planStartDate) {
                cloneError['planStartDate'] = true
                cloneError['errorPlanStartDate'] = "Please enter athlete plan start date"
                setNewAthError({ ...cloneError })
                return status == false
            }
            if (!newAthField?.athletePlanType) {
                cloneError['athletePlanType'] = true
                cloneError['errorAthletePlanType'] = "Please select athlete plan type"
                setNewAthError({ ...cloneError })
                return status == false
            }
            if(newAthField?.planStartDate && newAthField?.installmentDueDate && new Date(newAthField?.planStartDate) > new Date(newAthField?.installmentDueDate)){
                cloneError['installmentDueDate'] = true
                cloneError['errorInstallmentDueDate'] = "Installment due date should be greater than start date"
                setNewAthError({ ...cloneError })
                return status == false
            }
        }

        if (isAdvisorOpen) {
            if (!newAthField?.advisorEmailId) {
                cloneError['advisorEmailId'] = true
                cloneError['errorAdvisorEmailId'] = "Please enter advisor emailid"
                setNewAthError({ ...cloneError })
                return status == false
            }
            if (typeof(newAthField?.advisorEmailId) !== "object") {
                cloneError['advisorEmailId'] = true
                cloneError['errorAdvisorEmailId'] = "Please select advisor Email ID from list"
                setNewAthError({ ...cloneError })
                return status == false
            }
        }

        return status
    }

    const handleAdvisorChange = async (fields, e) => {
        let value = !!e?.target?.value ? e?.target?.value : "" || "";
        if (fields === "advisorFirstName") {
            let advisorLastName = typeof (newAthField?.advisorLastName) === "object" ? newAthField?.advisorLastName?.lastname : newAthField?.advisorLastName;
            let advisorEmailId = typeof (newAthField?.advisorEmailId) === "object" ? newAthField?.advisorEmailId?.emailid : newAthField?.advisorEmailId;
            let variables = { where: { active: 1, and: [] } };
            variables["where"]["and"].push({ firstname: { like: `%${value}%` } });
            if (advisorLastName) variables["where"]["and"].push({ lastname: { like: `%${advisorLastName}%` } });
            if (advisorEmailId) variables["where"]["and"].push({ emailid: { like: `%${advisorEmailId}%` } });
            if (e.target.value.toString().length > 2) {
                let res = await services.location.getAllAdvisor(variables);
                if (res?.status == 200) {
                    setAdvisorData(res?.data?.data?.allAdvisors?.Advisors);
                }
                else {
                    setAdvisorData([]);
                }
            }
        }
        else if (fields === "advisorLastName") {
            let advisorFirstName = typeof (newAthField?.advisorFirstName) === "object" ? newAthField?.advisorFirstName?.firstname : newAthField?.advisorFirstName;
            let advisorEmailId = typeof (newAthField?.advisorEmailId) === "object" ? newAthField?.advisorEmailId?.emailid : newAthField?.advisorEmailId;
            let variables = { where: { active: 1, and: [] } };
            variables["where"]["and"].push({ lastname: { like: `%${value}%` } });
            if (advisorFirstName) variables["where"]["and"].push({ firstname: { like: `%${advisorFirstName}%` } });
            if (advisorEmailId) variables["where"]["and"].push({ emailid: { like: `%${advisorEmailId}%` } });

            if (e.target.value.toString().length > 2) {
                let res = await services.location.getAllAdvisor(variables);
                if (res?.status == 200) {
                    setAdvisorData(res?.data?.data?.allAdvisors?.Advisors);
                }
                else {
                    setAdvisorData([]);
                }
            }
        }
        else if (fields === "advisorEmailId") {
            let advisorFirstName = typeof (newAthField?.advisorFirstName) === "object" ? newAthField?.advisorFirstName?.firstname : newAthField?.advisorFirstName;
            let advisorLastName = typeof (newAthField?.advisorLastName) === "object" ? newAthField?.advisorLastName?.lastname : newAthField?.advisorLastName;
            let variables = { where: { active: 1, and: [] } };
            variables["where"]["and"].push({ emailid: { like: `%${value}%` } });
            if (advisorFirstName) variables["where"]["and"].push({ firstname: { like: `%${advisorFirstName}%` } });
            if (advisorLastName) variables["where"]["and"].push({ lastname: { like: `%${advisorLastName}%` } });

            if (e.target.value.toString().length > 2) {
                let res = await services.location.getAllAdvisor(variables);
                if (res?.status == 200) {
                    setAdvisorData(res?.data?.data?.allAdvisors?.Advisors);
                }
                else {
                    setAdvisorData([]);
                }
            }
        }
        else {
            setAdvisorData([]);
        }
    };

    const removePlanInformation = () => {
        // if(newAthField?.athletePlan || newAthField?.planStartDate || newAthField?.athletePlanType || newAthField?.installmentDueDate || newAthField?.installmentNumber){
        //     setIsRemovePlanModal(true)
        // }
        // else{
            handlePlanInformation(false)
        // }
    }

    const saveNewAthlete = async (sendInvite = false) => {
        setIsLoading(true)
        const athletePayload = {
            "firstName": !!newAthField?.firstName ? newAthField?.firstName?.trim() : "",
            "lastName": !!newAthField?.lastName ? newAthField?.lastName?.trim() : "",
            "emailId": newAthField?.emailId,
            "mobileCountryCode": newAthField?.countryDetails ? newAthField?.countryDetails?.value
                : newAthField?.countryId ?? 1,
            "mobileNumber": newAthField?.mobileNumber,
            "sport": parseInt(newAthField?.sportId),
            "gradYear": parseInt(newAthField?.gradeYearId),
            "dateOfBirth": newAthField?.dateOfBirth,
            "gender": newAthField?.gender,
            "planProvided": isPlanOpen,
            "plan": isPlanOpen ? parseInt(newAthField?.athletePlan) : 0,
            "planStartDate": isPlanOpen ? newAthField?.planStartDate : "",
            "planType": isPlanOpen ? parseInt(newAthField?.athletePlanType) : 0,
            "installmentDueDate": isPlanOpen ? newAthField?.installmentDueDate ?? "" : "",
            "installmentNumber": isPlanOpen ? !!newAthField?.installmentNumber  ? newAthField?.installmentNumber?.toString()  : "" : "",
            "installmentLastDate": isPlanOpen ? newAthField?.lastPaymentDate ?? "" : "",
            "advisorProvided": isAdvisorOpen,
            "advisorId": newAthField?.advisorEmailId && typeof (newAthField?.advisorEmailId) === "object" ? newAthField?.advisorEmailId?.id : 0,
            "sendInvite": sendInvite,
            "isEdit": !!props?.match?.params?.id ? 1 : 0,
            "athleteId": !!props?.match?.params?.id ? +props?.match?.params?.id : 0,
            "referralpartner": newAthField?.referralpartner ?? "",
            "appointmentsetter": !!newAthField?.appointmentsetter ? newAthField?.appointmentsetter?.toString() : "null"  ,
            "frontendadvisor": newAthField?.frontendadvisor ?? "",
            "leadsource": !!newAthField?.leadsource ? newAthField?.leadsource?.toString()  : "null"
        };
        const isValidate = validateAthleteForm();
        if (isValidate) {
            const _resCNA = await services?.location.createNewAthlete(athletePayload);
            if (_resCNA?.status == 200) {
                if(_resCNA?.data?.response?.status == 400){
                    toastr.error(_resCNA?.data?.response?.message ? _resCNA?.data?.response?.message : "Athlete not created!")
                }
                else{
                    toastr.success(_resCNA?.data?.response?.message ? _resCNA?.data?.response?.message : "Athlete created successfully")
                    history.push({ pathname: "/athleteInfo"})
                }
            } 
            else {
                toastr.error("Athlete not created try again!")
            }
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
            //toastr.error("Error Occurred!")
        }
    }

    const getDefaultCountry = () => {
        let countryT = {};
        if(newAthField?.countryId){
            allCountryList.map(item => {
                if (item.id == newAthField?.countryId) {
                    countryT.value = item.id;
                    countryT.label = item.countryName + `(+${item.countryCode})`;
                    countryT.flag = item.countryFlagImage;
                    countryT.countryCode = item.countryCode;
                    countryT.countryShortName = item.countryShortName
                }
            })
        }
        else{
            allCountryList.map(item => {
                if (item.id == 1) {
                    countryT.value = item.id;
                    countryT.label = item.countryName + `(+${item.countryCode})`;
                    countryT.flag = item.countryFlagImage;
                    countryT.countryCode = item.countryCode;
                    countryT.countryShortName = item.countryShortName
                }
            })
        }
        return countryT
    }

    const handlePlanInformation = (status) => {
        let formArray = formSequence;
        if (status) {
            formArray.push("plan")
        }
        else {
            formArray.splice(formArray.indexOf("plan"), 1)
            // const cloneState = newAthField;
            // cloneState["athletePlan"] = "";
            // cloneState["planStartDate"] = "";
            // cloneState["athletePlanType"] = "";
            // cloneState["installmentDueDate"] = "";
            // cloneState["installmentNumber"] = "";
            // cloneState["lastPaymentDate"] = "";
            // setNewAthField({ ...cloneState })
        }
        setFormSequence(formArray);
        setIsPlanOpen(status)
    }

    const handleAddAdvisor = (status) => {
        let formArray = formSequence;
        if (status) {
            formArray.push("advisor")
        }
        else {
            formArray.splice(formArray.indexOf("advisor"), 1)
        }
        setFormSequence(formArray);
        setIsAdvisorOpen(status)
    }

    const removeAddAdvisor = () => {
        const cloneState = newAthField;
        cloneState['advisorFirstName'] = ""
        cloneState['advisorLastName'] = ""
        cloneState['advisorEmailId'] = ""
        setNewAthField({ ...cloneState })
        setAdvisorData([]);
        setIsRemoveAdvisorModal(false)
    }

    const removeAddAdvisorPanel = () => {
        // if(newAthField?.advisorFirstName || newAthField?.advisorLastName || newAthField?.advisorEmailId) {
        //     setIsRemoveAdvisorModal(true)
        // }
        // else{
        //     removeAddAdvisor()
            handleAddAdvisor(false)
        // }
    }
    const  onEditSave = () =>{
        if (isPlanChange){
            setIsChangePop(true)
        }else{
            saveNewAthlete()
        }
    }

    const cancelPlan = async () =>{
           const body={athleteid: props?.match?.params?.id ?? 0},
               _res = await services.location.cancelAthletePlan(body)
        if (_res?.status == 200 && _res?.data?.response?.status == 200){
            toastr.success(!!_res?.data?.response?.message ? _res?.data?.response?.message
                : 'Plan canceled successfully.')
            const cloneState = newAthField;
            cloneState["athletePlan"] = "";
            cloneState["planStartDate"] = "";
            cloneState["athletePlanType"] = "";
            cloneState["installmentDueDate"] = "";
            cloneState["installmentNumber"] = "";
            cloneState["lastPaymentDate"] = "";
            setNewAthField({ ...cloneState })
            setAthCancelPlanPop(false)
            removePlanInformation()
            setTimeout(()=>{
                athleteData()
            },500)
        }else{
            toastr.error('plan cancel failed')
        }
    }
    const removeAdvisorEdit = async () =>{
        const body={athleteId : +props?.match?.params?.id ?? 0, advisorId: +advisorRemoveData?.[0]?.advisorid ?? 0,},
            _res = await services.location.removeAdvisorEditAth(body)
        if (_res?.status == 200 && _res?.data?.response?.status == 200){
            toastr.success(!!_res?.data?.response?.message ? _res?.data?.response?.message
                : 'Advisor removed successfully.')
            const cloneState = newAthField;
            cloneState['advisorFirstName'] = ""
            cloneState['advisorLastName'] = ""
            cloneState['advisorEmailId'] = ""
            setNewAthField({ ...cloneState })
            setIsRemoveAdvisorPop(false)
            removeAddAdvisorPanel()
            setTimeout(()=>{
                athleteData()
            },500)
        }else{
            toastr.error('remove advisor failed')
        }
    }
    return (
        <>
            <BackdropLoader open={isLoading} />
            <div className="content">
                <AdminHeader title={"Athlete Information"} />
                <div className="dashboard" style={{overflow:"hidden"}}>
                    <div className="filter-box tagged-filter">
                        <div className="search-box tab-width-none">
                            <div className="tab-wrap">
                                <ul className="tabs">
                                    <li className={tabIndex === 1 ? "current" : ""} onClick={() => setTabIndex(1)} data-tab="tab1">Athlete Information</li>
                                    {/* <li className={tabIndex === 2 ? "current" : ""} onClick={() => setTabIndex(2)} data-tab="tab2">Parent/Guardian Information</li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="white-box athlete-infofrom">
                        <div className="tab-contents">
                            <div id="tab1" className={tabIndex === 1 ? "tab-content current" : "tab-content"}>
                                <div className="athlete-formbox-item">
                                    <div className="form-group-row flexbox">
                                        <div className="form-group">
                                            <label>Athlete First Name <sup>*</sup></label>
                                            <input value={newAthField?.firstName ?? ""} onChange={(e) => onHandleChange(e)} name="firstName" type="text" id="firstname" placeholder="Athlete first name" />
                                            {newAthError?.firstName && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAthError?.errorFirstName ?? ""}
                                            </span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Athlete Last Name <sup>*</sup></label>
                                            <input value={newAthField?.lastName ?? ""} onChange={(e) => onHandleChange(e)} name="lastName" type="text" id="lastname" placeholder="Athlete Last Name" />
                                            {newAthError?.lastName && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAthError?.errorLastName ?? ""}
                                            </span>}
                                        </div>
                                        <div className="form-group" style={!!props?.match?.params?.id ? {opacity : "0.7"} : {}}>
                                            <label>Athlete Email ID <sup>*</sup></label>
                                            <input value={newAthField?.emailId ?? ""} onChange={(e) => onHandleChange(e)} name="emailId" type="text" id="emailid" placeholder="Athlete email id" disabled={!!props?.match?.params?.id ? true : false} />
                                            {newAthError?.emailId && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAthError?.errorEmailId ?? ""}
                                            </span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Select Gender <sup>*</sup></label>
                                            <div className="radioflexBox">
                                                <div className="option"><input type="radio" name="Male" checked={ newAthField?.gender == "Male" ? true : false } onChange={(e) => onHandleChange(e)} /><label>Male</label></div>
                                                <div className="option"><input type="radio" name="Female" checked={ newAthField?.gender == "Female" ? true : false } onChange={(e) => onHandleChange(e)} /><label>Female</label></div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Sport <sup>*</sup></label>
                                            <select name="sportId"
                                                    value={newAthField?.sportId}
                                                    onChange={(e) =>
                                                newAthField?.gender ? onHandleChange(e) : toastr.error("Please Select Gender First")
                                            }>
                                                <option value={""}>Select Sport</option>
                                                {statsSports?.length > 0 &&
                                                    statsSports?.map((el) => {
                                                        if (el?.id == 1 && newAthField?.gender == "Male") {
                                                            return (
                                                                <option value={el?.id}
                                                                        // selected={ el?.id == newAthField?.sportId}
                                                                >
                                                                    {el?.sportname ?? ""}
                                                                </option>
                                                            );
                                                        } else if (el?.id == 4 && newAthField?.gender == "Female") {
                                                            return (
                                                                <option value={el?.id}>
                                                                    {el?.sportname ?? ""}
                                                                </option>
                                                            );
                                                        }
                                                    })}
                                            </select>
                                            {newAthError?.sportId && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAthError?.errorSportId ?? ""}
                                            </span>}
                                        </div>
                                        <div className="form-group phone">
                                            <label>Athlete Mobile Number <sup>*</sup></label>
                                            <PhoneNumber
                                                dropdownName="countryCode"
                                                dropdownOnChange={handleCountryChange}
                                                dropdownValue={newAthField?.countryDetails ?? getDefaultCountry()}
                                                dropDownOptions={
                                                    allCountryList?.map((item) => ({
                                                        value: item.id,
                                                        label: item.countryName + `(+${item.countryCode})`,
                                                        flag: item.countryFlagImage,
                                                        countryCode: item.countryCode,
                                                        countryShortName: item.countryShortName,
                                                    }))
                                                }
                                                formatOptionLabel={(option, { context, selectValue }) => {
                                                    return context === "menu" ? (
                                                        <>
                                                            <img
                                                                src={option.flag}
                                                                alt=""
                                                                style={{ width: "40px", marginRight: "15px" }}
                                                            />
                                                            <span>{option.label}</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                src={option.flag}
                                                                alt=""
                                                                style={{ width: "40px", marginRight: "15px" }}
                                                            />
                                                            <span>+{option.countryCode}</span>
                                                        </>
                                                    );
                                                }}
                                                inputPlaceholder="Phone Number"
                                                inputType="phonenumber"
                                                inputValue={newAthField?.mobileNumber || ""}
                                                inputOnChange={(e) => {
                                                    onHandleChange({
                                                        target: {
                                                            value: e.target.value.replace(/[^0-9]/gi, ""),
                                                            name: "mobileNumber"
                                                        },
                                                    });
                                                }}
                                            />
                                            {newAthError?.mobileNumber && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAthError?.errorMobileNumber ?? ""}
                                            </span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Grad Year <sup>*</sup></label>
                                            <select name="gradeYearId" onChange={(e) => onHandleChange(e)}>
                                                <option value={""}>Select Grad Year</option>
                                                {statsGradyear?.length > 0 &&
                                                    statsGradyear?.map((name, indx) => {
                                                        return <option value={name?.id} selected={newAthField?.gradeYearId == name?.id}>{name?.yearvalue}</option>
                                                    })}
                                            </select>
                                            {newAthError?.gradeYearId && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAthError?.errorGradeYearId ?? ""}
                                            </span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Date of Birth <sup>*</sup></label>
                                            {
                                                //Math.floor(moment(new Date()).diff(moment(e?.target?.value,"YYYY-MM-DD"),'years',true))
                                            }
                                            <input name="dateOfBirth" value={newAthField?.dateOfBirth}
                                                   onChange={(e) =>curDate(e?.target?.value)?
                                                       onHandleChange(e) : ""}
                                                   type="date" id="date" placeholder="mm/dd/yyyy"
                                                   max={moment().format("YYYY-MM-DD")}
                                            />
                                            {newAthError?.dateOfBirth && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAthError?.errorDateOfBirth ?? ""}
                                            </span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Referral Partner </label>
                                            <input value={newAthField?.referralpartner ?? ""} onChange={(e) => onHandleChange(e)}
                                                   name="referralpartner" type="text" placeholder="Referral partner" />
                                            {newAthError?.referralpartner && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAthError?.errorReferralpartner ?? ""}
                                            </span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Front-end Advisor </label>
                                            <input value={newAthField?.frontendadvisor ?? ""} onChange={(e) => onHandleChange(e)}
                                                   name="frontendadvisor" type="text"  placeholder="Front-end advisor" />
                                            {newAthError?.frontendadvisor && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAthError?.errorFrontendadvisor ?? ""}
                                            </span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Lead Source </label>
                                            <select name={'leadsource'} value={newAthField?.leadsource ?? ""}
                                            onChange={(e)=>onHandleChange(e)}
                                            >
                                                <option value={''}>Select option</option>
                                                {allLeadSource?.length > 0 &&
                                                    allLeadSource?.map(ls => <option value={ls?.id}>{ls?.name ?? ""}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Appointment Setter</label>
                                            <select name={'appointmentsetter'}
                                                    value={newAthField?.appointmentsetter ?? ""}
                                            onChange={(e)=>onHandleChange(e)}
                                            >
                                                <option value={''}>Select option</option>
                                                {allAppointSetter?.length > 0 &&
                                                allAppointSetter?.map(as => <option value={as?.id}>{as?.name ?? ""}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        !isPlanOpen && !isAdvisorOpen ? <div className="buttonWrap">
                                            <div className="buttonsLinks">
                                                <button type="button" onClick={() => handlePlanInformation(true)} className="btnLink">{isEdit ? isAthPlan ? "Edit Athlete Plan" : "Add Athlete Plan" : "Add Athlete Plan"}</button>
                                                <button type="button" onClick={() => handleAddAdvisor(true)} className="btnLink">{isEdit ? isRemoveAdvisorCheck ? "Edit Advisor" : "Add Advisor" : "Add Advisor"}</button>
                                            </div>
                                        </div> : null
                                    }

                                </div>

                                {
                                    formSequence.map(form => {
                                        if (form === "plan") {
                                            return (
                                                <div className={"athlete-formbox-item show"}>
                                                    <div className="titlebox">
                                                        <h3 className="title">Plan information</h3>
                                                        <div className="buttonbox">
                                                            {/* <button type="button" className="editLink">Edit</button> */}
                                                            <button type="button" onClick={() => { removePlanInformation() }}
                                                                    className="editLink removeLink"
                                                                    style={{textDecoration:"none"}}
                                                            ><span style={{fontSize:"35px"}}>-</span></button>
                                                        </div>
                                                    </div>
                                                    <div className="form-group-row flexbox">
                                                        <div className="form-group">
                                                            <label>Athlete Plan <sup>*</sup></label>
                                                            <select name="athletePlan" onChange={(e) => onHandleChange(e)}>
                                                                <option selected="" value={""}>Select plan</option>
                                                                {athletePlans?.length > 0 &&
                                                                    athletePlans?.map((plan, indx) => {
                                                                        return <option value={plan?.id} selected={newAthField?.athletePlan == plan?.id ? true : false}>{plan?.planname}</option>
                                                                    })}
                                                            </select>
                                                            {newAthError?.athletePlan && <span style={{ color: "red", fontSize: "12px" }}>
                                                                {newAthError?.errorAthletePlan ?? ""}
                                                            </span>}
                                                        </div>
                                                        <div className="form-group" style={isEdit ? !isSubCancelled ?
                                                                {opacity:"0.7"} : {} : {} }>
                                                            <label>Start Date <sup>*</sup></label>
                                                            <input name="planStartDate"
                                                                   style={isEdit ? !isSubCancelled ? {opacity:"0.7"} : {} : {}}
                                                                    disabled={isEdit ? !isSubCancelled : false}
                                                                   value={newAthField?.planStartDate}
                                                                   onChange={(e) =>{
                                                                       onHandleChange(e)
                                                                       setIsPlanChange(true)
                                                                   }} type="date" id="startdate" placeholder="13/01/2013" max="9999-12-31" />
                                                            {newAthError?.planStartDate && <span style={{ color: "red", fontSize: "12px" }}>
                                                                {newAthError?.errorPlanStartDate ?? ""}
                                                            </span>}
                                                        </div>
                                                        <div className="form-group" style={isEdit ? !isSubCancelled ?
                                                                {opacity:"0.7"} : {} : {}}>
                                                            <label>Plan Type <sup>*</sup></label>
                                                            <select name="athletePlanType"
                                                                    disabled={isEdit ? !isSubCancelled : false}
                                                                    onChange={(e) =>{
                                                                        onHandleChange(e)
                                                                        setIsPlanChange(true)
                                                                    }}>
                                                                <option selected="" value={""}>Select type</option>
                                                                {athletePlanType?.length > 0 &&
                                                                    athletePlanType?.map((plan, indx) => {
                                                                        return <option value={plan?.id} selected={newAthField?.athletePlanType == plan?.id ? true : false}>{plan?.plantype}</option>
                                                                    })}
                                                            </select>
                                                            {newAthError?.athletePlanType && <span style={{ color: "red", fontSize: "12px" }}>
                                                                {newAthError?.errorAthletePlanType ?? ""}
                                                            </span>}
                                                        </div>
                                                        <div className="form-group" style={isEdit ?
                                                            ( newAthField?.athletePlanType == 1 || !isSubCancelled ) ?
                                                                {opacity:"0.7"} : {} : newAthField?.athletePlanType == 1 ?
                                                                {opacity:"0.7"} : {}}>
                                                            <label>Installment Due Date</label>
                                                            <input name="installmentDueDate"
                                                                   style={isEdit ? !isSubCancelled ? {opacity:"0.7"} : {} : {}}
                                                                   disabled={isEdit ? !isSubCancelled : false}
                                                                   value={newAthField?.installmentDueDate} onChange={(e) =>{
                                                                       onHandleChange(e)
                                                                setIsPlanChange(true)
                                                            }} type="date" id="installmentdate" placeholder="13/01/2013"
                                                                   disabled={isEdit ?
                                                                       ((!isSubCancelled || newAthField?.athletePlanType == 1) ?
                                                                           true : false) :
                                                                       newAthField?.athletePlanType == 1 ? true : false}
                                                                   max="9999-12-31" />
                                                            {newAthError?.installmentDueDate && <span style={{ color: "red", fontSize: "12px" }}>
                                                                {newAthError?.errorInstallmentDueDate ?? ""}
                                                            </span>}
                                                        </div>
                                                        <div className="form-group" style={isEdit ?
                                                            ( newAthField?.athletePlanType == 1 || !isSubCancelled ) ?
                                                                {opacity:"0.7"} : {} : newAthField?.athletePlanType == 1 ?
                                                                {opacity:"0.7"} : {}}>
                                                            <label>Installment Paid</label>
                                                            <input name="installmentNumber"
                                                                   style={isEdit ? !isSubCancelled ? {opacity:"0.7"} : {} : {}}
                                                                   disabled={isEdit ? !isSubCancelled : false}
                                                                   onChange={(e) =>{
                                                                       onHandleChange(e)
                                                                       setIsPlanChange(true)
                                                                   }} value={newAthField?.installmentNumber} type="text" id="installmentnumber" placeholder="Installment number"
                                                                   disabled={isEdit ?
                                                                       ((!isSubCancelled || newAthField?.athletePlanType == 1) ?
                                                                           true : false) :
                                                                       newAthField?.athletePlanType == 1 ? true : false} />
                                                            {newAthError?.installmentNumber && <span style={{ color: "red", fontSize: "12px" }}>
                                                                {newAthError?.errorInstallmentNumber ?? ""}
                                                            </span>}
                                                        </div>
                                                        <div className="form-group" style={isEdit ?
                                                            ( newAthField?.athletePlanType == 1 || !isSubCancelled ) ?
                                                                {opacity:"0.7"} : {} : newAthField?.athletePlanType == 1 ?
                                                                {opacity:"0.7"} : {}}>
                                                            <label>Last Payment Date</label>
                                                            <input name="lastPaymentDate"
                                                                   style={isEdit ? !isSubCancelled ? {opacity:"0.7"} : {} : {}}
                                                                   disabled={isEdit ? !isSubCancelled : false}
                                                                   value={newAthField?.lastPaymentDate} onChange={(e) =>{
                                                                       onHandleChange(e)
                                                                setIsPlanChange(true)
                                                            }} type="date" id="installmentdate" placeholder="13/01/2013"
                                                                   disabled={isEdit ?
                                                                       ((!isSubCancelled || newAthField?.athletePlanType == 1) ?
                                                                           true : false) :
                                                                       newAthField?.athletePlanType == 1 ? true : false}
                                                                   max="9999-12-31" />
                                                            {newAthError?.lastPaymentDate && <span style={{ color: "red", fontSize: "12px" }}>
                                                                {newAthError?.errorLastPaymentDate ?? ""}
                                                            </span>}
                                                        </div>
                                                    </div>
                                                    <div className="buttonWrap">

                                                            <div className="buttonsLinks">
                                                                 {/*<button type="button" className="btnLink">Plan information</button>*/}
                                                                {
                                                                    !isAdvisorOpen &&
                                                                        <button type="button" className="btnLink"
                                                                                onClick={() => handleAddAdvisor(true)}>{isEdit ? isRemoveAdvisorCheck ? "Edit Advisor" : "Add Advisor" : "Add Advisor"}</button>
                                                                }
                                                                {(isEdit && isAthPlan) &&
                                                                <button class="btn cancel" disabled={isUserDeActivate} style={{padding:"5px 20px"}}
                                                                onClick={()=>setAthCancelPlanPop(true)}
                                                                >Cancel Plan</button>
                                                                }
                                                            </div>
                                                        </div>
                                                </div>
                                            )
                                        }
                                        else if (form === "advisor") {
                                            return (
                                                <div className={isAdvisorOpen ? "athlete-formbox-item show" : "athlete-formbox-item hide"}>
                                                    <div className="titlebox">
                                                        <h3 className="title">Add Advisor</h3>
                                                        <div className="buttonbox">
                                                            {/* <button type="button" className="editLink">Edit</button> */}
                                                            <button type="button" onClick={() => { removeAddAdvisorPanel() }}
                                                                    className="editLink removeLink"
                                                            style={{textDecoration:"none"}}
                                                            >
                                                                <span style={{fontSize:"35px"}}>-</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="form-group-row flexbox">
                                                        <div className="form-group">
                                                            <label>Advisor First Name <sup>*</sup></label>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                className="search-input"
                                                                freeSolo={true}
                                                                options={advisorData}
                                                                getOptionLabel={(option) =>
                                                                    option.firstname ? option.firstname : option
                                                                }
                                                                onChange={(e, data) => {
                                                                    onHandleChange({
                                                                        target: { value: data ? data : "", name: "advisorFirstName" },
                                                                    });
                                                                    // handleAdvisorChange("firstName", {
                                                                    //     target: {
                                                                    //         value: data ? data?.firstName : ""
                                                                    //     }
                                                                    // });
                                                                }}
                                                                value={newAthField.advisorFirstName || ""}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Advisor first name"
                                                                        value={newAthField.advisorFirstName || ""}
                                                                        onChange={(e) => {
                                                                            handleAdvisorChange("advisorFirstName", {
                                                                                target: {
                                                                                    value: e.target.value ? e.target.value : ""
                                                                                }
                                                                            });
                                                                            onHandleChange({
                                                                                target: {
                                                                                    value: e.target.value ? e.target.value : "",
                                                                                    name: "advisorFirstName"
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Advisor Last Name <sup>*</sup></label>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                className="search-input"
                                                                freeSolo={true}
                                                                options={advisorData}
                                                                getOptionLabel={(option) =>
                                                                    option.lastname ? option.lastname : option

                                                                }
                                                                onChange={(e, data) => {
                                                                    onHandleChange({
                                                                        target: { value: data ? data : "", name: "advisorLastName" },
                                                                    });
                                                                    handleAdvisorChange("advisorLastName", {
                                                                        target: {
                                                                            value: data ? data?.lastname : ""
                                                                        }
                                                                    });
                                                                }}
                                                                value={newAthField.advisorLastName || ""}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Advisor last name"
                                                                        value={newAthField.advisorLastName || ""}
                                                                        onChange={(e) => {
                                                                            handleAdvisorChange("advisorLastName", {
                                                                                target: {
                                                                                    value: e.target.value ? e.target.value : ""
                                                                                }
                                                                            });
                                                                            onHandleChange({
                                                                                target: {
                                                                                    value: e.target.value ? e.target.value : "",
                                                                                    name: "advisorLastName"
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Advisor Email ID <sup>*</sup></label>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                className="search-input"
                                                                freeSolo={true}
                                                                options={advisorData}
                                                                getOptionLabel={(option) =>
                                                                    option.emailid ? option.emailid : option
                                                                }
                                                                onChange={(e, data) => {
                                                                    onHandleChange({
                                                                        target: { value: data ? data : "", name: "advisorEmailId" },
                                                                    });
                                                                }}
                                                                value={newAthField.advisorEmailId || ""}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Advisor email id"
                                                                        value={newAthField.advisorEmailId || ""}
                                                                        onChange={(e) => {
                                                                            handleAdvisorChange("advisorEmailId", {
                                                                                target: {
                                                                                    value: e.target.value,
                                                                                },
                                                                            });
                                                                            onHandleChange({
                                                                                target: {
                                                                                    value: e.target.value ? e.target.value : "",
                                                                                    name: "advisorEmailId"
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {newAthError?.advisorEmailId && <span style={{ color: "red", fontSize: "12px" }}>
                                                                {newAthError?.errorAdvisorEmailId ?? ""}
                                                            </span>}
                                                        </div>
                                                    </div>
                                                    <div className="buttonWrap">
                                                        <div className="buttonsLinks">
                                                            {!isPlanOpen ? <button type="button" className="btnLink" onClick={() => handlePlanInformation(true)}>{isEdit ? isAthPlan ? "Edit Athlete Plan" : "Add Athlete Plan" : "Add Athlete Plan"}</button> : null}
                                                            {/* <button type="button" className="btnLink">Add Advisor</button> */}
                                                            {(isEdit && isRemoveAdvisorCheck)
                                                            &&
                                                            <button disabled={isUserDeActivate} className="btn cancel" style={{padding: "5px 20px"}}
                                                                    onClick={() => setIsRemoveAdvisorPop(true)}
                                                            >Remove Advisor</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                <div className="buttonWrap">
                                    <div className="buttons">
                                        <button type="button" disabled={isUserDeActivate} onClick={() =>isEdit ? onEditSave() :  saveNewAthlete()} className="btn lightblue">Save</button>
                                        {   !!props?.match?.params?.id ? null :
                                            <button type="button" onClick={() => saveNewAthlete(true)} className="btn">Save & Invite</button>
                                        }    
                                    </div>
                                </div>
                            </div>

                            {/* <div id="tab2" className={tabIndex === 2 ? "tab-content current" : "tab-content"}>
                                <form>
                                    <div className="athlete-formbox-item">
                                        <div className="titlebox">
                                            <h3 className="title">Parent/Guardian 1</h3>
                                        </div>
                                        <div className="form-group-row flexbox">
                                            <div className="form-group">
                                                <label>First Name <sup>*</sup></label>
                                                <input type="text" id="firstname" placeholder="First name" />
                                            </div>
                                            <div className="form-group">
                                                <label>Last Name <sup>*</sup></label>
                                                <input type="text" id="lastname" placeholder="Last name" />
                                            </div>
                                            <div className="form-group phone">
                                                <label>Mobile Number <sup>*</sup></label>
                                                <div className="flexBox">
                                                    <div className="countryFlag">
                                                        <img src="../../assets/img/americanFlag.png" alt="countryFlag" />
                                                    </div>
                                                    <select>
                                                        <option>+1</option>
                                                        <option>+91</option>
                                                        <option>+92</option>
                                                    </select>
                                                    <input type="text" placeholder="Mobile phone" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Primary Email <sup>*</sup></label>
                                                <input type="text" id="emailid" placeholder="Primary email id" />
                                            </div>
                                            <div className="form-group">
                                                <label>Relationship with athlete <sup>*</sup></label>
                                                <select>
                                                    <option>Select Relationship</option>
                                                    <option selected>Relationship</option>
                                                    <option>Relationship 1</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="add-parentBox">
                                            <button className="addLink">Add Parent/Guardian</button>
                                        </div>
                                    </div>
                                    <div className="athlete-formbox-item">
                                        <div className="titlebox">
                                            <h3 className="title">Parent/Guardian 1</h3>
                                            <div className="buttonbox">
                                                <button className="editLink" data-toggle="modal" data-target="#InfoNotSavedModal">Edit</button>
                                                <button className="editLink removeLink">Remove</button>
                                            </div>
                                        </div>
                                        <div className="form-group-row flexbox">
                                            <div className="form-group">
                                                <label>First Name <sup>*</sup></label>
                                                <input type="text" id="firstname" placeholder="First name" />
                                            </div>
                                            <div className="form-group">
                                                <label>Last Name <sup>*</sup></label>
                                                <input type="text" id="lastname" placeholder="Last name" />
                                            </div>
                                            <div className="form-group phone">
                                                <label>Mobile Number <sup>*</sup></label>
                                                <div className="flexBox">
                                                    <div className="countryFlag">
                                                        <img src="../../assets/img/americanFlag.png" alt="countryFlag" />
                                                    </div>
                                                    <select>
                                                        <option>+1</option>
                                                        <option>+91</option>
                                                        <option>+92</option>
                                                    </select>
                                                    <input type="text" placeholder="Mobile phone" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Primary Email <sup>*</sup></label>
                                                <input type="text" id="emailid" placeholder="Primary email id" />
                                            </div>
                                            <div className="form-group">
                                                <label>Relationship with athlete <sup>*</sup></label>
                                                <select>
                                                    <option>Select Relationship</option>
                                                    <option selected>Relationship</option>
                                                    <option>Relationship 1</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="add-parentBox">
                                            <button className="addLink">Add Parent/Guardian</button>
                                        </div>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                    </div>
                </div>

                <Dialog open={isRemovePlanModal}
                        onClose={(event, reason)=>{
                            if (reason == "backdropClick" || reason == "escapeKeyDown"){
                                setIsRemovePlanModal(false)
                            }
                        }}
                >
                    <div class="modal verifymodal" id="RemovePlanInformationModal" style={{display:"block", position:"initial", overflowY:"auto"}}>
                        <div class="modal-dialog modal-dialog-centered m-0" role="document">
                            <div class="modal-content">
                                <div class="modal-header border-none">
                                    <h5 class="modal-title">Remove Plan Information</h5>
                                    <button type="button" onClick={() => setIsRemovePlanModal(false)} class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <p>Are you sure you want to remove plan information?</p>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" onClick={() => { setIsRemovePlanModal(false); handlePlanInformation(false) }} class="btn blue" data-dismiss="modal">Yes</button>
                                    <button type="button" onClick={() => setIsRemovePlanModal(false)} class="btn cancel" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog open={isRemoveAdvisorModal}
                        onClose={(event, reason)=>{
                            if (reason == "backdropClick" || reason == "escapeKeyDown"){
                                setIsRemoveAdvisorModal(false)
                            }
                        }}
                >
                    <div class="modal verifymodal" id="RemoveAdvisorModal" style={{display:"block", position:"initial", overflowY:"auto"}}>
                        <div class="modal-dialog modal-dialog-centered m-0" role="document">
                            <div class="modal-content">
                                <div class="modal-header border-none">
                                    <h5 class="modal-title">Remove advisor</h5>
                                    <button type="button" onClick={() => setIsRemoveAdvisorModal(false)} class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <p>Are you sure you want to remove advisor details?</p>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" onClick={() => { removeAddAdvisor(); handleAddAdvisor(false) }} class="btn blue" data-dismiss="modal">Yes</button>
                                    <button type="button" onClick={() => setIsRemoveAdvisorModal(false)} class="btn cancel" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog open={isChangePop}
                        onClose={(event, reason)=>{
                            if (reason == "backdropClick" || reason == "escapeKeyDown"){
                                setIsChangePop(false)
                            }
                        }}
                >
                    <div className="modal unsaved-modal" id="ChangePlanModal" style={{display:"block", position:"initial", overflowY:"auto"}}>
                        <div className="modal-dialog modal-dialog-centered m-0" role="document">
                            <div className="modal-content">
                                <div className="modal-header border-none">
                                    <h5 className="modal-title">Change Plan</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                            onClick={()=>setIsChangePop(false)}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="icon-box">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="66.262" height="66.262"
                                             viewBox="0 0 66.262 66.262">
                                            <g id="Group_1948" data-name="Group 1948" transform="translate(3472 -2594)">
                                                <path id="Path_2192" data-name="Path 2192"
                                                      d="M36.006,7.768A28.244,28.244,0,0,1,55.977,55.977,28.244,28.244,0,0,1,16.035,16.035,28.046,28.046,0,0,1,36.006,7.768m0-4.393A32.631,32.631,0,1,0,68.637,36.006,32.626,32.626,0,0,0,36.006,3.375Z"
                                                      transform="translate(-3474.875 2591.125)" fill="#fdb944"
                                                      stroke="#fff" stroke-width="1"/>
                                                <g id="Group_1947" data-name="Group 1947"
                                                   transform="translate(-0.011 -0.016)">
                                                    <path id="Path_2211" data-name="Path 2211"
                                                          d="M16.5,33.309a2.62,2.62,0,0,0,5.24,0V17.59a2.62,2.62,0,0,0-5.24,0Z"
                                                          transform="translate(-3458.489 2596.046)" fill="#fdb944"/>
                                                    <path id="Path_2210" data-name="Path 2210"
                                                          d="M19.12,14.316a2.62,2.62,0,1,1,2.62-2.62A2.62,2.62,0,0,1,19.12,14.316Z"
                                                          transform="translate(-3458.489 2627.954)" fill="#fdb944"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="content-box">
                                        <p>Are you sure you want to change plan?</p>
                                        <p><em>Note: Payments will change to offline mode onwards</em></p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button"
                                            className="btn lightblue"
                                            data-dismiss="modal"
                                    onClick={()=>saveNewAthlete()}
                                    >Ok</button>
                                    <button type="button" className="btn" data-dismiss="modal"
                                            onClick={()=>setIsChangePop(false)}
                                    >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog open={athCancelPlanPop}
                        onClose={(event, reason)=>{
                            if (reason == "backdropClick" || reason == "escapeKeyDown"){
                                setAthCancelPlanPop(false)
                            }
                        }}
                >
                    <div className="modal unsaved-modal" id="ChangePlanModal" style={{display:"block", position:"initial", overflowY:"auto"}}>
                        <div className="modal-dialog modal-dialog-centered m-0" role="document">
                            <div className="modal-content">
                                <div className="modal-header border-none">
                                    <h5 className="modal-title">Cancel Plan</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                            onClick={()=>setAthCancelPlanPop(false)}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="icon-box">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="66.262" height="66.262"
                                             viewBox="0 0 66.262 66.262">
                                            <g id="Group_1948" data-name="Group 1948" transform="translate(3472 -2594)">
                                                <path id="Path_2192" data-name="Path 2192"
                                                      d="M36.006,7.768A28.244,28.244,0,0,1,55.977,55.977,28.244,28.244,0,0,1,16.035,16.035,28.046,28.046,0,0,1,36.006,7.768m0-4.393A32.631,32.631,0,1,0,68.637,36.006,32.626,32.626,0,0,0,36.006,3.375Z"
                                                      transform="translate(-3474.875 2591.125)" fill="#fdb944"
                                                      stroke="#fff" stroke-width="1"/>
                                                <g id="Group_1947" data-name="Group 1947"
                                                   transform="translate(-0.011 -0.016)">
                                                    <path id="Path_2211" data-name="Path 2211"
                                                          d="M16.5,33.309a2.62,2.62,0,0,0,5.24,0V17.59a2.62,2.62,0,0,0-5.24,0Z"
                                                          transform="translate(-3458.489 2596.046)" fill="#fdb944"/>
                                                    <path id="Path_2210" data-name="Path 2210"
                                                          d="M19.12,14.316a2.62,2.62,0,1,1,2.62-2.62A2.62,2.62,0,0,1,19.12,14.316Z"
                                                          transform="translate(-3458.489 2627.954)" fill="#fdb944"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="content-box">
                                        <p>Are you sure you want to cancel plan?</p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                            disabled={clickCancelCount == 1 ? false : true}
                                            type="button"
                                            className="btn lightblue"
                                            data-dismiss="modal"
                                            onClick={()=>{
                                                setClickCancelCount(clickCancelCount + 1)
                                                cancelPlan()
                                            }}
                                    >Ok</button>
                                    <button type="button" className="btn" data-dismiss="modal"
                                            onClick={()=>setAthCancelPlanPop(false)}
                                    >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog open={isRemoveAdvisorPop}
                        onClose={(event, reason)=>{
                            if (reason == "backdropClick" || reason == "escapeKeyDown"){
                                setIsRemoveAdvisorPop(false)
                            }
                        }}
                >
                    <RemoveAdvisorEditAth
                    onClose={()=>setIsRemoveAdvisorPop(false)}
                    onSubmit={()=>removeAdvisorEdit()}
                    />
                </Dialog>
                <AdminFooter />
            </div>
        </>
    )
}