import React, { useState, useEffect, useRef } from "react";
import "../../../assets/css/stylesheet.css";
import "../assets/css/athleteProfileFinalDark.scss";
import MyFeed from "./MyFeed";
import {Link, useLocation} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import SharePost from "./SharePost";
import services from "../../../services";
import swal from "sweetalert";
import ENV from "../../../config";
import {flameAPI} from "../../common/flameAPI";
import {getUserInfo} from '../../../utils/constant'
import {profileURLData} from "../../common/getProfileURLData";

const EditTimeline = (props) => {
    const location = useLocation()
  const Athid = profileURLData(location?.pathname, 'athlete_id')
      // +props?.props?.match?.params?.id
  let isToken = profileURLData(location?.pathname, 'token')
      // props?.props?.match?.params?.isToken == 1 ? 1 : 0;
  const [totalCount, setTotalCount] = useState(0);
  const [myPostList, setMyPostList] = useState(null);
  const [containPin, setContainPin] = useState(false);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  });
  const fakeInput = useRef(null);
  const pubViewID = JSON.parse(localStorage.getItem('pubViewID'))
  const [modalData, setModalData] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [isLike, setIsLike] = useState(false);
  useEffect(() => {
    getTimelineData(pagination.limit, pagination.offset);
    fakeInput.current.focus();
    fakeInput.current.select();
  }, [pubViewID]);

  useEffect(()=>{
    getTimelineData(pagination.limit, pagination.offset);
  },[isLike])

  const getTimelineData = async (limit, offset = 0) => {
   const res = await services.location.timelineData({athleteId: Athid,
    isAccessToken:  isToken ,
    limit: limit,
    offset: offset,
    role: getUserInfo()?.role,
    adminoradvisorid: getUserInfo()?.id ?? 0
   });
    if(res && res.status === 200 && res?.data?.response?.data?.ShareData?.status == 200){
    const data = res.data.response.data.ShareData.myFeedData;
      let finalData = [];
      if (data.allFavCollege && data.allFavCollege.length) {
        finalData = [
          ...finalData,
          ...data.allFavCollege.map((item) => {
            item["postType"] = "favorite";
            if (item.profileImages) {
              item.profileImages = `${ENV.DOWNLOAD_URL}/${item.profileImages}`;
            }
            return item;
          }),
        ];
      }
      if (data.Event && data.Event.length) {
        finalData = [
          ...finalData,
          ...data.Event.map((item) => {
            item["postType"] = "event";
            if (item.profileImages) {
              item.profileImages = `${ENV.DOWNLOAD_URL}/${item.profileImages}`;
            }
            return item;
          }),
        ];
      }
      if (data.allEvent && data.allEvent.length) {
        finalData = [
          ...finalData,
          ...data.allEvent.map((item) => {
            item["postType"] = "eventNew";
            if (item.profileImages) {
              item.profileImages = `${ENV.DOWNLOAD_URL}/${item.profileImages}`;
            }
            return item;
          }),
        ];
      }
      if (data.allMessage && data.allMessage.length) {
        finalData = [
          ...finalData,
          ...data.allMessage.map((item) => {
            item["postType"] = "message";
            if (item.msgSendByProfileImage) {
              item.msgSendByProfileImage = `${ENV.DOWNLOAD_URL}/${item.msgSendByProfileImage}`;
            }
            if (item.msgSendToProfileImage) {
              item.msgSendToProfileImage = `${ENV.DOWNLOAD_URL}/${item.msgSendToProfileImage}`;
            }
            return item;
          }),
        ];
      }
      if (data.allPost && data.allPost.length) {
        finalData = [
          ...finalData,
          ...data.allPost.map((item) => {
            item["postType"] = "post";
            if (item.profileImages) {
              item.profileImages = `${ENV.DOWNLOAD_URL}/${item.profileImages}`;
            }
            return item;
          }),
        ];
      }
      if (data.allProfile && data.allProfile.length) {
        finalData = [
          ...finalData,
          ...data.allProfile.map((item) => {
            item["postType"] = "profile";
            if (item.profileViewedByImageName) {
              item.profileViewedByImageName = `${ENV.DOWNLOAD_URL}/${item.profileViewedByImageName}`;
            }
            if (item.profileViewedToImageName) {
              item.profileViewedToImageName = `${ENV.DOWNLOAD_URL}/${item.profileViewedToImageName}`;
            }
            return item;
          }),
        ];
      }
      const sortedData = finalData.sort((a, b) => {
        return b.serialNo - a.serialNo;
      });
      let finalNewData = [];
      for (const post of sortedData) {
        if (post.pinedStatus) {
            finalNewData = [
            {
              ...post,
              isPostLiked:
                post.userpostLikeStatus && post.userpostLikeStatus[0]
                  ? post.userpostLikeStatus[0].isLike
                  : null,
              postLikeId:
                post.userpostLikeStatus && post.userpostLikeStatus[0]
                  ? post.userpostLikeStatus[0].id
                  : null,
              typeofPost: post?.allVideoAudioPost?.length > 0 
                  ? post?.allVideoAudioPost?.[0]?.typeofpost
                  : null,
              postimagevideoandlinkurl :  post?.allVideoAudioPost?.length > 0 
              ? post?.allVideoAudioPost?.[0]?.postimagevideoandlinkurl
              : null
            },
            ...finalNewData,
          ];
          setContainPin(true);
        } else {
            finalNewData = [
            ...finalNewData,
            {
              ...post,
              isPostLiked:
                post.userpostLikeStatus && post.userpostLikeStatus[0]
                  ? post.userpostLikeStatus[0].isLike
                  : null,
              postLikeId:
                post.userpostLikeStatus && post.userpostLikeStatus[0]
                  ? post.userpostLikeStatus[0].id
                  : null,
              typeofPost: post?.allVideoAudioPost?.length > 0 
                  ? post?.allVideoAudioPost?.[0]?.typeofpost
                  : null,
              postimagevideoandlinkurl :  post?.allVideoAudioPost?.length > 0 
              ? post?.allVideoAudioPost?.[0]?.postimagevideoandlinkurl
              : null
            },
          ];
        }
      }
      setMyPostList(finalNewData);
      setTotalCount(data.totalCount);
    }
    else{
        // swal({ text: "Timeline data failed", icon: "error" });
        setMyPostList([]);
        setTotalCount(0);
    }
  };

  const handlePagination = () => {
    let totalLimit = +pagination.limit + 10;
    setPagination({
      limit: totalLimit,
      offset: 0,
    });
    getTimelineData(totalLimit);
  };

  const handleModal = (e, value) => {
    setModalData(value)
    setShowModal(!showModal);
  };

  const refreshApiData = () => {
    setIsLike(!isLike)
  }

  const handleLike = async (e, post) => {
    e.preventDefault();
      let flameUserId = getUserInfo()?.id ?? 0
   const res = await services.location.savePostLike({
        obj: {
            active: 1,
            postactivityid: post.serialNo,
            userid: null,
            adminid: getUserInfo()?.role == "ADMIN" ? getUserInfo()?.id : null,
            advisorid: getUserInfo()?.role == "ADVISORS" ? getUserInfo()?.id : null,
            id: post.postLikeId ? post.postLikeId : 0,
          }
    })
    if(res && res.status === 200){
        const saveUserpostlike = res.data
        ? res.data.data
          ? res.data.data.saveUserpostlike
          : null
        : null;
      if (saveUserpostlike && saveUserpostlike.id) {
        const modifiedData = myPostList.map((item) => {
            if (item.serialNo === post.serialNo) {
              item.isPostLiked = 1;
              item.totalLikeCount = item.totalLikeCount + 1;
              item.postLikeId = saveUserpostlike.id;
            }
            return item;
          });
         setMyPostList(modifiedData);
         refreshApiData();
      }
    }
    else{
        swal({ text: "Post like failed", icon: "error" });
        refreshApiData();
    }
          flameAPI('flamefromprofile',
              post?.userid || post?.userId ,
              flameUserId,
              false,
              getUserInfo()?.role
          )
  };

  const handleUnlike = async (e, post) => {
    e.preventDefault();
    const res = await services.location.savePostLike({
        obj: {
            active: 0,
            postactivityid: post.serialNo,
            userid: post.userId ? post.userId : Athid,
            id: post.postLikeId ? post.postLikeId : 0,
          }
    })
    if(res && res.status === 200){
        const saveUserpostlike = res.data
        ? res.data.data
          ? res.data.data.saveUserpostlike
          : null
        : null;
      if (saveUserpostlike && saveUserpostlike.id) {
        const modifiedData = myPostList.map((item) => {
            if (item.serialNo === post.serialNo) {
              item.isPostLiked = 1;
              item.totalLikeCount = item.totalLikeCount + 1;
              item.postLikeId = saveUserpostlike.id;
            }
            return item;
          });
         setMyPostList(modifiedData);
         refreshApiData();
      }
    }
    else{
        swal({ text: "Post like failed", icon: "error" });
        refreshApiData();
    }
  };

  return (
    <div className="TimelineData">
      <input className="fakeInput" type="text" ref={fakeInput} />
      <div className="item">
        <div className="athleteDashSection" style={{ marginTop: "0" }}>
          <div className="container">
            <div className="flexBox" style={{ display: "block" }}>
              <div className="dataBox" style={{ width: "100%" }}>
                <div className="tabsBox">
                  <MyFeed
                    postList={myPostList}
                    containPin={containPin}
                    isTimeline={false}
                    isTimelineValue={true}
                    isPublicView={props.isPublicView}
                    handleModal={handleModal}
                    handleLike={handleLike}
                    handleUnlike={handleUnlike}
                    isAuth={ isToken }
                    page = { "Profile_Timeline" }
                  />
                  {myPostList?.length > 0 ?
                   myPostList.length < totalCount ? (
                    <div className="loadMoreBox">
                      <Link to="#" onClick={(e) => handlePagination(e)}>
                        Load More
                      </Link>
                    </div>
                  ) : "" : <p>No Record Found</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={showModal}
              onClose={(event, reason)=>{
                  if (reason == "backdropClick" || reason == "escapeKeyDown"){
                      handleModal()
                  }
              }}
      >
        <SharePost
            handleModal={handleModal}
            modalData={modalData}
            pageType={'Home'}
        />
      </Dialog>
    </div>
  );
};

export default EditTimeline;