import React, {useEffect, useState} from "react";
import History from '../../../history'
import BackdropLoader from "../../common/Loader";
import {getUserInfo} from "../../../utils/constant";
import {AdminHeader} from "../../header/adminHeader";
import "../../header/assets/css/stylesheet.scss";
import "../../header/assets/css/dashboard.scss";
import services from "../../../services";
import toastr from 'toastr'
import Pagination from "react-js-pagination";
import {AdminFooter} from "../../header/adminFooter";

export const AthleteConnect = (props) =>{
    const [isLoading, setIsLoading] = useState(false),
          [athleteList, setAthleteList] = useState([]),
          [totalCount, setTotalCount] = useState(0),
          [goNum, setGoNum] = useState(1),
          [fields, setFields] = useState({
              id: !!props?.match?.params?.id ? +props?.match?.params?.id : 0,
              limit: 10, offset: 1}),
          [currentPage, setCurrentPage] = useState(1),
         advisorID = !!props?.match?.params?.id ? +props?.match?.params?.id : 0
    useEffect(()=>{
        getAthleConnected(fields)
    },[])
    const getAthleConnected = async (value) =>{
        setIsLoading(true)
        const obj = {
            where: {
                advisorid: +value?.id, active: 1,
                order: "id DESC"
            },
            first: value?.offset * value.limit,
            last: value?.limit
        }
        const res = await services.location.getAthleteConnected(obj)
        if (res?.status == 200){
            const athleteData = res?.data?.data?.allAdvisorathletemappings?.Advisorathletemappings?.length > 0 ?
                res?.data?.data?.allAdvisorathletemappings?.Advisorathletemappings : []
            setAthleteList(athleteData)
            setIsLoading(false)
            setTotalCount(res?.data?.data?.allAdvisorathletemappings?.totalCount ?? 0)
        }else {
            toastr.error("error")
            setAthleteList([])
            setIsLoading(false)
            setTotalCount(0)
        }
    }
    const handlePagination = (page_num) => {
        const cloneStats = { ...fields },
            finalOffset = (page_num); //offset formula is page number * limit
        cloneStats["offset"] = Math.abs(finalOffset);
        setCurrentPage(page_num);
        setFields(cloneStats);
        getAthleConnected(cloneStats);
    }
    const onHandlePerPage = (page_num) => {
        const cloneStats = { ...fields };
        cloneStats["limit"] = page_num;
        setFields(cloneStats);
        getAthleConnected(cloneStats);
    }
    return(
     <>
         <BackdropLoader open={isLoading} />
         <div className={'content'}>
             {getUserInfo()?.role == "ADMIN" && <AdminHeader title={"Advisor Information"} />}
         <div className="dashboard">
             <div className="filter-box">
                 <div className="search-box">
                     <a href={void 0} className="back-btn"
                        style={{cursor:"pointer"}}
                     onClick={()=>History?.push('/advisorinfo')}
                     >
                         <svg xmlns="http://www.w3.org/2000/svg" width="13.541" height="13.236"
                              viewBox="0 0 13.541 13.236">
                             <g id="arrow-back" transform="translate(-8.625 -9.439)">
                                 <path id="Path_2409" data-name="Path 2409" d="M14.932,21.615,9.375,16.057,14.932,10.5"
                                       fill="none" stroke="#00b9ff" stroke-linecap="round" stroke-linejoin="round"
                                       stroke-width="1.5"/>
                                 <path id="Path_2410" data-name="Path 2410" d="M11.25,24H22.519"
                                       transform="translate(-1.103 -7.943)" fill="none" stroke="#00b9ff"
                                       stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                             </g>
                         </svg>
                         <span style={{color: '#17befe', marginLeft:"5px"}}>Back</span>
                     </a>
                 </div>
             </div>

             <div className="white-box">

                 <div className="table-responsive tableFixHead">
                     <h3 className="sec-title">Connected Athletes</h3>
                     <table className="table">
                         <thead>
                         <tr>
                             <th>S.No</th>
                             <th>Athlete Name</th>
                             <th>Grad Year</th>
                             <th>Sport</th>
                         </tr>
                         </thead>
                         <tbody>
                         {athleteList?.length > 0 ?
                             athleteList?.map((ath, idx)=>{
                                 {console.log("ath ==>>", ath)}
                           return(
                               <tr>
                                   <td>{idx + 1}</td>
                                   <td>{ath?.advisorathletemappingathleteidmaprel?.Athletes?.[0]?.athletefirstname ?? ""}&nbsp;{ath?.advisorathletemappingathleteidmaprel?.Athletes?.[0]?.athletelastname}</td>
                                   <td>{ath?.advisorathletemappingathleteidmaprel?.Athletes?.[0]?.athleteathletegraduationyearmaprel?.Graduationyears?.[0]?.yearvalue ?? ""}</td>
                                   <td>{ath?.advisorathletemappingathleteidmaprel?.Athletes?.[0]?.athleteathletesportsmaprel?.Athletesports?.[0]?.sportname ?? ""}</td>
                               </tr>
                           )
                         })
                             :
                             <td colSpan={"4"}>No Record Found</td>
                         }
                         </tbody>
                     </table>
                 </div>
                 <div className="pagination">
                     <div className="flex">
                         <div className="item perPage">
                             <label>{totalCount ?? 0} Records</label>
                             <select
                                 value={fields?.limit}
                                 onChange={(e) =>
                                     onHandlePerPage(parseInt(e?.target?.value))
                                 }
                             >
                                 <option value={10}>10</option>
                                 <option value={20}>20</option>
                                 <option value={30}>30</option>
                                 <option value={40}>40</option>
                                 <option value={50}>50</option>
                             </select>
                             <label>Records per page</label>
                         </div>

                         <div className="item pages">
                             <Pagination
                                 innerClass="paging"
                                 itemClass=""
                                 linkClass=""
                                 activePage={currentPage}
                                 activeClass={"active"}
                                 itemsCountPerPage={fields?.limit || 10}
                                 totalItemsCount={totalCount}
                                 pageRangeDisplayed={5}
                                 onChange={handlePagination}
                             />
                         </div>
                         <div className="item goto">
                             <label>Go to page</label>
                             <input
                                 value={goNum}
                                 onChange={(e) =>
                                     parseInt(e.target.value) <= 0 || e.target.value == ""
                                         ? ""
                                         : setGoNum(parseInt(e?.target?.value))
                                 }
                                 type="number"
                             />
                             <button
                                 type={"button"}
                                 onClick={() => handlePagination(goNum)}
                             >
                                 Go
                             </button>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
             <AdminFooter/>
         </div>
     </>
    )
}