import React, { useState, useEffect } from "react";
import "../../../assets/css/stylesheet.css";
import "../assets/css/athleteProfileFinalDark.scss";
import {Link, useLocation} from "react-router-dom";
import EditTimeline from "./EditTimeline";
import ProfileVideoPhoto from "./ProfileVideoPhoto";
import { Report } from "./Report";
import ProfileStats from "./ProfileStats";
import { ProfileAbout } from "./ProfileAbout";
import services from "../../../services";
import swal from "sweetalert";
import {getUserInfo} from "../../../utils/constant";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/owl.carousel.js";
import "owl.carousel/dist/owl.carousel.min.js";
import toastr from 'toastr'
import {profileURLData} from "../../common/getProfileURLData";
const optionCarousel = {
    className: "owl-carousel owl-theme",
    loop:true,
    autoPlay:false,
    nav: true,
    dots:false,
    items: 1
}
export default function ProfileTabs({ isActive, isPublicView, isTab, props, athletePersonalInfo }) {
    const [fields, setFields] = useState({
        myProfile: true,
        profilePhoto: true,
        dob: true,
        gaurdians: true,
        hometown: true,
        heightWeight: true,
        videosPhotos: true,
        stats: true,
        academicGPA: true,
        academicTranscript: false,
        academicActSat: false,
        academicPlannedMajor: true,
        events: true,
        references: true,
        offers: false,
    });
    const [openMenu, setOpenMenu] = useState(false);
    const [publicProfileData, setPublicProfileData] = useState([]);
    const [selectedTab, setSelectedTab] = useState("Timeline");
    const [tabSel, setTabSel] = useState(1)
    const location = useLocation()
    const Athid = profileURLData(location?.pathname, 'athlete_id')
        // +props?.match?.params?.id;
    let isToken = profileURLData(location?.pathname, 'token')
        // props?.match?.params?.isToken == 1 ? 1 : 0;

    useEffect(() => {
        getProfileData()
    }, [])

    useEffect(() => {
        setSelectedTab("Timeline");
    }, [isTab])

    const onTabsChange = (e, tab, tabNum) => {
        console.log("tab in timeline ==>>", tab, tabNum)
        e.preventDefault();
        setOpenMenu(!openMenu)
        setSelectedTab(tab);
        setTabSel(tabNum)
        setTimeout(()=>{
            setSelectedTab(tab);
        }, 500)
    };
    const handleOpenMenu = () => {
        setOpenMenu(true);
    };
    const handleCloseMenu = () => {
        setOpenMenu(false);
    };
    const profileCall = async (e, item) => {
        let profileArray = [],
            status = e.target.checked ? 1 : 0
        if (item.publicprofilefieldid === 20) {
            publicProfileData.map((el, ie) => {
                if (status) {
                    if (el.publicprofilefieldid != 34 && el.publicprofilefieldid != 29 && el.publicprofilefieldid != 26) {
                        profileArray.push(el.id)
                    }
                } else if (el.publicprofilefieldid != 26 && el.publicprofilefieldid != 29 && el.publicprofilefieldid != 34 && status == 0) {
                    profileArray.push(el.id)
                }
            })
        } else {
            const filData = publicProfileData.filter(ed => ed.publicprofilefieldid === item.publicprofilefieldid || ed.publicprofilefieldid === 20)
            if (filData[0].active === 0 && e.target.checked) {
                profileArray.push(filData[0].id)
                profileArray.push(filData[1].id)
            }
            else {
                profileArray.push(filData[1].id)
            }
        }
        const res = await services.location.updateProfileTimelineData({
            athleteId: Athid,
            updateProfileIds: profileArray || [],
            active: status
        });
        if (res && res.status === 200) {
            swal({ text: "Setting Are Updated", icon: "success" })
            getProfileData()
        }
        else {
            swal({ text: "Server Error,Try Again", icon: "error" });
        }
    }
    const getProfileData = async () => {
        const _res = await services.location.editProfileTimelineData({ athleteId: Athid, isAccessToken: isToken });
        if (
            _res &&
            _res.status === 200 &&
            _res.data.response.status === 200
        ) {
            setPublicProfileData(_res.data.response.data)
        }
        else {
            swal({ text: "Profile Status failed", icon: "error" });
        }
    }
    return (
        <div>
            {console.log("timeline tab sel", tabSel, selectedTab)}
            <div className="container">
                <div className="public-setting-wrapper">
                    {isActive && (
                        <div className="profile-setting-menu">
                            <div className="title-box">
                                {publicProfileData?.length > 0 ? publicProfileData.slice(0, 1).map((item, idx) => {
                                    return (
                                        <div className="form-check form-switch">
                                            <label className="form-check-label" htmlFor="Myprofile1">
                                                {item?.fieldname ?? "N/A"}
                                            </label>
                                            <span style={{ fontSize: "12px", marginRight: "5px" }}>{item?.active ? "ON" : "OFF"}</span>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={item.active ?? 0}
                                                name="myProfile"
                                                onChange={(e) => profileCall(e, item)}
                                            />
                                        </div>
                                    )
                                }) : ""}
                            </div>
                            <div className="profile-setting-info">
                                <h3>My Public Profile Settings</h3>
                                <p>
                                    Information Including Your name, positions, recruiting
                                    className, and teams will we seen publicly.
                                </p>
                                <p>
                                    Other information like your phone number, email, home address
                                    and transcripts are only available upon request from.
                                </p>
                            </div>
                            <div className="profile-menu">
                                <ul>
                                    {publicProfileData?.length > 0 ? publicProfileData.slice(1).map((item, idx) => {
                                        if (item.publicprofilefieldid != (26) && item.publicprofilefieldid != 29 && item.publicprofilefieldid != 34) {
                                            return (
                                                <li>
                                                    <div className="form-check form-switch">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="Profilemenu1"
                                                        >
                                                            {item?.fieldname ?? "N/A"}
                                                        </label>
                                                        <span style={{ fontSize: "12px" }}>{item?.active ? "Public" : "Private"}
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={item?.active ?? 0}
                                                                name={item?.fieldname ?? "error"}
                                                                onChange={(e) => profileCall(e, item)}
                                                            /> </span>
                                                    </div>
                                                </li>
                                            )
                                        }
                                    }) : ""}
                                </ul>
                            </div>
                        </div>
                    )}

                    <div className="public-setting-details" style={isActive ? {} : { width: "100%" }}>
                        <div>
                            <div className={openMenu ? "tabBox profileTab-hide-mobile tabBoxshow" : "tabBox profileTab-hide-mobile"}>
                                <nav className={openMenu ? "show" : ""}>
                                    <ul>
                                        <li className="closeBtnBox">
                                            <button className="closeBtn" onClick={() => handleCloseMenu()}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="15.804"
                                                    height="15.803"
                                                    viewBox="0 0 15.804 15.803"
                                                >
                                                    <defs>
                                                        <style></style>
                                                    </defs>
                                                    <g
                                                        className="a"
                                                        transform="translate(-6.776 -6.776)"
                                                    >
                                                        <path
                                                            d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                                                            transform="translate(0 0)"
                                                        />
                                                    </g>
                                                </svg>
                                            </button>
                                        </li>
                                        <li
                                            className={selectedTab === "Timeline" ? "selected" : ""}
                                        >
                                            <Link
                                                to="#"
                                                onClick={(e) => onTabsChange(e, "Timeline")}
                                            >
                                                Timeline
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                selectedTab === "Videos & Photos" ? "selected" : ""
                                            }
                                        >
                                            <Link
                                                to="#"
                                                onClick={(e) => {
                                                    onTabsChange(e, "Videos & Photos")
                                                }
                                                }
                                            >
                                                Videos & Photos
                                            </Link>
                                        </li>
                                        <li className={selectedTab === "About" ? "selected" : ""}>
                                            <Link to="#" onClick={(e) => onTabsChange(e, "About")}>
                                                About
                                            </Link>
                                        </li>
                                        <li className={selectedTab === "Stats" ? "selected" : ""}>
                                            <Link to="#" onClick={(e) => {
                                                isPublicView && publicProfileData?.length > 0 ? publicProfileData?.map((item, idx) => {
                                                    if (item?.publicprofilefieldid == 27 && item.active) return onTabsChange(e, "Stats")
                                                })
                                                :
                                                onTabsChange(e, "Stats")
                                            }}>
                                                Stats
                                                {isPublicView && publicProfileData?.length > 0 && publicProfileData?.map((item, idx) => {
                                                    if (item?.publicprofilefieldid == 27 && !item.active) return <span className="icon" style={{ marginLeft: "10px" }}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="13.286"
                                                            height="15.5"
                                                            viewBox="0 0 13.286 15.5"
                                                        >
                                                            <defs>
                                                                <style></style>
                                                            </defs>
                                                            <g transform="translate(-4.5 -2.25)">
                                                                <path
                                                                    fill={'#c0321f'}
                                                                    className="a"
                                                                    d="M15.571,8.893a2.214,2.214,0,0,1,2.214,2.214v4.429a2.214,2.214,0,0,1-2.214,2.214H6.714A2.214,2.214,0,0,1,4.5,15.536V11.107A2.214,2.214,0,0,1,6.714,8.893V6.679a4.429,4.429,0,1,1,8.857,0ZM11.143,3.726A2.952,2.952,0,0,1,14.1,6.679V8.893H8.19V6.679a2.952,2.952,0,0,1,2.952-2.952Zm4.429,6.643H6.714a.738.738,0,0,0-.738.738v4.429a.738.738,0,0,0,.738.738h8.857a.738.738,0,0,0,.738-.738V11.107A.738.738,0,0,0,15.571,10.369Z"
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                })
                                                }
                                            </Link>
                                        </li>
                                        <li
                                            className={`${selectedTab === "Reports" ? "selected" : ""
                                                } 
                        `}
                                        >
                                            <Link
                                                to="#"
                                                onClick={(e) => {getUserInfo()?.role == "ADMIN" || getUserInfo()?.role == "ADVISORS" ?
                                                    onTabsChange(e, "Reports")
                                                :
                                                console.log("")
                                                }}
                                            >Reports</Link>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="flexBox">
                                    <div className="menuBtnBox">
                                        <button className="tabMenuBtn" onClick={() => handleOpenMenu()}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="12"
                                                viewBox="0 0 16 12"
                                            >
                                                <defs>
                                                    <style></style>
                                                </defs>
                                                <g transform="translate(-284.664 -20.498)">
                                                    <rect
                                                        className="a"
                                                        width="16"
                                                        height="2"
                                                        rx="1"
                                                        transform="translate(284.664 20.498)"
                                                    />
                                                    <rect
                                                        className="a"
                                                        width="16"
                                                        height="2"
                                                        rx="1"
                                                        transform="translate(284.664 25.498)"
                                                    />
                                                    <rect
                                                        className="a"
                                                        width="16"
                                                        height="2"
                                                        rx="1"
                                                        transform="translate(284.664 30.498)"
                                                    />
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                    <div>
                                        <p>{selectedTab}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tabBox profileTab-show-mobile">
                                <nav>
                                    <ul>
                                        <OwlCarousel {...optionCarousel}
                                                     startPosition={tabSel - 1}
                                        >
                                            <li
                                                className={selectedTab === "Timeline" ? "selected" : ""}
                                            >
                                                <a
                                                    href={void 0}
                                                    onClick={(e) => onTabsChange(e, "Timeline", 1)}
                                                >
                                                    Timeline
                                                </a>
                                            </li>
                                            <li
                                                className={
                                                    selectedTab === "Videos & Photos" ? "selected" : ""
                                                }
                                            >
                                                <a
                                                    href={void 0}
                                                    onClick={(e) => {
                                                        onTabsChange(e, "Videos & Photos", 2)
                                                    }
                                                    }
                                                >
                                                    Videos & Photos
                                                </a>
                                            </li>
                                            <li className={selectedTab === "About" ? "selected" : ""}>
                                                <a href={void 0} onClick={(e) => onTabsChange(e, "About", 3)}>
                                                    About
                                                </a>
                                            </li>
                                            <li className={selectedTab === "Stats" ? "selected" : ""}>
                                                <a href={void 0} onClick={(e) => {
                                                    isPublicView && publicProfileData?.length > 0 ? publicProfileData?.map((item, idx) => {
                                                            if (item?.publicprofilefieldid == 27 && item.active) return onTabsChange(e, "Stats", 4)
                                                        })
                                                        :
                                                        onTabsChange(e, "Stats", 4)
                                                }}>
                                                    Stats
                                                    {isPublicView && publicProfileData?.length > 0 && publicProfileData?.map((item, idx) => {
                                                        if (item?.publicprofilefieldid == 27 && !item.active) return <span className="icon" style={{ marginLeft: "10px" }}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="13.286"
                                                            height="15.5"
                                                            viewBox="0 0 13.286 15.5"
                                                        >
                                                            <defs>
                                                                <style></style>
                                                            </defs>
                                                            <g transform="translate(-4.5 -2.25)">
                                                                <path
                                                                    fill={'#c0321f'}
                                                                    className="a"
                                                                    d="M15.571,8.893a2.214,2.214,0,0,1,2.214,2.214v4.429a2.214,2.214,0,0,1-2.214,2.214H6.714A2.214,2.214,0,0,1,4.5,15.536V11.107A2.214,2.214,0,0,1,6.714,8.893V6.679a4.429,4.429,0,1,1,8.857,0ZM11.143,3.726A2.952,2.952,0,0,1,14.1,6.679V8.893H8.19V6.679a2.952,2.952,0,0,1,2.952-2.952Zm4.429,6.643H6.714a.738.738,0,0,0-.738.738v4.429a.738.738,0,0,0,.738.738h8.857a.738.738,0,0,0,.738-.738V11.107A.738.738,0,0,0,15.571,10.369Z"
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    })
                                                    }
                                                </a>
                                            </li>
                                            <li
                                                className={`${selectedTab === "Reports" ? "selected" : ""
                                                } 
                        `}
                                            >
                                                <a
                                                    href={void 0}
                                                    onClick={(e) => {getUserInfo()?.role == "ADMIN" || getUserInfo()?.role == "ADVISORS" ?
                                                        onTabsChange(e, "Reports", 5)
                                                        :
                                                        console.log("")
                                                    }}
                                                >Reports</a>
                                            </li>
                                        </OwlCarousel>
                                    </ul>
                                </nav>
                            </div>
                            <div>
                                {selectedTab === "Timeline" ? <EditTimeline isPublicView={isPublicView} props={props} /> : null}
                                {selectedTab === "Videos & Photos" ? (
                                    <ProfileVideoPhoto isPublicView={isPublicView} props={props} />
                                ) : null}
                                {selectedTab === "Reports" ? <Report isPublicView={isPublicView} props={props} athletePersonalInfo={athletePersonalInfo} /> : null}
                                {selectedTab === "Stats" ? <ProfileStats isPublicView={isPublicView} props={props} /> : null}
                                {selectedTab === "About" ? <ProfileAbout isPublicView={isPublicView} props={props} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
