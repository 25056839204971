import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import toastr from 'toastr'
import {checkPhoneNumberValidation, formatPhoneNumber, IsValidEmail} from "../../../utils/validationUtils";
import services from "../../../services";
import PhoneNumber from "../../common/PhoneNumber";

export const ClaimProfilePop = ({closeModal, allCountryList, athleteInfo, updateClaimReq, isToken}) =>{
    const [inputData, setInputData] = useState({})
    const [errors, setErrors] = useState({});
    useEffect(()=>{
        setData()
    },[])
    const setData = () =>{
        if (allCountryList?.length > 0){
            const cloneInput = {...inputData }
            let countryT = null;
            allCountryList?.map((country) => {
                if (country.id == 1) {
                    countryT = {
                        value: country.id,
                        label: country.countryName + `(+${country.countryCode})`,
                        flag: country.countryFlagImage,
                        countryCode: country.countryCode,
                        countryShortName: country.countryShortName,
                    };
                }
            });
            cloneInput['countryDetails'] = countryT
            setInputData(cloneInput)
        }
    }
    //handle is for modal input country dropdown field
    const handleCountryChange = (option) => {
        const fieldsT = { ...inputData };
        setErrors({})
        fieldsT["countryDetails"] = option;
        setInputData({ ...fieldsT, athleteNumber: "" });
    };
    const checkModal = () =>{
        let cloneState = {...inputData},
            cloneError = {...errors},
            status = true
        if (!!cloneState?.email){
            if (!IsValidEmail(cloneState?.email ?? "")){
                cloneError['email'] = "Please enter valid email ID"
                setErrors(cloneError)
                return status = false
            }
        }
        if(!!cloneState?.athleteNumber){
            if(!checkPhoneNumberValidation(cloneState?.athleteNumber,
                cloneState?.countryDetails?.countryShortName ?? "US")){
                cloneError['athleteNumber'] = "Please enter valid phonenumber"
                setErrors(cloneError)
                return status = false
            }
        }
        return status
    }
    //handle is for modal input field
    const handleChangeModal = (field, e) =>{
        const cloneState = { ...inputData }
        setErrors({})
        if(field == "email"){
            cloneState[field] = e?.target?.value
            setInputData(cloneState)
        }else {
            if(e.target.value?.length > 10) return
            cloneState[field] = formatPhoneNumber(e?.target?.value)
            setInputData(cloneState)
        }
    }
    const onSaveModal = async () =>{
        if(!!inputData?.email || !!inputData?.athleteNumber){
            const valStatus = checkModal()
            if(valStatus){
                const body={
                    email : inputData?.email ?? "",
                    // athleteid: isToken == 1 ?  athleteInfo?.AthleteId ?? 0 : athleteInfo?.userId ?? 0,
                    athleteid : athleteInfo?.AthleteId ?? 0,
                    phonenumber: inputData?.athleteNumber?.toString() ?? "",
                    countrycode: inputData?.countryDetails?.countryCode?.toString() ?? "1"
                }
                const _res = await services.location.onSubmitClaimProfile(body)
                if(_res?.status == 200){
                    if(_res?.data?.response?.status == 401){
                        swal({
                            text: !!_res?.data?.response?.msg ? _res?.data?.response?.msg : "Temporary password cannot send",
                            icon: "error",
                        })
                    }else{
                        toastr.success("Request Sent Successfully")
                        updateClaimReq()
                        setTimeout(()=>{
                            closeModal()
                        },500)
                    }
                }
            }
        }else {
            const errorT = {...errors}
            errorT['required'] = "* Please enter either email or mobile number"
            setErrors(errorT)
        }
    }
    return(
        <div
            className="modal fade common-modal show"
            id="ClaimProfileModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="ClaimProfileLabel"
            aria-hidden="true"
            style={{display:"block", position:"initial", overflowY:"auto"}}
        >
            <div className="modal-dialog m-0" role="document">
                <form className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title claim-title">Profile Invitation</h5>
                        <button
                            onClick={() => closeModal()}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body border-none">
                        <div className="form-group">
                            <label>Full Name :</label>
                            <label style={{fontWeight: "bold"}}>{athleteInfo?.athleteFirstName ?? ""} {athleteInfo?.athleteLastName ?? ""}</label>
                            {/* <div className="name-text">{inputModal.fullName}</div> */}
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                onChange={(e) => handleChangeModal("email", e)}
                                // value={inputModal.email }
                                value={inputData?.email}
                                // disabled
                                className="form-control"
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Email ID"
                            />
                            <div style={{ color: "red", textAlign:"left" }}>{errors?.["email"]?? ""}</div>
                        </div>
                        <div className="form-group">
                            <div className="half">
                                <label>Mobile Number (Highly Recommended)</label>
                                <PhoneNumber
                                    dropdownName="countryCode"
                                    dropdownOnChange={handleCountryChange}
                                    dropdownValue={inputData?.countryDetails ?? 1}
                                    dropDownOptions={
                                        allCountryList?.map((item) => ({
                                            value: item.id,
                                            label: item.countryName + `(+${item.countryCode})`,
                                            flag: item.countryFlagImage,
                                            countryCode: item.countryCode,
                                            countryShortName: item.countryShortName,
                                        }))
                                    }
                                    formatOptionLabel={(option, { context, selectValue }) => {
                                        return context === "menu" ? (
                                            option.label
                                        ) : (
                                            <>
                                                <img
                                                    src={option.flag}
                                                    alt=""
                                                    style={{ width: "40px", marginRight: "15px" }}
                                                />
                                                <span>+{option.countryCode}</span>
                                            </>
                                        );
                                    }}
                                    inputPlaceholder="Phone Number"
                                    inputType="phonenumber"
                                    inputValue={inputData?.athleteNumber || ""}
                                    inputOnChange={(e) => {
                                        handleChangeModal("athleteNumber", {
                                            target: {
                                                value: e.target.value.replace(/[^0-9]/gi, ""),
                                            },
                                        });
                                    }}
                                />
                            </div>
                            <div style={{ color: "red", textAlign:"left" }}>{errors?.["athleteNumber"] ?? ""}</div>
                        </div>
                        <div style={{color: "red", textAlign: "left"}}>{errors?.['required'] ?? ""}</div>
                    </div>
                    <div className="modal-footer">
                        <button
                            onClick={() => onSaveModal()}
                            type="button"
                            className="btn blue"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#ClaimProfileOtpModal"
                        >
                            Send
                        </button>

                        <button
                            onClick={() => closeModal()}
                            type="button"
                            className="btn primary"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}