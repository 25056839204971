import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./css/notification.css";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory, Link } from "react-router-dom";
import { Menu, MenuItem, IconButton, Button } from "@material-ui/core";
import moment from "moment";
import ENV from '../../../config';
import closeGrayIcon from "./img/close-gray-icon.svg";
import trashCircleIcon from "./img/trash-circle-icon.svg";
import Dialog from "@material-ui/core/Dialog";
import messagePopup from "../../../utils/messagePopup";
import {
  NavigateBefore,
  NavigateNext
} from "@material-ui/icons";
import { AdminHeader } from '../../header/adminHeader';
import BackdropLoader from "../../common/Loader";
import services from "../../../../src/services";
import {useSelector, useDispatch} from "react-redux";
import { Tooltip } from "@material-ui/core";
import toastr from 'toastr'
const ITEM_HEIGHT = 48;

const Pagination = ({
  dataPerPage,
  totalData,
  paginate,
  initialPage,
  dataRange
}) => {
  const rows = dataPerPage; //parseInt(localStorage.getItem("rows"));
  //const [defaultRows, setRows] = useState(rows);
  const defaultRows = rows;
  const [pageNumber, setPageNumber] = useState(initialPage);
  const pageNumbers = [];
  const NumberOfPages = totalData / defaultRows;
  
  for (let i = 1; i <= Math.ceil(NumberOfPages); i++) {
    pageNumbers.push(i);
  }

  const handlePageNumber = number => {
    setPageNumber(number);
    paginate(number * rows, defaultRows);
  };

  return (
    <div className="PaginationFlexWrap">
      <div className="PaginationNumberText">
        <span>
          {dataRange.first} - {dataRange.last}
        </span>
        of <span> {totalData}</span>
      </div>
      <nav className="paginationNav">
        <ul className="pagination">
          <li className="page-item">
            <span
              className="page-link"
              aria-label="Previous"
              onClick={() =>
                pageNumber !== 1 ? handlePageNumber(pageNumber - 1) : ""
              }
            >
              <NavigateBefore />
            </span>
          </li>
          <li className="page-item">
            <span
              className="page-link"
              aria-label="Next"
              onClick={() =>
                pageNumber < NumberOfPages
                  ? handlePageNumber(pageNumber + 1)
                  : ""
              }
            >
              <NavigateNext />
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
}

const Message = props => {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const store = useSelector(store => store);
  const dispatch = useDispatch();
  const openMenu = Boolean(anchorE2),
    [perPage, setPerPage] = useState(20),
    [offset, setOffSet] = useState(20),
    [id, setId] = useState(null),
    [count, setCount] = useState(0),
    [showDeleteModal, setShowDeleteModal] = useState(false),
    [coachData, setCoachData] = useState([]),
    [ischecked, setIsChecked] = useState('20'),
    [loading, setLoading] = useState(true),
    [messageFilter, setMessageFilter] = useState({
      order: 'updatedon desc',
      archivebyotherrole:0,
      deletebyotherrole:0,
      and: [{or: [{messagesendbyadmin: {neq: null}},{messagesendtoadmin: {neq: null}}]},{or:[{messagesendto:{neq:null}}, {messagesendby:{neq:null}}]}]
    });
  const fakeInput = useRef();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClose = value => {
    setAnchorEl(null);
    setIsChecked(value);
    setPerPage(parseFloat(value));
    setOffSet(parseFloat(value));
    getMessageData(parseInt(value), parseInt(value))
  }
  const openComposeMenu = event => {
    setAnchorE2(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorE2(null);
  };
  const handleCoachMenu = () => {
    setAnchorE2(null);
    history.push({ pathname: "/message/coach/composeMessage" });
  };
  const handleAdvisorMenu = () => {
    setAnchorE2(null);
    history.push({ pathname: "/message/support/advisor/composeMessage" });
  }
  const handleAthleteMenu = () => {
    setAnchorE2(null);
    history.push({ pathname: "/message/athlete/composeMessage" });
  }

  const formatDateTime = newDate => {
    let dateTime = null;
    if (moment(newDate).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')) {
      dateTime = moment(newDate).format('LT');
    }
    else {
      dateTime = moment(newDate).format('DD/MM/YYYY');
    }
    return dateTime;
  }

  const messageReadData = async (item) => { 
      if(!item.messageSendByAdmin){ 
        await services.location.saveMessages({ obj:{id: item.id, readbyotherrole: 1} }).then(async (res) =>{
          await services.location.updateMessageReplies({ where:{msgid: item.id}, data: {readbyotherrole: 1} }).then(res => {
            history.push({ pathname: "/message/athlete/replyMessage", state: { data: item } });
          });
        });
      }
      else{
        history.push({ pathname: "/message/athlete/replyMessage", state: { data: item } });
      }
  }

  const onDeleteClick = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const DeleteItem = ({ onDeleteClick, onCancelClick }) => {
    return (
      <div
        className="modal fade common-modal trash-modal show"
        id="MessageTrashModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={onCancelClick}>
                <img src={closeGrayIcon} alt="close Gray Icon" />
              </button>
              <div className="icon-box-first">
                <img src={trashCircleIcon} alt="trash Circle Icon" />
              </div>
              <div className="content-box">
                <h4>
                  Are you sure you want to
                  <br /> delete this message?
                </h4>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={onDeleteClick}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn gray"
                data-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const onDeleteHandler = async () => {
    await services.location.saveMessages({ obj: { id: id, deletebyotherrole:1, readbyotherrole:1 } }).then(async (res) => {
      await services.location.updateMessageReplies({ where:{msgid: id}, data: {deletebyotherrole:1, readbyotherrole:1} }).then(res => {
        if(res?.data?.data?.MessagereplyUpdateAll){
          services.location.getMessagesReplyTotalCount({ where: { 
            readbyotherrole:0,
            deletebyotherrole:0,
            archivebyotherrole:0,
            msgsenderid:{neq:null},
            msgreceiveradminid:{neq:null}
          } }).then(athleteRes => { 
            if(athleteRes.status == "200"){
                dispatch({type:"ATHLETE_UNREAD_MESSAGES", payload:{unreadCount: athleteRes?.data?.data?.allMessagereplies?.totalCount}})
            }
          });
          toastr.success("Message deleted successfully").css({"width":"auto"})
          getMessageData(perPage, offset);
        }
        else{
          messagePopup("", "Message not deleted try again", "error")
        }
      });
    });
    setShowDeleteModal(!showDeleteModal)
  }
  const deleteMessageData = (e, item) => {
    e.stopPropagation();
    setShowDeleteModal(!showDeleteModal);
    setId(item.id)
  }

  const archiveMessageData = async (e, item) => {
    e.stopPropagation();
    await services.location.saveMessages({ obj: { id: item.id, active: 0, archivebyotherrole: 1 } }).then(async (res) => {
      await services.location.updateMessageReplies({ where:{msgid: item.id}, data: {active: 0, archivebyotherrole: 1} }).then(res => { 
        messagePopup("", "Chat archived successfully", "success");
        services.location.getMessagesReplyTotalCount({ where: { 
          readbyotherrole:0,
          deletebyotherrole:0,
          archivebyotherrole:0,
          msgsenderid:{neq:null},
          msgreceiveradminid:{neq:null}
        } }).then(athleteRes => { 
          if(athleteRes.status == "200"){
              dispatch({type:"ATHLETE_UNREAD_MESSAGES", payload:{unreadCount: athleteRes?.data?.data?.allMessagereplies?.totalCount}})
          }
        });
        services.location.getMessagesReplyTotalCount({ where: { 
          readbyotherrole:0,
          deletebyotherrole:0,
          archivebyotherrole:1,
          msgsenderid:{neq:null},
          msgreceiveradminid:{neq:null}
        } }).then(athleteRes => { 
          if(athleteRes.status == "200"){
              dispatch({type:"ARCHIVE_UNREAD_MESSAGES", payload:{unreadCount: athleteRes?.data?.data?.allMessagereplies?.totalCount}})
          }
        });
        getMessageData(perPage, offset);
      });
    });
  }

  useEffect(() => {
    getMessageData(perPage, offset)
    fakeInput?.current?.focus();
    fakeInput?.current?.select();

  }, []);

  const getMessageData = async (first, last) => {
    let athleteData = [];
    const _res = await services.location.getAllMessages({where:messageFilter, first:first, last:last});
    
    let data = _res?.data?.data?.allMessages?.totalCount > 0 ? _res?.data?.data?.allMessages?.Messages?.map(item => { 
      let messageData = item?.messagereplyMsgidMap?.Messagereplies.filter(filterItem => filterItem.deletebyotherrole == 0);  
        if(messageData?.length){
          const newMessage = messageData?.length ? messageData[parseInt(messageData?.length-1)] : {};
          item['message'] = messageData?.length ? newMessage['msg'] : item?.message;
          item['senderName'] = item?.messagemessagesendtomaprel?.Athletes.length ? item?.messagemessagesendtomaprel?.Athletes[0]['athletefirstname']+" "+item?.messagemessagesendtomaprel?.Athletes[0]['athletelastname'] : item?.messagemessagesendbymaprel?.Athletes?.length ? item?.messagemessagesendbymaprel?.Athletes[0]['athletefirstname']+" "+item?.messagemessagesendbymaprel?.Athletes[0]['athletelastname'] : "";
          item['messageSenderId'] = item?.messagemessagesendtomaprel?.Athletes.length ? item?.messagemessagesendtomaprel?.Athletes[0]['id'] : item?.messagemessagesendbymaprel?.Athletes?.length ? item?.messagemessagesendbymaprel?.Athletes[0]['id'] : "";
          item['messageSenderRemoved'] = item?.messagemessagesendtomaprel?.Athletes.length ? item?.messagemessagesendtomaprel?.Athletes[0]['athleteuseridmaprel']['Userdata'][0]['issoftdeleted'] : item?.messagemessagesendbymaprel?.Athletes?.length ? item?.messagemessagesendbymaprel?.Athletes[0]['athleteuseridmaprel']['Userdata'][0]['issoftdeleted'] : 0;
          item['messageSenderActive'] = item?.messagemessagesendtomaprel?.Athletes.length ? item?.messagemessagesendtomaprel?.Athletes[0]['athleteuseridmaprel']['Userdata'][0]['active'] : item?.messagemessagesendbymaprel?.Athletes?.length ? item?.messagemessagesendbymaprel?.Athletes[0]['athleteuseridmaprel']['Userdata'][0]['active'] : 0;
          item['senderProfileImage'] =  item?.messagemessagesendtomaprel?.Athletes.length && item?.messagemessagesendtomaprel?.Athletes[0]['onBoardingUserIdMap']['Onboardings'].length  ? item?.messagemessagesendtomaprel?.Athletes[0]['onBoardingUserIdMap']['Onboardings'][0]['profileimages'] : item?.messagemessagesendbymaprel?.Athletes?.length && item?.messagemessagesendbymaprel?.Athletes[0]['onBoardingUserIdMap']['Onboardings'].length ? item?.messagemessagesendbymaprel?.Athletes[0]['onBoardingUserIdMap']['Onboardings'][0]['profileimages'] : "";
          item['dateTime'] = messageData?.length ? formatDateTime(messageData[parseInt(messageData?.length-1)]['createdon']) : formatDateTime(item?.createdon);
          item['messageSendByAdmin'] = messageData?.length ? newMessage?.messagereplymsgsenderidrel?.Athletes?.length ? false : true : true;
          let firstname =  item['senderName'].split(" ").filter(elm => elm)[0];
          let lastname =  item['senderName'].split(" ").filter(elm => elm)[1];
          item['senderInitials'] = firstname && lastname ? firstname[0].toUpperCase() + lastname[0].toUpperCase() : firstname == "SportsForce" ? "SF" : "";
          item['sysGenId'] = !!item?.messagemessagesendtomaprel?.Athletes?.[0]?.athleteuseridmaprel?.Userdata?.[0]?.sysgenid  ? item?.messagemessagesendtomaprel?.Athletes?.[0]?.athleteuseridmaprel?.Userdata?.[0]?.sysgenid :
          item?.messagemessagesendbymaprel?.Athletes?.[0]?.athleteuseridmaprel?.Userdata?.[0]?.sysgenid ?? 0
          athleteData.push(item);
        }
      }) : [];
    setCoachData(athleteData);
    setCount(_res?.data?.data?.allMessages?.totalCount);
    setLoading(false);
  }

  const handlePagination = async (pageNumber, size) => {
    setPerPage(pageNumber);
    setOffSet(size);
    getMessageData(pageNumber, size)
  }

  let dataRange = {
    first:
      perPage - offset + 1,
    last:
      perPage -
      offset +
      coachData.length,
  };
  return (
    <>
      <BackdropLoader open={loading} />
      <div className="content">
        <AdminHeader
          title={"Message"}
        />

        <input className="fakeInput" type="text" ref={fakeInput} />

        <div className="notification-with-sidebar">
          <nav className="notification-sidebar">
            <ul>
              <li className="button-link dropdown">
                <Button
                  className="btn lightblue dropdown-toggle"
                  onClick={e => openComposeMenu(e)}
                >
                  Compose
                </Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button"
                  }}
                  anchorEl={anchorE2}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch"
                    }
                  }}
                >
                  <MenuItem>
                    <strong>Compose for</strong>
                  </MenuItem>
                  <MenuItem onClick={handleAthleteMenu}>Athlete</MenuItem>
                  <MenuItem onClick={handleAdvisorMenu}>Advisor</MenuItem>
                  {/* <MenuItem onClick={handleCoachMenu}>Coach</MenuItem> */}
                </Menu>
              </li>
              <li
                className={window.location.href.includes('/athlete') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/athlete" })}
              >
                <div className="link-item">
                  <span>Athlete</span> <span>{(parseInt(store?.adminMessages?.athleteUnreadMessages)) ? (parseInt(store?.adminMessages?.athleteUnreadMessages)) : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/advisor') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor" })}
              >
                <div className="link-item">
                  <span>Advisor</span><span>{(parseInt(store?.adminMessages?.advisiorUnreadMessages)) ? (parseInt(store?.adminMessages?.advisiorUnreadMessages)) : ""}</span>
                </div>
              </li>
              {/*
              <li
                className={window.location.href.includes('/coach') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/coach" })}
              >
                <div className="link-item">
                  <span>Coaches</span> <span>{(parseInt(store?.adminMessages?.coachesUnreadMessages)) ? (parseInt(store?.adminMessages?.coachesUnreadMessages)) : ""}</span>
                </div>
              </li> */}
              <li
                className={window.location.href.includes('/archive') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/archive" })}
              >
                <div className="link-item">
                  <span>Archive</span><span>{(parseInt(store?.adminMessages?.archiveUnreadMessages)) ? (parseInt(store?.adminMessages?.archiveUnreadMessages)) : ""}</span>
                </div>
              </li>
            </ul>
          </nav>
          <div className="notification-detail-box">
            <div className="notification-search-box">
              <div className="search-box">
                <label>Athlete</label>
              </div>
              <div className="notification-pagination">
                <ul>
                  <li>
                    {coachData?.length > 0 ? <Pagination
                      dataPerPage={parseFloat(ischecked)}
                      totalData={count}
                      paginate={handlePagination}
                      initialPage={perPage / offset}
                      dataRange={dataRange}
                    /> : ""}</li>
                  <li>
                    <div className="notification-dropdown-info">
                      {coachData?.length > 0 ? <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls="long-menu"
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton> : ""}
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button"
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch"
                          }
                        }}
                      >
                        <MenuItem>
                          <strong>Show up to</strong>
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuClose('20')}>
                          {ischecked === '20' ? <span className="icon-box" style={{ marginRight: '10px' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          20 items
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuClose('50')}>
                          {ischecked === '50' ? <span className="icon-box" style={{ marginRight: '10px' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          50 items
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuClose('75')}>
                          {ischecked === '75' ? <span className="icon-box" style={{ marginRight: '10px' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          75 items
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuClose('100')}>
                          {ischecked === '100' ? <span className="icon-box" style={{ marginRight: '10px' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          100 items
                        </MenuItem>
                      </Menu>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              {coachData?.length > 0 ? (
                coachData.map(item => {
                  return (
                    <div className={`item notification-item ${!item.readbyotherrole ? "active" : "" }`} >
                      {
                        !item?.messageSenderRemoved ? 
                        <Link className="user-profile"
                        to={`/athlete/${item?.senderName?.trim()?.toLowerCase()?.split(" ")?.join('-')}-${item?.sysGenId ?? 0}`}
                              // to={`/publicProfile/1/${+item?.messageSenderId}/${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.id}`}
                              target="_blank">
                            {item.senderProfileImage ? <img src={ENV.DOWNLOAD_URL + `/${item.senderProfileImage}`} alt="" /> : <div className="initial">{item.senderInitials}</div>}
                        </Link>
                        : 
                        <div className="user-profile" style={{cursor:"default"}}>
                            {item.senderProfileImage ? <img src={ENV.DOWNLOAD_URL + `/${item.senderProfileImage}`} alt="" /> : <div className="initial">{item.senderInitials}</div>}
                        </div>
                      }
                      <div className="user-detial-box" onClick={() => { messageReadData(item) }}>
                        <div className="item">
                          {/* <h4 className="name">{item.senderCollegeName}</h4> */}
                          {/* <h4 className="name">{item.senderAddress?`(${item.senderAddress})`:""}</h4> */}
                          {/* <h4 className="name">{item.senderName}</h4> */}
                          <div className="text-box">
                            { !item.readbyotherrole ? 
                              <span className="subject-line" style={{fontWeight: "700"}} >{item.senderName}</span>
                              : <span className="subject-line">{item.senderName}</span>
                            }
                            <p>{item.message}</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="time-text">{item.dateTime}</div>
                          <div className="button-box">
                            <Tooltip placement="bottom" title={"Reply"} arrow>
                            <button
                              className="button"
                              onClick={() => {
                                history.push({ pathname: "/message/athlete/replyMessage", state: { data: item } });
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19.2"
                                height="16"
                                viewBox="0 0 19.2 16"
                              >
                                <path
                                  id="Icon_material-reply"
                                  data-name="Icon material-reply"
                                  d="M11.967,11.767V7.5L4.5,14.967l7.467,7.467V18.06c5.333,0,9.067,1.707,11.733,5.44C22.633,18.167,19.433,12.833,11.967,11.767Z"
                                  transform="translate(-4.5 -7.5)"
                                  fill="#3e3e3e"
                                />
                              </svg>
                            </button>
                            </Tooltip>
                            <Tooltip placement="bottom" title={"Archive"} arrow>
                            <button className="button" onClick={(e) => archiveMessageData(e, item)}>
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="19.2" 
                                height="16" 
                                viewBox="0 0 29.981 29.982">
                                  <path 
                                    id="archive-30_30" 
                                    data-name="archive-30*30" 
                                    d="M2,10.547a3,3,0,0,1,.657-1.873L6.646,3.688A4.5,4.5,0,0,1,10.158,2H23.824a4.5,4.5,0,0,1,3.512,1.688l3.989,4.986a3,3,0,0,1,.657,1.873V27.484a4.5,4.5,0,0,1-4.5,4.5H6.5a4.5,4.5,0,0,1-4.5-4.5ZM10.158,5H23.824a1.5,1.5,0,0,1,1.171.563l2.661,3.326a.375.375,0,0,1-.293.609H6.619a.375.375,0,0,1-.293-.609L8.987,5.561A1.5,1.5,0,0,1,10.158,5Zm8.335,10.493a1.5,1.5,0,1,0-3,0v7.625L14.3,21.924a1.5,1.5,0,0,0-2.12,2.12l2.691,2.69a3,3,0,0,0,4.24,0L21.8,24.05a1.5,1.5,0,1,0-2.12-2.12l-1.186,1.186Z" transform="translate(-2 -2)" fill="#3e3e3e" 
                                    fill-rule="evenodd"
                                  />
                              </svg>
                            </button>
                            </Tooltip>
                            <Tooltip placement="bottom" title={"Delete"} arrow>
                            <button className="button" onClick={(e) => deleteMessageData(e, item)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="16"
                                viewBox="0 0 14 16"
                              >
                                <path
                                  id="Icon_awesome-trash-alt"
                                  data-name="Icon awesome-trash-alt"
                                  d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z"
                                  fill="#3e3e3e"
                                />
                              </svg>
                            </button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="noMsg">
                  <span>No new message</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <Dialog open={showDeleteModal}
                onClose={(event, reason)=>{
                  if (reason == "backdropClick" || reason == "escapeKeyDown"){
                    onDeleteClick()
                  }
                }}
        >
          <DeleteItem
            onDeleteClick={onDeleteHandler}
            onCancelClick={onDeleteClick}
          />
        </Dialog>
      </div>
    </>
  );
};

export default Message;