import React, { useEffect, useState } from "react";
import "./css/account.scss";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { getUserInfo, logOut } from "../../../utils/constant";
import services from "../../../services";
import { useHistory } from "react-router-dom";
import AccountSettingLeftNavigation from "./AccountSettingLeftNavigation";
import { alphaNumericValidation } from "../../common/validation";
import swal from 'sweetalert'
import messagePopup from '../../../utils/messagePopup';
import { AdminHeader } from "../../header/adminHeader";

const PasswordChange = (props) => {
  const [changePasswordData, setChangePasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const history = useHistory()

  const handleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const inputHandler = (field, e) => {
    setErrors({});
    let fieldsT = changePasswordData;
    fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
    setChangePasswordData({ ...fieldsT });
  };

  const formvalidation = () => {
    let errorsT = errors;
    let formIsValid = true;

    if (changePasswordData.currentPassword === "") {
      formIsValid = false;
      errorsT["currentPassword"] = "Please enter current password.";
    } else if (
      changePasswordData.currentPassword == changePasswordData.newPassword &&
      changePasswordData.currentPassword ===
      changePasswordData.confirmNewPassword
    ) {
      formIsValid = false;
      errorsT["newPassword"] =
        "New password and confirm password should not be same as current password.";
    }

    let regularExp = new RegExp(
      /^(?=.*\d)(?=.*[a-zA-Z])/
    );
    let blankSpaceValidation = stringVal => {
      return /\s/g.test(stringVal);
    };
    if (changePasswordData.newPassword == "") {
      formIsValid = false;
      errorsT["newPassword"] = "Please enter new password.";
      errorsT["confirmNewPassword"] = "";
    }
    else if (changePasswordData.newPassword.length < 6) {
      formIsValid = false;
      errorsT["newPassword"] = "Password must be at least 6 characters.";
    }
    else if (changePasswordData.newPassword.length > 32) {
      formIsValid = false;
      errorsT["newPassword"] = "Maximum length should be 32 characters.";
    }
    else if (regularExp.test(changePasswordData.newPassword) == false && changePasswordData.newPassword !== "") {
      formIsValid = false;
      errorsT["newPassword"] =
        "Password must contain 1 alphabetical letter and 1 numeral.";
    }
    if (getUserInfo()?.role == "ADMIN" ? changePasswordData.newPassword === getUserInfo().email : changePasswordData.newPassword === getUserInfo().emailid) {// earlier it was "Email" and this key is  not present in LS
      formIsValid = false;
      errorsT["newPassword"] = "Email id can’t be set as your password.";
    }
    if (blankSpaceValidation(changePasswordData.newPassword)) {
      formIsValid = false;
      errorsT["newPassword"] = "Blank spaces are not allowed"
    }

    // else if (regularExp.test(changePasswordData.confirmNewPassword) == false && changePasswordData.confirmNewPassword !== "") {
    //   formIsValid = false;
    //   errorsT["confirmNewPassword"] =
    //     "Password must contain 1 alphabetical letter and 1 numeral.";
    // }
    // else if (changePasswordData.confirmNewPassword === getUserInfo().emailid) {//earlier it was "Email" and this key is  not present in LS
    //   formIsValid = false;
    //   errorsT["confirmNewPassword"] = "Email id can’t be set as your password.";
    // }
    if (changePasswordData.confirmNewPassword == "") {
      formIsValid = false;
      errorsT["confirmNewPassword"] = "Please enter confirm password.";
    } else if (
      changePasswordData.newPassword.localeCompare(
        changePasswordData.confirmNewPassword
      ) != 0 && changePasswordData.newPassword !== ""
    ) {
      formIsValid = false;
      errorsT["confirmNewPassword"] =
        "New password and confirm new password must be same.";
    }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
    };
  };

  const saveChangePassword = async (event) => {
    event.preventDefault();
    const validateData = formvalidation();
    if (validateData.formIsValid == true) {
      await services.location.advisorChangePassword({
        id: getUserInfo()?.role == "ADMIN" ? getUserInfo()?.id ?? 0 : getUserInfo()?.userid ?? null,
        oldPassword: changePasswordData.currentPassword,
        newPassword: changePasswordData.newPassword,
      }).then(res => {
        if (res?.status == 200) {
          if (res?.data?.errors?.length) {
            messagePopup("", res?.data?.errors[0]['message'], "error");
          } else {
            swal({
              title: "",
              text: "Password changed successfully ! Please login with your new credentials",
              type: "success",
              icon: "success"
            }).then(function (res) {
              logOut()
              history.push('/')
            });
          }
        }
      })
    } else {
    }
  };
  //this for scroll the page on the top
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1500)
  }, [])

  return (
    <div className={getUserInfo()?.role == "ADMIN" ? 'content' : ''}>
      {getUserInfo()?.role == "ADMIN" && <AdminHeader title={"Change Password"} />}
      <section className="account-main-sec" style={getUserInfo()?.role == "ADMIN" ? { marginTop: "30px" } : {}}>
        <div className="container">
          <div className="account-with-sidebar">
            {getUserInfo()?.role == "ADVISORS" &&
              <AccountSettingLeftNavigation />
            }
            <div className="account-data-box">
              <h1 className="title">Change Password</h1>
              <div className="account-form-box">
                <form>
                  <div className="form-group">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Email Id
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      id="password"
                      className="text-field"
                      fullWidth
                      disabled={true}
                      value={getUserInfo()?.role == "ADMIN" ? getUserInfo()?.email : getUserInfo().emailid}
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Current Password
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      id="password"
                      placeholder="Current Password*"
                      type={showCurrentPassword ? "text" : "password"}
                      className="text-field"
                      fullWidth
                      name="password"
                      value={changePasswordData.currentPassword || ""}
                      onChange={(e) => {
                        inputHandler("currentPassword", e);
                      }}
                      onBlur={(e) => {
                        inputHandler("currentPassword", e);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleShowCurrentPassword()}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showCurrentPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                    {errors.currentPassword != "" ? (
                      <div className="error">{errors.currentPassword}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <InputLabel htmlFor="outlined-adornment-password">
                      New Password
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      id="password"
                      placeholder="New Password*"
                      type={showNewPassword ? "text" : "password"}
                      className="text-field"
                      fullWidth
                      name="password"
                      value={changePasswordData.newPassword || ""}
                      onChange={(e) => {
                        inputHandler("newPassword", e);
                      }}
                      onBlur={(e) => {
                        inputHandler("newPassword", e);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleShowNewPassword()}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showNewPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                    {errors.newPassword != "" ? (
                      <div className="error">{errors.newPassword}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Confirm New Password
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      id="password"
                      placeholder="Confirm New Password*"
                      type={showConfirmPassword ? "text" : "password"}
                      className="text-field"
                      fullWidth
                      name="password"
                      value={changePasswordData.confirmNewPassword || ""}
                      onChange={(e) => {
                        inputHandler("confirmNewPassword", e);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleShowConfirmPassword()}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                    {errors.confirmNewPassword != "" ? (
                      <div className="error">{errors.confirmNewPassword}</div>
                    ) : null}
                  </div>

                  <div className="form-group mb-0">
                    <button
                      className="btn green"
                      type="button"
                      onClick={saveChangePassword}
                    >
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PasswordChange;
