import React from "react";
import "../../../assets/css/owl.carousel.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/owl.carousel.js";
import "owl.carousel/dist/owl.carousel.min.js";
import ENV from "../../../config";
const ProfilePicCarousel = (props) => {
  return (
    <>
      <OwlCarousel
        className="owl-theme"
        margin={1}
        nav={props?.profileImages?.length > 1 ? true : false}
        items="1"
        key={props.profileImages?.length}
      >
        {/*remove reverse() before map as it is rendering again and again*/}
        {props.profileImages && props?.profileImages?.length > 0 ? (
          props.profileImages.map((item, index) => (
            <div className="item" key={index}>
              {item?.photosname ? (
                <div className="profile-inner-box">
                  <img
                    src={`${ENV.DOWNLOAD_URL}/${item?.photosname ?? ""}`}
                    alt={`${
                      !!props?.athletePersonalInfo?.athleteFirstName &&
                      !!props?.athletePersonalInfo?.athleteLastName &&
                      props?.athletePersonalInfo?.athleteFirstName?.split(
                        ""
                      )[0] +
                        "/" +
                        props?.athletePersonalInfo?.athleteLastName?.split(
                          ""
                        )[0]
                    }`}
                  />
                </div>
              ) : (
                <div className="initial">
                {props?.athletePersonalInfo?.athleteFirstName ? (props?.athletePersonalInfo?.athleteFirstName?.split("")[0].concat(props?.athletePersonalInfo?.athleteLastName ? props?.athletePersonalInfo?.athleteLastName?.split("")[0] : "")).toUpperCase() : ""}
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="item">
            <div className="initial">
              {props?.athletePersonalInfo?.athleteFirstName ? (props?.athletePersonalInfo?.athleteFirstName?.split("")[0].concat(props?.athletePersonalInfo?.athleteLastName ? props?.athletePersonalInfo?.athleteLastName?.split("")[0] : "")).toUpperCase() : ""}
            </div>
          </div>
        )}
      </OwlCarousel>
    </>
  );
};

export default ProfilePicCarousel;
