import React, { useEffect } from "react";
import { useState } from "react";
import "./css/message-trash.css";
//import "./css/notification.css";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem, IconButton, Button, Tooltip } from "@material-ui/core";
import moment from "moment";
import closeGrayIcon from "./img/close-gray-icon.svg";
import trashCircleIcon from "./img/trash-circle-icon.svg";
import Dialog from "@material-ui/core/Dialog";
import messagePopup from "../../../utils/messagePopup";
import {
  NavigateBefore,
  NavigateNext
} from "@material-ui/icons";
import BackdropLoader from "../../common/Loader";
import services from "../../../services";
import { useSelector, useDispatch } from "react-redux";
import { getUserInfo } from "../../../../src/utils/constant";
import AdvisorHeader from "../../header/advisorHeader";
import toastr from 'toastr';

const ITEM_HEIGHT = 48;

const Pagination = ({
  dataPerPage,
  totalData,
  paginate,
  initialPage,
  dataRange
}) => {
  const rows = parseInt(localStorage.getItem("rows"));
  const [defaultRows, setRows] = useState(rows);
  const [pageNumber, setPageNumber] = useState(initialPage);
  const pageNumbers = [];
  const NumberOfPages = totalData / defaultRows;

  for (let i = 1; i <= Math.ceil(NumberOfPages); i++) {
    pageNumbers.push(i);
  }

  const handlePageNumber = number => {
    setPageNumber(number);
    paginate(number * rows, defaultRows);
  };

  return (
    <div className="PaginationFlexWrap">
      <div className="PaginationNumberText">
        <span>
          {dataRange.first} - {dataRange.last}
        </span>
        of <span> {totalData}</span>
      </div>
      <nav className="paginationNav">
        <ul className="pagination">
          <li className="page-item">
            <span
              className="page-link"
              aria-label="Previous"
              onClick={() =>
                pageNumber !== 1 ? handlePageNumber(pageNumber - 1) : ""
              }
            >
              <NavigateBefore />
            </span>
          </li>
          <li className="page-item">
            <span
              className="page-link"
              aria-label="Next"
              onClick={() =>
                pageNumber < NumberOfPages
                  ? handlePageNumber(pageNumber + 1)
                  : ""
              }
            >
              <NavigateNext />
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
}

const TrashMessage = props => {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const store = useSelector(store => store);
  const dispatch = useDispatch();
  const openMenu = Boolean(anchorE2),
    [perPage, setPerPage] = useState(20),
    [filter,] = useState({
      order: 'updatedon desc',
      archivebyadvisor: 1,
      and: [{ or: [{ messagesendbyadvisor: parseInt(getUserInfo()?.userid) }, { messagesendtoadvisorid: parseInt(getUserInfo()?.userid) }] }]
    }),
    [offset, setOffSet] = useState(20),
    [showDeleteModal, setShowDeleteModal] = useState(false),
    [trashCount, setTrashCount] = useState(0),
    [trashData, setTrashData] = useState([]),
    [ischecked, setIsChecked] = useState('20'),
    [selected, setSelected] = useState({}),
    [selectedArray, setSelectedArray] = useState([]),
    [messageList, setMessageList] = useState([]),
    [loading, setLoading] = useState(false),
    [selectAll, setSelectAll] = useState(false);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClose = value => {
    setAnchorEl(null);
    setIsChecked(value);
    setPerPage(parseFloat(value));
    setOffSet(parseFloat(value))
  }
  const openComposeMenu = event => {
    setAnchorE2(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorE2(null);
  };

  const handleSupportMenu = () => {
    setAnchorE2(null)
    history.push({ pathname: "/message/support/composeMessage" })
  }

  const handleAthleteMenu = () => {
    setAnchorE2(null);
    history.push({ pathname: "/message/advisor/composeMessage" });
  }

  const formatDateTime = newDate => {
    let dateTime = null;
    if (moment(newDate).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')) {
      dateTime = moment(newDate).format('LT');
    }
    else {
      dateTime = moment(newDate).format('DD/MM/YYYY');
    }
    return dateTime;
  }

  const onDeleteClick = () => {
    setShowDeleteModal(!showDeleteModal)
  }
  const DeleteItem = ({ onDeleteClick, onCancelClick }) => {
    return (
      <div
        className="modal fade common-modal trash-modal show"
        id="MessageTrashModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={onCancelClick}>
                <img src={closeGrayIcon} alt="close Gray Icon" />
              </button>
              <div className="icon-box-first">
                <img src={trashCircleIcon} alt="trash Circle Icon" />
              </div>
              <div className="content-box">
                <h4>
                  Are you sure you want to delete this
                  <br /> message thread permanently?
                </h4>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={onDeleteClick}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn gray"
                data-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const onDeleteHandler = async () => {
    let messageIds = [];
    selectedArray.map(item => {
      messageIds.push(item.id)
    })
    await services.location.deleteOrMovetoInbox({
      role: getUserInfo()?.role,
      isdelete: 1,
      msgids: messageIds
    }).then(async (res) => {
      toastr.success("Message deleted successfully").css({"width":"auto"})
      setSelectAll(false);
      setSelectedArray([]);
      setSelected({});
      UpdateUnreadCount();
      getMessageData(perPage, offset);
    });

    setShowDeleteModal(!showDeleteModal)
  }

  const UpdateUnreadCount = async () => {
    await services.location.getMessagesReplyTotalCount({
      where: {
        readbyadvisor: 0,
        deletebyadvisor: 0,
        archivebyadvisor: 0,
        msgreceiveradvisorid: parseInt(getUserInfo()?.userid),
        and: [{ or: [{ msgsenderid: { neq: null } }, { msgsendercoachid: { neq: null } }, { msgsenderadvisorid: { neq: null } }] }]
      }
    }).then(res => {
      if (res.status == "200") {
        dispatch({ type: "All_ADVISOR_UNREAD_MESSAGES", payload: { unreadCount: res?.data?.data?.allMessagereplies?.totalCount } })
      }
    });

    await services.location.getMessagesReplyTotalCount({
      where: {
        readbyadvisor: 0,
        deletebyadvisor: 0,
        archivebyadvisor: 0,
        msgreceiveradvisorid: parseInt(getUserInfo()?.userid),
        msgsenderid: { neq: null },
      }
    }).then(res => {
      if (res.status == "200") {
        dispatch({ type: "ADVISOR_ATHLETE_UNREAD_MESSAGES", payload: { unreadCount: res?.data?.data?.allMessagereplies?.totalCount } })
      }
    });

    await services.location.getMessagesReplyTotalCount({
      where: {
        readbyadvisor: 0,
        deletebyadvisor: 0,
        archivebyadvisor: 1,
        msgsenderid: { neq: null },
        msgreceiveradvisorid: parseInt(getUserInfo()?.userid)
      }
    }).then(coachesRes => {
      if (coachesRes.status == "200") {
        dispatch({ type: "ADVISOR_ARCHIVE_UNREAD_MESSAGES", payload: { unreadCount: coachesRes?.data?.data?.allMessagereplies?.totalCount } })
      }
    });
  }

  useEffect(() => {
    getMessageData(perPage, offset)
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000)
  }, []);

  const getMessageData = async (first, last) => {
    let trashData = [];
    const _res = await services.location.getAdvisiorMessages({ where: filter, first: first, last: last });
    let data = _res?.data?.data?.allMessages?.Messages?.length > 0 ? _res?.data?.data?.allMessages?.Messages?.map(item => {
      let messageReplyData = item?.messagereplyMsgidMap?.Messagereplies.filter(data => data.deletebyadvisor == 0)
      item['messagereplyMsgidMap']['Messagereplies'] = messageReplyData;
      item['dateTime'] = messageReplyData.length ? formatDateTime(messageReplyData[parseInt(messageReplyData?.length-1)]['createdon'])  : item.createdon ? formatDateTime(item.createdon) : "";
      item['senderName'] = item?.messagemessagesendtomaprel?.Athletes?.length ? item?.messagemessagesendtomaprel?.Athletes[0]['athletefirstname'] + " " + item?.messagemessagesendtomaprel?.Athletes[0]['athletelastname'] :
      item?.messagemessagesendbymaprel?.Athletes?.length ? item?.messagemessagesendbymaprel?.Athletes[0]['athletefirstname'] + " " + item?.messagemessagesendbymaprel?.Athletes[0]['athletelastname'] : 
      item?.messagemessagesendtoadminrel?.Userdata?.length ? getUserInfo()?.firstname+" "+getUserInfo()?.lastname :
      item?.messagemessagesendbyadminmaprel?.Userdata?.length ? item?.messagemessagesendbyadminmaprel?.Userdata[0]['fullname'].replace(/ /g,'') : "";
      trashData.push(item);
    }) : [];
    setTrashData(trashData);
    setTrashCount(_res?.data?.data?.allMessages?.totalCount)
  }

  // useEffect(() => {
  //   let trashData = [];
  //   let data = props?.messageList?.data?.data?.length > 0 ? props?.messageList?.data?.data?.map(item => {
  //     item['dateTime'] = item.createdon ? formatDateTime(item.createdon) : "";
  //     trashData.push(item);
  //   }) : [];
  //   setTrashData(trashData);
  //   setTrashCount(trashData.length)
  // }, [messageList]);

  const onRowSelected = sarray => {
    if (sarray.length === this.state.listData.length) {
      this.setState({ selectAll: true });
    } else {
      this.setState({ selectAll: false });
    }
  };

  const onHandleCheckBox = (e, id, rowInfo) => {
    e.stopPropagation();
    const newSelected = Object.assign({}, selected);
    newSelected[id] = !selected[id];
    setSelected(newSelected)
    let arr = selectedArray.filter(val => val.id !== id);
    if (!arr.length) {
      setSelectedArray([], () => { onRowSelected(selectedArray) });
    }
    if (arr.length === selectedArray.length) {
      setSelectedArray([...selectedArray, rowInfo], () => { onRowSelected(selectedArray) });
    } else {
      setSelectedArray(arr, () => { onRowSelected(selectedArray) });
    }
  };

  const backToInboxBtn = async (e) => {
    e.preventDefault();
    let messageIds = [];
    selectedArray.map(item => {
      messageIds.push(item.id)
    })
    await services.location.deleteOrMovetoInbox({
      role: getUserInfo()?.role,
      isdelete: 0,
      msgids: messageIds
    }).then(async (res) => {
      messagePopup("", "Message sent successfully to inbox", "success");
      UpdateUnreadCount();
      history.push({ pathname: "/message/advisor/athlete" })
    });
  }

  const deleteForeverBtn = async (e) => {
    e.stopPropagation();
    setShowDeleteModal(!showDeleteModal);
  };


  const handlePagination = async (pageNumber, size) => {
    setPerPage(pageNumber);
    setOffSet(size);
    getMessageData(pageNumber, size)
  }

  const onHandleAllCheckBox = (isChecked) => {
    if (isChecked) {
      let selectedArr = [];
      let consolidateArray = [];
      trashData.map(item => {
        consolidateArray[item.id] = true;
        selectedArr.push({ id: item.id });
      });
      setSelectAll(true);
      setSelectedArray(selectedArr);
      setSelected(consolidateArray);
    } else {
      setSelectAll(false);
      setSelectedArray([]);
      setSelected({});
    }
  }

  let dataRange = {
    first:
      perPage - offset + 1,
    last:
      perPage -
      offset +
      trashData.length,
  };

  const messageReadData = async item => {
    item.archivebyadvisor = 1
    if(!item.readbyadvisor){
      setLoading(true);
      await services.location.saveMessages({ obj:{id: item.id, readbyadvisor: 1} }).then(async (res) =>{
        await services.location.updateMessageReplies({ where:{msgid: item.id}, data: {readbyadvisor: 1} }).then(async (res) => {
          UpdateUnreadCount();
          if(item.messagesendtoadmin || item.messagesendbyadmin){
            history.push({ pathname: "/message/support/composeMessage", state: { data: {archivebyadvisor:1} } });
          }
          else{
            history.push({ pathname: "/message/advisor/replyMessage", state: { data: item } });
          }
        });
      });
    }
    else{
      if(item.messagesendtoadmin || item.messagesendbyadmin){
        history.push({ pathname: "/message/support/composeMessage", state: { data: {archivebyadvisor:1} } });
      }
      else{
        history.push({ pathname: "/message/advisor/replyMessage", state: { data: item } });
      }
    }
  }

  return (
    <div className="pageContent">
      <BackdropLoader open={loading} />
      <div className="container">
        <div className="notification-with-sidebar">
          <nav className="notification-sidebar">
            <ul>
              <li className="button-link dropdown">
                <Button
                  className="btn lightblue dropdown-toggle"
                  onClick={e => openComposeMenu(e)}
                >
                  Compose
                </Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button"
                  }}
                  anchorEl={anchorE2}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch"
                    }
                  }}
                >
                  <MenuItem>
                    <strong>Compose for</strong>
                  </MenuItem>
                  <MenuItem onClick={handleSupportMenu}>Support</MenuItem>
                  <MenuItem onClick={handleAthleteMenu}>Athlete</MenuItem>
                  {/* <MenuItem onClick={handleAdvisorMenu}>Advisor</MenuItem>
                  <MenuItem onClick={handleCoachMenu}>Coach</MenuItem> */}
                </Menu>
              </li>
              <li
                className={window.location.href.includes('/message/support/composeMessage') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/support/composeMessage" })}
              >
                <div className="link-item">
                  <span>Support</span> <span>{(parseInt(store?.adminMessages?.advisorSupportUnreadMessages)) ? (parseInt(store?.adminMessages?.advisorSupportUnreadMessages)) : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/message/advisor/athlete') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor/athlete" })}
              >
                <div className="link-item">
                  <span>Athlete</span> <span>{(parseInt(store?.adminMessages?.advisorAthleteUnreadMessages)) ? (parseInt(store?.adminMessages?.advisorAthleteUnreadMessages)) : ""}</span>
                </div>
              </li>
              {/* <li
                className={window.location.href.includes('/advisor') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor" })}
              >
                <div className="link-item">
                  <span>Advisor</span> <span>{(parseInt(store?.adminMessages?.advisiorUnreadMessages)) ? (parseInt(store?.adminMessages?.advisiorUnreadMessages)) : ""}</span>
                </div>
              </li>
              <li
                className={window.location.href.includes('/coach') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/coach" })}
              >
                <div className="link-item">
                  <span>Coaches</span> <span>{(parseInt(store?.adminMessages?.coachesUnreadMessages)) ? (parseInt(store?.adminMessages?.coachesUnreadMessages)) : ""}</span>
                </div>
              </li> */}
              <li
                className={window.location.href.includes('/archive') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/advisor/archive" })}
              >
                <div className="link-item">
                  <span>Archive</span><span>{(parseInt(store?.adminMessages?.advisorArchiveUnreadMessages)) ? (parseInt(store?.adminMessages?.advisorArchiveUnreadMessages)) : ""}</span>
                </div>
              </li>
            </ul>
          </nav>
          <div className="notification-detail-box">
            <div className="notification-search-box TrahsMessage">
              <div className="search-box">
                {trashData?.length > 0 ?
                  <>
                    <div className="checkbox-info">
                      <input
                        className="PrivateSwitchBase-input-20"
                        name="checkedId"
                        type="checkbox"
                        checked={selectAll}
                        onChange={(e) => onHandleAllCheckBox(e.target.checked)}
                      />
                      <label className="singleSelect">&nbsp;</label>
                    </div>
                    {selectedArray?.length > 0 ? <Tooltip placement="bottom" title={"Move to Inbox"} arrow><button className="delete-foreverBtn" onClick={(e) => backToInboxBtn(e)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="14.781" viewBox="0 0 16.5 14.781">
                        <g id="movetoinbox" transform="translate(0.25 0.109)">
                          <path id="Path_2380" data-name="Path 2380" d="M15.9,76.732l-1.557-3.414a2.1,2.1,0,0,0-1.909-1.228h-.992a.388.388,0,1,0,0,.776h.992a1.326,1.326,0,0,1,1.2.774l1.35,2.961H11.535a.388.388,0,0,0-.352.226l-.669,1.45a1.326,1.326,0,0,1-1.2.768h-2.7a1.326,1.326,0,0,1-1.2-.768l-.669-1.45a.388.388,0,0,0-.352-.226H1.059L2.7,73.562a1.321,1.321,0,0,1,1.164-.7h.7a.388.388,0,0,0,0-.776h-.7a2.1,2.1,0,0,0-1.848,1.1L.12,76.708a.387.387,0,0,0-.12.281C0,77,0,77,0,77.009s0,.013,0,.02v3.6a2.957,2.957,0,0,0,2.954,2.954H13.046A2.957,2.957,0,0,0,16,80.625v-3.6c0-.007,0-.013,0-.02s0-.013,0-.02a.387.387,0,0,0-.1-.257Zm-.679,3.893A2.18,2.18,0,0,1,13.046,82.8H2.954A2.18,2.18,0,0,1,.776,80.625V77.377H4.145L4.71,78.6A2.1,2.1,0,0,0,6.615,79.82h2.7A2.1,2.1,0,0,0,11.218,78.6l.565-1.224h3.441v3.248Zm0,0" transform="translate(0 -69.156)" fill="#3e3e3e" stroke="#3e3e3e" stroke-width="0.5" />
                          <path id="Path_2381" data-name="Path 2381" d="M141.565,4.892l2.126,1.72a.388.388,0,0,0,.49,0l2.092-1.72a.388.388,0,1,0-.493-.6l-1.458,1.2V.529a.388.388,0,0,0-.776,0V5.5l-1.494-1.208a.388.388,0,0,0-.488.6Zm0,0" transform="translate(-135.935 0)" fill="#3e3e3e" stroke="#3e3e3e" stroke-width="0.5" />
                        </g>
                      </svg>
                      </button></Tooltip> : ''}
                    {selectedArray?.length > 0 ? <Tooltip placement="bottom" title={"Delete"} arrow><button className="delete-foreverBtn" onClick={(e) => deleteForeverBtn(e)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                        <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z" fill="#3e3e3e"></path>
                      </svg>
                      </button></Tooltip> : ''}
                    {/* <button className="EmptyTrashNowBtn" onClick={(e) => emptyTrashNow(e)}>Empty trash now</button> */}
                  </>
                  : ""}
              </div>
              <div className="notification-pagination">
                <ul>
                  <li>
                    {trashData?.length > 0 ?
                      <Pagination
                        dataPerPage={parseFloat(ischecked)}
                        totalData={trashCount}
                        paginate={handlePagination}
                        initialPage={perPage / offset}
                        dataRange={dataRange}
                      />
                      : ""}
                  </li>
                  <li>
                    <div className="notification-dropdown-info">
                      {trashData?.length > 0 ?
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls="long-menu"
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        : ""}
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button"
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch"
                          }
                        }}
                      >
                        <MenuItem>
                          <strong>Show up to</strong>
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuClose('20')}>
                          {ischecked === '20' ? <span className="icon-box" style={{ marginRight: '10px' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          20 items
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuClose('50')}>
                          {ischecked === '50' ? <span className="icon-box" style={{ marginRight: '10px' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          50 items
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuClose('75')}>
                          {ischecked === '75' ? <span className="icon-box" style={{ marginRight: '10px' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          75 items
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuClose('100')}>
                          {ischecked === '100' ? <span className="icon-box" style={{ marginRight: '10px' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.999"
                              height="11.999"
                              viewBox="0 0 15.999 11.999"
                            >
                              <path
                                id="checked"
                                d="M0,70.953l5.479,5.489L16,65.932l-1.51-1.489-9.01,9L1.489,69.453Z"
                                transform="translate(0 -64.443)"
                              ></path>
                            </svg>
                          </span> : ""}
                          100 items
                        </MenuItem>
                      </Menu>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              {trashData?.length > 0 ? (
                trashData.map(item => {
                  return (
                    <div
                      className={`item notification-item ${!item.readbyadvisor ? "active" : ""
                        }`}>
                      <div className="button-boxinfo">
                        <div className="item">
                          <div className="checkbox-info"><input
                            className="PrivateSwitchBase-input-20"
                            name="id"
                            type="checkbox"
                            checked={selected[item.id] === true}
                            onChange={(e) => onHandleCheckBox(e, item.id, item)}
                          /><label>&nbsp;</label>
                          </div>
                        </div>
                        <div className="item">
                          <button class="button" style={{ cursor: "auto" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                              <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M1,14.5A1.5,1.5,0,0,0,2.5,16h9A1.5,1.5,0,0,0,13,14.5V4H1Zm8.5-8a.5.5,0,1,1,1,0v7a.5.5,0,1,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0Zm-3,0a.5.5,0,0,1,1,0v7a.5.5,0,0,1-1,0ZM13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1Z" fill="#3e3e3e"></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="user-detial-box" onClick={() => { messageReadData(item) }}>
                        <div className="item">
                          <div className="text-box">
                            <span className="subject-line">
                              { item?.senderName }
                            </span>
                            <p>{item?.messagereplyMsgidMap?.Messagereplies[item?.messagereplyMsgidMap?.Messagereplies?.length - 1]?.msg}</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="time-text">{item.dateTime}</div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="noMsg">
                  <span>No new message</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <Dialog open={showDeleteModal}
                onClose={(event, reason)=>{
                  if (reason == "backdropClick" || reason == "escapeKeyDown"){
                    onDeleteClick()
                  }
                }}
        >
          <DeleteItem
            onDeleteClick={onDeleteHandler}
            onCancelClick={onDeleteClick}
          />
        </Dialog>
      </div>
    </div>
  );
};

export default TrashMessage;