import React, { useState, useEffect} from "react";
import AccountSettingLeftNavigation from "./AccountSettingLeftNavigation";
import "./css/account.scss";
import services from "../../../services";

const NotificationSetting = (props) => {
  const [fields, setFields] = useState({});
  const advisor = JSON.parse(localStorage.getItem("user_Admin_Advisor"));

  const [isNotification, setIsNotification] = useState({});
  const [email, setIsEmail] = useState({});
  const [isText, setIsText] = useState({});
  const [notificationSettingList, setNotificationSettingList] = useState([]);

  useEffect(() => {
    getNotificationSettingList();
  }, []);

//this is added to scroll the page on top
  useEffect(() => {
    setTimeout(()=>{
      window.scroll(0,0)
    },1000)
  }, []);

  const getNotificationSettingList = async () => {
    await services.location.getAdvisorNotificationSettings({ 
      advisorId: advisor.id
    }).then(res => {
      if(res.status === 200){
        setNotificationSettingList(res?.data?.response?.data)
      }
    })
  };
  const handleNotificationCheck = (e, items) => {
    let fieldsT = isNotification;
   
    if (e.target.checked) {
      fieldsT["isNotificationChecked"] = 1;
    } else {
      fieldsT["isNotificationChecked"] = 0;
    }
    setIsNotification({ ...fieldsT });
    saveNotificationSettingId(items)
  };

  const handleEmailCheck = (e, items) => {
    let fieldsT = email;

    if (e.target.checked) {
      fieldsT["isEmailChecked"] = 1;
    } else {
      fieldsT["isEmailChecked"] = 0;
    }
    setIsEmail({ ...fieldsT });
    saveNotificationSettingId(items);
  };

  const handleTextCheck = (e, items) => {
    let fieldsT = isText;

    if (e.target.checked) {
      fieldsT["isTextChecked"] = 1;
    } else {
      fieldsT["isTextChecked"] = 0;
    }
    setIsText({ ...fieldsT });
    saveNotificationSettingId(items);
  };
  const handleToggle = (e, data) => {
    let fieldsT = fields;
    if (e.target.checked) {
      fieldsT["selectedData"] = 1;
    } else {
      fieldsT["selectedData"] = 0;
    }
    setFields({ ...fieldsT });
    saveNotificationSettings(data);
  };

  const saveNotificationSettingId = async (items) => {
    await services.location.saveNotificationSettings({ 
      obj: {
        advisorid: advisor.id,
        id: items.id,
        emailstatus: email.isEmailChecked,
        notificationstaus: isNotification.isNotificationChecked,
        textstatus: isText.isTextChecked,
      }
    }).then(res => {
      if(res.status === 200){
        getNotificationSettingList()
      }
    })
  };
  
  const saveNotificationSettings = async (data) => {
    await services.location.saveAdvisorNotificationSettings({ 
      advisorId: advisor.id,
      notificationadvisorsettingId: data.id,
      notificationadvisorsettingStatus: fields.selectedData,
    }).then(res => {
      if(res.status === 200){
        getNotificationSettingList()
      }
    })
  };

  const notificationSettings = notificationSettingList.reduce(
    function (r, a) {
      r[a.mainLable] = r[a.mainLable] || [];
      r[a.mainLable].push(a);
      return r;
    },
    {}
  
  );
  let finalList = Object.keys(notificationSettings).map(function (key) {
    return { mainLable: key, subList: notificationSettings[key] };
  });
  
  return (
    <div>
      <section className="account-main-sec">
        <div className="container">
          <div className="account-with-sidebar">
            <AccountSettingLeftNavigation />
            <div className="account-data-box">
              <h1 className="title">Notification Settings</h1>
              <div className="notification-settings-box">
                {/* {notificationSettingList && notificationSettingList.length > 0
                  ? notificationSettingList.slice(-1).map((item, id) => {
                      return (
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                            htmlFor="notificationSettings"
                          >
                            {item.fieldname ?? "N/A"}
                          </label>
                          {item?.active ? <h2 className={'form-check-label'} style={{marginRight:"5px"}}>ON</h2> :
                              <h2 className={'form-check-label'} style={{marginRight:"5px"}}>OFF</h2>}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={item?.active ?? 0}
                            onChange={(e) => handleToggle(e, item)}
                            id="notificationSettings"
                          />
                        </div>
                      );
                    })
                  : ""} */}

                <div className="notification-content">
                  <ul className="flexbox-row">
                    <li className="item">Notify me when....</li>
                    <li className="item">Notification</li>
                    <li className="item">Email</li>
                    <li className="item">Text / SMS</li>
                  </ul>
                  <div className="notification-list-row">
                    {finalList && finalList.length > 0
                      ? finalList.slice(0, -1).map((item, idx) => (
                          <div className="notification-item" key={idx}>
                           
                            <h4>
                              {item.mainLable !== null ? item.mainLable : ""}
                            </h4>
                           

                            {item.subList &&
                              item.subList.map((items, index) => (
                                <div className="flexbox-row" key={index}>
                                  <div className="item">{items.fieldname}</div>
                                  
                                  <div className="item">
                                    <input
                                          type="checkbox"
                                          name={items.fieldname}
                                          checked={items.notificationstaus ?? 0}
                                          disabled={fields.selectedData == 0 ? true : false}
                                          onChange={(e) =>
                                            handleNotificationCheck(e, items)
                                          }
                                        />
                                   </div>
                                  {/*below code is commented as per task jira 281*/}
                                  {/*jira task 281 revert by task 282*/}
                                  <div className="item">
                                    <input
                                        type="checkbox"
                                        name={items.fieldname}
                                        checked={items.emailstatus ?? 0}
                                        onChange={(e) => handleEmailCheck(e, items)}
                                        disabled={fields.selectedData == 0 ? true : false}
                                        />
                                  </div>
                                  <div className="item">
                                  <input
                                    type="checkbox"
                                    name={items.fieldname}
                                    checked={items.textstatus ?? 0}
                                    onChange={(e) => handleTextCheck(e, items)}
                                    disabled={fields.selectedData == 0 ? true : false}
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotificationSetting;
