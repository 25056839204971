import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, TextField } from "@material-ui/core";
const useStyles = makeStyles({
  popperDisablePortal: {
    position: "relative",
  },
});
export default function MuiAutoComplete(props) {
  const classes = useStyles();
  return (
    <div className="autocomplete-class">
      <Autocomplete
        id={props.id}
        // classes={classes}
        // disablePortal={true}
        freeSolo={props.freeSolo ? true : false}
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChangeObj}
        inputValue={props.inputValue}
        onInputChange={props.onChange}
        clearOnBlur={props.clearOnBlur ? true : false}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.inputPlaceholder}
            margin="normal"
            variant="outlined"
            autoComplete="new-password"
          />
        )}
      />
    </div>
  );
}
