const initialState = {
    allUnreadMessages: 0,
    athleteUnreadMessages: 0,
    advisiorUnreadMessages: 0,
    coachesUnreadMessages: 0,
    archiveUnreadMessages: 0,
    allAdvisorUnreadMessages: 0,
    advisorAthleteUnreadMessages: 0,
    advisorSupportUnreadMessages: 0,
    advisorArchiveUnreadMessages: 0
}
export const adminMessages = (state= initialState, action) =>{
    switch (action.type){
        case "All_UNREAD_MESSAGES":
            return {
                ...state,
                allUnreadMessages: action?.payload?.unreadCount
            }
        case "ATHLETE_UNREAD_MESSAGES":
            return {
                ...state,
                athleteUnreadMessages: action?.payload?.unreadCount
            }
        case "ADVISOR_UNREAD_MESSAGES":
            return {
                ...state,
                advisiorUnreadMessages: action?.payload?.unreadCount
            }
        case "COACHES_UNREAD_MESSAGES":
            return {
                ...state,
                coachesUnreadMessages: action?.payload?.unreadCount
            }  
        case "ARCHIVE_UNREAD_MESSAGES":
            return {
                ...state,
                archiveUnreadMessages: action?.payload?.unreadCount
            }
        case "All_ADVISOR_UNREAD_MESSAGES":
            return {
                ...state,
                allAdvisorUnreadMessages: action?.payload?.unreadCount
            }
        case "ADVISOR_ATHLETE_UNREAD_MESSAGES":
            return {
                ...state,
                advisorAthleteUnreadMessages: action?.payload?.unreadCount
            }
        case "ADVISOR_SUPPORT_UNREAD_MESSAGES":
            return {
                ...state,
                advisorSupportUnreadMessages: action?.payload?.unreadCount
            }    
        case "ADVISOR_ARCHIVE_UNREAD_MESSAGES":
            return {
                ...state,
                advisorArchiveUnreadMessages: action?.payload?.unreadCount
            }                              
        default:
            return state
    }
}