import { useState, useEffect } from "react";
import { IsValidEmail, alphaNumericValidation } from '../common/validation'
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import './assets/css/forgotPassword.scss'
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import swal from "sweetalert";
import services from "../../services";
const warningDeleteParentPopup = async (title, icon) => {
    const willDelete = await swal({
        // title: title,
        text: title,
        icon: icon,
        display: "inline-table",
        buttons: {
            confirm: { text: "Ok" }
        },
        dangerMode: true,
    });
    return willDelete;
};

export const ChangePassword = (props) => {
    const emailData = window.location.href.split("/");
    const [changePasswordData, setChangePasswordData] = useState({
        emailId: emailData[emailData.length - 1],
        newPassword: "",
        confirmPassword: "",
    });
    const [errors, setErrors] = useState({
        emailId: "",
        newPassword: "",
        confirmPassword: "",
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        localStorage.clear();
    }, [])

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleShowPasswordClick = () => {
        setShowPassword(!showPassword);
    };
    const handleshowConfirmPasswordClick = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const inputHandler = (field, e) => {
        setErrors({});
        let fieldsT = changePasswordData;
        fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
        setChangePasswordData({ ...fieldsT });
    };

    const formvalidation = () => {
        let errorsT = errors;
        let formIsValid = true;
        let regularExp = new RegExp(
            /^(?=.*\d)(?=.*[a-zA-Z])/
        );
        let blankSpaceValidation = stringVal => {
            return /\s/g.test(stringVal);
        };
        if (changePasswordData.emailId == "") {
            formIsValid = false;
            errorsT["emailId"] = "Please enter emailId.";
            if (changePasswordData.emailId != "") {
                if (!IsValidEmail(changePasswordData.emailId)) {
                    formIsValid = false;
                    errorsT["emailId"] = "Please correct format of emailId.";
                }
            }
        }
        if (changePasswordData.newPassword == "") {
            formIsValid = false;
            errorsT["newPassword"] = "Please enter new password.";
            errorsT["confirmPassword"] = "";
        }
        else if (changePasswordData.newPassword.length < 6) {
            formIsValid = false;
            errorsT["newPassword"] = "Password must be at least 6 characters.";
        }
        else if (changePasswordData.newPassword.length > 32) {
            formIsValid = false;
            errorsT["newPassword"] = "Maximum length should be 32 characters.";
        }
        else if (regularExp.test(changePasswordData.newPassword) == false && changePasswordData.newPassword !== "") {
            formIsValid = false;
            errorsT["newPassword"] =
                "Password must contain 1 alphabetical letter and 1 numeral.";
        }
        if (changePasswordData.newPassword === changePasswordData.emailId) {
            formIsValid = false;
            errorsT["newPassword"] = "Email id can’t be set as your password.";
        }
        if (blankSpaceValidation(changePasswordData.newPassword)) {
            formIsValid = false;
            errorsT["newPassword"] = "Blank spaces are not allowed"
        }
        if (changePasswordData.confirmPassword == "") {
            formIsValid = false;
            errorsT["confirmPassword"] = "Please enter confirm password.";
        }
        else if (
            changePasswordData.newPassword.localeCompare(
                changePasswordData.confirmPassword
            ) != 0 && changePasswordData.newPassword !== ""
        ) {
            formIsValid = false;
            errorsT["confirmPassword"] =
                "New password and confirm new password must be same.";
        }

        setErrors({
            ...errorsT,
        });
        return {
            formIsValid,
        };
    };
    const onSubmitHandler = async (event) => {
        event.preventDefault();
        const validateData = await formvalidation();
        if (validateData.formIsValid == true) {
            const body = {
                emailId: changePasswordData.emailId,
                newPassword: changePasswordData.newPassword,
            }
            const response = await services?.location?.resetPassword(body)
            if (response && response.data && response.data.response && response.data.response.statusCode == 200) {
                if (response?.data.response?.data?.length > 0) {
                    localStorage?.setItem('authToken_Admin_Advisor', response.data.response?.data?.[0].token)
                    const role = response.data.response?.data?.length > 0 ? response.data.response?.data?.[0]?.role : "ADMIN"
                    const isDone = await swal({ text: !!response.data.response?.msg ? response.data.response?.msg : "Change Password Failed", icon: "success" });
                    if (isDone) {
                        localStorage.setItem('loginType', role)
                        localStorage?.setItem('user_Admin_Advisor', JSON.stringify(response.data.response?.data?.[0]))
                        if (role == "ADMIN") {
                            props?.history?.push('/dashboard')
                        } else {
                            props?.history?.push('/advisor/myAthlete')
                        }
                    }
                }
            }
            else if (response.data.response.status == 201 || response?.data?.response?.statusCode == 201) {
                swal({ text: !!response.data.response?.msg ? response.data.response?.msg : "Change Password Failed", icon: "error" });
            }
            else {
                swal({ text: "API Failed", icon: "error" });
            }
        } else {

        }
    };
    return (
        <section className="forgot-pass-sec">
            {/*<BackdropLoader open={props.changePassword.loading} />*/}
            <div className="container">
                <div className="forgot-box">
                    <div className="detail-text-box">
                        <div className="logo-box">
                            <img src="img/logoV.png" alt="" />
                        </div>
                        <h1>Change Your Password</h1>
                    </div>
                    <form className="form-box">
                        <div className="form-group">
                            <input
                                type="email"
                                placeholder="Registered email address"
                                onChange={(e) => {
                                    inputHandler("emailId", e);
                                }}
                                value={changePasswordData.emailId}
                                disabled={true}
                            />
                            {errors.emailId != "" ? (
                                <div className="error">{errors.emailId}</div>
                            ) : null}
                        </div>

                        <div className="form-group">
                            <TextField
                                variant="outlined"
                                id="password"
                                placeholder="New Password*"
                                type={showPassword ? "text" : "password"}
                                className="text-field"
                                fullWidth
                                name="password"
                                value={changePasswordData.newPassword}
                                onChange={(e) => {
                                    inputHandler("newPassword", e);
                                }}
                                onBlur={(e) => {
                                    inputHandler("newPassword", e);
                                }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleShowPasswordClick()}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                            />
                            {errors.newPassword != "" ? (
                                <div className="error">{errors.newPassword}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <TextField
                                variant="outlined"
                                id="password"
                                placeholder="Confirm Password*"
                                type={showConfirmPassword ? "text" : "password"}
                                className="text-field"
                                fullWidth
                                name="password"
                                value={changePasswordData.confirmPassword}
                                onChange={(e) => {
                                    inputHandler("confirmPassword", e);
                                }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleshowConfirmPasswordClick()}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                            />
                            {errors.confirmPassword != "" ? (
                                <div className="error">{errors.confirmPassword}</div>
                            ) : null}
                        </div>

                        <div className="form-group">
                            <button className="btn" type="submit" onClick={onSubmitHandler}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}