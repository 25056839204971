import React, { useEffect, useState, useRef } from "react";
import {Link, useLocation, withRouter} from 'react-router-dom';
import moment from 'moment'
import TeamLogo from "../../../assets/img/TeamLogo.png";
import "../../../assets/css/stylesheet.css";
import "../assets/css/athleteProfileFinalDark.scss";
import ENV from "../../../config";
import coacheImg1 from "../../../assets/img/coache-img1.png";
import teamDefault from "../assets/img/event_default.png";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import eventLogoImg01 from "../assets/img/event_default.png"
import GoTopButton from "./GoTopButton";
import services from "../../../services";
import swal from "sweetalert";
import thumbnail from '../../../assets/img/video-to-link-thumbnail.jpg'
import {VideoPop} from "../../common/vidPopUp";
import {getCamelCase} from "../../common/getCamelCase";
import {getThumbnail, typeConvert} from '../../common/getThumbnail'
import {vidCountIncre} from "../../common/flameAPI";
import {getUserInfo} from "../../../utils/constant";
import {getUTCTimeDateOverall, getUTCTimeDateDash} from '../../../utils/dateTimeFormatter'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/owl.carousel.js";
import "owl.carousel/dist/owl.carousel.min.js";
import {profileURLData} from "../../common/getProfileURLData";
const optionCarousel = {
    className: "owl-carousel owl-theme",
    loop:true,
    autoPlay:false,
    nav: true,
    dots:false,
    items: 1
}
export const ProfileAbout = withRouter((props) => {
    const [profileAbout, setProfileAbout] = useState(null),
        [publicProfileData, setPublicProfileData] = useState([]),
        [relationshipData, setRelationshipData] = useState([]),
        location = useLocation(),
        Athid = profileURLData(location?.pathname, 'athlete_id'),
            // +props?.match?.params?.id,
        [activeClass, setActiveClass] = useState('Basic'),
        pubViewID = JSON.parse(localStorage.getItem('pubViewID')),
        isToken = profileURLData(location?.pathname , 'token')
            // props?.match?.params?.isToken == 1 ? 1 : 0;
    const [vidID, setVidID] = React.useState(null)
    const [isVidPop, setIsVidPop] = React.useState(false)
    const [isLink, setIsLink] = useState(false)
    const fakeInput = useRef(null);
    const [allOfferPrivate, setAllOfferPrivate] = useState(false);
    const [tabSel, setTabSel] = useState(1)
    useEffect(async () => {
        const res = await services.location.getRelationshipData();
        if(res && res.status === 200){
            setRelationshipData(res.data.data)
        }
        else{
            swal({ text: "Relationship List failed", icon: "error" });
            setRelationshipData([]);
        }
        const _res = await services.location.editProfileTimelineData({ athleteId: Athid, isAccessToken: isToken });
        if (
            _res &&
            _res.status === 200 &&
            _res.data.response.status === 200
        ) {
            setPublicProfileData(_res.data.response.data)
        }
        else {
            swal({ text: "Profile Status failed", icon: "error" });
            setPublicProfileData([]);
        }
        const response = await services.location.getAllAboutData(getUserInfo()?.role == "ADMIN" ?
            { athleteId: Athid, isAccessToken: isToken } :
            { athleteId: Athid, isAccessToken: isToken, role : getUserInfo()?.role, advisorid : getUserInfo()?.id})
        if(response && response.status === 200 && response.data.response.status === 200){
            console.log(response.data.response.About_data[0],"response all about data")
            setProfileAbout(response.data.response.About_data[0]);
        }
        else{
            swal({ text: "Profile Timeline About failed", icon: "error" });
            setProfileAbout(null);
        }
        fakeInput.current.focus();
        fakeInput.current.select();
    }, [pubViewID])

    //This effect will check if all the offers are private in public profile.
    useEffect(() => {
        if(profileAbout?.offerData.length && props.isPublicView) {
            var privateOffers = profileAbout?.offerData.filter((data) => data.managevisible === 0);
            if(privateOffers.length === profileAbout?.offerData.length) {
                setAllOfferPrivate(true);
            } else {
                setAllOfferPrivate(false);
            }
        }
    }, [profileAbout?.offerData]);

    const srcollToId = (value, tabNum) => {
        setTabSel(tabNum)
        const elm = document.getElementById(value)
        elm.scrollIntoView({ block: "center" });
        setActiveClass(value);
    }

    const openVideoOnNewTab = (url, linkType, data) => {
        if (url) {
            // window.open(url, "_blank");
            setVidID(url)
            setIsLink(linkType)
            setTimeout(()=>{
                setIsVidPop(true)
            },500)
            vidCountIncre(data, getUserInfo()?.role == "ADMIN" ? true : false)
        }
    };

    const getNameFromCount = (data) => {
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var monthName = months[parseInt(data)-1];
        return monthName;
    }
    const showContact = () =>{
        if (getUserInfo()?.role == "ADMIN"){
            return true
        }
        else if(getUserInfo()?.role == "ADVISORS"){
            if(profileAbout?.athleteBasicInfo?.[0]?.iscontactshow == 1){
                return true
            }
            else{
                return "NotCurrentAdvisor"
            }
        }
        else{
            return false
        }
    }
    return (
        <>
        <div className="TimelineData">
            <input className="fakeInput" type="text" ref={fakeInput} />
            <div className="item pt-0 pb-0 mb-0">
                <div className="about-sec-wrap">
                    <div className="sidebar aboutsidebar-hide-mobile" id="myScrollspy">
                        <ul className="menu-links nav">
                            <li><a className={`nav-link ${activeClass === "Basic" ? "active" : ""}`} style={{ cursor: "pointer" }}
                                onClick={() => { srcollToId('Basic') }}
                            >Basic</a></li>
                            <li><a className={`nav-link ${activeClass === "VideoandPhoto" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('VideoandPhoto')}
                            >Videos & Photos</a></li>
                            <li><a className={`nav-link ${activeClass === "Contact" ? "active" : ""}`}
                                   style={{cursor: "pointer"}} onClick={() => srcollToId('Contact')
                            }>Contact</a></li>
                            <li><a className={`nav-link ${activeClass === "Vitals" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Vitals')
                            }>Vitals</a></li>
                            <li><a className={`nav-link ${activeClass === "Teams" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Teams')
                            }>Teams</a></li>
                            <li><a className={`nav-link ${activeClass === "Events" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Events')
                            }>Events</a></li>
                            <li><a className={`nav-link ${activeClass === "References" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('References')
                            }>References</a></li>
                            <li><a className={`nav-link ${activeClass === "AdditionalSports" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('AdditionalSports')
                            }>Additional Sports</a></li>
                            <li><a className={`nav-link ${activeClass === "Press" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Press')
                            }>Press</a></li>
                            <li><a className={`nav-link ${activeClass === "Academics" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Academics')
                            }>Academics</a></li>
                            <li><a className={`nav-link ${activeClass === "GPAScores" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('GPAScores')
                            }>Grades & Scores</a></li>
                            {!props.isPublicView &&
                            <li><a className={`nav-link ${activeClass === "CollegeInsights" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('CollegeInsights')
                            }>College Insights</a></li>}
                            <li><a className={`nav-link ${activeClass === "Offers" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Offers')
                            }>Offers</a></li>
                            <li><a className={`nav-link ${activeClass === "Commitment" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Commitment')
                            }>Commitment</a></li>
                        </ul>
                    </div>
                    <div className="sidebar aboutsidebar-show-mobile" id="myScrollspy">
                        <ul className="menu-links nav">
                            <OwlCarousel {...optionCarousel}
                                         startPosition={tabSel - 1}
                            >
                                <li><a className={`nav-link ${activeClass === "Basic" ? "active" : ""}`} style={{ cursor: "pointer" }}
                                    // window.scrollTo(0, 504.79998779296875);
                                       onClick={() => { srcollToId('Basic', 1) }}
                                >Basic</a></li>
                                <li><a className={`nav-link ${activeClass === "VideoandPhoto" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('VideoandPhoto', 2)}
                                    // window.scrollTo(0,1153.5999755859375)}
                                >Videos & Photos</a></li>
                                <li><a className={`nav-link ${activeClass === "Contact" ? "active" : ""}`}
                                       style={{cursor: "pointer"}} onClick={() => srcollToId('Contact', 3)
                                    // window.scrollTo(0,1876)
                                }>Contact</a></li>
                                <li><a className={`nav-link ${activeClass === "Vitals" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Vitals', 4)
                                    // window.scrollTo(0,2707.199951171875)
                                }>Vitals</a></li>
                                <li><a className={`nav-link ${activeClass === "Teams" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Teams', 5)
                                    // window.scrollTo(0,3202.39990234375)
                                }>Teams</a></li>
                                <li><a className={`nav-link ${activeClass === "Events" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Events', 6)
                                    // window.scrollTo(0,3927.199951171875)
                                }>Events</a></li>
                                <li><a className={`nav-link ${activeClass === "References" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('References', 7)
                                    // window.scrollTo(0,4319.2001953125)
                                }>References</a></li>
                                <li className={'tabslide-item'}><a className={`nav-link ${activeClass === "AdditionalSports" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('AdditionalSports', 8)
                                    // window.scrollTo(0,4934.39990234375)
                                }>Additional Sports</a></li>
                                <li><a className={`nav-link ${activeClass === "Press" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Press', 9)
                                    // window.scrollTo(0,5098.39990234375)
                                }>Press</a></li>
                                <li><a className={`nav-link ${activeClass === "Academics" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Academics', 10)
                                    // window.scrollTo(0,5239.2001953125)
                                }>Academics</a></li>
                                <li><a className={`nav-link ${activeClass === "GPAScores" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('GPAScores', 11)
                                    // window.scrollTo(0,5779.2001953125)
                                }>Grades & Scores</a></li>
                                {!props.isPublicView &&
                                <li><a className={`nav-link ${activeClass === "CollegeInsights" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('CollegeInsights', 12)
                                    // window.scrollTo(0,7599.2001953125)
                                }>College Insights</a></li>}
                                <li><a className={`nav-link ${activeClass === "Offers" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Offers', 13)
                                    // window.scrollTo(0,8212)
                                }>Offers</a></li>
                                <li><a className={`nav-link ${activeClass === "Commitment" ? "active" : ""}`} style={{ cursor: "pointer" }} onClick={() => srcollToId('Commitment', 14)
                                    // window.scrollTo(0,8212)
                                }>Commitment</a></li>

                            </OwlCarousel>
                        </ul>
                    </div>
                    <div className="about-detail-box">
                        <div id="Basic" className="common-item-box">
                            <h3 className="title">Basic</h3>
                            <div className="content-box">
                                <ul className="data-info-item">
                                    <li className="w-100">
                                        <span>Profile Photo</span>
                                        {props.isPublicView && publicProfileData?.length > 0 ?
                                            publicProfileData?.map((itm, idx) => {
                                                if (itm?.publicprofilefieldid == 21 && itm?.active) {
                                                    return <>
                                                        <span className="profile-img">
                                                            {profileAbout?.athleteBasicInfo?.[0]?.profileImages ?
                                                                <img
                                                                    src={`${ENV.DOWNLOAD_URL + "/" + profileAbout?.athleteBasicInfo?.[0]?.profileImages || ""}`}
                                                                    alt={""}
                                                                /> :
                                                                <span style={{ fontWeight: "700", fontSize: "28px" }}>
                                                                    {`${profileAbout?.athleteBasicInfo?.[0]?.athleteFirstName ? (profileAbout?.athleteBasicInfo?.[0]?.athleteFirstName?.split("")[0].concat(profileAbout?.athleteBasicInfo?.[0]?.athleteLastName ? profileAbout?.athleteBasicInfo?.[0]?.athleteLastName?.split("")[0] : "")).toUpperCase() : ""}`}
                                                                </span>
                                                            }
                                                        </span>
                                                        <span className="edit-profile-link"><a className="edit-link"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                window.scrollTo(0, 0)
                                                                setTimeout(() => {
                                                                    props.history.push('/basic')
                                                                }, 500)
                                                            }}
                                                        >
                                                        </a>
                                                        </span>
                                                    </>
                                                }
                                                else if (itm?.publicprofilefieldid == 21 && itm?.active == 0) {
                                                    return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
                                                }
                                            }) || ""
                                            :
                                            <>
                                                <span className="profile-img">
                                                    {profileAbout?.athleteBasicInfo?.[0]?.profileImages ?
                                                        <img
                                                            src={`${ENV.DOWNLOAD_URL + "/" + profileAbout?.athleteBasicInfo?.[0]?.profileImages || ""}`}
                                                            alt={""}
                                                        /> :
                                                        <span style={{ fontWeight: "700", fontSize: "28px" }}>
                                                            {`${profileAbout?.athleteBasicInfo?.[0]?.athleteFirstName ? (profileAbout?.athleteBasicInfo?.[0]?.athleteFirstName?.split("")[0].concat(profileAbout?.athleteBasicInfo?.[0]?.athleteLastName ? profileAbout?.athleteBasicInfo?.[0]?.athleteLastName?.split("")[0] : "")).toUpperCase() : ""}`}
                                                        </span>
                                                    }
                                                </span>
                                                <span className="edit-profile-link"><a className="edit-link" style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        window.scrollTo(0, 0)
                                                        setTimeout(() => {
                                                            props.history.push('/basic')
                                                        }, 500)
                                                    }}
                                                >Edit</a>
                                                </span>
                                            </>
                                        }
                                    </li>
                                    <li>
                                        <span>First Name</span>
                                        <span>{profileAbout && getCamelCase(profileAbout?.athleteBasicInfo?.[0]?.athleteFirstName || "")}</span>
                                    </li>
                                    <li>
                                        <span>Last Name</span>
                                        <span>{profileAbout && getCamelCase(profileAbout?.athleteBasicInfo?.[0]?.athleteLastName || "")}</span>
                                    </li>
                                    <li>
                                        <span>Gender</span>
                                        <span>{profileAbout && getCamelCase(profileAbout?.athleteBasicInfo[0]?.gender || "")}</span>
                                    </li>
                                    <li>
                                        <span>Date of Birth</span>
                                        {props.isPublicView && publicProfileData?.length > 0 ?
                                            publicProfileData?.map((itm, idx) => {
                                                if (itm?.publicprofilefieldid == 22 && itm?.active) return <span>{profileAbout && profileAbout?.athleteBasicInfo?.[0]?.dateOfBirth && getUTCTimeDateOverall(profileAbout?.athleteBasicInfo[0]?.dateOfBirth) || ""}</span>
                                                else if (itm?.publicprofilefieldid == 22 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
                                            }) || ""
                                            :
                                            <span>{profileAbout && profileAbout?.athleteBasicInfo?.[0]?.dateOfBirth && getUTCTimeDateOverall(profileAbout?.athleteBasicInfo?.[0]?.dateOfBirth) || ""}</span>
                                        }
                                    </li>
                                    <li>
                                        <span>High School Grad Year</span>
                                        <span>{profileAbout && profileAbout?.athleteBasicInfo?.[0]?.yearValue || ""}</span>
                                    </li>
                                    <li>
                                        <span>What's Your GPA scale?</span>
                                        {props.isPublicView && publicProfileData?.length > 0 ?
                                            publicProfileData?.map((itm, idx) => {
                                                if (itm?.publicprofilefieldid == 28 && itm?.active) return <span>{profileAbout && profileAbout?.athleteBasicInfo[0]?.GpaScaleValue || ""}</span>
                                                else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>

                                            }) || ""
                                            : <span>{profileAbout && profileAbout?.athleteBasicInfo?.[0]?.GpaScaleValue || ""}</span>}
                                    </li>
                                    <li>
                                        <span>GPA (Unweighted)</span>
                                        {props.isPublicView && publicProfileData?.length > 0 ?
                                            publicProfileData?.map((itm, idx) => {
                                                if (itm?.publicprofilefieldid == 28 && itm?.active) return <span>{profileAbout && profileAbout?.athleteBasicInfo[0]?.unweightedgpa || ""}</span>
                                                else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>

                                            }) || ""
                                            : <span>{profileAbout && profileAbout?.athleteBasicInfo?.[0]?.unweightedgpa || ""}</span>}
                                    </li>
                                    <li>
                                        <span>GPA (Weighted)</span>
                                        {props.isPublicView && publicProfileData?.length > 0 ?
                                            publicProfileData?.map((itm, idx) => {
                                                if (itm?.publicprofilefieldid == 28 && itm?.active) return <span>{profileAbout && profileAbout?.athleteBasicInfo[0]?.gpaScale || ""}</span>
                                                else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>

                                            }) || ""
                                            : <span>{profileAbout && profileAbout?.athleteBasicInfo?.[0]?.gpaScale || ""}</span>}
                                    </li>
                                    <li>
                                        <span>
                                            {/*Not in high school currently?*/}
                                            Post High School
                                        </span>
                                        <span>{profileAbout && profileAbout?.athleteBasicInfo?.[0]?.highScoolstatus === 1 ? 'Yes' : "No" || ""}</span>
                                    </li>
                                    <li>
                                        <span>Current Level</span>
                                        <span>{profileAbout?.athleteBasicInfo?.[0]?.currentLevelValue ? profileAbout?.athleteBasicInfo?.[0]?.currentLevelValue : ""}</span>
                                    </li>
                                </ul>
                                <h3>About Me</h3>
                                <p>{profileAbout && profileAbout?.athleteBasicInfo?.[0]?.aboutMe || ""} </p>
                            </div>
                        </div>
                        <div id="VideoandPhoto" className="common-item-box">
                            <h3 className="title">Videos & Photos</h3>
                            <div className="content-box">
                                {props.isPublicView && publicProfileData?.length > 0 ?
                                    publicProfileData?.map((itm, idx) => {
                                        if (itm?.publicprofilefieldid == 26 && itm?.active) return <div className="edit-video-link">
                                            <a className="edit-link" style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    window.scrollTo(0, 0)
                                                    setTimeout(() => {
                                                        props.history.push('/videoPhotos')
                                                    }, 1000)
                                                }}
                                            >
                                            </a>
                                        </div>
                                    }) || "" ?
                                        <div className="edit-video-link">
                                            <a className="edit-link" style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    window.scrollTo(0, 0)
                                                    setTimeout(() => {
                                                        props.history.push('/videoPhotos')
                                                    }, 1000)
                                                }}
                                            >
                                            </a>
                                        </div>
                                        : ""
                                    : <div className="edit-video-link">
                                        <a className="edit-link" style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                window.scrollTo(0, 0)
                                                setTimeout(() => {
                                                    props.history.push('/videoPhotos')
                                                }, 1000)
                                            }}
                                        >Edit</a>
                                    </div>
                                }

                                {props.isPublicView && publicProfileData?.length > 0 ?
                                    publicProfileData?.map((itm, idx) => {
                                        if (itm?.publicprofilefieldid == 26 && itm?.active) {
                                            return (<ul className="video-grid-list clearfix">
                                                {profileAbout && profileAbout.featuredPhotosAndVideos.length > 0 ?
                                                    profileAbout.featuredPhotosAndVideos.map((item, index) => {
                                                        if (item.isphotosorvideosLik === 1) {
                                                            return (
                                                                <li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
                                                                    <div className="img-box">
                                                                        <video 
                                                                        //controls
                                                                        >
                                                                            <source
                                                                                src={`${ENV.DOWNLOAD_URL +
                                                                                    "/" +
                                                                                    typeConvert(item?.videoname, "Athlete_About")+"#t=0.2"
                                                                                    }`}
                                                                                alt="video"
                                                                            />
                                                                        </video>
                                                                        <div className="video-play-button"  onClick={() =>
                                                                            openVideoOnNewTab(`${ENV.DOWNLOAD_URL +
                                                                            "/" +
                                                                            item?.videoname
                                                                            }`, false, item)}>
                                                                            <div className="video-play-inner">
                                                                                <button style={{background: "#c0321f"}}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.802"
                                                                                         fill={'#fff'}
                                                                                         height="16.203" viewBox="0 0 10.802 16.203">
                                                                                        <path className="a" d="M8.1,0l8.1,10.8H0Z"
                                                                                              transform="translate(10.802) rotate(90)"></path>
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="video-detail-box">
                                                                        <div className="title-box">
                                                                            <div className="video-title half">
                                                                                {item?.originalname || ""}
                                                                            </div>
                                                                        </div>
                                                                        <div className="video-info">
                                                                            <div className="half">
                                                                                <span>{getUTCTimeDateDash(item.createdOn)}</span>
                                                                                <span>{moment(item.createdOn).format('LT')}</span>
                                                                                <span>{`${item?.totalViewCount ? item?.totalViewCount : 0} Views`}</span>
                                                                                <span>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                        height="16.679" viewBox="0 0 12 16.679">
                                                                                        <path className="a"
                                                                                            d="M31.132,13.883c-.593,3.784-3.795,4.647-6.609,4.533a5.415,5.415,0,0,1-5.335-5.335,6.982,6.982,0,0,1,2.371-4.708s-.976,2.214.645,2.476.039-3.19,1.517-4.708c1.308-1.343,2.014-.825,2.576-1.87a2,2,0,0,0-.092-2.524s2.863.986,1.968,3.6A4.219,4.219,0,0,1,26.982,7.1c-.635.569-1.66,1.812-.174,2.978.889.7,2.633-.07,2.842-1.988,0,0,1.883,3.23,1.482,5.788Z"
                                                                                            transform="translate(-19.188 -1.747)"></path>
                                                                                        <path className="b"
                                                                                            d="M39.567,75.488s0-.879,1.682-1.648a1.276,1.276,0,0,0,1.185,1.225c1.061.091,2.661-1.143,1.81-2.311,0,0,3.936,1.377,3.8,3.014-.113,1.34-2.692,1.627-4.689,1.587-2.089-.042-3.785-.836-3.785-1.868"
                                                                                            transform="translate(-37.815 -60.681)"></path>
                                                                                    </svg>
                                                                                    {item?.totalLikeCount ? item?.totalLikeCount : 0}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                        else if (item.isphotosorvideosLik === 2) {
                                                            return (
                                                                <li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
                                                                    <div className="img-box">
                                                                        <video
                                                                        poster={getThumbnail(item?.videolink, item, "ProfileABT")}
                                                                            // controls
                                                                        >
                                                                            <source
                                                                                src={item?.videolink}
                                                                                alt="video/youtube"
                                                                            />
                                                                        </video>
                                                                        <div className="video-play-button"  onClick={() =>
                                                                            openVideoOnNewTab(item?.videolink, true, item)
                                                                        }>
                                                                            <div className="video-play-inner">
                                                                                <button style={{background: "#c0321f"}}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.802"
                                                                                         fill={"#fff"}
                                                                                        height="16.203" viewBox="0 0 10.802 16.203">
                                                                                        <path className="a" d="M8.1,0l8.1,10.8H0Z"
                                                                                            transform="translate(10.802) rotate(90)"></path>
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="video-detail-box">
                                                                        <div className="title-box">
                                                                            <div className="video-title half">
                                                                                {item?.originalname || ""}
                                                                            </div>
                                                                        </div>
                                                                        <div className="video-info">
                                                                            <div className="half">
                                                                                <span>{getUTCTimeDateDash(item.createdOn)}</span>
                                                                                <span>{moment(item.createdOn).format('LT')}</span>
                                                                                <span>{`${item?.totalViewCount ? item?.totalViewCount : 0} Views`}</span>
                                                                                <span>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                        height="16.679" viewBox="0 0 12 16.679">
                                                                                        <path className="a"
                                                                                            d="M31.132,13.883c-.593,3.784-3.795,4.647-6.609,4.533a5.415,5.415,0,0,1-5.335-5.335,6.982,6.982,0,0,1,2.371-4.708s-.976,2.214.645,2.476.039-3.19,1.517-4.708c1.308-1.343,2.014-.825,2.576-1.87a2,2,0,0,0-.092-2.524s2.863.986,1.968,3.6A4.219,4.219,0,0,1,26.982,7.1c-.635.569-1.66,1.812-.174,2.978.889.7,2.633-.07,2.842-1.988,0,0,1.883,3.23,1.482,5.788Z"
                                                                                            transform="translate(-19.188 -1.747)"></path>
                                                                                        <path className="b"
                                                                                            d="M39.567,75.488s0-.879,1.682-1.648a1.276,1.276,0,0,0,1.185,1.225c1.061.091,2.661-1.143,1.81-2.311,0,0,3.936,1.377,3.8,3.014-.113,1.34-2.692,1.627-4.689,1.587-2.089-.042-3.785-.836-3.785-1.868"
                                                                                            transform="translate(-37.815 -60.681)"></path>
                                                                                    </svg>
                                                                                    {item?.totalLikeCount ? item?.totalLikeCount : 0}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                        else if (item.isphotosorvideosLik === 0) {
                                                            return (
                                                                <li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
                                                                    <div className="img-box">
                                                                        <img
                                                                            src={`${ENV.DOWNLOAD_URL + "/" + item?.photosname
                                                                                }`}
                                                                            alt="profile Bg"
                                                                            style={{ width: "100%", height: "100%" }}
                                                                        />
                                                                        <div className="video-play-button">
                                                                            <div className="video-play-inner">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="video-detail-box">
                                                                        <div className="title-box">
                                                                            <div className="video-title half">
                                                                                {item?.originalname || ""}
                                                                            </div>
                                                                        </div>
                                                                        <div className="video-info">
                                                                            <div className="half">
                                                                                <span>{getUTCTimeDateDash(item.createdOn)}</span>
                                                                                <span>{moment(item.createdOn).format('LT')}</span>
                                                                                <span>{`${item?.totalViewCount ? item?.totalViewCount : 0} Views`}</span>
                                                                                <span>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                        height="16.679" viewBox="0 0 12 16.679">
                                                                                        <path className="a"
                                                                                            d="M31.132,13.883c-.593,3.784-3.795,4.647-6.609,4.533a5.415,5.415,0,0,1-5.335-5.335,6.982,6.982,0,0,1,2.371-4.708s-.976,2.214.645,2.476.039-3.19,1.517-4.708c1.308-1.343,2.014-.825,2.576-1.87a2,2,0,0,0-.092-2.524s2.863.986,1.968,3.6A4.219,4.219,0,0,1,26.982,7.1c-.635.569-1.66,1.812-.174,2.978.889.7,2.633-.07,2.842-1.988,0,0,1.883,3.23,1.482,5.788Z"
                                                                                            transform="translate(-19.188 -1.747)"></path>
                                                                                        <path className="b"
                                                                                            d="M39.567,75.488s0-.879,1.682-1.648a1.276,1.276,0,0,0,1.185,1.225c1.061.091,2.661-1.143,1.81-2.311,0,0,3.936,1.377,3.8,3.014-.113,1.34-2.692,1.627-4.689,1.587-2.089-.042-3.785-.836-3.785-1.868"
                                                                                            transform="translate(-37.815 -60.681)"></path>
                                                                                    </svg>
                                                                                    {item?.totalLikeCount ? item?.totalLikeCount : 0}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                    }) : "No Videos or Photos"}
                                            </ul>)
                                        }
                                        else if (itm?.publicprofilefieldid == 26 && itm?.active == 0) {
                                            return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
                                        }
                                    }) || ""
                                    :
                                    <ul className="video-grid-list clearfix">
                                        {profileAbout && profileAbout.featuredPhotosAndVideos.length > 0 ?
                                            profileAbout.featuredPhotosAndVideos.map((item, index) => {
                                                if (item.isphotosorvideosLik === 1) {
                                                    return (
                                                        <li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
                                                            <div className="img-box">
                                                                <video 
                                                                //controls
                                                                >
                                                                    <source
                                                                        src={`${ENV.DOWNLOAD_URL +
                                                                            "/" +
                                                                            item?.videoname
                                                                            }`}
                                                                        alt="video"
                                                                    />
                                                                </video>
                                                            </div>
                                                            <div className="video-detail-box">
                                                                <div className="title-box">
                                                                    <div className="video-title half">
                                                                        {item?.originalname || ""}
                                                                    </div>
                                                                </div>
                                                                <div className="video-info">
                                                                    <div className="half">
                                                                        <span>{getUTCTimeDateDash(item.createdOn)}</span>
                                                                        <span>{moment(item.createdOn).format('LT')}</span>
                                                                        <span>{`${item?.totalViewCount ? item?.totalViewCount : 0} Views`}</span>
                                                                        <span>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                height="16.679" viewBox="0 0 12 16.679">
                                                                                <path className="a"
                                                                                    d="M31.132,13.883c-.593,3.784-3.795,4.647-6.609,4.533a5.415,5.415,0,0,1-5.335-5.335,6.982,6.982,0,0,1,2.371-4.708s-.976,2.214.645,2.476.039-3.19,1.517-4.708c1.308-1.343,2.014-.825,2.576-1.87a2,2,0,0,0-.092-2.524s2.863.986,1.968,3.6A4.219,4.219,0,0,1,26.982,7.1c-.635.569-1.66,1.812-.174,2.978.889.7,2.633-.07,2.842-1.988,0,0,1.883,3.23,1.482,5.788Z"
                                                                                    transform="translate(-19.188 -1.747)"></path>
                                                                                <path className="b"
                                                                                    d="M39.567,75.488s0-.879,1.682-1.648a1.276,1.276,0,0,0,1.185,1.225c1.061.091,2.661-1.143,1.81-2.311,0,0,3.936,1.377,3.8,3.014-.113,1.34-2.692,1.627-4.689,1.587-2.089-.042-3.785-.836-3.785-1.868"
                                                                                    transform="translate(-37.815 -60.681)"></path>
                                                                            </svg>
                                                                            {item?.totalLikeCount ? item?.totalLikeCount : 0}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                                else if (item.isphotosorvideosLik === 2) {
                                                    return (
                                                        <li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
                                                            <div className="img-box">
                                                                <video
                                                                poster={thumbnail}
                                                                    // controls
                                                                >
                                                                    <source
                                                                        src={item?.videolink}
                                                                        alt="video/youtube"
                                                                    />
                                                                </video>
                                                                {/*earlier in openVideoOnNewTab func last two parameter was not there in below code*/}
                                                                <div className="video-play-button" onClick={() =>
                                                                    openVideoOnNewTab(item?.videolink, true, item)
                                                                }>
                                                                    <div className="video-play-inner">
                                                                        <button style={{background: "#c0321f"}}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.802"
                                                                                 fill={'#fff'}
                                                                                height="16.203" viewBox="0 0 10.802 16.203">
                                                                                <path className="a" d="M8.1,0l8.1,10.8H0Z"
                                                                                    transform="translate(10.802) rotate(90)"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="video-detail-box">
                                                                <div className="title-box">
                                                                    <div className="video-title half">
                                                                        {item?.originalname || ""}
                                                                    </div>
                                                                </div>
                                                                <div className="video-info">
                                                                    <div className="half">
                                                                        <span>{getUTCTimeDateDash(item.createdOn)}</span>
                                                                        <span>{moment(item.createdOn).format('LT')}</span>
                                                                        <span>{`${item?.totalViewCount ? item?.totalViewCount : 0} Views`}</span>
                                                                        <span>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                height="16.679" viewBox="0 0 12 16.679">
                                                                                <path className="a"
                                                                                    d="M31.132,13.883c-.593,3.784-3.795,4.647-6.609,4.533a5.415,5.415,0,0,1-5.335-5.335,6.982,6.982,0,0,1,2.371-4.708s-.976,2.214.645,2.476.039-3.19,1.517-4.708c1.308-1.343,2.014-.825,2.576-1.87a2,2,0,0,0-.092-2.524s2.863.986,1.968,3.6A4.219,4.219,0,0,1,26.982,7.1c-.635.569-1.66,1.812-.174,2.978.889.7,2.633-.07,2.842-1.988,0,0,1.883,3.23,1.482,5.788Z"
                                                                                    transform="translate(-19.188 -1.747)"></path>
                                                                                <path className="b"
                                                                                    d="M39.567,75.488s0-.879,1.682-1.648a1.276,1.276,0,0,0,1.185,1.225c1.061.091,2.661-1.143,1.81-2.311,0,0,3.936,1.377,3.8,3.014-.113,1.34-2.692,1.627-4.689,1.587-2.089-.042-3.785-.836-3.785-1.868"
                                                                                    transform="translate(-37.815 -60.681)"></path>
                                                                            </svg>
                                                                            {item?.totalLikeCount ? item?.totalLikeCount : 0}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                                else if (item.isphotosorvideosLik === 0) {
                                                    return (
                                                        <li className="video-box" data-toggle="modal" data-target="#VEDIOMAINModal">
                                                            <div className="img-box">
                                                                <img
                                                                    src={`${ENV.DOWNLOAD_URL + "/" + item?.photosname
                                                                        }`}
                                                                    alt="profile Bg"
                                                                    style={{ width: "100%", height: "100%" }}
                                                                />
                                                                <div className="video-play-button">
                                                                    <div className="video-play-inner">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="video-detail-box">
                                                                <div className="title-box">
                                                                    <div className="video-title half">
                                                                        {item?.originalname || ""}
                                                                    </div>
                                                                </div>
                                                                <div className="video-info">
                                                                    <div className="half">
                                                                        <span>{getUTCTimeDateDash(item.createdOn)}</span>
                                                                        <span>{moment(item.createdOn).format('LT')}</span>
                                                                        <span>{`${item?.totalViewCount ? item?.totalViewCount : 0} Views`}</span>
                                                                        <span>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                height="16.679" viewBox="0 0 12 16.679">
                                                                                <path className="a"
                                                                                    d="M31.132,13.883c-.593,3.784-3.795,4.647-6.609,4.533a5.415,5.415,0,0,1-5.335-5.335,6.982,6.982,0,0,1,2.371-4.708s-.976,2.214.645,2.476.039-3.19,1.517-4.708c1.308-1.343,2.014-.825,2.576-1.87a2,2,0,0,0-.092-2.524s2.863.986,1.968,3.6A4.219,4.219,0,0,1,26.982,7.1c-.635.569-1.66,1.812-.174,2.978.889.7,2.633-.07,2.842-1.988,0,0,1.883,3.23,1.482,5.788Z"
                                                                                    transform="translate(-19.188 -1.747)"></path>
                                                                                <path className="b"
                                                                                    d="M39.567,75.488s0-.879,1.682-1.648a1.276,1.276,0,0,0,1.185,1.225c1.061.091,2.661-1.143,1.81-2.311,0,0,3.936,1.377,3.8,3.014-.113,1.34-2.692,1.627-4.689,1.587-2.089-.042-3.785-.836-3.785-1.868"
                                                                                    transform="translate(-37.815 -60.681)"></path>
                                                                            </svg>
                                                                            {item?.totalLikeCount ? item?.totalLikeCount : 0}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            }) : "No Videos or Photos"}
                                    </ul>

                                }
                                {props.isPublicView ? "" :
                                    <div className="buton-box text-center">
                                        <Link className="btn outlined blue rounded medium" to={'/videoPhotos'}>Go To Videos and Photos</Link>
                                    </div>
                                }
                            </div>
                        </div>
                        {showContact() == "NotCurrentAdvisor" ? 
                        <div id="Contact" className="common-item-box">
                            <h3 className="title">Contact</h3>
                            <div className="content-box">Private athlete information</div>
                        </div>    
                        : showContact() == true ?       
                        <div id="Contact" className="common-item-box">
                            <h3 className="title">Contact</h3>
                            <div className="content-box">
                                <div className="box-item">
                                    <h4>ATHLETE <a className="edit-link" style={{cursor: "pointer"}} onClick={() => {
                                        window.scrollTo(0, 0)
                                        setTimeout(() => {
                                            props.history.push('/contact')
                                        }, 1000)
                                    }}>
                                        {props.isPublicView ? "" : "Edit"}
                                    </a></h4>
                                    <ul className="data-info-item">
                                        <li>
                                            <span>Mobile Phone</span>
                                            <span>{profileAbout && profileAbout.athleteBasicInfo[0]?.athletePhoneNumber || ""}</span>
                                        </li>
                                        <li>
                                            <span>Phone Type</span>
                                            <span>{profileAbout && getCamelCase(profileAbout.athleteBasicInfo[0]?.relationShipName || "")}</span>
                                        </li>
                                        <li>
                                            <span>Primary Email</span>
                                            <span>{profileAbout && profileAbout.athleteBasicInfo[0]?.athleteEmailId || ""}</span>
                                        </li>
                                        <li>
                                            <span>Twitter</span>
                                            <span
                                                className="name">{profileAbout && profileAbout.athleteBasicInfo[0]?.twitterAccountName ?
                                                `@${profileAbout.athleteBasicInfo[0]?.twitterAccountName}` : ""}</span>
                                        </li>
                                        <li>
                                            <span>Instagram</span>
                                            <span
                                                className="name">{profileAbout && profileAbout.athleteBasicInfo[0]?.instagramAccountName ?
                                                `@${profileAbout.athleteBasicInfo[0]?.instagramAccountName}` : ""
                                            }</span>
                                        </li>
                                        <li>
                                            <span>Home Address</span>
                                            {props.isPublicView && publicProfileData?.length > 0 ?
                                                publicProfileData?.map((itm, idx) => {
                                                    if (itm?.publicprofilefieldid == 24 && itm?.active) return <span>
                                                        {`${profileAbout?.athleteBasicInfo?.[0]?.address ? profileAbout?.athleteBasicInfo?.[0]?.address + ", " : ""}
                                                        ${profileAbout?.athleteBasicInfo?.[0]?.addresscountinue ? profileAbout?.athleteBasicInfo?.[0]?.addresscountinue + ", " : ""}
                                                    ${profileAbout?.athleteBasicInfo?.[0]?.city ? profileAbout?.athleteBasicInfo?.[0]?.city + ", " : ""}
                                                    ${profileAbout?.athleteBasicInfo?.[0]?.stateName ? profileAbout?.athleteBasicInfo?.[0]?.stateName + ", " : ""}
                                                    ${profileAbout?.athleteBasicInfo?.[0]?.countryName ? profileAbout?.athleteBasicInfo?.[0]?.countryName + ", " : ""}${profileAbout?.athleteBasicInfo?.[0]?.athletezipcode ? profileAbout?.athleteBasicInfo?.[0]?.athletezipcode : ""}`}
                                                    </span>
                                                    else if (itm?.publicprofilefieldid == 24 && itm?.active == 0) {
                                                        return <span><p style={{fontWeight: "bold"}}>Permission Not Granted</p></span>
                                                    }
                                                }) || ""
                                                :
                                                <span className="address-link">
                                                    {profileAbout && (profileAbout?.athleteBasicInfo?.[0]?.address || profileAbout?.athleteBasicInfo?.[0]?.city || profileAbout?.athleteBasicInfo?.[0]?.stateName || profileAbout?.athleteBasicInfo?.[0]?.countryName || profileAbout?.athleteBasicInfo?.[0]?.athletezipcode) &&
                                                    <svg className="pin-icon" xmlns="http://www.w3.org/2000/svg"
                                                         width="15.152" height="21.219"
                                                         viewBox="0 0 15.152 21.219">
                                                        <g id="location" transform="translate(-6.75 -2.248)">
                                                            <path id="Path_431" data-name="Path 431"
                                                                  d="M18.781,12.765a1.515,1.515,0,1,1-1.515-1.515A1.515,1.515,0,0,1,18.781,12.765Z"
                                                                  transform="translate(-2.938 -2.938)"
                                                                  fill="#ff0000"/>
                                                            <path id="Path_432" data-name="Path 432"
                                                                  d="M14.326,2.25A7.426,7.426,0,0,0,6.75,9.5c0,1.9.867,4.432,2.577,7.519a55.569,55.569,0,0,0,3.788,5.839,1.5,1.5,0,0,0,2.424,0,55.789,55.789,0,0,0,3.788-5.835C21.036,13.932,21.9,11.4,21.9,9.5A7.426,7.426,0,0,0,14.326,2.25Zm0,10.607a3.031,3.031,0,1,1,3.031-3.031,3.031,3.031,0,0,1-3.031,3.031Z"
                                                                  transform="translate(0)" fill="#ff0000"/>
                                                        </g>
                                                    </svg>
                                                    }
                                                    {`${profileAbout?.athleteBasicInfo?.[0]?.address ? profileAbout?.athleteBasicInfo?.[0]?.address + ", " : ""}
                                                     ${profileAbout?.athleteBasicInfo?.[0]?.addresscountinue ? profileAbout?.athleteBasicInfo?.[0]?.addresscountinue + ", " : ""}
                                                    ${profileAbout?.athleteBasicInfo?.[0]?.city ? profileAbout?.athleteBasicInfo?.[0]?.city + ", " : ""}
                                                    ${profileAbout?.athleteBasicInfo?.[0]?.stateName ? profileAbout?.athleteBasicInfo?.[0]?.stateName + ", " : ""}
                                                    ${profileAbout?.athleteBasicInfo?.[0]?.countryName ? profileAbout?.athleteBasicInfo?.[0]?.countryName + ", " : ""}${profileAbout?.athleteBasicInfo?.[0]?.athletezipcode ? profileAbout?.athleteBasicInfo?.[0]?.athletezipcode : ""}`}
                                                </span>
                                            }
                                        </li>
                                    </ul>
                                </div>
                                {props.isPublicView && publicProfileData?.length > 0 ?
                                    publicProfileData?.map((itm, idx) => {
                                        if (itm?.publicprofilefieldid == 23 && itm?.active) {
                                            return profileAbout && profileAbout.parentData.length > 0 ?
                                                profileAbout.parentData.map((el, ex) => {
                                                    return (
                                                        <div className="box-item">
                                                            <h4>PARENT / GUARDIAN {ex + 1} <a className="edit-link"
                                                                                            style={{cursor: "pointer"}}
                                                                                            onClick={() => {
                                                                                                window.scrollTo(0, 0)
                                                                                                setTimeout(() => {
                                                                                                    props.history.push('/contact')
                                                                                                }, 1000)
                                                                                            }}>
                                                            </a></h4>
                                                            <ul className="data-info-item">
                                                                <li>
                                                                    <span>Relationship With Athlete</span>
                                                                    <span>{getCamelCase(el?.relationBetweenParentAndAthlete ?? "")}</span>
                                                                </li>
                                                                <li>
                                                                    <span>First Name</span>
                                                                    <span>{getCamelCase(el?.parentFirstName ?? "")}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Last Name</span>
                                                                    <span>{getCamelCase(el?.parentLastName ?? "")}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Mobile Phone</span>
                                                                    <span>{el?.parentPhoneNumber ?? ""}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Primary Email</span>
                                                                    <span>{el?.parentEmail ?? ""}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )
                                                })
                                                : "No Record Found"
                                        } else if (itm?.publicprofilefieldid == 23 && itm?.active == 0) {
                                            <span>
                                                <h4>PARENT / GUARDIAN</h4>
                                                <p style={{fontWeight: "bold"}}>Permission Not Granted</p>
                                            </span>
                                        }
                                    }) || ""

                                    :
                                    profileAbout && profileAbout.parentData.length > 0 ?
                                        profileAbout.parentData.map((el, ex) => {
                                            return (
                                                <div className="box-item">
                                                    <h4>PARENT / GUARDIAN {ex + 1} <a className="edit-link"
                                                                                    style={{cursor: "pointer"}}
                                                                                    onClick={() => {
                                                                                        window.scrollTo(0, 0)
                                                                                        setTimeout(() => {
                                                                                            props.history.push('/contact')
                                                                                        }, 1000)
                                                                                    }}>Edit</a></h4>
                                                    <ul className="data-info-item">
                                                        <li>
                                                            <span>Relationship With Athlete</span>
                                                            <span>{getCamelCase(el?.relationBetweenParentAndAthlete ?? "")}</span>
                                                        </li>
                                                        <li>
                                                            <span>First Name</span>
                                                            <span>{getCamelCase(el?.parentFirstName ?? "")}</span>
                                                        </li>
                                                        <li>
                                                            <span>Last Name</span>
                                                            <span>{getCamelCase(el?.parentLastName ?? "")}</span>
                                                        </li>
                                                        <li>
                                                            <span>Mobile Phone</span>
                                                            <span>{el?.parentPhoneNumber ?? ""}</span>
                                                        </li>
                                                        <li>
                                                            <span>Primary Email</span>
                                                            <span>{el?.parentEmail ?? ""}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )
                                        })
                                        : "No Record Found"
                                }
                            </div>
                        </div> : null
                        }
                        <div id="Vitals" className="common-item-box">
                            <h3 className="title">Vitals</h3>
                            <div className="content-box">
                                <div className="box-item">
                                    <h4>PHYSICAL MEASUREMENTS <a className="edit-link" style={{ cursor: "pointer" }} onClick={() => {
                                        window.scrollTo(0, 0)
                                        setTimeout(() => {
                                            props.history.push('/vitals')
                                        }, 1000)
                                    }}>{props.isPublicView ? "" : "Edit"}</a></h4>
                                    <ul className="data-info-item">
                                        <li>
                                            <span>Height</span>
                                            {props.isPublicView && publicProfileData?.length > 0 ?
                                                publicProfileData?.map((itm, idx) => {
                                                    if (itm?.publicprofilefieldid == 25 && itm?.active) return <span>{profileAbout?.vitalData?.[0]?.heightValue ? profileAbout?.vitalData?.[0]?.heightValue + " ft " : ""}{profileAbout?.vitalData?.[0]?.inchValue || profileAbout?.vitalData?.[0]?.inchValue === 0 ? profileAbout?.vitalData?.[0]?.inchValue + " inch" : ""}</span>
                                                    else if (itm?.publicprofilefieldid == 25 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
                                                }) || ""
                                                :
                                                <span>{profileAbout?.vitalData?.[0]?.heightValue ? profileAbout?.vitalData?.[0]?.heightValue + " ft " : ""}{profileAbout?.vitalData?.[0]?.inchValue || profileAbout?.vitalData?.[0]?.inchValue === 0 ? profileAbout?.vitalData?.[0]?.inchValue + " inch" : ""}</span>
                                            }
                                        </li>
                                        <li>
                                            <span>Weight</span>
                                            {props.isPublicView && publicProfileData?.length > 0 ?
                                                publicProfileData?.map((itm, idx) => {
                                                    if (itm?.publicprofilefieldid == 25 && itm?.active) return <span>{profileAbout?.vitalData?.[0]?.weight ? profileAbout?.vitalData?.[0]?.weight + " lbs" : ""}</span>
                                                    else if (itm?.publicprofilefieldid == 25 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
                                                }) || ""
                                                :
                                                <span>{profileAbout?.vitalData?.[0]?.weight ? profileAbout?.vitalData?.[0]?.weight + " lbs" : ""}</span>
                                            }
                                        </li>
                                    </ul>
                                </div>
                                <div className="box-item">
                                    <h4>BAT / THROW <Link className="edit-link" to={'/vitals'}>{props.isPublicView ? "" : "Edit"}</Link></h4>
                                    <ul className="data-info-item">
                                        <li>
                                            <span>Bats</span>
                                            <span>{profileAbout && profileAbout?.vitalData[0]?.battingPreferenceValue || ""}</span>
                                        </li>
                                        <li>
                                            <span>Throws</span>
                                            <span>{profileAbout && profileAbout?.vitalData[0]?.throwingPreferenceValue || ""}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="box-item">
                                    <h4>POSITIONS <Link className="edit-link" to={'/vitals'}>{props.isPublicView ? "" : "Edit"}</Link></h4>
                                    <ul className="data-info-item">
                                        <li>
                                            <span>Primary Position</span>
                                            <span>{profileAbout && profileAbout?.vitalData[0]?.primaryPositionshortform || ""}</span>
                                        </li>
                                        <li>
                                            <span>Secondary Position</span>
                                            <span>{profileAbout && profileAbout?.vitalData[0]?.secondaryPositionshortform || ""}</span>
                                        </li>
                                        <li>
                                            <span>Other Position</span>
                                            <span>{profileAbout && profileAbout?.vitalData[0]?.otherPositionshortform || ""}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="Teams" className="common-item-box">
                            <h3 className="title">Teams</h3>
                            <div className="content-box">
                                <div className="box-item">
                                    <h4>CLUB TEAMS <a className="edit-link" style={{ cursor: "pointer" }} onClick={() => {
                                        window.scrollTo(0, 0);
                                        setTimeout(() => {
                                            props.history.push('/teams')
                                        }, 1000)
                                    }}  >{props.isPublicView ? "" : "Edit"}</a></h4>
                                    <ul className="data-info-item team-list"
                                        style={profileAbout?.teamData?.length == 0 ? {borderBottom:"none"} : {}}
                                    >
                                        {profileAbout?.teamData?.length > 0 ?
                                            profileAbout.teamData.map((ed, id) => {
                                                return (
                                                    <li>
                                                        <div className="img-box">
                                                            <img
                                                                src={`${ed?.teamlogo ? ENV.DOWNLOAD_URL + "/" + ed?.teamlogo : teamDefault
                                                                    }`}
                                                                alt="teamlogo"
                                                            />
                                                        </div>
                                                        <div className="detail-box">
                                                            <h5>{ed?.teamname || ed?.highschoolname ? ed?.teamname || ed?.highschoolname : ""}</h5>
                                                            <p>{ed?.schoolOrTeamType || ""}
                                                                {/*<br/>Varsity Team<br/>*/}
                                                                {ed?.city || ""}{(!!ed?.state && !!ed?.city) ? ", " + ed?.state : ed?.state ?? ""}</p>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                            :
                                            ""}
                                    </ul>
                                    <ul className="data-info-item team-list"
                                        style={profileAbout?.clubTeam?.length == 0 ? {borderBottom:"none"} : {}}
                                    >
                                        {profileAbout?.clubTeam?.length > 0 ?
                                            profileAbout.clubTeam.map((club, index) => {
                                                return (
                                                    <li>
                                                        <div className="img-box">
                                                            <img
                                                                src={`${club?.teamlogo ? ENV.DOWNLOAD_URL + "/" + club?.teamlogo : teamDefault
                                                                    }`}
                                                                alt="team logo" />
                                                        </div>
                                                        <div className="detail-box">
                                                            <h5>{club?.verifiedbycoachname || ""}</h5>
                                                            <p>{club?.highschooltype || ""}</p>
                                                            <p><span style={{ display: "block", color: "#000" }}><strong style={{ color: "#000", fontWeight: "700" }}>Club / Travel</strong>{!!club?.teamname ? `: ${club?.teamname}` : "" || ""}</span> {club?.city || ""}{club?.state ? `${(!!club?.city && !!club?.state) ? ',' : ""} ${club?.state}` : "" || ""}</p>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                            :
                                            ""
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="Events" className="common-item-box">
                            <h3 className="title">Events</h3>
                            {props.isPublicView && publicProfileData?.length > 0 ?
                                publicProfileData?.map((itm, idx) => {
                                    if (itm?.publicprofilefieldid === 32 && itm?.active) return <div className="content-box">
                                        <div className="edit-link-box">
                                            <Link className="edit-link" style={{ cursor: "pointer" }} onClick={() => {
                                                window.scrollTo(0, 0)
                                                setTimeout(() => {
                                                    props.history.push('/events')
                                                }, 1000)
                                            }}>
                                            </Link>
                                        </div>
                                        <div className="box-item">
                                            <ul className="data-info-item team-list event">
                                                {profileAbout && profileAbout.EventData.length > 0 ?
                                                    profileAbout.EventData.map((item, index) => {
                                                        return (
                                                            <li>
                                                                <div className="img-box">
                                                                    {<img src={`${item?.eventlogoname ? ENV.DOWNLOAD_URL + "/" + item?.eventlogoname : eventLogoImg01}`}
                                                                        alt="teamlogo"
                                                                    />
                                                                    }
                                                                </div>
                                                                <div className="detail-box">
                                                                    <h5>{item.eventname || ""}
                                                                        <span className="icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="23"
                                                                                height="23" viewBox="0 0 23 23">
                                                                                <g transform="translate(-471 -2209)"><rect
                                                                                    className="a" width="23" height="23"
                                                                                    rx="11.5" transform="translate(471 2209)" />
                                                                                    <path className="b"
                                                                                        d="M17.221,9,9.507,17.416,6,13.59"
                                                                                        transform="translate(471.125 2207.292)" />
                                                                                </g>
                                                                            </svg>
                                                                        </span>
                                                                    </h5>
                                                                    <ul>
                                                                        <li>
                                                                            <span style={{ color: "#000", marginBottom: "0" }}>{getUTCTimeDateOverall(item.startdate)}<em>{getUTCTimeDateOverall(item.enddate)}</em></span>
                                                                            <span style={{ color: "#000" }}>{item?.location || ""}</span>
                                                                        </li>
                                                                        <li><span style={{ color: "#000" }}>{item?.divisionorlevel || ""}</span><span style={{ color: "#000" }}>{(item?.city ? item?.city : "").concat(item?.city && item?.stateName ? ", " : "").concat(item?.stateName ? item?.stateName : "").concat(item?.stateName && item?.countryShortName ? ", " : "").concat(item?.countryShortName ? item?.countryShortName : "")}</span></li>

                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    :
                                                    "No Record Found"}
                                            </ul>
                                        </div>
                                    </div>
                                    else if (itm?.publicprofilefieldid === 32 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
                                }) || ""
                                :
                                <div className="content-box">
                                    <div className="edit-link-box">
                                        <Link className="edit-link" style={{ cursor: "pointer" }} onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/events')
                                            }, 1000)
                                        }}>Edit</Link>
                                    </div>
                                    <div className="box-item">
                                        <ul className="data-info-item team-list event">
                                            {profileAbout && profileAbout.EventData.length > 0 ?
                                                profileAbout.EventData.map((item, index) => {
                                                    return (
                                                        <li>
                                                            <div className="img-box">
                                                                {<img src={`${item?.eventlogoname ? ENV.DOWNLOAD_URL + "/" + item?.eventlogoname : eventLogoImg01}`}
                                                                    alt="teamlogo"
                                                                />
                                                                }
                                                            </div>
                                                            <div className="detail-box">
                                                                <h5>{item.eventname || ""}
                                                                    <span className="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23"
                                                                            height="23" viewBox="0 0 23 23">
                                                                            <g transform="translate(-471 -2209)"><rect
                                                                                className="a" width="23" height="23"
                                                                                rx="11.5" transform="translate(471 2209)" />
                                                                                <path className="b"
                                                                                    d="M17.221,9,9.507,17.416,6,13.59"
                                                                                    transform="translate(471.125 2207.292)" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </h5>
                                                                <ul>
                                                                    <li><span style={{ color: "#000", marginBottom: "0" }}>{getUTCTimeDateOverall(item.startdate)}<em>{getUTCTimeDateOverall(item.enddate)}</em></span> <span style={{ color: "#000" }}>{item?.location || ""}</span>

                                                                    </li>
                                                                    <li><span style={{ color: "#000" }}>{item?.divisionorlevel || ""}</span> <span style={{ color: "#000" }}>{(item?.city ? item?.city : "").concat(item?.city && item?.stateName ? ", " : "").concat(item?.stateName ? item?.stateName : "").concat(item?.stateName && item?.countryShortName ? ", " : "").concat(item?.countryShortName ? item?.countryShortName : "")}</span></li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                                :
                                                "No Record Found"}
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        <div id="References" className="common-item-box">
                            <h3 className="title">References</h3>
                            {props.isPublicView && publicProfileData?.length > 0 ?
                                publicProfileData?.map((itm, idx) => {
                                    if (itm?.publicprofilefieldid == 33 && itm?.active) return <div className="content-box">
                                        <div className="edit-link-box">
                                            <Link className="edit-link" style={{ cursor: "pointer" }} onClick={() => {
                                                window.scrollTo(0, 0)
                                                setTimeout(() => {
                                                    props.history.push('/References')
                                                }, 1000)
                                            }}>
                                            </Link>
                                        </div>
                                        <div className="box-item">
                                            <ul className="data-info-item team-list circle">
                                                {profileAbout && profileAbout.ReferenceData.length > 0 ?
                                                    profileAbout.ReferenceData.map((ref, index) => {
                                                        return (
                                                            <li>
                                                                <div className="img-box">
                                                                    <img
                                                                        src={`${ref?.teamlogo ? ENV.DOWNLOAD_URL + "/" + ref?.teamlogo : teamDefault
                                                                            }`}
                                                                        alt="teamlogo"
                                                                    />
                                                                </div>
                                                                <div className="detail-box">
                                                                    <h5><strong>Coach Name:</strong> <span className="referenceName">{ref?.reffirstname ? ref?.reffirstname.concat(ref?.reflastName ? " " + ref?.reflastName : "") : ""}</span></h5>
                                                                    <p className="reference-item">
                                                                        <strong>Coach Type:</strong>
                                                                        <span className="referenceName">{ref?.coachTypeName || ""}</span>
                                                                    </p>
                                                                    <p className="reference-item">
                                                                        <strong>Mobile Phone: </strong>
                                                                        <span className="referenceName">{ref?.refphone || ""}</span>
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    :
                                                    "No Record Found"
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    else if (itm?.publicprofilefieldid == 33 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
                                }) || ""
                                :
                                <div className="content-box">
                                    <div className="edit-link-box">
                                        <Link className="edit-link" style={{ cursor: "pointer" }} onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/References')
                                            }, 1000)
                                        }}>Edit</Link>
                                    </div>
                                    <div className="box-item">
                                        <ul className="data-info-item team-list circle">
                                            {profileAbout && profileAbout.ReferenceData.length > 0 ?
                                                profileAbout.ReferenceData.map((ref, index) => {
                                                    return (
                                                        <li>
                                                            <div className="img-box">
                                                                <img
                                                                    src={`${ref?.teamlogo ? ENV.DOWNLOAD_URL + "/" + ref?.teamlogo : teamDefault
                                                                        }`}
                                                                    alt="teamlogo"
                                                                />
                                                            </div>
                                                            <div className="detail-box">
                                                                <h5><strong>Coach Name:</strong> <span className="referenceName">{ref?.reffirstname ? ref?.reffirstname.concat(ref?.reflastName ? " " + ref?.reflastName : "") : ""}</span></h5>
                                                                <p className="reference-item"><strong>Coach Type
                                                                    :</strong> <span className="referenceName">{ref?.coachTypeName || ""}</span>
                                                                </p>
                                                                <p className="reference-item"><strong>Mobile Phone
                                                                    : </strong><span className="referenceName">{ref?.refphone || ""}</span></p>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                                :
                                                "No Record Found"
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        <div id="AdditionalSports" className="common-item-box">
                            <h3 className="title">Additional Sports</h3>
                            <div className="content-box">
                                {!props.isPublicView && <div className="edit-link-box">
                                    <a className="edit-link" style={{ cursor: "pointer" }} onClick={() => {
                                        window.scrollTo(0, 0)
                                        setTimeout(() => {
                                            props.history.push('/addtionalSports')
                                        }, 1000)
                                    }}>Edit</a>
                                </div>
                                }
                                <div className="box-item">
                                    <ul className="data-info-item team-list circle">
                                        {profileAbout && profileAbout.additionalSportData.length > 0 ?
                                            profileAbout.additionalSportData.map((add, inx) => {
                                                return (
                                                    <li>
                                                        <div className="img-box">
                                                            <img
                                                                src={`${add?.teamlogo ? ENV.DOWNLOAD_URL + "/" + add?.teamlogo : teamDefault
                                                                    }`}
                                                                alt="teamlogo"
                                                            />
                                                        </div>
                                                        <div className="detail-box">
                                                            <h5>{add?.additionalsportsname || ""}
                                                            </h5>
                                                            <p><strong>Years Experience :</strong> {add?.startyear || ""}{add?.endyear ? `-${add?.endyear}` : ""}<br /><strong>Level : </strong>
                                                                {add?.levelName || ""}<br /></p>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                            : "No Record Found"
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="Press" className="common-item-box">
                            <h3 className="title">Press</h3>
                            <div className="content-box">
                                <div className="edit-link-box">
                                    <a className="edit-link" style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/press')
                                            }, 1000)
                                        }}
                                    >
                                        {props.isPublicView ? "" : "Edit"}
                                    </a>
                                </div>
                                <div className="box-item">
                                    <ul className="data-info-item team-list">
                                        {profileAbout && profileAbout.pressData.length > 0 ?
                                            profileAbout.pressData.map((press, indx) => {
                                                return (
                                                    <li>
                                                        <div className="detail-box">
                                                            <h5>{press?.title || ""}</h5>
                                                            <p><strong>Press weblink: </strong> <br /><a
                                                                href={press?.link || "www.google.com"} target="_blank">{press?.link || ""}</a></p>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                            :
                                            "No Record Found"}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="Academics" className="common-item-box">
                            <h3 className="title">Academics</h3>
                            <div className="content-box">
                                <div className="box-item">
                                    <h4>HIGH SCHOOL INFORMATION <a className="edit-link" style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/academics')
                                            }, 1000)
                                        }}
                                    >{props.isPublicView ? "" : "Edit"}</a></h4>
                                    {profileAbout && profileAbout.academicData[0]?.schoolData.length > 0 ?
                                        profileAbout.academicData[0]?.schoolData.map((aced, indx) => {
                                            return (indx === profileAbout?.academicData[0]?.schoolData?.length - 1 ?
                                                <ul className="data-info-item">
                                                    <li>
                                                        <span>Zip Code</span>
                                                        <span>{aced?.zipcode && aced?.zipcode !== "null" ? aced?.zipcode : ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>High School Name</span>
                                                        <span>{getCamelCase(aced?.highschoolname) || ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>High School Type</span>
                                                        <span>{aced?.highschooltype === 1 ? "Public" : aced?.highschooltype === 2 ? "Private" : aced?.highschooltype === 3 ? "Home school" : ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>Division</span>
                                                        <span>{aced?.division || ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            {/*Not in high school currently?*/}
                                                            Post High School
                                                        </span>
                                                        <span>{aced?.currentltnotinhighschool === 1 ? "Yes" : aced?.currentltnotinhighschool === 0 ? "No" : ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>Option</span>
                                                        <span>{aced?.currentLevelValue || ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>Start Date</span>
                                                        <span>{aced?.startdatemonth ? getNameFromCount(aced?.startdatemonth).concat(aced?.startdateyear ? ", " + aced?.startdateyear : "") : ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>End Date</span>
                                                        <span>{aced?.enddatemonth ? getNameFromCount(aced?.enddatemonth).concat(aced?.enddateyear ? ", " + aced?.enddateyear : "") : ""}</span>
                                                    </li>
                                                </ul> : <ul className="data-info-item">
                                                    <li>
                                                        <span>Zip Code</span>
                                                        <span>{aced?.zipcode && aced?.zipcode !== "null" ? aced?.zipcode : ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>High School Name</span>
                                                        <span>{getCamelCase(aced?.highschoolname) || ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>High School Type</span>
                                                        <span>{aced?.highschooltype === 1 ? "Public" : aced?.highschooltype === 2 ? "Private" : aced?.highschooltype === 3 ? "Home school" : ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>Division</span>
                                                        <span>{aced?.division || ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            {/*Not in high school currently?*/}
                                                        Post High School
                                                        </span>
                                                        <span>{aced?.currentltnotinhighschool === 1 ? "Yes" : aced?.currentltnotinhighschool === 0 ? "No" : ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>Option</span>
                                                        <span>{aced?.currentLevelValue || ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>Start Date</span>
                                                        <span>{aced?.startdatemonth ? getNameFromCount(aced?.startdatemonth).concat(aced?.startdateyear ? ", " + aced?.startdateyear : "") : ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>End Date</span>
                                                        <span>{aced?.enddatemonth ? getNameFromCount(aced?.enddatemonth).concat(aced?.enddateyear ? ", " + aced?.enddateyear : "") : ""}</span>
                                                    </li>
                                                </ul>
                                            )
                                        })
                                        :
                                        "No Record Found"
                                    }
                                </div>
                                <div className="box-item">
                                    <h4>COLLEGE INFORMATION <a className="edit-link" style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/academics')
                                            }, 1000)
                                        }}
                                    >{props.isPublicView ? "" : "Edit"}</a></h4>
                                    {profileAbout && profileAbout.academicData[0]?.collegeData.length > 0 ?
                                        profileAbout.academicData[0]?.collegeData.map((clg, indx) => {
                                            return (indx === profileAbout.academicData[0]?.collegeData.length - 1 ?
                                                <ul className="data-info-item">
                                                    <li>
                                                        <span>College Name</span>
                                                        <span>{getCamelCase(clg?.collegename) || ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>Division</span>
                                                        <span>{clg?.collegedivisionname || ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>Current Level</span>
                                                        <span>{clg?.collegelevelname || ""}</span>
                                                    </li>
                                                </ul> : <ul className="data-info-item">
                                                    <li>
                                                        <span>College Name</span>
                                                        <span>{getCamelCase(clg?.collegename) || ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>Division</span>
                                                        <span>{clg?.collegedivisionname || ""}</span>
                                                    </li>
                                                    <li>
                                                        <span>Current Level</span>
                                                        <span>{clg?.collegelevelname || ""}</span>
                                                    </li>
                                                </ul>
                                            )
                                        })
                                        :
                                        "No Record Found"
                                    }
                                </div>
                            </div>
                        </div>
                        <div id="GPAScores" className="common-item-box">
                            <h3 className="title">Grades & Scores</h3>
                            <div className="content-box">
                                <div className="box-item">
                                    <h4>ACADEMIC STATUS <a className="edit-link" style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/gradesScores')
                                            }, 1000)
                                        }}
                                    >{props.isPublicView ? "" : "Edit"}</a></h4>
                                    <ul className="data-info-item">
                                        {profileAbout?.gpaScoreData?.gpaDtails?.length > 0 ?
                                            profileAbout.gpaScoreData.gpaDtails.map((gpa, indx) => {
                                                return (indx === 0 ?
                                                    <>
                                                        <li>
                                                            <span>GPA range</span>
                                                            {props.isPublicView && publicProfileData?.length > 0 ?
                                                                publicProfileData?.map((itm, idx) => {
                                                                    if (itm?.publicprofilefieldid == 28 && itm?.active) return <span>{gpa?.gpaRange || ""}</span>
                                                                    else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <span><p
                                                                        style={{ fontWeight: "bold" }}>Permission not Granted</p></span>
                                                                }) || ""
                                                                :
                                                                <span>{gpa?.gpaRange || ""}</span>
                                                            }
                                                        </li>
                                                        <li>
                                                            <span>GPA (Unweighted)</span>
                                                            {props.isPublicView && publicProfileData?.length > 0 ?
                                                                publicProfileData?.map((itm, idx) => {
                                                                    if (itm?.publicprofilefieldid == 28 && itm?.active) return <span>{gpa?.unweightedgpa || ""}</span>
                                                                    else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <span><p
                                                                        style={{ fontWeight: "bold" }}>Permission not Granted</p></span>
                                                                }) || ""
                                                                :
                                                                <span>{gpa?.unweightedgpa || ""}</span>
                                                            }
                                                        </li>
                                                        <li>
                                                            <span>GPA (Weighted)</span>
                                                            {props.isPublicView && publicProfileData?.length > 0?
                                                                publicProfileData?.map((itm, idx) => {
                                                                    if (itm?.publicprofilefieldid == 28 && itm?.active) return <span>{gpa?.gpa || ""}</span>
                                                                    else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <span><p
                                                                        style={{ fontWeight: "bold" }}>Permission not Granted</p></span>
                                                                }) || ""
                                                                :
                                                                <span>{gpa?.gpa || ""}</span>
                                                            }
                                                        </li>
                                                        <li>
                                                            <span>Class Rank</span>
                                                            <span>{gpa?.classrank1 ? gpa?.classrank1 + (gpa?.outof ? ` out of ${gpa?.outof}` : "") : ""}</span>
                                                        </li>
                                                        <li>
                                                            <span>NCAA Eligibility Number</span>
                                                            <span>{profileAbout?.gpaScoreData?.gpanacci?.length > 0 ?
                                                                profileAbout?.gpaScoreData?.gpanacci?.[0]?.havencaaandnadianumber === 1 ? profileAbout?.gpaScoreData?.gpanacci[0]?.ncaaNumber : ""
                                                                : ""}</span>
                                                        </li>
                                                        <li>
                                                            <span>NAIA Eligibility Number</span>
                                                            <span>{profileAbout?.gpaScoreData?.gpanacci?.length > 0 ?
                                                                profileAbout?.gpaScoreData?.gpanacci?.[0]?.havencaaandnadianumber === 1 ? profileAbout?.gpaScoreData?.gpanacci?.[0]?.nadianumber : ""
                                                                : ""}</span>
                                                        </li>
                                                    </> : ""
                                                )
                                            })
                                            :
                                            "No Record Found"
                                        }
                                    </ul>
                                </div>
                                {props.isPublicView && publicProfileData?.length > 0 ? publicProfileData?.map((itm, idx) => {
                                    if (itm?.publicprofilefieldid == 28 && itm?.active) return <div className="box-item">
                                        <h4>ACADEMIC FILES <a className="edit-link" style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                window.scrollTo(0, 0)
                                                setTimeout(() => {
                                                    props.history.push('/gradesScores')
                                                }, 1000)
                                            }}
                                        >{props.isPublicView ? "" : "Edit"}</a></h4>
                                        <ul className="data-info-item fourColumn">
                                            {profileAbout && profileAbout.gpaScoreData.trenscriptFile.length > 0 ?
                                                profileAbout.gpaScoreData.trenscriptFile.map((tren, indx) => {
                                                    return (
                                                        <>
                                                            <li>
                                                                <span className="black-text">{tren?.fileName || ""}</span>
                                                            </li>
                                                            <li>
                                                                <span className="black-text">{tren?.type || ""}</span>
                                                            </li>
                                                            <li>
                                                                <span className="black-text">{moment(tren?.createdOn).format("MMM DD, YYYY") || ""}</span>
                                                            </li>
                                                            <li>
                                                                <span className="link-text" onClick={()=>window.open(`${ENV.DOWNLOAD_URL}/${tren?.fileName}`, '_blank')}
                                                                >View file</span>
                                                            </li>
                                                        </>
                                                        // <li>
                                                        //     <span className="black-text">{tren?.type || ""}</span>
                                                        // </li>
                                                    )
                                                })
                                                :
                                                "No Record Found"
                                            }
                                        </ul>
                                    </div>
                                    else if (itm?.publicprofilefieldid == 28 && itm?.active == 0) return <div className="box-item">
                                        <h4>ACADEMIC FILES <a className="edit-link" style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                window.scrollTo(0, 0)
                                                setTimeout(() => {
                                                    props.history.push('/gradesScores')
                                                }, 1000)
                                            }}
                                        >Edit</a></h4>
                                        <ul className="data-info-item fourColumn">
                                            {profileAbout && profileAbout.gpaScoreData.trenscriptFile.length > 0 ?
                                                profileAbout.gpaScoreData.trenscriptFile.map((tren, indx) => {
                                                    return (
                                                        <>
                                                            <li>
                                                                <span className="black-text">{tren?.fileName || ""}</span>
                                                            </li>
                                                            <li>
                                                                <span className="black-text">{tren?.type || ""}</span>
                                                            </li>
                                                            <li>
                                                                <span className="black-text">{moment(tren?.createdOn).format("MMM DD, YYYY") || ""}</span>
                                                            </li>
                                                            <li>
                                                                <span className="link-text" onClick={()=>window.open(`${ENV.DOWNLOAD_URL}/${tren?.fileName}`, '_blank')}
                                                                >View file</span>
                                                            </li>
                                                        </>
                                                        // <li>
                                                        //     <span className="black-text">{tren?.type || ""}</span>
                                                        // </li>
                                                    )
                                                })
                                                :
                                                "No Record Found"
                                            }
                                        </ul>
                                    </div>
                                }) || ""
                                    :
                                    <div className="box-item">
                                        <h4>ACADEMIC FILES <a className="edit-link" style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                window.scrollTo(0, 0)
                                                setTimeout(() => {
                                                    props.history.push('/gradesScores')
                                                }, 1000)
                                            }}
                                        >Edit</a></h4>
                                        <ul className="data-info-item fourColumn">
                                            {profileAbout && profileAbout.gpaScoreData.trenscriptFile.length > 0 ?
                                                profileAbout.gpaScoreData.trenscriptFile.map((tren, indx) => {
                                                    return (
                                                        <>
                                                            <li>
                                                                <span className="black-text">{tren?.fileName || ""}</span>
                                                            </li>
                                                            <li>
                                                                <span className="black-text">{tren?.type || ""}</span>
                                                            </li>
                                                            <li>
                                                                <span className="black-text">{moment(tren?.createdOn).format("MMM DD, YYYY") || ""}</span>
                                                            </li>
                                                            <li>
                                                                <span className="link-text" onClick={()=>window.open(`${ENV.DOWNLOAD_URL}/${tren?.fileName}`, '_blank')}
                                                                >View file</span>
                                                            </li>
                                                        </>
                                                        // <li>
                                                        //     <span className="black-text">{tren?.type || ""}</span>
                                                        // </li>
                                                    )
                                                })
                                                :
                                                "No Record Found"
                                            }
                                        </ul>
                                    </div>
                                }
                                <div className="box-item">
                                    {props.isPublicView && publicProfileData?.length > 0 ?
                                        publicProfileData?.map((itm, idx) => {
                                            if (itm?.publicprofilefieldid == 30 && itm?.active) return <h4>TEST SCORES <a className="edit-link"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    window.scrollTo(0, 0)
                                                    setTimeout(() => {
                                                        props.history.push('/gradesScores')
                                                    }, 1000)
                                                }}
                                            >
                                            </a></h4>
                                            else if (itm?.publicprofilefieldid == 30 && itm?.active == 0) return <span>
                                                <h4>TEST SCORES</h4>
                                                <p style={{ fontWeight: "bold" }}></p>
                                            </span>

                                        }) || ""
                                        :
                                        <h4>TEST SCORES <a className="edit-link"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                window.scrollTo(0, 0)
                                                setTimeout(() => {
                                                    props.history.push('/gradesScores')
                                                }, 1000)
                                            }}
                                        >Edit</a></h4>
                                    }
                                    <ul className="scores-listing">
                                        {props.isPublicView && publicProfileData?.length > 0 ?
                                            publicProfileData?.map((itm, idx) => {
                                                if (itm?.publicprofilefieldid == 30 && itm?.active) {
                                                    return (profileAbout && profileAbout.gpaScoreData.testScore.length > 0 ?
                                                        profileAbout.gpaScoreData.testScore.map((test, idx) => {
                                                            return (
                                                                <>
                                                                    <li>
                                                                        <h5>SAT</h5>
                                                                        <div className="circle-box">
                                                                            <CircularProgressbar className="progressChart" value={test?.sattotalnumber ? Math.round((test?.sattotalnumber / 1600) * 100) : 0} text={`${test?.sattotalnumber || ""} / 1600`} />
                                                                        </div>
                                                                        <div className="botom-detail">
                                                                            <div className="item-box">
                                                                                <h6>{test?.mathValue || ""}<span> / 800
                                                                                </span></h6>
                                                                                <p>Math</p>
                                                                            </div>
                                                                            <div className="item-box">
                                                                                <h6>{test?.readingandwritingValue || ""}
                                                                                    <span> / 800
                                                                                    </span></h6>
                                                                                <p>Reading & Writing</p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <h5>PSAT</h5>
                                                                        <div className="circle-box">
                                                                            <CircularProgressbar className="progressChart" value={test?.psatotalnumer ? Math.round((test?.psatotalnumer / 1520) * 100) : 0} text={`${test?.psatotalnumer || ""} / 1520`} />
                                                                        </div>
                                                                        <div className="botom-detail">
                                                                            <div className="item-box">
                                                                                <h6>{test?.psatmath || ""}
                                                                                    <span> / 760
                                                                                    </span></h6>
                                                                                <p>Math</p>
                                                                            </div>
                                                                            <div className="item-box">
                                                                                <h6>{test?.psatreadingandwritingvalue || ""}
                                                                                    <span> / 760
                                                                                    </span></h6>
                                                                                <p>Reading & Writing</p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <h5>ACT</h5>
                                                                        <div className="circle-box" style={{ height: "175px" }}>
                                                                            <div className="item-box">
                                                                                <p>ACT Composite Score</p>
                                                                                <h6>{test?.actcompletescoreid || ""}<span></span></h6>
                                                                            </div>
                                                                            <div className="item-box">
                                                                                <p>Notes</p>
                                                                                <h6>{test?.actNotes || ""}<span></span></h6>
                                                                            </div>
                                                                            <div className="item-box">
                                                                                <p>Test Date</p>
                                                                                <h6>{test?.acttestdate ? getUTCTimeDateOverall(test?.acttestdate) : ""}<span></span></h6>
                                                                            </div>
                                                                        </div>
                                                                        <div className="botom-detail">
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            )
                                                        })
                                                        :
                                                        "No Record Found"
                                                    )
                                                }
                                                else if (itm?.publicprofilefieldid == 30 && itm?.active == 0) {
                                                    return (profileAbout && profileAbout.gpaScoreData.testScore.length > 0 ?
                                                        profileAbout.gpaScoreData.testScore.map((test, idx) => {
                                                            return (
                                                                <>
                                                                    <li>
                                                                        <h5>SAT</h5>
                                                                        <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
                                                                    </li>
                                                                    <li>
                                                                        <h5>PSAT</h5>
                                                                        <div className="circle-box">
                                                                            <CircularProgressbar className="progressChart" value={test?.psatotalnumer ? Math.round((test?.psatotalnumer / 1520) * 100) : 0} text={`${test?.psatotalnumer || ""} / 1520`} />
                                                                        </div>
                                                                        <div className="botom-detail">
                                                                            <div className="item-box">
                                                                                <h6>{test?.psatmath || ""}
                                                                                    <span> / 760
                                                                                    </span></h6>
                                                                                <p>Math</p>
                                                                            </div>
                                                                            <div className="item-box">
                                                                                <h6>{test?.psatreadingandwritingvalue || ""}
                                                                                    <span> / 760
                                                                                    </span></h6>
                                                                                <p>Reading & Writing</p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <h5>ACT</h5>
                                                                        <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
                                                                    </li>
                                                                </>
                                                            )
                                                        })
                                                        :
                                                        "No Record Found"
                                                    )
                                                }
                                            }) || ""
                                            :
                                            (profileAbout && profileAbout.gpaScoreData.testScore.length > 0 ?
                                                profileAbout.gpaScoreData.testScore.map((test, idx) => {
                                                    return (
                                                        <>
                                                            <li>
                                                                <h5>SAT</h5>
                                                                <div className="circle-box">
                                                                    <CircularProgressbar className="progressChart" value={test?.sattotalnumber ? Math.round((test?.sattotalnumber / 1600) * 100) : 0} text={`${test?.sattotalnumber || ""} / 1600`} />
                                                                </div>
                                                                <div className="botom-detail">
                                                                    <div className="item-box">
                                                                        <h6>{test?.mathValue || ""}<span> / 800
                                                                        </span></h6>
                                                                        <p>Math</p>
                                                                    </div>
                                                                    <div className="item-box">
                                                                        <h6>{test?.readingandwritingValue || ""}
                                                                            <span> / 800
                                                                            </span></h6>
                                                                        <p>Reading & Writing</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <h5>PSAT</h5>
                                                                <div className="circle-box">
                                                                    <CircularProgressbar className="progressChart" value={test?.psatotalnumer ? Math.round((test?.psatotalnumer / 1520) * 100) : 0} text={`${test?.psatotalnumer || ""} / 1520`} />
                                                                </div>
                                                                <div className="botom-detail">
                                                                    <div className="item-box">
                                                                        <h6>{test?.psatmath || ""}
                                                                            <span> / 760
                                                                            </span></h6>
                                                                        <p>Math</p>
                                                                    </div>
                                                                    <div className="item-box">
                                                                        <h6>{test?.psatreadingandwritingvalue || ""}
                                                                            <span> / 760
                                                                            </span></h6>
                                                                        <p>Reading & Writing</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <h5>ACT</h5>
                                                                <div className="circle-box" style={{ height: "175px" }}>
                                                                    <div className="item-box">
                                                                        <p>ACT Composite Score</p>
                                                                        <h6>{test?.actcompletescoreid || ""}<span></span></h6>
                                                                    </div>
                                                                    <div className="item-box">
                                                                        <p>Notes</p>
                                                                        <h6>{test?.actNotes || ""}<span></span></h6>
                                                                    </div>
                                                                    <div className="item-box">
                                                                        <p>Test Date</p>
                                                                        <h6>{test?.acttestdate ? getUTCTimeDateOverall(test?.acttestdate) : ""}<span></span></h6>
                                                                    </div>
                                                                </div>
                                                                <div className="botom-detail">
                                                                </div>
                                                            </li>
                                                        </>
                                                    )
                                                })
                                                :
                                                "No Record Found"
                                            )
                                        }
                                    </ul>
                                </div>
                                <div className="box-item">
                                    <h4>HONORS CLASSES <a className="edit-link"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/gradesScores')
                                            }, 1000)
                                        }}
                                    >{props.isPublicView ? "" : "Edit"}</a></h4>
                                    <ul className="data-info-item">
                                        {profileAbout && profileAbout.gpaScoreData.honorsAndAcadmicAccomplishment.length > 0 ?
                                            profileAbout.gpaScoreData.honorsAndAcadmicAccomplishment.map((hor, idx) => {
                                                return (
                                                <>
                                                    <li>
                                                        <span>Honors Classes</span>
                                                        <span>{hor?.havehonors == 1 ? "Yes" : hor?.havehonors == 2 ? "No" : "" || ""}</span>
                                                    </li>
                                                <li>
                                                    <span>Taking Honors classes currently</span>
                                                    <span>{hor?.honorclassestimeperiod || ""}</span>
                                                </li>
                                                <li>
                                                    <span>Honors Class Details</span>
                                                    <span>{hor?.havehonors == 1 ? hor?.classdetails ?? "" : ""}</span>
                                                </li>
                                                </>
                                                )
                                            })
                                            :
                                            "No Record Found"
                                        }
                                    </ul>
                                </div>
                                <div className="box-item">
                                    <h4>AP / IB CLASSES <a className="edit-link"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/gradesScores')
                                            }, 1000)
                                        }}
                                    >{props.isPublicView ? "" : "Edit"}</a></h4>
                                    <ul className="data-info-item">
                                        {profileAbout && profileAbout.gpaScoreData.ap_IP_Class.length > 0 ?
                                            profileAbout.gpaScoreData.ap_IP_Class.map((ip, idx) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <span>AP / IB Classes</span>
                                                            <span>
                                                                {ip?.areyoutakeapbpclass == 1 ? "Yes" : ip?.areyoutakeapbpclass == 2 ? "No" : "" || ""}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span>Taking AP / IB classes currently</span>
                                                            <span>{ip?.timeperiodforcources || ""}</span>
                                                        </li>
                                                        <li>
                                                            <span>AP / IB Class Details</span>
                                                            <span>{ip?.areyoutakeapbpclass == 1 && ip?.classdetails ? ip?.classdetails : ""}</span>
                                                        </li>
                                                    </>
                                                )
                                            })
                                            :
                                            "No Record Found"
                                        }
                                    </ul>
                                </div>
                                <div className="box-item">
                                    <h4>ACADEMIC ACCOMPLISHMENTS <a className="edit-link"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/gradesScores')
                                            }, 1000)
                                        }}
                                    >{props.isPublicView ? "" : "Edit"}</a></h4>
                                    <ul className="data-info-item">
                                        {profileAbout && profileAbout.gpaScoreData.honorsAndAcadmicAccomplishment.length > 0 ?
                                            profileAbout.gpaScoreData.honorsAndAcadmicAccomplishment.map((hor, idx) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <span>Academic Honors</span>
                                                            <span>{hor?.acadhonors || ""}</span>
                                                        </li>
                                                        <li>
                                                            <span>Extracurricular Activities</span>
                                                            <span>{hor?.extraactivity || ""}</span>
                                                        </li>
                                                        <li>
                                                            <span>Intended Major / Area of Study</span>
                                                            {props.isPublicView && publicProfileData?.length > 0 ?
                                                                publicProfileData?.map((itm, idx) => {
                                                                    if (itm?.publicprofilefieldid == 31 && itm?.active) return <span>{hor?.areaOfStudyName || ""}</span>
                                                                    else if (itm?.publicprofilefieldid == 31 && itm?.active == 0) return <span><p style={{ fontWeight: "bold" }}>Permission Not Granted</p></span>
                                                                }) || ""
                                                                :
                                                                <span>{hor?.areaOfStudyName || ""}</span>
                                                            }
                                                        </li>
                                                    </>
                                                )
                                            })
                                            :
                                            "No Record Found"
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {!props.isPublicView &&
                        <div id="CollegeInsights" className="common-item-box">
                            <h3 className="title">College Insights</h3>
                            <div className="content-box">
                                <div className="box-item">
                                    <h4>FAMILY BACKGROUD <a className="edit-link"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/collegeInsights')
                                            }, 1000)
                                        }}
                                    >{props.isPublicView ? "" : "Edit"}</a></h4>
                                    <ul className="data-info-item">
                                        {profileAbout && profileAbout.collegeInsightData.length > 0 ?
                                            profileAbout.collegeInsightData.map((clgIn, idx) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <span>Older siblings gone to college</span>
                                                            <span>{clgIn?.haveanysiblingswhogonetocollege == 1 ? "Yes" : clgIn?.haveanysiblingswhogonetocollege == 0 ? "No" : ""}</span>
                                                        </li>
                                                        <li>
                                                            <span>Influential college decision</span>
                                                            {relationshipData?.length > 0 ?
                                                                <>
                                                                    {relationshipData?.map((clgInMap, idx) => {
                                                                        if (clgInMap?.id == clgIn?.influentialdecision) {
                                                                            return clgInMap?.relationShipName || ""
                                                                        }
                                                                    })}
                                                                </>
                                                                : ""}
                                                        </li>
                                                        <li>
                                                            <span>Live with</span>
                                                            <span>{clgIn?.wholiveswithyou || ""}</span>
                                                        </li>
                                                        <li>
                                                            <span>Are Your parents divorced?</span>
                                                            <span>{clgIn?.isparentdivorced == 1 ? "Yes" : clgIn?.isparentdivorced == 0 ? "No" : ""}</span>
                                                        </li>
                                                    </>
                                                )
                                            })
                                            :
                                            "No Record Found"
                                        }
                                    </ul>
                                </div>
                                <div className="box-item">
                                    <h4>COLLEGE FINANCIAL AID ASSESSMENT <a className="edit-link"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/collegeInsights')
                                            }, 1000)
                                        }}
                                    >{props.isPublicView ? "" : "Edit"}</a></h4>
                                    <ul className="data-info-item">
                                        {profileAbout && profileAbout.collegeInsightData.length > 0 ?
                                            profileAbout.collegeInsightData.map((clgIn, idx) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <span>Ethnic heritage</span>
                                                            {clgIn?.ethnicValue || ""}
                                                        </li>
                                                        <li>
                                                            <span>Annual household income</span>
                                                            {clgIn?.incomeRange || ""}
                                                        </li>
                                                        <li>
                                                            <span>EFC family contribution</span>
                                                            <span>{clgIn?.efc || ""}</span>
                                                        </li>
                                                        <li>
                                                            <span>Family qualify for The National School Lunch Program or other need-based assistance</span>
                                                            <span>{clgIn?.isyourfamilyqualifyanynationalprogram == 1 ? "Yes" : clgIn?.isyourfamilyqualifyanynationalprogram == 0 ? "No" : ""}</span>
                                                        </li>
                                                    </>
                                                )
                                            })
                                            :
                                            "No Record Found"}
                                    </ul>
                                </div>
                                <div className="box-item">
                                    <h4>FAMILY RESIDENCE <a className="edit-link"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/collegeInsights')
                                            }, 1000)
                                        }}
                                    >{props.isPublicView ? "" : "Edit"}</a></h4>
                                    <ul className="data-info-item">
                                        {
                                            (profileAbout && profileAbout.collegeInsightData.length > 0 ?
                                                profileAbout.collegeInsightData.map((clgIn, idx) => {
                                                    return (
                                                        <>
                                                            <li>
                                                                <span>Home Address</span>
                                                                <span>
                                                                    {`${clgIn?.address ? clgIn?.address + ", " : ""}
                                                        ${clgIn?.addresscountinue ? clgIn?.addresscountinue + ", " : ""}
                                                        ${clgIn?.city ? clgIn?.city + ", " : ""}
                                                        ${clgIn?.state ? clgIn?.state + ", " : ""}
                                                        ${clgIn?.countryName ? clgIn?.countryName + ", " : ""}${clgIn?.zipcode ? clgIn?.zipcode : ""}`}
                                                                </span>
                                                            </li>
                                                        </>)
                                                })
                                                :
                                                "No Record Found")
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>}
                        <div id="Offers" className="common-item-box">
                            <h3 className="title">Offers </h3>
                            <div className="content-box">
                                <div className="edit-link-box">
                                    <a className="edit-link" style={{ cursor: "pointer", marginLeft: "auto" }}
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            props.history.push('/Offers')
                                        }}>
                                        {props.isPublicView ? "" : "Edit"}
                                    </a>
                                </div>
                                <div className="box-item">
                                    <ul className="data-info-item team-list">
                                        {profileAbout && profileAbout?.offerData.length > 0 ? allOfferPrivate ? "No Record Found" :
                                            profileAbout && profileAbout?.offerData.map((off, idx) => {
                                                if((props.isPublicView && off.managevisible === 1) || (!props.isPublicView)) {
                                                    return (
                                                        <>
                                                            <li>
                                                                <div className="img-box">
                                                                    {
                                                                        off?.collegelogo ? <img src={`${ENV.DOWNLOAD_URL}/${off?.collegelogo ?? ""}`} alt="" /> : <img src={`${TeamLogo}`} alt="" />
                                                                    }
                                                                </div>
                                                                <div className="detail-box">
                                                                    <h5>{off?.collegename || ""}
                                                                    </h5>
                                                                    <p><strong>Offer: </strong>{off?.offeresvalue || ''}<br /><strong>Interest
                                                                        Level:</strong> {off?.interestlevelValue || ''}<br /><strong>Offer Date:</strong> {off?.offerdate ? getUTCTimeDateOverall(off?.offerdate) : ''}</p>
                                                                </div>
                                                            </li>
                                                        </>
                                                    )
                                                }                                                
                                            })
                                            :
                                            "No Record Found"
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="Commitment" className="common-item-box">
                            <h3 className="title">Commitment</h3>
                            <div className="content-box">
                                <div className="edit-link-box">
                                    <a className="edit-link" style={{ cursor: "pointer", marginLeft: "auto" }}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            setTimeout(() => {
                                                props.history.push('/edit')
                                            }, 1000)
                                        }}
                                    >
                                        {props.isPublicView ? "" : "Edit"}
                                    </a>
                                </div>
                                <div className="box-item">
                                    <ul className="data-info-item team-list">
                                        {profileAbout && profileAbout.collegeCommitmentData.length > 0 ?
                                            profileAbout.collegeCommitmentData.map((com, idx) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <div className="img-box">
                                                                {
                                                                    com?.collegelogo ? <img src={`${ENV.DOWNLOAD_URL}/${com?.collegelogo ?? ""}`} alt="" /> : <img src={`${TeamLogo}`} alt="" />
                                                                }
                                                            </div>
                                                            <div className="detail-box">
                                                                <h5>{getCamelCase(com?.collegename) || ""}
                                                                </h5>
                                                                <p>{com?.commitmentStatusValue || ""}<br />{getUTCTimeDateOverall(com?.commitmentDate) || ""}</p>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                            })
                                            :
                                            "No Record Found"
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <GoTopButton goTop={() => { window.scrollTo(0, 0) }} />
                    </div>
                </div>
            </div>
        </div>
            <VideoPop
                setIsTestSingle={()=>setIsVidPop(false)}
                isTestSingle={isVidPop}
                vidID={vidID}
                isToken={ isToken }
                isLink={ isLink }
            />
        </>
    );
})