export const statsStatusJSON =
[
    {
        id: 1,
        name: "Not verified"
    },
    {
        id: 2,
        name: "Requested"
    },
    {
        id: 3,
        name: "In progress"
    },
    {
        id: 4,
        name: "Coach verified"
    },
    {
        id: 5,
        name: "SF/3rd party verified"
    },
    {
        id: 5,
        name: "Coach and SF/3rd party verified"
    },
]

export const ProfileStatus =
    [
        {
            id: 1,
            name: "Active"
        },
        {
            id: 0,
            name: "In Active"
        },
    ]

    export const athleteStatsStatusJSON =
    [
        {
            id: 1,
            name: "Not verified"
        },
        {
            id: 2,
            name: "Requested"
        },
        {
            id: 3,
            name: "In progress"
        },
        {
            id: 4,
            name: "Coach verified"
        },
        {
            id: 5,
            name: "SF/3rd party verified"
        },
        {
            id: 5,
            name: "Coach and SF/3rd party verified"
        },
        {
            id:6,
            name: "No stats"
        }
    ]

    export const InviteStatusJSON =
    [
        {
            id: 0,
            name: "Invite"
        },
        {
            id: 1,
            name: "Resend invite"
        },
        {
            id: 2,
            name: "Accepted"
        },
        {
            id: 3,
            name: "Onboarded"
        },
    ]
export const AdvisorStatusJSON =
    [
        {
            id: 0,
            name: "Invite"
        },
        {
            id: 1,
            name: "Invitation Sent"
        },
        {
            id: 2,
            name: "Logged-In"
        },
    ]

