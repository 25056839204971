import React, { useEffect, useState, useRef } from "react";
import ENV from "../../../config";
// import {createBrowserHistory } from "history";
import "../../../assets/css/stylesheet.css";
import "../assets/css/athleteProfileFinalDark.scss";
import { postDateFormat } from "../../../utils/dateTimeFormatter";
import services from "../../../services";
import swal from "sweetalert";
import "../assets/css/editProfile.scss";
import "../assets/css/athleteProfileFinalDark.scss";
import {VideoPop} from "../../common/vidPopUp";
import thumbnail from '../../../assets/img/video-to-link-thumbnail.jpg'
import {getThumbnail, typeConvert} from '../../common/getThumbnail'
import SharePost from './SharePost'
import Dialog from "@material-ui/core/Dialog";
import {vidCountIncre} from "../../common/flameAPI";
import {getUserInfo} from '../../../utils/constant'
import history from '../../../history'
import {useLocation} from "react-router-dom";
import {profileURLData} from "../../common/getProfileURLData";
export function ProfileVideoPhoto(props) {
  const [photoVideoList, setPhotoVideoList] = useState(null);
  const [photoList, setPhotoList] = useState(null);
  const [videoList, setVideoList] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [selectedTab, setSelectedTab] = useState("all");
  const pubViewID = JSON.parse(localStorage.getItem('pubViewID'));
  const fakeInput = useRef(null);
  const location = useLocation()
  const Athid = profileURLData(location?.pathname, 'athlete_id')
      // +props?.props?.match?.params?.id;
  let isToken = profileURLData(location?.pathname, 'token')
      // props?.props?.match?.params?.isToken == 1 ? 1 : 0;
  const [vidID, setVidID] = React.useState(null)
  const [isVidPop, setIsVidPop] = React.useState(false)
  const [isLink, setIsLink] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState(null);
  useEffect(async () => {
   const res = await services.location.getVideoPhotoData({ athleteId: Athid, isAccessToken: isToken});
   if(res && res.status === 200 && res.data.response.status == 200){
    let featuredList = [];
      let unfeaturedList = [];
      let videoFeaturedList = [];
      let photoFeaturedList = [];
      let videoUnfeaturedList = [];
      let photoUnfeaturedList = [];
      
      for (const data of res?.data?.response?.data || []) {
        if (data.isfeatured) {
          featuredList = [...featuredList, { ...data }];
        } else {
          unfeaturedList = [...unfeaturedList, { ...data }];
        }
      }
      for (const data of featuredList) {
        if (data.isphotosorvideosLik === 1 || data.isphotosorvideosLik === 2) {
          videoFeaturedList = [...videoFeaturedList, { ...data }];
        } else {
          photoFeaturedList = [...photoFeaturedList, { ...data }];
        }
      }

      let rankedItem = [];
      let nonRankedItem = [];
      for (const data of unfeaturedList) {
        if (data.uploadedrank) {
          rankedItem = [...rankedItem, { ...data }];
        } else {
          nonRankedItem = [...nonRankedItem, { ...data }];
        }
      }
      rankedItem = rankedItem.sort(
        (a, b) => a.uploadedrank - b.uploadedrank
      );

      unfeaturedList = [...rankedItem, ...nonRankedItem];
      let programPricingVideo = [];
      let programPricingPhoto = [];

      for (const data of unfeaturedList) {
        if (data.isphotosorvideosLik === 1 || data.isphotosorvideosLik === 2) {
          if (data.videophotosuploadedtype === "ProgramAndPricing") {
            programPricingVideo = [...programPricingVideo, { ...data }];
          } else {
            videoUnfeaturedList = [...videoUnfeaturedList, { ...data }];
          }
        } else {
          if (data.videophotosuploadedtype === "ProgramAndPricing") {
            programPricingPhoto = [...programPricingPhoto, { ...data }];
          } else {
            photoUnfeaturedList = [...photoUnfeaturedList, { ...data }];
          }
        }
      }
      const videoPhotoList = [
        ...videoFeaturedList,
        ...photoFeaturedList,
        ...videoUnfeaturedList,
        ...programPricingVideo,
        ...photoUnfeaturedList,
        ...programPricingPhoto,
      ];
      setPhotoVideoList(videoPhotoList);
      const tempData = [...videoPhotoList];

      const videoListT = tempData.filter(
        (item) => item.isphotosorvideosLik !== 0
      );
      const photoListT = tempData.filter(
        (item) => item.isphotosorvideosLik === 0
      );
      setVideoList(videoListT);
      setPhotoList(photoListT);
      setFilteredData(tempData);
   }
    fakeInput?.current?.focus();
    fakeInput?.current?.select();
  }, [pubViewID]);

  const openVideoOnNewTab = (url, linType, data) => {
    if (url) {
      // window.open(url, "_blank");
      setVidID(url)
      setIsLink(linType)
      setTimeout(()=>{
        setIsVidPop(true)
      },500)
        vidCountIncre(data, getUserInfo()?.role == "ADMIN" ? true : false)
    }
  };

  const onTabsChange = (e, tab) => {
    if (tab === "all") {
      setFilteredData([...photoVideoList]);
    }
    if (tab === "video") {
      setFilteredData([...videoList]);
    }
    if (tab === "photo") {
      setFilteredData([...photoList]);
    }
    setSelectedTab(tab);
  };
  const handleModal = (value) => {
    setModalData(value);
    setShowModal(!showModal);
  };
  return (
      <>
    <div className="TimelineData video-tab-data">
      <input className="fakeInput" type="text" ref={fakeInput} />
      <div className="item">
        <div className="video-and-photo-wrap">
          <div className="video-and-photo-main">
            <div className="sidebarbox">
              <ul className="menu-links">
                <li
                  className={selectedTab === "all" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <a href={void 0} onClick={(e) => onTabsChange(e, "all")}>
                    All
                  </a>
                </li>
                <li
                  className={selectedTab === "video" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <a href={void 0} className="videoTag" onClick={(e) => onTabsChange(e, "video")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26.334"
                      height="16.819"
                      viewBox="0 0 26.334 16.819"
                    >
                      <g transform="translate(8851.167 20311.82)">
                        <path
                          className="a"
                          d="M24.067,9.9a2.528,2.528,0,0,0-2.633-2.4H5.633A2.528,2.528,0,0,0,3,9.9V21.916a2.528,2.528,0,0,0,2.633,2.4h15.8a2.528,2.528,0,0,0,2.633-2.4v-4l5.267,4V9.9l-5.267,4ZM22.314,22.717H4.756V9.1H22.312l0,6.806,0,0,0,0,0,6.806Z"
                          transform="translate(-8854.167 -20319.32)"
                        />
                        <path
                          className="a"
                          d="M16.044,11.338a.322.322,0,0,0-.168-.282L8.793,6.967a.322.322,0,0,0-.506.264.361.361,0,0,0,0,.036h0V15.48h0a.319.319,0,0,0,.479.242l0,.005,7.112-4.106,0-.005a.321.321,0,0,0,.162-.278Z"
                          transform="translate(-8852.776 -20314.748)"
                        />
                      </g>
                    </svg>
                    <span>({videoList ? videoList.length : 0})</span>
                  </a>
                </li>
                <li
                  className={selectedTab === "photo" ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <a href={void 0} className="videoTag" onClick={(e) => onTabsChange(e, "photo")}>
                  <svg xmlns="http://www.w3.org/2000/svg" id="image" width="24" height="24" viewBox="0 0 24 24">
                <g id="Group_1127" data-name="Group 1127">
                  <g id="Group_1126" data-name="Group 1126">
                    <path id="Path_2126" data-name="Path 2126" d="M21.75,0H2.25A2.25,2.25,0,0,0,0,2.25v19.5A2.25,2.25,0,0,0,2.25,24h19.5A2.25,2.25,0,0,0,24,21.75V2.25A2.25,2.25,0,0,0,21.75,0Zm.75,14.692-3.217-3.217a.75.75,0,0,0-1.058,0L14.25,15.442,9.532,10.725a.75.75,0,0,0-1.057,0L1.5,17.692V2.25a.75.75,0,0,1,.75-.75h19.5a.75.75,0,0,1,.75.75Z" fill="#3e3e3e" />
                    <path id="Path_2127" data-name="Path 2127" d="M227.093,80a3.093,3.093,0,1,0,3.093,3.093A3.093,3.093,0,0,0,227.093,80Zm0,4.64a1.547,1.547,0,1,1,1.547-1.547A1.547,1.547,0,0,1,227.093,84.64Z" transform="translate(-213.609 -76.289)" fill="#3e3e3e" />
                  </g>
                </g>
              </svg>
                    <span>({photoList ? photoList.length : 0})</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="content-box">
              <div className="video-second-block">
                <div className="video-tab-contents pt-0">
                  <div className="edit-videolink-box">
                    <a
                      href={void 0}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/videoPhotos");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {window.location.href.includes('/profile/1') ? "Edit" : ""}
                    </a>
                  </div>
                  <ul className="video-grid-list clearfix">
                    {filteredData?.length > 0
                      ? filteredData.map((item, index) => {
                        if (item.isphotosorvideosLik === 0) {
                          return (
                            <li className="video-box" key={index}>
                              <div className="img-box">
                                <img
                                  src={`${ENV.DOWNLOAD_URL + "/" + item?.photosname
                                    }`}
                                  alt="profile Bg"
                                />
                                <ul className="info-icon">
                                 {item?.isfeatured ? <li
                                    className={`button-favorite ${item?.isfeatured && "active"
                                      }`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15.057"
                                      height="14.43"
                                      viewBox="0 0 15.057 14.43"
                                    >
                                      <defs></defs>
                                      <path
                                        className="a"
                                        d="M15.018,5.948a.8.8,0,0,0-.688-.55L9.983,5,8.265.982a.8.8,0,0,0-1.473,0L5.075,5,.727,5.4A.8.8,0,0,0,.272,6.8L3.558,9.68l-.969,4.267a.8.8,0,0,0,1.191.865l3.748-2.241,3.747,2.241a.8.8,0,0,0,1.192-.865L11.5,9.68,14.784,6.8a.8.8,0,0,0,.233-.851ZM7.6,12.528"
                                        transform="translate(0 -0.496)"
                                      />
                                    </svg>
                                  </li> : ""}
                                </ul>
                              </div>
                              <div className="video-detail-box" style={{minHeight: "105px"}}>
                                <div className="title-box">
                                  <div className="title half" style={{width: "100%"}}>
                                    {item?.originalname
                                      ? item.originalname
                                      : ""}
                                  </div>
                                </div>
                                <div className="video-info d-block">
                                  <div className="half">
                                    <span>
                                      Photo Type :{" "}
                                      {item?.videophotosuploadedtype
                                        ? item.videophotosuploadedtype
                                        : ""}
                                    </span>
                                    {!item?.actualProfilePhoto ? (
                                      <span>
                                        {/*below is commented as per task aug no 36*/}
                                        {/*{`${item?.totalViewCount ? item?.totalViewCount : 0} Views - `}{" "}*/}
                                        {postDateFormat(item?.createdOn)}
                                      </span>
                                    ) : null}
                                    <span className="shareLink">
                                      <button className={"share-btn"} onClick={()=>handleModal(item)}>
                                        Share
                                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16.804" height="14" viewBox="0 0 16.804 14"><defs><style></style></defs><path class="a" d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z" transform="translate(-4.502 -6.749)"></path></svg>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        } else {
                          return (
                            <li className="video-box" key={index}>
                              <div className="img-box">
                                {item ? (
                                  item.isphotosorvideosLik === 1 ? (
                                      <>
                                    <video>
                                      <source
                                        src={`${ENV.DOWNLOAD_URL +
                                        "/" +
                                        typeConvert(item?.videoname, 'Timeline_Photo')}`+"#t=0.2"}
                                        alt="video"
                                      />
                                    </video>
                                        <div
                                            className="video-play-button"
                                            onClick={() =>
                                                openVideoOnNewTab(`${ENV.DOWNLOAD_URL +
                                                "/" +
                                                item?.videoname
                                                }`, false, item)
                                            }
                                        >
                                          <div className="video-play-inner">
                                            <button>
                                              <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="10.802"
                                                  height="16.203"
                                                  viewBox="0 0 10.802 16.203"
                                              >
                                                <defs></defs>
                                                <path
                                                    className="a"
                                                    d="M8.1,0l8.1,10.8H0Z"
                                                    transform="translate(10.802) rotate(90)"
                                                />
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                    </>
                                  ) : (
                                    <>
                                      <video
                                          poster={getThumbnail(item?.videolink, item, "ProfileVP")}
                                          // controls
                                      >
                                        <source
                                          src={item?.videolink}
                                          alt="video/youtube"
                                        />
                                      </video>
                                      <div
                                        className="video-play-button"
                                        onClick={() =>
                                          openVideoOnNewTab(item?.videolink, true, item)
                                        }
                                      >
                                        <div className="video-play-inner">
                                          <button>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="10.802"
                                              height="16.203"
                                              viewBox="0 0 10.802 16.203"
                                            >
                                              <defs></defs>
                                              <path
                                                className="a"
                                                d="M8.1,0l8.1,10.8H0Z"
                                                transform="translate(10.802) rotate(90)"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )
                                ) : null}

                                <ul className="info-icon">
                                  {item?.isfeatured ? <li
                                    className={`button-favorite ${item?.isfeatured && "active"
                                      }`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15.057"
                                      height="14.43"
                                      viewBox="0 0 15.057 14.43"
                                    >
                                      <defs></defs>
                                      <path
                                        className="a"
                                        d="M15.018,5.948a.8.8,0,0,0-.688-.55L9.983,5,8.265.982a.8.8,0,0,0-1.473,0L5.075,5,.727,5.4A.8.8,0,0,0,.272,6.8L3.558,9.68l-.969,4.267a.8.8,0,0,0,1.191.865l3.748-2.241,3.747,2.241a.8.8,0,0,0,1.192-.865L11.5,9.68,14.784,6.8a.8.8,0,0,0,.233-.851ZM7.6,12.528"
                                        transform="translate(0 -0.496)"
                                      />
                                    </svg>
                                  </li> : ""}
                                </ul>
                              </div>
                              <div className="video-detail-box" style={{minHeight: "105px"}}>
                                <div className="title-box">
                                  <div className="title half" style={{width: "100%"}}>
                                    {item?.isphotosorvideosLik === 1
                                      ? item?.originalname
                                        ? item.originalname
                                        : ""
                                      : item?.originalname
                                        ? item.originalname === "Video"
                                          ? `${item.originalname} ${item.uploadedrank}`
                                          : `${item.originalname}`
                                        : ""}
                                  </div>
                                </div>
                                <div className="video-info d-block">
                                  <div className="half">
                                    <span>
                                      Video Type :{" "}
                                      {item?.videophotosuploadedtype
                                        ? item.videophotosuploadedtype
                                        : ""}
                                    </span>
                                    <span>
                                      {`${item?.viewcount ? item?.viewcount : 0} Views - `}{" "}
                                      {postDateFormat(item?.createdOn)}
                                    </span>
                                    <span className="shareLink">
                                      <button className={"share-btn"} type={'button'} onClick={()=>handleModal(item)}>
                                        Share
                                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16.804" height="14" viewBox="0 0 16.804 14"><defs><style></style></defs><path class="a" d="M21.157,12.63,14.984,6.855a.316.316,0,0,0-.245-.105c-.193.009-.438.144-.438.35V10a.187.187,0,0,1-.158.179c-6.138.941-8.724,5.535-9.639,10.3-.035.188.219.363.337.214a11.158,11.158,0,0,1,9.275-4.7.216.216,0,0,1,.184.21v2.844a.375.375,0,0,0,.634.232L21.153,13.4a.483.483,0,0,0,.153-.368A.586.586,0,0,0,21.157,12.63Z" transform="translate(-4.502 -6.749)"></path></svg>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        }
                      })
                      : "No Videos & Photos"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <VideoPop
            setIsTestSingle={()=>setIsVidPop(false)}
            isTestSingle={isVidPop}
            vidID={vidID}
            isLink={ isLink }
            isToken={ isToken }
        />
        <Dialog open={showModal}
                onClose={(event, reason)=>{
                  if (reason == "backdropClick" || reason == "escapeKeyDown"){
                    handleModal()
                  }
                }}
        >
          <SharePost
              handleModal={handleModal}
              pageType={"Vid/Photo"}
              modalData={modalData}
          />
        </Dialog>
     </>
  );
}

export default ProfileVideoPhoto;