import React from "react";
import "../../assets/css/Footer.scss";
import logoV from "../../assets/img/newlogoVWhite.png";
import groupPhone from "../../assets/img/newGroupPhone.png";
import androidApp from "../../assets/img/android-app.png";
import iosApp from "../../assets/img/ios-app.png";
import facebook from "../../assets/img/facebook.svg";
import twitter from "../../assets/img/twitter.svg";
import instagram from "../../assets/img/instagram.svg";
import youtube from "../../assets/img/youtube.svg";
import linkedin from "../../assets/img/linkedin.svg";
import ncaa from "../../assets/img/ncaa.png";
import naia from "../../assets/img/naia.png";
import Nwac from "../../assets/img/Nwac.png";
import cccaa from "../../assets/img/cccaa.png";
import njcaa from "../../assets/img/njcaa.png";
import norton from "../../assets/img/norton.png";
import CertifiedCompliant from "../../assets/img/CertifiedCompliant.svg";
import "../../assets/css/stylesheet.scss";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import { getAuthToken } from "../../utils/constant";
export default function DashboardFooter() {
  const hitory = useHistory();
  return (
    <>
      <footer>
        <div className="dashFooterSection">
          <div className="contactSection">
            <div className="container">
              <div className="logoSection">
                <div className="logoPart">
                  <img src={logoV} alt="logoV" />
                </div>
              </div>
              <div className="flexBox">
                <div className="social">
                  <ul>
                    <li>
                      <span>Connect with us</span>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/SportsForceBB/"
                        target="_blank"
                      >
                        <img src={facebook} alt="Share us on Facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://www.twitter.com/sportsforcebb"
                        target="_blank"
                      >
                        <img src={twitter} alt="Follow us on Twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@sportsforceTV"
                        target="_blank"
                      >
                        <img src={youtube} alt="subscribe with us on Youtube" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://www.instagram.com/sportsforcebb"
                        target="_blank"
                      >
                        <img
                          src={instagram}
                          alt="Follow with us on Instagram"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/sportsforce/"
                        target="_blank"
                      >
                        <img src={linkedin} alt="Follow with us on LinkedIn" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="contact">
                  <ul>
                    <li>
                      <span>Have questions? Call</span>
                    </li>
                    <li className="phone">
                      <span>
                        <a style={{ color: "#00B9FF" }} href="tel:888.978.7084">
                          888.978.7084
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="comingSoonSection">
            <div className="container">
              <div className="flexBox">
                <div className="phoneImg">
                  <img src={groupPhone} alt="groupPhone" />
                </div>
                <div className="infoFlexBox">
                  <div className="info">
                    <h4>Coming Soon</h4>
                    <h5>Recruiting in your hands</h5>
                    <p>Tools and advice to find out the right fit.</p>
                  </div>
                  <div className="action">
                    <a href={void 0}>
                      <img src={androidApp} alt="androidApp" />
                    </a>
                    <a href={void 0}>
                      <img src={iosApp} alt="iosApp" />
                    </a>
                  </div>
                  <h4></h4>
                </div>
              </div>
            </div>
          </div>
          <div className="footerMenuSection">
            <div className="container">
              <div className="flexBox">
                {/*<div className="menu">*/}
                {/*  <h5 className="heading">Company</h5>*/}
                {/*  <ul>*/}
                {/*    <li>*/}
                {/*      <Link to="/aboutlp">About Us</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to={'/stafflp'}>Our Team</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to={'/careers'}>Careers "We're Hiring"</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to={'highlightlp'}>Internships</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to={'/testinomialslp'}>Press</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Contact Us</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to={'/assessmentlp'}>Legal</Link>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
                {/*<div className="menu">*/}
                {/*  <h5 className="heading">Our Programs</h5>*/}
                {/*  <ul>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Pricing & Programs</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>How We Help</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Reviews</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to={'/commitslp'}>Commits</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Video Packages</a>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
                {/*<div className="menu">*/}
                {/*  <h5 className="heading">Recruiting Resources</h5>*/}
                {/*  <ul>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>FREE Masterclass</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Athletic Scholarships</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to='/recruitingGuide'>Recruiting Guide</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to="/advertisement">How to Get Recruited</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Rating System</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Getting Scouted</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Blog</a>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
                {/* <div className="menu">
                  <h5 className="heading">Partners</h5>
                  <ul>
                    <li>
                      <Link
                        to="/Club"
                        onClick={() =>
                          setTimeout(() => {
                            window.scrollTo(0, 0);
                          }, 1800)
                        }
                      >
                        Club Teams
                      </Link>
                    </li> */}
                {/*    <li>*/}
                {/*      <Link to="/highSchools">High Schools</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Facilities</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Events</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to="/collegelp">Colleges</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Pro Teams</a>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
                {/*<div className="menu">*/}
                {/*  <h5 className="heading">Men's sports</h5>*/}
                {/*  <ul>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Baseball </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Lacrosse</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Football</a>*/}
                {/*    </li>*/}
                {/* </ul>
                </div> */}
                {/*<div className="menu">*/}
                {/*  <h5 className="heading">Women's sports</h5>*/}
                {/*  <ul>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Softball </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Lacrosse</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href={void 0}>Volleyball</a>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
                <div className="menu">
                  <h5 className="heading">Account</h5>
                  <ul>
                    {!getAuthToken() &&
                      <li>
                        <Link to={'/'}>Log In</Link>
                      </li>
                    }
                    {/*<li>*/}
                    {/*  <Link to={'/signupstep1'}>Sign Up</Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*  <a href={void 0}>Help</a>*/}
                    {/*</li>*/}
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(
                          "https://general-faqs.helpscoutdocs.com/",
                          "_blank"
                        )
                      }
                    >
                      <a
                        href={void 0}
                      // to='/faqaskedques'
                      >
                        FAQ's
                      </a>
                      {/*<a href="">FAQ's</a>*/}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footerBottom">
            <div className="container">
              {/* <h5 className="heading">Top recruiting colleges</h5> */}
              <div className="flexBox">
                <ul>
                  <li>
                    <span>
                      <img src={ncaa} alt="ncaa" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={naia} alt="naia" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={njcaa} alt="njcaa" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={cccaa} alt="cccaa" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={Nwac} alt="Nwac" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={norton} alt="norton" />
                    </span>
                  </li>
                  <li>
                    <img src={CertifiedCompliant} alt="Certified Compliant" />
                  </li>
                </ul>
              </div>
              <div className="contactEmailFlexBox">
                <ul>
                  <li>
                    <span className="label">Contact:</span>
                    <span>7514 Girard Ave. #1327 San Diego, CA 92037;</span>
                  </li>
                  <li>
                    <span className="label">&nbsp;Email address:</span>
                    <span>
                      <a href="mailto:team@sportsforceonline.com">
                        team@sportsforceonline.com
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="rightsTerms">
                <ul>
                  <li>© SportsForce 2023. All rights reserved</li>
                  <li>
                    <a href={void 0}>Privacy Policy</a>
                  </li>
                  <li>
                    <a href={void 0}>Terms of use</a>
                  </li>
                  {/* <li>
                    <a href={void 0}>Disclaimer</a>
                  </li>
                  <li>
                    <a href={void 0}>Sitemap</a>
                  </li> */}
                </ul>
              </div>
              <div className="phoneBlock">
                <span>
                  Have questions? Call{" "}
                  <a
                    className="phone"
                    style={{ color: "#00B9FF" }}
                    href="tel:888.978.7084"
                  >
                    888.978.7084
                  </a>
                </span>
              </div>
            </div>
          </div>
          {/*<div className="chatIcon">*/}
          {/*  <a href="/#/askaquestion">*/}
          {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">*/}
          {/*      <path d="M30 1.5c-16.542 0-30 12.112-30 27 0 5.204 1.646 10.245 4.768 14.604-.591 6.537-2.175 11.39-4.475 13.689a1 1 0 00.846 1.697c.405-.057 9.813-1.411 16.618-5.339C21.621 54.71 25.737 55.5 30 55.5c16.542 0 30-12.112 30-27s-13.458-27-30-27zm-14 31c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm14 0c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm14 0c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" />*/}
          {/*    </svg>*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
      </footer>
    </>
  );
}
