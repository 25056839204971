export const getCamelCase = (str = "") =>{
    const splitStr = str?.split(" ")
    let newStr = "",
        isFirstAlpha = true,
        regExp = /^[a-zA-Z]+$/
    splitStr?.map((itm, idx)=>{
        if (idx > 0){
            newStr += " "
            isFirstAlpha = true
        }
        const newWord =  itm?.split("")
        newWord?.map((inner, innerIdx)=>{
            if (
                // innerIdx == 0 ||
                (isFirstAlpha && regExp.test(inner))){
                newStr += inner?.toUpperCase()
                isFirstAlpha = false
            }else{
                newStr += inner?.toLowerCase()
            }
        })
    })
    return newStr
}