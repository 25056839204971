import React, {useEffect, useState} from 'react'
import logo from './assets/img/logo.png'
import './assets/css/sidebar.scss'
import './assets/css/header.scss'
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import { subscribe } from 'toastr';
import { useLocation } from "react-router-dom";
import { EntityStore } from '@apollo/client/cache';
import services from "../../services";
import {getUserInfo} from "../../utils/constant";
export const SideBar = () =>{

const location = useLocation();
const { pathname } = location;
const splitRoute = pathname.split("/")
const splitPath = splitRoute?.[splitRoute?.length - 1];
const urlInclude = pathname

    const [menuId, setMenuId] = useState(0),
          [isShow, setisShow] = useState(false),
          [isSetting, setIsSetting] = useState(false),
          [totalReq, setTotalReq] = useState(0),
          [isTotalRq, setIsTotalReq] = useState(true),
          [isAmin, setIsAdmin] = useState(true),
          [isAdvDD, setIsAdvDD] = useState(false),
          dispatch = useDispatch(),  
          store = useSelector(store => store);
          const toggleClass = () => {
            setisShow(!isShow);
            setMenuId(1)
          }
          useEffect(()=>{
              if (getUserInfo()?.role == "ADVISORS"){
                  setIsAdmin(true)
              }else {
                  setIsAdmin(false)
              }

          },[])
          useEffect(()=>{
            if(splitPath == 'athleteInfo' || splitPath == 'statsApproval'){
                setisShow(true)
                setIsSetting(false)
                // setIsTotalReq(false)
            }
            else if(splitPath == 'dashboard'){
                setMenuId(0)
                setisShow(false);
                setIsSetting(false)
                // setIsTotalReq(false)
            }
            else if (urlInclude.includes("tagSchoolCollege")){
                setIsSetting(true)
                setIsTotalReq(false)
            }
            else if (urlInclude.includes("commitmentStory")){
                // setIsTotalReq(true)
            }
        },[splitPath])
    useEffect(()=>{
        getRequestCount()
    },[store?.navStatus?.isReqCount])
   const getRequestCount = async () =>{
              const res = await services.location.requestCommitOnCS({role:"",adminstatus: 0, athletename: "", limit: 10, offset: 0})
       if (res?.status == 200 && res?.data?.response?.status == 200){
           setTotalReq(res?.data?.response?.data?.totalCount ?? 0)
           if (res?.data?.response?.data?.totalCount == 0){
               setIsTotalReq(false)
           }else{
               setIsTotalReq(true)
           }
       }else{
           setTotalReq(0)
       }
   }

    return(
        <>
        <div className={getUserInfo()?.role=="ADMIN" ? "sidebar-admin":"sidebar-admin sidebar-advisor"}
        >
            <div className="logo">
                <Link to={getUserInfo()?.role == "ADMIN" ? '/dashboard' : "/advisor/myAthlete"}
                onClick={()=>dispatch({type: "nav_setting_change", payload: {status: false}})}
                ><img src={logo} alt="Logo | SportsForce"/></Link>
            </div>
            {isAmin ?
                <>
                    <ul className="menu">
                                <>
                                    <li>
                                        <Link
                                            to={'/advisor/myAthlete'}
                                            className={urlInclude.includes('myAthlete') ? "active" : ""}
                                        >My Athletes</Link>
                                    </li>
                                </>
                    </ul>
                </>
                :
                <>
                    <ul className="menu">
                        {
                            isSetting
                                ?
                                <>
                                    <li>
                                        <Link className={urlInclude.includes('tagSchoolCollege') ? "active" : ""}
                                            // to={'/dashboard'}
                                        >Tag a College</Link>
                                    </li>
                                </>
                                :
                                <>
                                    <li>
                                        <Link className={splitPath == ('dashboard') ? "active" : ""}
                                              to={'/dashboard'}
                                        >Dashboard</Link>
                                    </li>
                                    <li className="show-submenu">
                                        <Link to={'#'}
                                              className={splitPath == 'athleteInfo' || splitPath == 'statsApproval' ? "active" : ""}
                                              href={void 0} onClick={toggleClass}>Athlete
                                            <span className={isShow ? "droparrow uparrow" : "droparrow"}
                                                  onClick={() => {
                                                      toggleClass()
                                                  }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10.885" height="5.476"
                             viewBox="0 0 10.885 5.476">
                        <path id="__TEMP__SVG__"
                              d="M.777,0a.776.776,0,0,0-.6,1.272L3.655,5.432.3,9.6A.776.776,0,0,0,.42,10.691a.776.776,0,0,0,1.133-.116L5.3,5.92a.776.776,0,0,0,0-.985L1.421.281A.776.776,0,0,0,.777,0Z"
                              transform="translate(10.885) rotate(90)" fill="#c0321f"/>
                        </svg>
                    </span>
                            </Link>
                            <ul className="dropdown-menu" style={isShow ? {display: "block"} : {display: "none"}}>
                                <li>
                                    <Link
                                        className={(
                                            splitPath == ('statsApproval')) ? "active" : ""}
                                        to={'/statsApproval'}
                                    >Stats Approval</Link>
                                </li>
                                <li>
                                    <Link
                                        className={(
                                            splitPath == ('athleteInfo')) ? "active" : ""}
                                        to={'/athleteInfo'}
                                    >Athlete Information</Link>
                                </li>
                            </ul>
                        </li>
                         <li className="show-submenu">
                             <Link to={'#'}
                                   className={splitPath == 'advisorinfo' ? "active" : ""}
                                   href={void 0} onClick={()=>setIsAdvDD(!isAdvDD)}>Advisor
                                 <span className={isAdvDD ? "droparrow uparrow" : "droparrow"}
                                       onClick={() => setIsAdvDD(!isAdvDD)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10.885" height="5.476"
                             viewBox="0 0 10.885 5.476">
                        <path id="__TEMP__SVG__"
                              d="M.777,0a.776.776,0,0,0-.6,1.272L3.655,5.432.3,9.6A.776.776,0,0,0,.42,10.691a.776.776,0,0,0,1.133-.116L5.3,5.92a.776.776,0,0,0,0-.985L1.421.281A.776.776,0,0,0,.777,0Z"
                              transform="translate(10.885) rotate(90)" fill="#c0321f"/>
                        </svg>
                    </span>
                             </Link>
                             <ul className="dropdown-menu" style={isAdvDD ? {display: "block"} : {display: "none"}}>
                                 <li>
                                     <Link
                                         className={(
                                             splitPath == ('advisorinfo')) ? "active" : ""}
                                         to={'/advisorinfo'}
                                     >Advisor Information</Link>
                                 </li>
                             </ul>
                         </li>
                        <li className="requestlink">
                            <Link
                                className={(
                                    splitPath == ('commitmentStory')) ? "active" : ""}
                                to={'/commitmentStory'}
                            >Requests  {isTotalRq ?  <span className="count">
                                {totalReq}</span> : ""}
                                        </Link>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <a href={void 0}*/}
                                    {/*    >Sports</a>*/}
                                    {/*</li>*/}
                                </>
                        }
                    </ul>

                </>
            }
        </div>
    </>
    )
}