import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import "./assets/css/enterOTP.scss";
import services from "../../services";
import toastr from "toastr";
export const EnterOTP = (props) => {
  let history = useHistory();
  const [enterOtpModal, setEnterOtpModal] = useState(true);
  const [enterOtp, setEnterOtp] = useState(""); // for otp inputs
  const [enterOtpError, setEnterOtpError] = useState(""); // for otp inputs
  const [isPassCodeExpire, setIsPassCodeExpire] = useState(false);
  //   const [secTime, setSecTime] = useState(1800);
  const [countDiv, setCountDiv] = useState(null);
  const [intervlId, setIntervlId] = useState(null);

  useEffect(() => {
    startTimer();
  }, []);

  const handleOtp = (otp) => {
    setEnterOtpError("");
    setEnterOtp(otp);
  };
  const changePasswordHandle = () => {
    // if(props?.props?.location?.search){
    //   history.push(`/changePassword/${props.userEmailId}?isCoach=true`);
    // } else {
    //   history.push(`/changePassword/${props.userEmailId}`);
    // }
    if (sessionStorage.getItem("ispasswordStatus")) {
      sessionStorage.removeItem("ispasswordStatus");
    }
    history.push(`/changePassword/${props.userEmailId}`);
  };

  const handleOnClick = async () => {
    if (enterOtp != "") {
      const body = {
        otp: enterOtp,
        emailId: props.userEmailId,
      };
      const response = await services?.location?.enterOTP(body);
      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.status == 200
      ) {
        history.push(`/changePassword/${props.userEmailId}`);
      } else if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.status == 201
      ) {
        toastr.error(
          !!response.data.response.msg
            ? response.data.response.msg
            : "Error Occurred"
        );
      } else {
      }
    } else {
      setEnterOtpError("Please Enter Passcode.");
    }
  };

  function hideemail() {
    let email = props.userEmailId;
    let hiddenEmail = "";
    for (let i = 0; i < email.length; i++) {
      if (i > 2 && i < email.indexOf("@")) {
        hiddenEmail += "*";
      } else {
        hiddenEmail += email[i];
      }
    }
    return hiddenEmail;
  }

  function hidePhone() {
    let phoneNumber = props.phone;
    let hiddenPhone = "";
    for (let i = 0; i < phoneNumber.length; i++) {
      if (i < phoneNumber?.length - 3) {
        hiddenPhone += "*";
      } else {
        hiddenPhone += phoneNumber[i];
      }
    }
    return hiddenPhone;
  }

  // const setTimer = () =>{
  //     var sec  = secTime,
  //         countDivTime = document.getElementById("timer"),
  //         Timepass,
  //         countDownDiv = setInterval(function () {
  //             Timepass();
  //         }, 1000);
  //     setCountDiv(countDownDiv)
  //     function Timepass() {
  //         var min     = Math.floor(sec / 60),
  //             remSec  = sec % 60;
  //         if (remSec < 10) {
  //             remSec = '0' + remSec;
  //         }
  //         if (min < 10) {
  //             min = '0' + min;
  //         }
  //         countDivTime.innerHTML = min + ":" + remSec;
  //         if (sec > 0) {
  //             sec = sec - 1;
  //         } else {
  //             clearInterval(countDownDiv);
  //             countDivTime.innerHTML = 'countdown done';
  //             setIsPassCodeExpire(true)
  //         }
  //     }
  // }

  const startTimer = () => {
    const countDivTime = document.getElementById("timer");
    const newTime = Date.now() + 1802000;
    const intervalId = setInterval(function () {
      const currentTime = Date.now();
      const timeDiff = newTime - currentTime;
      if (timeDiff <= 0) {
        clearInterval(intervalId);
        countDivTime.innerHTML = "countdown done";
        setIsPassCodeExpire(true);
      } else {
        const minute = Math.floor(timeDiff / 1000 / 60),
          seconds = Math.floor((timeDiff / 1000) % 60);
        const paddedMinute = String(minute).padStart(2, 0);
        const paddedSecond = String(seconds).padStart(2, 0);
        countDivTime.innerHTML = paddedMinute + ":" + paddedSecond;
      }
    }, 1000);
    setIntervlId(intervalId);
  };

  const resendPasscode = async () => {
    const body = {
      emailId: props?.userEmailId,
      forverification: 0, //this condition is for email header
    };
    const _res = await services.location.sendEmail(body);
    if (_res?.status == 200) {
      clearInterval(intervlId);
      startTimer();
      toastr.success(props.phone ? "Passcode has been sent to your registered Email ID / Mobile Number" : "Passcode has been sent to your registered Email ID" );
    } else {
      toastr.error("Passcode failed");
    }
  };
  return (
    <div
      className="modal fade common-modal show"
      id="ClaimProfileOtpModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="ClaimProfileOtpLabel"
      aria-hidden="true"
      style={{ display: enterOtpModal ? "block" : "none", position:"initial", overflowY: "auto" }}
      >
      <div className="modal-dialog m-0" role="document">
        <form className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Enter Passcode</h5>
            <button
              onClick={() => props.closeButton()}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{
              padding: "1.4em 1.2em 1.6em",
              fontSize: ".875rem",
              color: "#3e3e3e",
              textAlign: "center",
              position: "relative",
              border: "none",
              marginBottom: 0,
            }}
          >
            <p
              style={{
                fontSize: ".875rem",
                fontWeight: "400",
                color: "#3e3e3e",
                lineHeight: "22px",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              {/* An Email with a Passcode has been sent to your registered email
              address {hideemail()} */}
              A passcode has been sent to your registered
              {!!props.userEmailId
                ? " " + "Email ID" + " " + hideemail() + " "
                : ""}
              {!!props.phone ? ` / ` + hidePhone() : ""}
            </p>
            <ul className="claimprofile-password-box">
              <OtpInput
                placeholder="******"
                value={enterOtp}
                onChange={handleOtp}
                numInputs={6}
                isInputNum="true"
              />
            </ul>
            {enterOtpError != "" ? (
              <center>
                <p style={{ color: "red", textAlign: "center" }}>
                  Please Enter Passcode
                </p>
              </center>
            ) : null}
            <div className={"row"}>
              <div className={"col-md-12"}>
                {isPassCodeExpire ? (
                  <span
                    style={{
                      display: "block",
                      textAlign: "center",
                      marginTop: "5px",
                      fontStyle: "italic",
                    }}
                  >
                    *Passcode Expired
                  </span>
                ) : (
                  <span
                    style={{
                      display: "block",
                      textAlign: "center",
                      marginTop: "5px",
                      fontStyle: "italic",
                    }}
                  >
                    *Passcode will expire in{" "}
                    <span
                      style={{
                        color: "red",
                        display: "inline-block",
                        width: "45px",
                        fontStyle: "italic",
                      }}
                      id={"timer"}
                    ></span>{" "}
                    minutes
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer flex-column">
            <button
              onClick={() => handleOnClick()}
              type="button"
              className="btn blue"
              data-dismiss="modal"
            >
              Verify
            </button>
            <div className={"row"} style={{ marginLeft: "-15px" }}>
              <div className={"col-md-12"}>
                <span
                  style={{
                    display: "block",
                    textAlign: "center",
                    fontWeight: 700,
                    color: "rgb(77, 77, 243)",
                    textDecoration: "underline",
                    marginTop: "12px",
                    cursor: "pointer",
                  }}
                  // style={{display:"block", textAlign:"right", color:"#4d4df3", textDecoration:"underline", marginTop:"5px", cursor:"pointer"}}
                  onClick={() => resendPasscode()}
                >
                  Resend Passcode
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
