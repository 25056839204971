export const getAllCountriesQuery = `
  query allCountries{
    allCountries{
        Countries{
            countrycode
            countryname
            countryflagimage
        }
    }
  } 
  `;

export const getAllStatesQuery = `
  query allStates($where: JSON){
    allStates(where:$where){
      States{
        statename
        id
        initials
      }
    }
  } 
  `;
export const getAllUserDataQuery = `
query allUserdata($obj: JSON){
  allUserdata(where: $obj){
    Userdata{
      athletecountrycodeid
      athletecountryflag
      athleteemail
      athletefirstname
      athletegraduationyear
      athletelastname
      athleteoutsideusorcanada
      athletephonenumber
      athletesports
      athletezipcode
      clubteamname
      email
      emailverified
      gender
      graduationmonthvalue
      id
      memberofbaseballclubteam
      role
      username
      verifieduser
      athleteSports
     userdataathletesportsmaprel {
        Athletesports {
          sportname
        }
      }
      userdataathletegraduationyearmaprel {
        Graduationyears {
          yearvalue
        }
      }
      onBoardingUserIdMap {
        Onboardings {
					primaryposition
          onboardingprimarypositionmaprel{
            Primarypositions{
              selectionfield
              id
            }
          }
          secondaryposition
          onboardingsecondarypositionmaprel{
            Secondarypositions{
              selectionfield
              id
            }
          }
          otherposition
          heightinfeet
          heightininches
          weight
          weightgpa
          weightinkilogram
          battingprefencevalue
          throwingprefencevalue
          gpascale
          gpascalevalue
          twitteraccountname
          usetwitterforschool
          profileimages
        }
      }
      followersFollowerIdMap(where: {isfollowed: 1}){
        totalCount
      }
      followersFollowingIdMap(where: {isfollowed: 1}){
        totalCount
      }
    }
  }
} 
`;

export const getAllAthletesDataQuery = `
query allAthletes($obj: JSON){
  allAthletes(where: $obj){
    Athletes{
      athletecountrycodeid
      athletecountryflag
      athleteemailid
      athletefirstname
      athletegraduationyear
      athletelastname
      athleteoutsideusorcanada
      athletephonenumber
      phonenumberrelation
      stateid
      isathletemobilenumber
      athletestateidmaprel{
        States{
          statename
        }
      }
      homecountryid
      athleteathletecountrycodeidmaprel{
        Countries{
          id
          countryname
        }
      }

      address
      addresscountinue
      homezipcode
      homeoutsideusorcanada
      athletesports
      athletezipcode
      city
      clubteamname
      gender
      graduationmonthvalue
      id
      memberofbaseballclubteam
      verifieduser
      advisorid
      athleteSports
      athleteuseridmaprel{
        Userdata{
          email
          emailverified
          username
          role
        }
      }
      athleteathletesportsmaprel{
        Athletesports{
          sportname
        }
      }
      athleteathletegraduationyearmaprel{
        Graduationyears{
          yearvalue
        }
      }
      onBoardingUserIdMap{
        Onboardings{
          id
          primaryposition
          dateofbirth
          onboardingprimarypositionmaprel{
            Primarypositions{
              selectionfield
              id
            }
          }
          secondaryposition
          onboardingsecondarypositionmaprel{
            Secondarypositions{
              selectionfield
              id
            }
          }
          otherposition
          heightinfeet
          heightininches
          weight
          weightgpa
          weightinkilogram
          battingprefencevalue
          throwingprefencevalue
          gpascale
          unweightedgpa
          gpascalevalue
          twitteraccountname
          usetwitterforschool
          instagramaccountname
          profileimages
        }
      }
      followersFollowerIdMap(where: {isfollowed: 1}){
        totalCount
      }
      followersFollowingIdMap(where: {isfollowed: 1}){
        totalCount
      }
    }
  }
} 
`;

export const getAllProfileSectionQuery = `
query allAthleteprofilecategories($obj: JSON){
    allAthleteprofilecategories(where: $obj){
    Athleteprofilecategories{
      id
      profilecategoryname
      profilename
      redirecturl
    }
  }
 }
  `;

export const getAllAnnualhouseholdincomes = `
  query allAnnualhouseholdincomes{
    allAnnualhouseholdincomes{
      Annualhouseholdincomes{
        id
        incomerange
      
    }
    }
  }`;

export const getAllRacialethnicheritages = `
  query allRacialethnicheritages{
      allRacialethnicheritages{
        Racialethnicheritages{
          id
          ethnicvalue
        }
      }
  }`;

export const getAllSatMathQuery = `
  query{
    allSatmaths{
      Satmaths{
        id
        mathvalue
      }
    }
  } 
  `;

export const getAllSatReadingAndWritingQuery = `
  query {
    allSatreadingandwritings{
      Satreadingandwritings{
        id
        readingandwritingvalue
      }
    }
  }
  `;

export const getAllPstMathQuery = `
  query {
    allPsatmaths{
     Psatmaths{
       id
       psatmath
     }
   }
   }
  `;

export const getAllPstReadingAndWriting = `
  query {
    allPsatreadingandwritings{
      Psatreadingandwritings{
        id
        psatreadingandwritingvalue
      }
    }
    }
  `;

export const getAllActCompositeScoresQuery = `
  query {
    allActcompositescores
      {
        Actcompositescores
        {
          id
          actcompositescorevalue
        }
      }
    }
  `;
export const getAllAcademicNotesQuery = `
  query {
    allAcademicnotes{
      Academicnotes{
        id
        notevalue
      }
      
    }
    }
  `;

export const getAllReportCardTypeQuery = `
  query {
    allReportcardtypes{
      Reportcardtypes{
        id
        type
      }
    }
  }
  `;

export const getAllApIbClassesQuery = `
  query{
    allApandibclassestimeperiods{
     
        Apandibclassestimeperiods{
          id
         timeperiodforcources
        }
      
    }
  }
  `;

export const getAllCoachAndTeamDetailsQuery = `
  query filter {
    allCoachandteamdetails{
      Coachandteamdetails{
        id
        coachfirstname
        coachlastname
        coachemail
        coachphonenumber
        teamname
      }
    }
  }
  `;
export const getAllCoachTypes = `
  query{
    allCoachtypes{
      Coachtypes{
        id
        coachtypename
      }
    }
  }
  `;
export const getAllOfferListQuery = `query{
    allOffersdropdwns{
      Offersdropdwns{
        id
        offeresvalue
        imagename
      }
    }
  }`;

export const getAllInterestLevelQuery = `
  query
    {
    allInterestlevels{
       Interestlevels{
          id
          interestlevelvalue
    }
  }
}
  `;

export const getAllCoachAndTeamDetails = `
query  {
    allCoachandteamdetails{
      Coachandteamdetails{
        id
        teamname
        coachfirstname
        coachemail
        coachlastname
        coachtypeid
        teamname
      }
    }
  }`;

export const getAllEventCatergoriesQuery = `
  query allEventcategories{
      allEventcategories{
      Eventcategories{
      id
      categoryname
      }
      }
      }`;

export const getAllOrganizationsQuery = `
query allOrganizations{
    allOrganizations(where: {isverified: 1}){
      Organizations{
        id
        eventorgname
        isverified
      }
    }
  }
`;

export const getAllRelationshipsQuery = `
query allRelationships{
    allRelationships{
      Relationships{
      relationshipname
      }
    }
  }
`;

export const getAllCheckListDataQuery = `
query fetchCheckListData($obj: JSON) {
  allAthletes(where: $obj) {
    Athletes {
      id
      athletefirstname
      athletelastname
      gender
      athletephonenumber
      verifieduser
      address
      athleteathletegraduationyearmaprel(where:{active:1}) {
        Graduationyears {
          id
          yearvalue
        }
      }
      referenceUserIdMap(where:{active:1}) {
        References {
          id
          reffirstname
        }
      }
      eventmappingaddedby(where:{active:1}){
        Eventmappings{
          eventid
        }
      }
      schoolTeamusermappingUserIdMap(where:{active:1}){
        Schoolteamusermappings{
          id
          teamid
        }
      }
      teamUserIdMap(where:{active:1}){
        Teams{
          id
          teamname
        }
      }
      athleteparentmappingAthleteIdmap(where:{active:1}) {
        Athleteparentmappings {
          id
          parentid
          athleteparentmappingmappingparentidmaprel{
            Athleteparents{
              id
              parentphonenumber
            }
          }
        }
      }
      testScoreUserIdMap(where:{active:1}) {
        Testscores {
          id
          satmathid
          satreadingandwritingid
          actcompletescoreid
        }
      }
      athleteschoolUserIdMap(where:{active:1}) {
        Athleteschools {
          id
          userid
          highschooltype
          division
          highschoolname
        }
      }
      athletemoreschoolUseridMap(where:{active:1}) {
        Athletemoreschools{
          schoolid
          highschoolname
          userid
          highschooltype
          division
          id
        athletemoreschoolschoolidmaprel {
          Athleteschools{
             id
          userid
          highschooltype
          division
          highschoolname
          }
       }  
        }
      }
      onBoardingUserIdMap(where:{active:1}) {
        Onboardings {
          id
          primaryposition
          weight
          dateofbirth
          profileimages
          heightinfeet
          heightInInches
          gpascale
          unweightedgpa
          aboutme
        }
      }
    }
  }
}
`;

export const getProfileCalculationListQuery = `
{
  allProfilecalculationmasters{
    Profilecalculationmasters{
      id
      profilesectionname
      profilecalculationvalue
    }
  }
}
`;

export const getAthleteInfoQuery = `
query allAthletes($obj: JSON){
  allAthletes(where: $obj){
    Athletes{
      active
      referralpartner
      appointmentsetter
      frontendadvisor
      leadsource
      athletecountrycodeid
      athletecountryflag
      athletefirstname
      athletegraduationyear
      athletelastname
      athleteemailid
      athletephonenumber
      athletegraduationyear
      gender
      athletesports
      advisorathletemappingAthleteidMap{
        Advisorathletemappings{
          advisorid
          advisorathletemappingadvisoridmaprel{
            Advisors{
              id
              firstname
              lastname
              emailid
            }
          }
        }
      }
      membershipuseridMap{
        Memberships{
          active
          installmentnumber
          issubscriptioncancelled
          planstartdate
          planrenewaldate
          lastpaymentdate
          issubscriptioncancelled
          membershipsubscriptionplanidmaprel{
            Subscriptionplans{
              id
              planname
            }
          }
          membershipsubscriptionplantypemaprel{
            Subscriptionplantypes{
              id
              plantype
            }
          }
        }
      }
      onBoardingUserIdMap{
        Onboardings{
          dateofbirth
        }
      }
    }
  }
} 
`;
