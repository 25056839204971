import '../header/assets/css/stylesheet.scss'
import React from "react";
import MuiAutoComplete from "./AutoComplete";
import {getCamelCase} from "./getCamelCase";
import {Link} from 'react-router-dom'
import { useState } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {  MenuItem, Select } from "@material-ui/core";
export const StatsPopup = ({modalCheckBox, handle, onClose, onSubmitModal, brandName, setBrandName, modalData}) =>{
    const [isStatsOkDisabled, setIsStatsOkDisabled] = useState(false);
    return(
        <>
            <div className="modal verifymodal" style={{display:"block", position:"initial", overflowY:"auto"}}>
                <div className="modal-dialog modal-dialog-centered m-0" role="document">
                    <form className="modal-content">
                        <div className="modal-header border-none">
                            <h5 className="modal-title">Verify</h5>
                            <button type="button" className="close"  data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={()=>onClose()}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label className="name">{modalData?.athleteFirstName ?? ""} {modalData?.athleteLastName ?? ""}</label>
                                <ul className="checkbox-group">
                                    <li>
                                        <span className="checkbox"><input
                                            value={modalCheckBox?.admin}
                                            name={'admin'}
                                            onChange={(e)=>handle(e)}
                                            type="checkbox"/><label>SportsForce/3rd party verified</label></span>
                                    </li>
                                    <li>
                                        <span className="checkbox"><input
                                          value={modalCheckBox?.coach}
                                          name={'coach'}
                                          onChange={(e)=>handle(e)}
                                          type="checkbox"
                                        /><label>Coach verified</label></span>
                                    </li>
                                </ul>
                            </div>
                            <div className="form-group flexbox">
                                {/*<div className="half">*/}
                                {/*    <label>Brand Name</label>*/}
                                {/*    <select value={brandName} onChange={(e)=>setBrandName(e?.target?.value)}>*/}
                                {/*        <option value={''}>Select Brand</option>*/}
                                {/*        <option value={'Nike'}>Nike</option>*/}
                                {/*        <option value={'Reebok'}>Reebok</option>*/}
                                {/*        <option value={'TrackMan'}>TrackMan</option>*/}
                                {/*    </select>*/}
                                {/*    </div>*/}
                                </div>
                        </div>
                        {(modalCheckBox?.coach || modalCheckBox?.admin) &&
                        <div className="modal-footer">
                            <button disabled={isStatsOkDisabled} type="button" onClick={() => { setIsStatsOkDisabled(true); onSubmitModal() }} className="btn blue"
                                    data-dismiss="modal">Submit
                            </button>
                        </div>
                        }
                    </form>
                </div>
            </div>
        </>
    )
}
export const InvitePopup = ({onClose, onSubmitModal}) =>{
    const [isInviteOkDisabled, setIsInviteOkDisabled] = useState(false);
    return(
        <>
            <div className="modal inviteAthleteInfoPopup" style={{display:"block", position:"initial", overflowY:"auto"}}>
                <div className="modal-dialog modal-dialog-centered m-0" role="document">
                    <form className="modal-content">
                        <div className="modal-header border-none">
                            <h5 className="modal-title">Athlete Invitation</h5>
                            <button type="button" className="close"  data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={()=>onClose()}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group text-center">
                                    Invite will be sent to only those athletes who are not connected with SportsForce.
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button"
                                    onClick={() => {setIsInviteOkDisabled(true); onSubmitModal()}}
                                    className="btn blue"
                                    disabled={isInviteOkDisabled}
                                    data-dismiss="modal">OK
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export const InviteAllPopup = ({onClose, onSubmitModal, lastInviteAllDate}) =>{
    const [isInviteAllOkDisabled, setIsInviteAllOkDisabled] = useState(false);
    return(
        <>
            <div className="modal inviteAthleteInfoPopup" style={{display:"block", position:"initial", overflowY:"auto"}}>
                <div className="modal-dialog modal-dialog-centered m-0" role="document">
                    <form className="modal-content">
                        <div className="modal-header border-none">
                            <h5 className="modal-title">Athlete Invitation</h5>
                            <button type="button" className="close"  data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={()=>onClose()}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {lastInviteAllDate && <div className="form-group text-center" style={{fontSize: "14px"}}>
                                <p>Last bulk invitations have been sent at {`${lastInviteAllDate}`}</p>
                            </div>}
                            <div className="form-group text-center">
                                {/* Invite will be sent to all athletes and parent / guardian who are not connected with SportsForce. */}
                                Invite will be sent to all athletes and parent / guardian who have not accepted the invitation.
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button"
                                    onClick={(e) => {setIsInviteAllOkDisabled(true); onSubmitModal(e)}}
                                    className="btn blue"
                                    disabled={isInviteAllOkDisabled}
                                    data-dismiss="modal">OK
                            </button>
                            <button style={{marginLeft:"10px", background: "#c0321f", color:"white"}} type="button"
                                    onClick={() => onClose()}
                                    className="btn blue"
                                    data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export const DeletePopup = ({onClose, onSubmitModal}) =>{
    return(
        <>
            <div className="modal inviteAthleteInfoPopup" style={{display:"block", position:"initial", overflowY: "auto"}}>
                <div className="modal-dialog modal-dialog-centered m-0" role="document">
                    <form className="modal-content">
                        <div className="modal-header border-none">
                            <h5 className="modal-title">
                                {/*Athlete Invitation*/}
                                {/*Do you want to deactivate ?*/}
                            </h5>
                            <button type="button" className="close"  data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={()=>onClose()}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group text-center">
                                <h1 style={{fontWeight:"bold", fontSize:"20px"}}>Do you want to deactivate ?</h1>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={() => onSubmitModal()} className="btn blue"
                                    data-dismiss="modal">OK
                            </button>
                            <button
                                // style={{marginLeft:"10px", background: "#c0321f", color:"white"}}
                                type="button" onClick={() => onClose()} className="btn cancel"
                                    data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export const TagPop = ({setTagOpen, collegeData, fields, handleOnClgChange, onSubmit, isError}) =>{
    return(
        <>
            <div className="modal verifymodal" style={{display:"block", position:"initial", overflowY:"auto"}}>
                <div className="modal-dialog modal-dialog-centered m-0" role="document">
                    <form className="modal-content">
                        <div className="modal-header border-none">
                            <h5 className="modal-title">Tag a New College</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={()=>setTagOpen()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                    <MuiAutoComplete
                                        id="combo-box-demo"
                                        freeSolo={true}
                                        options={collegeData}
                                        getOptionLabel={(option) => (option.collegename && option?.city && option?.state) ?
                                            `${!!option.collegename ? getCamelCase(option.collegename) : ""} - [${!!option?.city ? getCamelCase(option?.city) : ""}, ${!!option?.state ? getCamelCase(option?.state) : ""}]` :
                                            option?.collegename }
                                        value={fields.collegeName || null}
                                        onChangeObj={(e, data) => {
                                            handleOnClgChange("collegeName", {
                                                target: { value: data },
                                            });
                                        }}
                                        inputPlaceholder="College Name"
                                    />
                                    {/*<label>School / College Name</label>*/}
                                    {/*<select>*/}
                                    {/*    <option selected>Select</option>*/}
                                    {/*    <option>College Name 1</option>*/}
                                    {/*    <option>College Name 2</option>*/}
                                    {/*</select>*/}
                                    {isError && <div className="error">Please Select The College To Tag</div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn blue" data-dismiss="modal"
                            onClick={()=>onSubmit()}
                            >Submit</button>
                            <button type="button" className="btn cancel" data-dismiss="modal"
                            onClick={()=>setTagOpen()}
                            >Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export const NewAthPop = ({onClose, newAthField, onHandleChange, statsSports, onSubmitNewAth, statsGradyear, newAthError}) =>{
    return(
        <>
            <div className="modal verifymodal" id="AddNewAthleteModal" style={{display:"block", position:"initial", overflowY:"auto"}}>
                <div className="modal-dialog modal-dialog-centered m-0" role="document">
                    <form className="modal-content">
                        <div className="modal-header border-none">
                            <h5 className="modal-title">Add New Athlete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                            onClick={()=>onClose()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group flexbox">
                                <div className="half">
                                    <label>Athlete First Name<span style={{color:"red"}}>*</span></label>
                                    <input type="text" placeholder="Athlete First Name"
                                           name={'athletefirstname'}
                                           value={newAthField?.athletefirstname ?? ""}
                                           onChange={(e)=>onHandleChange(e)}
                                    />
                                    {newAthError?.firstName && <span style={{color:"red", fontSize:"12px"}}>
                                        {newAthError?.errorFirstName ?? ""}
                                    </span>}
                                </div>
                                <div className="half">
                                    <label>Athlete Last Name<span style={{color:"red"}}>*</span></label>
                                    <input type="text" placeholder="Athlete Last Name"
                                           name={'athletelastname'}
                                           value={newAthField?.athletelastname ?? ""}
                                           onChange={(e)=>onHandleChange(e)}
                                    />
                                    {newAthError?.lastName && <span style={{color:"red", fontSize:"12px"}}>
                                        {newAthError?.errorLastName ?? ""}</span>}
                                </div>
                            </div>
                            <div className="form-group flexbox">
                                <div className="half">
                                    <label>Athlete Email ID<span style={{color:"red"}}>*</span></label>
                                    <input type="email" placeholder="Athlete Email Id"
                                           name={'athleteEmailId'}
                                           value={newAthField?.athleteEmailId ?? ""}
                                           onChange={(e)=>onHandleChange(e)}
                                    />
                                    {newAthError?.emailId && <span style={{color:"red", fontSize:"12px"}}>
                                        {newAthError?.errorEmailID ?? ""}
                                    </span>}
                                </div>
                                <div className="half">
                                    <label>Select Gender<span style={{color:"red"}}>*</span></label>
                                    <div className="radioflexBox">
                                        <div className="option">
                                            <input type="radio"
                                             checked={newAthField?.gender == "Male" ? true : false}
                                                   onChange={(e)=>onHandleChange(e)}
                                                   name="Male"/><label>Male</label>
                                        </div>
                                        <div className="option">
                                            <input
                                                type="radio"
                                                name="Female"
                                                checked={newAthField?.gender == "Female" ? true : false}
                                                onChange={(e)=>onHandleChange(e)}
                                            /><label>Female</label></div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group flexbox">
                                <div className="half">
                                    <label>Sports<span style={{color:"red"}}>*</span></label>
                                    <select
                                        name={'athletesports'}
                                        value={newAthField?.athletesports ?? ""}
                                        onChange={(e)=>onHandleChange(e)}
                                    >
                                        <option value={""}>Select</option>
                                        {statsSports?.length > 0 &&
                                        statsSports?.map((el) => {
                                            if (el?.id == 1 && newAthField?.gender == "Male") {
                                                return (
                                                    <option value={el?.id}>
                                                        {el?.sportname ?? ""}
                                                        {/*{el?.sportforgender ?? ""}*/}
                                                    </option>
                                                );
                                            } else if (
                                                el?.id == 4 &&
                                                newAthField?.gender == "Female"
                                            ) {
                                                return (
                                                    <option value={el?.id}>
                                                        {el?.sportname ?? ""}
                                                        {/*{el?.sportforgender ?? ""}*/}
                                                    </option>
                                                );
                                            }
                                        })}
                                    </select>
                                    {newAthError?.sports && <span style={{color:"red", fontSize:"12px"}}>Sport Name Is Required</span>}
                                </div>
                                <div className="half">
                                    <label>Grad Year<span style={{color:"red"}}>*</span></label>
                                    <select
                                        name={'athletegraduationyear'}
                                        value={newAthField?.athletegraduationyear ?? ""}
                                        onChange={(e)=>onHandleChange(e)}
                                    >
                                        <option value={""}>Select</option>
                                        {statsGradyear?.length > 0 &&
                                        statsGradyear?.map((name, indx) =>{
                                            return <option value={name?.id}>{name?.yearvalue}</option>
                                        })}
                                    </select>
                                    {/*<input placeholder={"Grad Year"}*/}
                                    {/*type={'number'}*/}
                                    {/*       value={newAthField?.athletegraduationyear ?? ""}*/}
                                    {/*       name={'athletegraduationyear'}*/}
                                    {/*       onChange={(e)=>onHandleChange(e)}*/}
                                    {/*/>*/}
                                    {newAthError?.gradYear && <span style={{color:"red", fontSize:"12px"}}>Grad Year Is Required</span>}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn blue" data-dismiss="modal"
                            onClick={()=>onSubmitNewAth(0)}
                            >Save</button>
                            <button type="button" className="btn save-invitebtn" data-dismiss="modal"
                            onClick={()=>onSubmitNewAth(1)}
                            >Save and Invite
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export const ApproveAllPop = ({onClose, onSubmit}) =>{
    return(
        <div className="modal verifymodal" style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Approve All</h5>
                        <button type="button" className="close"
                                onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <p style={{lineHeight:"1.4"}}>Are you sure you want to approve all / selected commitment requests?
                                Only those will be approved where commitment is not added by athlete / admin earlier.</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn blue"
                                onClick={()=>onSubmit()}
                        >Yes</button>
                        <button type="button" className="btn btn cancel"
                                onClick={()=>onClose()}
                        >Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export const RejectAllPop = ({onClose, onSubmit}) =>{
    return(
        <div className="modal verifymodal" style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Reject All</h5>
                        <button type="button" className="close"
                                onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <p style={{lineHeight:"1.4"}}>Are you sure you want to reject all / selected commitment requests?
                                Only those will be rejected where commitment is not added by athlete / admin earlier.</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn blue"
                                onClick={()=>onSubmit()}
                        >Yes</button>
                        <button type="button" className="btn btn cancel"
                                onClick={()=>onClose()}
                        >Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export const ApproveCommitPop = ({onClose, onSubmit, isAppArr}) =>{
    return(
        <div className="modal verifymodal" style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Approve Commitment</h5>
                        <button type="button" className="close"
                                onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="name">{getCamelCase(isAppArr?.athleteFirstName ?? "")} {getCamelCase(isAppArr?.athleteLastName ?? "")}</label>
                            <p>Are you sure you want to approve commitment request ?</p>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn blue"
                                onClick={()=>onSubmit()}
                        >Yes</button>
                        <button type="button" className="btn btn cancel"
                                onClick={()=>onClose()}
                        >Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    )
 }

export const RejectCommitPop = ({onClose, onSubmit, isRejArr}) =>{
    return(
        <div className="modal verifymodal" style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Reject Commitment</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="name">{getCamelCase(isRejArr?.athleteFirstName ?? "")} {getCamelCase(isRejArr?.athleteLastName ?? "")}</label>
                            <p>Are you sure you want to reject commitment request ?</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn blue"
                                onClick={()=>onSubmit()}
                        >Yes</button>
                        <button type="button" className="btn btn cancel"
                        onClick={()=>onClose()}
                        >Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export const CommitmentDetail = ({onClose, detail, commitedBy}) =>{
    const replaceURLs = (message) => {
        if (!message) return;
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        let emailRegex = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
        message = message.replace(emailRegex, function (email) {
            return "<a href=mailto:'" + email + "'>" + email + "</a>"
        })
        return message.replace(urlRegex, function (url) {
            var hyperlink = url;
            if (!hyperlink.match('^https?:\/\/')) {
                hyperlink = 'http://' + hyperlink;
            }
            return "<a href='" + hyperlink + "' target='_blank'>" + url + "</a>"
        });
    }
    return(
        <div className="modal verifymodal" style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <div className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Commitment Request</h5>
                        <button type="button" className="close"
                        onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="name" style={{textDecoration:"underline"}}
                            >
                                <Link
                                target={'_blank'}
                                // to={`/publicProfile/1/${+commitedBy?.userid}/${JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.id}`}
                                to={`/athlete/${commitedBy?.athleteFirstName?.trim()?.toLowerCase()?.split(" ")?.join('-')}-${commitedBy?.athleteLastName?.trim()?.toLowerCase()?.split(" ")?.join('-')}-${commitedBy?.sysGenId ?? 0}`}
                                >
                                    {getCamelCase(commitedBy?.athleteFirstName ?? "")} {getCamelCase(commitedBy?.athleteLastName ?? "")}
                                </Link>
                            </label>
                            <ul className="two-col-grid">
                                <li>
                                    <h4>First Name</h4>
                                    <p>{getCamelCase(detail?.firstname ?? "")}</p>
                                </li>
                                <li>
                                    <h4>Last</h4>
                                    <p>{getCamelCase(detail?.lastname ?? "")}</p>
                                </li>
                                <li>
                                    <h4>Mobile No.</h4>
                                    <p>{detail?.phone ?? ""}</p>
                                </li>
                                <li>
                                    <h4>Role</h4>
                                    <p>{!!detail?.role ? getCamelCase(detail?.role == "Athletes" ? "Athlete" : detail?.role == "ADVISORS" ? "Advisor" : detail?.role) : "" }</p>
                                </li>
                                <li>
                                    <h4>Email Id</h4>
                                    <p>{!!detail?.email ? detail?.email : "" }</p>
                                </li>
                            </ul>
                            <div className={'row'} style={{marginTop: "20px"}}>
                                <div className={'col-md-12'}>
                                    <h4 style={{color: "#9e9e9e", marginBottom:"8px"}}>Commitment / Signing Announcement</h4>
                                    <p dangerouslySetInnerHTML={{__html: !!commitedBy?.notes  ? replaceURLs(commitedBy?.notes) : ""}}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div className="modal verifymodal" style={{display: "block"}}>
        //     <div className="modal-dialog modal-dialog-centered" role="document">
        //         <form className="modal-content">
        //             <div className="modal-header border-none">
        //                 <h5 className="modal-title">Reject Commitment</h5>
        //                 <button type="button" className="close" data-dismiss="modal" aria-label="Close"
        //                         onClick={()=>onClose()}
        //                 >
        //                     <span aria-hidden="true">&times;</span>
        //                 </button>
        //             </div>
        //             <div className="modal-body">
        //                 <div className="form-group">
        //                     <p>Name: {getCamelCase(detail?.athleteFirstName ?? "")} {getCamelCase(detail?.athleteLastName ?? "")}</p>
        //                 </div>
        //             </div>
        //         </form>
        //     </div>
        // </div>
    )
}

export const DeleteAllPop = ({onClose, onSubmit}) =>{
    return(
        <div className="modal verifymodal" style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Delete All</h5>
                        <button type="button" className="close"
                                onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <p style={{lineHeight:"1.4"}}>Are you sure you want to delete all / selected Commitment requests?</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn blue"
                                onClick={()=>onSubmit()}
                        >Yes</button>
                        <button type="button" className="btn btn cancel"
                                onClick={()=>onClose()}
                        >Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export const DeleteCommitPop = ({onClose, onSubmit}) =>{
    return(
        <div className="modal verifymodal" style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Delete Commitment</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <p>Are you sure you want to delete Commitment request ?</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn blue"
                                onClick={()=>onSubmit()}
                        >Yes</button>
                        <button type="button" className="btn btn cancel"
                        onClick={()=>onClose()}
                        >Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export const InvitePop = ({onClose, onSubmit, athleteInfo, linkData}) =>{
    let checkInvite = false; //it will show OK Btn
    if(linkData?.athleteinfo?.length > 0){
     if ((!!linkData?.athleteinfo[0]['athleteEmailId'] || !!linkData?.athleteinfo[0]['athletePhoneNumber'])){
         checkInvite = false //it will show athlete invite btn
       }else {
         checkInvite = true
     }
    }
    // if (linkData?.parentInfo?.length > 0){
    //     linkData?.parentInfo?.map((em, idx) =>{
    //         const partStatus = (!!em?.parentEmail || !!em?.parentPhoneNumber)
    //       if (partStatus || checkInvite){
    //                 checkInvite = true //it will show the send invite
    //       }
    //     })
    // }
    const parentDropDown = linkData?.parentInfo && linkData?.parentInfo?.length ? linkData?.parentInfo.filter(data => data.id) : []
    const athleteCopyLink = linkData?.athleteinfo && linkData?.athleteinfo?.length && linkData?.athleteinfo[0]['athleteEmailId'] == null ? `http://sfrglobal.com/#/login?emailid/${linkData?.athleteinfo[0]['sysGenId']}` : null;
    const [parentCopyLink, setParentCopyLink] = useState(null);
    const [linkCopied, setLinkCopied] = useState("Copy link");
    const [parentLinkCopied, setParentLinkCopied] = useState("Copy link");
    const [parentData, setParentData] = useState(null)
    const [isParentInvite, setIsParentInvite] = useState(false)
    const [isInviteDisabled, setIsInviteDisabled] = useState(false)
    const [isParentInviteDisabled, setIsParentInviteDisabled] = useState(false)
    const [isParentAlreadyInvited, setIsParentAlreadyInvited] = useState(null)
    const handleChange = (e) => {
        if(!!e.target.value) {
            const filData = linkData?.parentInfo?.filter(el =>el?.id == e?.target?.value)
            //filData?.[0]?.isinvited == 1 this key is added by Pr
            if(filData?.length > 0 && filData?.[0]?.athleteInviteStatus == 2 && filData?.[0]?.isinvited == 1){
                setIsParentInvite(false)
                setIsParentAlreadyInvited(true)
                setParentData(filData?.[0])
            }
            else{
                if (filData?.length > 0 && (!!filData?.[0]?.parentEmail || !!filData?.[0]?.parentPhoneNumber)){
                    setParentCopyLink(null)
                    setIsParentInvite(true)
                    setParentData(filData?.[0] ?? "ER")
                }else {
                    setParentCopyLink(`http://sfrglobal.com/#/login?emailid/${filData?.[0]?.sysGenId}`)
                    setParentData(null)
                    setIsParentInvite(true)
                }
            }
        }
        else{
            setParentCopyLink(null)
            setIsParentInvite(false)
        }
    }
    return(
        <>
            <div class="modal verifymodal" id="AthleteInvitationModal" style={{display:"block", position:"initial", overflowY:"auto"}}>
                <div class="modal-dialog modal-dialog-centered m-0" role="document">
                    <form class="modal-content">
                    <div class="modal-header border-none">
                        <h5 class="modal-title">Invite <span class="name">{athleteInfo.athleteFullName}</span></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>onClose()}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        { checkInvite  ?
                        <div class="form-group">
                            <label>Athlete invite link</label>
                            <div class="linkbox">
                                <input type="text" placeholder={athleteCopyLink} value={athleteCopyLink} />
                                <CopyToClipboard text={athleteCopyLink} onCopy={()=>{setLinkCopied("Copied!"); setParentLinkCopied("Copy link")}}>
                                    <button type="button" class="copyBtn">{linkCopied}</button>
                                </CopyToClipboard>
                            </div>
                        </div>
                        :
                         <>
                             <div className="form-group">
                                 <label>Athlete invite link</label>
                             </div>
                        <div class="modal-footer" style={{padding: "0 0 10px 0"}}>
                            <button
                                style={{background: "#00B9FE", color: "white", border: "none", margin: 0, display:"block"}}
                                type="button" className="btn save-invitebtn mal-0" data-dismiss="modal"
                                onClick={() => {setIsInviteDisabled(true); onSubmit(1, linkData?.athleteinfo)}}
                                disabled={isInviteDisabled}
                            >Invite {getCamelCase(athleteInfo.athleteFullName)}
                            </button>
                        </div>
                        </>
                        }
                        {
                            parentDropDown?.length ?
                                <div class="form-group flex">
                                    <div className={'half'}>
                                <label>Parent invite link</label>
                                <select onChange={e => handleChange(e)}>
                                    <option value="" selected>Select Parent / Guardian</option>
                                    {parentDropDown.map((item, index) => (
                                        <option key={index} value={item?.id}
                                                // data={item}
                                        >
                                            {item.parentFirstName+" "+item.parentLastName}
                                        </option>
                                    ))}
                                </select>
                                    </div>
                            </div>
                                : null
                        }
                        
                        { !!parentCopyLink  ?
                        <div class="form-group">
                            {/*<label>Parent invite link</label>*/}
                            <div class="linkbox">
                                <input type="text" placeholder={parentCopyLink} value={parentCopyLink} />
                                <CopyToClipboard text={parentCopyLink} onCopy={()=>{setParentLinkCopied("Copied!"); setLinkCopied("Copy link")}}>
                                    <button type="button" class="copyBtn">{parentLinkCopied}</button>
                                </CopyToClipboard>
                            </div>
                        </div>
                        :
                            <>
                                {isParentInvite ?  <div className="modal-footer" style={{padding: "0 0 10px 0"}}>
                                <button
                                    style={{background: "#00B9FE", color: "white", border: "none", margin: 0}}
                                    type="button" className="btn save-invitebtn mal-0" data-dismiss="modal"
                                    onClick={() => {setIsParentInviteDisabled(true); onSubmit(2, parentData)}}
                                    disabled={isParentInviteDisabled}
                                >Invite {getCamelCase(parentData?.parentFirstName ?? "")} {getCamelCase(parentData?.parentLastName ?? "")}
                                </button>
                                </div> : isParentAlreadyInvited ? <p>Invite status of the {getCamelCase(parentData?.parentFirstName ?? "")} {getCamelCase(parentData?.parentLastName ?? "")} is onboarded / accepted on SportsForce</p> : null
                                }
                              </>
                        }
                        {((athleteCopyLink == null) && (parentDropDown?.length == 0)) ?
                            <div class="form-group">
                                {/*<label>No information available</label>*/}
                            </div>
                            : null
                        }
                    </div>
                    <div class="modal-footer justify-content-center">
                        {/*{ checkInvite ?*/}
                        {/*    <button type="button" class="btn save-invitebtn mal-0" data-dismiss="modal" onClick={() => onSubmit()}>Send invite via Email / Phone number</button>*/}
                        {/*    : */}
                        {/*    <button type="button" class="btn save-invitebtn mal-0" data-dismiss="modal" onClick={()=>onClose()}>Ok</button>*/}
                        {/*}*/}
                    </div>
                    </form>
                </div>
            </div>  
        </>
    )
}

export const RemoveAthletePop = ({onClose, onSubmit, selectedRemoveAthlete, removeAthleteMessage, athleteDetails}) =>{
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    return(
        <>
            <div class="modal verifymodal" style={{display:"block", position:"initial", overflowY:"auto"}} id="RemoveAllAthletesModal">
                <div class="modal-dialog modal-dialog-centered m-0" role="document">
                    <form class="modal-content">
                    <div class="modal-header border-none">
                        <h5 class="modal-title">{selectedRemoveAthlete ? "Remove All" : "Remove "+athleteDetails?.athleteFullName}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" onClick={()=>onClose()}>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <p>{removeAthleteMessage}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn blue" data-dismiss="modal" disabled={isSubmitDisabled} onClick={() => { setIsSubmitDisabled(true); onSubmit() }}>Yes</button>
                        <button type="button" class="btn cancel" data-dismiss="modal" onClick={() => onClose()}>Cancel</button>
                    </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export const AssignedAdvisorPopup = ({onClose, onSubmit}) =>{
    const [isAssAdvisorOkDisabled, setIsAssAdvisorOkDisabled] = useState(false);
    return(
        <>
            <div className="modal verifymodal" style={{display:"block", position:"initial", overflowY:"auto"}}>
                <div className="modal-dialog modal-dialog-centered m-0" role="document">
                    <form className="modal-content">
                        <div className="modal-header border-none">
                            <h5 className="modal-title">Assign Advisor</h5>
                            <button type="button" className="close"  data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={()=>onClose()}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                Advisor will be assigned to only those athletes who have not been assigned any advisor.
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button"
                                    onClick={(e) => {setIsAssAdvisorOkDisabled(true); onClose(); onSubmit(e)}}
                                    className="btn blue"
                                    disabled={isAssAdvisorOkDisabled}
                                    data-dismiss="modal">OK
                            </button>
                            <button style={{marginLeft:"10px", background: "#c0321f", color:"white"}} type="button"
                                    onClick={() => onClose()}
                                    className="btn blue"
                                    data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export const RemoveAdvisorEditAth = ({onClose, onSubmit}) =>{
    const [clickState, setClickState] = useState(1)
    return(
        <>
            <div class="modal verifymodal" style={{display:"block", position:"initial", overflowY:"auto"}} id="RemoveAllAthletesModal">
                <div class="modal-dialog modal-dialog-centered m-0" role="document">
                    <form class="modal-content">
                        <div class="modal-header border-none">
                            <h5 class="modal-title">Remove Advisor</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={()=>onClose()}>&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <p>Are you sure you want to remove advisor?</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn blue" data-dismiss="modal"
                                    disabled={clickState == 1 ? false : true}
                                     onClick={() =>{
                                         setClickState(clickState + 1)
                                         onSubmit()
                                     }}>Yes</button>
                            <button type="button" class="btn cancel" data-dismiss="modal" onClick={() => onClose()}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export const VerifyAndUnverifyPop = ({onClose, athData, verifyStatus, onSubmit}) =>{
    return(
        <div className="modal unsaved-modal" id="ChangePlanModal" style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <div className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">{verifyStatus ? "Verify" : "Unverify"} profile</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group" style={{textAlign:"left"}}>
                        <label className="name">{!!athData?.athleteFullName ? getCamelCase(athData?.athleteFullName) : ""}</label>
                       </div>
                        <div className="icon-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="66.262" height="66.262"
                                 viewBox="0 0 66.262 66.262">
                                <g id="Group_1948" data-name="Group 1948" transform="translate(3472 -2594)">
                                    <path id="Path_2192" data-name="Path 2192"
                                          d="M36.006,7.768A28.244,28.244,0,0,1,55.977,55.977,28.244,28.244,0,0,1,16.035,16.035,28.046,28.046,0,0,1,36.006,7.768m0-4.393A32.631,32.631,0,1,0,68.637,36.006,32.626,32.626,0,0,0,36.006,3.375Z"
                                          transform="translate(-3474.875 2591.125)" fill="#fdb944"
                                          stroke="#fff" stroke-width="1"/>
                                    <g id="Group_1947" data-name="Group 1947"
                                       transform="translate(-0.011 -0.016)">
                                        <path id="Path_2211" data-name="Path 2211"
                                              d="M16.5,33.309a2.62,2.62,0,0,0,5.24,0V17.59a2.62,2.62,0,0,0-5.24,0Z"
                                              transform="translate(-3458.489 2596.046)" fill="#fdb944"/>
                                        <path id="Path_2210" data-name="Path 2210"
                                              d="M19.12,14.316a2.62,2.62,0,1,1,2.62-2.62A2.62,2.62,0,0,1,19.12,14.316Z"
                                              transform="translate(-3458.489 2627.954)" fill="#fdb944"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className="content-box">
                            <p>Make profile as {verifyStatus ? "verified" : "unverified"} profile?</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                                className="btn lightblue"
                                data-dismiss="modal"
                                onClick={()=>onSubmit()}
                        >Yes</button>
                        <button type="button" className="btn" data-dismiss="modal"
                                onClick={()=>onClose()}
                        >Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const SelectedVerifyAndUnverifyPop = ({onClose, onSubmit}) =>{
    return(
        <div className="modal unsaved-modal" id="ChangePlanModal" style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <div className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Unverified profile</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="icon-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="66.262" height="66.262"
                                 viewBox="0 0 66.262 66.262">
                                <g id="Group_1948" data-name="Group 1948" transform="translate(3472 -2594)">
                                    <path id="Path_2192" data-name="Path 2192"
                                          d="M36.006,7.768A28.244,28.244,0,0,1,55.977,55.977,28.244,28.244,0,0,1,16.035,16.035,28.046,28.046,0,0,1,36.006,7.768m0-4.393A32.631,32.631,0,1,0,68.637,36.006,32.626,32.626,0,0,0,36.006,3.375Z"
                                          transform="translate(-3474.875 2591.125)" fill="#fdb944"
                                          stroke="#fff" stroke-width="1"/>
                                    <g id="Group_1947" data-name="Group 1947"
                                       transform="translate(-0.011 -0.016)">
                                        <path id="Path_2211" data-name="Path 2211"
                                              d="M16.5,33.309a2.62,2.62,0,0,0,5.24,0V17.59a2.62,2.62,0,0,0-5.24,0Z"
                                              transform="translate(-3458.489 2596.046)" fill="#fdb944"/>
                                        <path id="Path_2210" data-name="Path 2210"
                                              d="M19.12,14.316a2.62,2.62,0,1,1,2.62-2.62A2.62,2.62,0,0,1,19.12,14.316Z"
                                              transform="translate(-3458.489 2627.954)" fill="#fdb944"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className="content-box">
                            <p>Do you want to verify all the selected unverified <br/>profile?</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                                className="btn lightblue"
                                data-dismiss="modal"
                                onClick={()=>onSubmit()}
                        >Yes</button>
                        <button type="button" className="btn" data-dismiss="modal"
                                onClick={()=>onClose()}
                        >Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const AllVerifyAndUnverifyPop = ({onClose, onSubmit}) =>{
    return(
        <div className="modal unsaved-modal" id="ChangePlanModal" style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <div className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Unverified profile</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="icon-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="66.262" height="66.262"
                                 viewBox="0 0 66.262 66.262">
                                <g id="Group_1948" data-name="Group 1948" transform="translate(3472 -2594)">
                                    <path id="Path_2192" data-name="Path 2192"
                                          d="M36.006,7.768A28.244,28.244,0,0,1,55.977,55.977,28.244,28.244,0,0,1,16.035,16.035,28.046,28.046,0,0,1,36.006,7.768m0-4.393A32.631,32.631,0,1,0,68.637,36.006,32.626,32.626,0,0,0,36.006,3.375Z"
                                          transform="translate(-3474.875 2591.125)" fill="#fdb944"
                                          stroke="#fff" stroke-width="1"/>
                                    <g id="Group_1947" data-name="Group 1947"
                                       transform="translate(-0.011 -0.016)">
                                        <path id="Path_2211" data-name="Path 2211"
                                              d="M16.5,33.309a2.62,2.62,0,0,0,5.24,0V17.59a2.62,2.62,0,0,0-5.24,0Z"
                                              transform="translate(-3458.489 2596.046)" fill="#fdb944"/>
                                        <path id="Path_2210" data-name="Path 2210"
                                              d="M19.12,14.316a2.62,2.62,0,1,1,2.62-2.62A2.62,2.62,0,0,1,19.12,14.316Z"
                                              transform="translate(-3458.489 2627.954)" fill="#fdb944"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className="content-box">
                            <p>Do you want to verify all the filtered unverified <br/>profile?</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                                className="btn lightblue"
                                data-dismiss="modal"
                                onClick={()=>onSubmit()}
                        >Yes</button>
                        <button type="button" className="btn" data-dismiss="modal"
                                onClick={()=>onClose()}
                        >Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


