export const saveClgTagUnTag = `
mutation saveCollegetag($obj: CollegetagInput!){
  saveCollegetag(obj:$obj){
    id
  }
}
`
export const delClgTag = (id)=>`
mutation{
  CollegetagDeleteById(id: ${id})
}
`
export const updateUserClg = (data)=>`
mutation {
  UsercollegeUpdateAll(where:{collegeid:${parseInt(data?.collegeid)},userId:${parseInt(data?.userid)}},
   data:{istag:${data?.istag}}) 
  }
`