import React from 'react'
import '../header/assets/css/footer.scss'
export const AdminFooter = () => {
    return (
        <div className="site-footer">
            <div className="container">
                <p className="copyright-text">© SportsForce 2023</p>
            </div>
        </div>
    )
}