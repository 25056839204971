import {Route} from "react-router-dom";
import DashboardHeader from "../components/header/DashboardHeader";
import {SideBar} from "../components/header/adminSideBar";
import {AdminFooter} from "../components/header/adminFooter";
import {AdminHeader} from "../components/header/adminHeader";
import React from "react";
import {getAuthToken, getUserInfo} from "../utils/constant";
import {WrapperComponent} from "./wrapperComponent";
//check auth token in localstorage
export const isAuthenticate ={
    isLoggedIn: !!localStorage.getItem("authToken_Admin_Advisor") ? true : false,
    onAuthentication(){
        this.isLoggedIn = true
    },
    onLoginOut(){
        this.isLoggedIn = false
    },
    getLoginStatus(){
        return this.isLoggedIn;
    },
    redirect(props){
        props.history.push('/')
    }
}

export const isAdmin = {
    isAdmin : JSON.parse(localStorage.getItem("user_Admin_Advisor"))?.role == "ADMIN" ? true : false,
    getAdminStatus(){
        return this.isAdmin;
    },
    redirect(props){
        props.history.push('/')
    }
}

export const isAdvisor = {
    isAdvisor : JSON?.parse(localStorage.getItem("user_Admin_Advisor"))?.role == "ADVISORS" ? true : false,
    getAdvisorStatus(){
        return this.isAdvisor;
    },
    redirect(props){
        props.history.push('/')
    }
}

//routes for after login
export const AdminRoutes = ({components:Component , ...props}) =>{
    return(
        <Route path={props.path} render={(props)=>
            // isAuthenticate.getLoginStatus()
                getAuthToken() && getUserInfo()?.role == "ADMIN"
         ?
            <WrapperComponent>
                    <SideBar/>
                    <Component {...props}/>
                    {/*<AdminFooter/>*/}
            </WrapperComponent>
            :
            isAuthenticate.redirect(props)
        }>
        </Route>
    )
}

//routes for after login
export const AdvisorRoutes = ({components:Component , ...props}) =>{
    return(
        <Route path={props.path} render={(props)=>(
            // isAuthenticate.getLoginStatus()
            // && isAdvisor.getAdvisorStatus()
            getAuthToken() && getUserInfo()?.role == "ADVISORS"
        ) ?
            ( <>
                    {/*<DashboardHeader/>*/}
                    <WrapperComponent>
                        <SideBar/>
                        <Component {...props}/>
                    </WrapperComponent>
                </>
            )
            :
            isAuthenticate.redirect(props)
        }>
        </Route>
    )
}