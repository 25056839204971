import ENV from "../../../config";
import {getThumbnail, typeConvert} from '../../common/getThumbnail'
import {VideoPop} from '../../common/vidPopUp'
import {useState} from 'react'
import {vidCountIncre} from "../../common/flameAPI";
import {getUserInfo} from '../../../utils/constant'
const UploadedVideoReview = (props) => {
  const [vidID, setVidID] = useState(null)//this state is for videoPopup
  const [isVidPop, setIsVidPop] = useState(false)//this state is for videoPopup
  const [isLink, setIsLink] = useState(false)//this state is for videoPopup
    const openUrlVideo = (data) => {
      if (data.isvideoorlink === 2) {
        // window.open(data.videoname, "_blank");
        //below func it for opening the ifram modal 1/8/2022 change doc
        setVidID(data?.videoname)
        setIsLink(true)
        setTimeout(()=>{
          setIsVidPop(true)
        },500)
      }else{
        //below func it for opening the video modal 1/8/2022 change doc
        setVidID(`${ENV.DOWNLOAD_URL + "/" + data?.videoname}`)
        setIsLink(false)
        setTimeout(()=>{
          setIsVidPop(true)
        },500)
      }
      if (!!data?.photovideoid){
        const finalObj = {
          id: data?.photovideoid ?? 0,
          userid : data?.userid ?? 0,
          data
        }
        vidCountIncre(finalObj, getUserInfo()?.role == "ADMIN" ? true : false)
      }
    };
    return (
        <>
      <div
        className="modal fade common-modal form-modal stats-uploaded-video show"
        id="StatsVideoUploadedModal"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Photo / Video</h5>
              <button type="button" className="close" onClick={props.onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="content-box">
                <div className="item">
                  <div className="img-box stats-video-thumbnail" style={{textAlign: "center"}}>
                    {props.metricData.isvideoorlink === 1 ? (
                        <>
                      <video
                      //     controls
                      // poster={getThumbnail(props.metricData?.videoname, props.metricData, props?.page)}
                      >
                        <source
                          src={`${ENV.DOWNLOAD_URL + "/" + typeConvert(props.metricData?.videoname, props?.page)+"#t=0.2"
                            }`}
                          alt="video"
                        />
                      </video>
                          <div className="video-play-button">
                            <div className="video-play-inner">
                              <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    openUrlVideo(props.metricData);
                                  }}
                              >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18.172"
                                    height="27.258"
                                    viewBox="0 0 18.172 27.258"
                                >
                                  <path
                                      className="a"
                                      d="M13.629,0,27.258,18.172H0Z"
                                      transform="translate(18.172) rotate(90)"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                      </>
                    ) : (
                      props.metricData.isvideoorlink === 3 ?
                        <img src={`${ENV.DOWNLOAD_URL + "/" + props.metricData?.videoname
                          }`} height="300px" width="300px"></img>
                        :
                        <>
                          <video
                              poster={getThumbnail(props.metricData?.videoname, props.metricData, props?.page)}
                          >
                            <source
                              src="http://clips.vorwaerts-gmbh.de/VfE_html5.mp4"
                              type="video/mp4"
                            />
                            <source
                              src="http://clips.vorwaerts-gmbh.de/VfE_html5.ogg"
                              type="video/ogg"
                            />
                          </video>
                          <div className="video-play-button">
                            <div className="video-play-inner">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  openUrlVideo(props.metricData);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18.172"
                                  height="27.258"
                                  viewBox="0 0 18.172 27.258"
                                >
                                  <path
                                    className="a"
                                    d="M13.629,0,27.258,18.172H0Z"
                                    transform="translate(18.172) rotate(90)"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer" style={{justifyContent: "center"}}>
              <button
                type="button"
                className="btn green"
                data-dismiss="modal"
                onClick={props.onClose}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
          <VideoPop
              isTestSingle={isVidPop}
              setIsTestSingle={()=>setIsVidPop(false)}
              vidID={vidID}
              isToken={props?.isAuth == 1 ? 1 : 0}
              isLink={isLink}
          />
          </>
    );
  };

  export default UploadedVideoReview;