import { useEffect, useState } from "react";
import { getSlashFormattedDate, getUTCTimeDateOverall } from "../../../utils/dateTimeFormatter";
import OwlCarousel from "react-owl-carousel";
import {getCamelCase} from "../../common/getCamelCase";
export const ProfileStatsGraphModal = (props) => {
    const [statmatrixdetails, setStatmatrixdetails] = useState([]);
    useEffect(() => {
        if (props?.tabData?.FullstatsData?.length > 0) {
            const StatmatrixdetailsT = props?.tabData?.FullstatsData || []
            setStatmatrixdetails(StatmatrixdetailsT);
        }
    }, [props?.tabData]);

    const getSelectedTabYAxis = () => {
        let maxVal = props?.tabData ? (props?.tabData?.statsmatrixId == 3 ? parseFloat(+props?.tabData?.maximumValue || 0) + 500 : parseFloat(+props?.tabData?.maximumValue || 0)) : null
        let minVal = parseFloat(+props?.tabData?.minimumvalue) || 0;
        let yAxis = [];
        if ((!minVal && isNaN(minVal)) || (!maxVal && isNaN(maxVal))) {
            minVal = 10;
            maxVal = 100;
        }
        if (parseFloat(maxVal) < 10) {
            if (props?.tabData?.statsmatrixId == 2){
                for (let i = maxVal; i > minVal; i--) {
                    yAxis.push(parseInt(i));
                }
            }else{
                for (let i = maxVal; i >= minVal; i--) {
                    yAxis.push(parseInt(i));
                }
            }
        }
        else {
            if (props?.tabData?.statsmatrixId == 2){
                for (let i = maxVal; i > minVal; i-=2) {
                    yAxis.push(parseInt(i));
                }
            }
            else if (parseFloat(maxVal) % 2) {
                for (let i = maxVal; i >= minVal; i -= maxVal / 10) {
                    yAxis.push(parseInt(i));
                }
            }
            else {
                for (let i = maxVal; i >= minVal; i -= maxVal / 10) {
                    yAxis.push(parseInt(i));
                }
            }
        }
        return yAxis.map((value, index) => (
            <li className="item" key={index}>
                <span>{value}</span>
            </li>
        ));
    };

    const showGraph = (metricData) => {
        var percentage = 0;
        let maxval = props?.tabData ? (props?.tabData?.statsmatrixId == 3 ? parseFloat(props?.tabData?.maximumValue)+500 : parseFloat(props?.tabData?.maximumValue || 0)) : null
        let maxVal = Math.floor(maxval);
        if (props?.tabData?.statsmatrixId == 2) {
            let resultData = (metricData.jumpincentimeter * 0.0328084)
            const ftValue =  metricData?.tabName == "Broad Jump" ?
                Math.round(resultData * 7.5) : Math.round(resultData * 17)
            percentage = parseFloat(ftValue)
        }
        else {
            percentage = Math.round((parseFloat(metricData?.result) / parseFloat(maxVal)) * 100);
        }
        return (
            <div className="item" key={metricData?.id}>
                <div
                    className="progress-bar"
                    style={{
                        height:
                        `${percentage != "" ? (isNaN(percentage) ? 0 : (percentage <= 100 ? (percentage == metricData?.result ? percentage + 1 : percentage) : 100)) : ((parseInt(metricData?.result) < 5 ? parseInt(metricData?.result) : (percentage == 0 ? percentage + 1 : 100)))
                        }%`,
                        backgroundColor: "#1CB79E",
                        background: "opaque",
                    }}
                >
                    {metricData.verified === 1 ? (
                        <div className="icon-box" style={{ top: "-20px" }}>
                            <span className="iconbox custom-tooltip">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14.449"
                                    height="16.117"
                                    viewBox="0 0 14.449 16.117"
                                >
                                    <g transform="translate(-2.872 -1.125)">
                                        <path
                                            className="a"
                                            d="M10.1,1.125a14.418,14.418,0,0,0-3.022.564q-1.5.4-2.978.876A1.563,1.563,0,0,0,3.018,3.837,13.013,13.013,0,0,0,5.562,13.9a12.025,12.025,0,0,0,2.6,2.471A7.481,7.481,0,0,0,9.241,17a2.232,2.232,0,0,0,.855.242A2.225,2.225,0,0,0,10.951,17a7.481,7.481,0,0,0,1.082-.63,12.024,12.024,0,0,0,2.6-2.471A13.017,13.017,0,0,0,17.175,3.837,1.563,1.563,0,0,0,16.1,2.565q-1.479-.473-2.978-.876A14.416,14.416,0,0,0,10.1,1.125Zm3.066,6.827a.538.538,0,0,0-.761-.761L9.559,10.035,8.327,8.8a.538.538,0,0,0-.761.761l1.612,1.612a.537.537,0,0,0,.761,0Z"
                                            transform="translate(0)"
                                        ></path>
                                    </g>
                                </svg>
                                <span class="tooltip-data">Coach Verified</span>
                            </span>
                        </div>
                    ) : metricData.verified === 2 ? (
                        <div className="icon-box red" style={{ top: "-20px" }}>
                            <span className="iconbox red custom-tooltip">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14.449"
                                    height="16.117"
                                    viewBox="0 0 20 22.309"
                                >
                                    <g transform="translate(-2.872 -1.125)">
                                        <path
                                            className="a"
                                            d="M12.872,1.125a19.957,19.957,0,0,0-4.183.781q-2.075.557-4.123,1.212A2.163,2.163,0,0,0,3.074,4.879,18.012,18.012,0,0,0,6.6,18.807a16.645,16.645,0,0,0,3.595,3.421,10.354,10.354,0,0,0,1.5.872,3.09,3.09,0,0,0,1.184.334,3.08,3.08,0,0,0,1.184-.334,10.355,10.355,0,0,0,1.5-.872,16.644,16.644,0,0,0,3.6-3.421A18.018,18.018,0,0,0,22.67,4.879a2.163,2.163,0,0,0-1.492-1.761q-2.047-.655-4.123-1.212A19.954,19.954,0,0,0,12.872,1.125Zm4.244,9.45a.745.745,0,0,0-1.053-1.053l-3.935,3.937-1.7-1.706A.745.745,0,0,0,9.37,12.806L11.6,15.036a.744.744,0,0,0,1.053,0Z"
                                        />
                                    </g>
                                </svg>
                                <span class="tooltip-data">SportsForce Verified</span>
                            </span>
                        </div>
                    ) : metricData.verified === 3 ? (
                        <div className="icon-box" style={{ top: "-20px" }}>
                            <span className="iconbox custom-tooltip">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14.449"
                                height="16.117"
                                viewBox="0 0 14.449 16.117"
                                >
                                <g transform="translate(-2.872 -1.125)">
                                    <path
                                    className="a"
                                    d="M10.1,1.125a14.418,14.418,0,0,0-3.022.564q-1.5.4-2.978.876A1.563,1.563,0,0,0,3.018,3.837,13.013,13.013,0,0,0,5.562,13.9a12.025,12.025,0,0,0,2.6,2.471A7.481,7.481,0,0,0,9.241,17a2.232,2.232,0,0,0,.855.242A2.225,2.225,0,0,0,10.951,17a7.481,7.481,0,0,0,1.082-.63,12.024,12.024,0,0,0,2.6-2.471A13.017,13.017,0,0,0,17.175,3.837,1.563,1.563,0,0,0,16.1,2.565q-1.479-.473-2.978-.876A14.416,14.416,0,0,0,10.1,1.125Zm3.066,6.827a.538.538,0,0,0-.761-.761L9.559,10.035,8.327,8.8a.538.538,0,0,0-.761.761l1.612,1.612a.537.537,0,0,0,.761,0Z"
                                    transform="translate(0)"
                                    ></path>
                                </g>
                                </svg>
                                <span class="tooltip-data">Coach Verified</span>
                            </span>
                            <span className="iconbox red custom-tooltip">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14.449"
                                height="16.117"
                                viewBox="0 0 20 22.309"
                                >
                                <g transform="translate(-2.872 -1.125)">
                                    <path
                                    className="a"
                                    d="M12.872,1.125a19.957,19.957,0,0,0-4.183.781q-2.075.557-4.123,1.212A2.163,2.163,0,0,0,3.074,4.879,18.012,18.012,0,0,0,6.6,18.807a16.645,16.645,0,0,0,3.595,3.421,10.354,10.354,0,0,0,1.5.872,3.09,3.09,0,0,0,1.184.334,3.08,3.08,0,0,0,1.184-.334,10.355,10.355,0,0,0,1.5-.872,16.644,16.644,0,0,0,3.6-3.421A18.018,18.018,0,0,0,22.67,4.879a2.163,2.163,0,0,0-1.492-1.761q-2.047-.655-4.123-1.212A19.954,19.954,0,0,0,12.872,1.125Zm4.244,9.45a.745.745,0,0,0-1.053-1.053l-3.935,3.937-1.7-1.706A.745.745,0,0,0,9.37,12.806L11.6,15.036a.744.744,0,0,0,1.053,0Z"
                                    />
                                </g>
                                </svg>
                                <span class="tooltip-data">SportsForce Verified</span>
                            </span>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };

    const showGraphLabel = (metricData) => {
        let heightinFeet = metricData?.heightinfeet || 0
        let heightinInch = metricData?.heightininch || 0
        return (
            <div className="item" key={metricData.id}>
                <div className="detial-box">
                <h4>{metricData.jumpincentimeter ? metricData.jumpincentimeter + "cm" : ""}</h4>
                    <h4>
                        {heightinFeet || ""}' {heightinInch || ""}" / {metricData.weight} lbs
                    </h4>
                    <h5>{getMetricLabel(metricData)}</h5>
                    {metricData.verified ? (
                        <p>VERIFIED <br />{!!metricData?.testdate ? getUTCTimeDateOverall(metricData?.testdate ?? "") : ""}</p>
                    ) : (
                        <p>UNVERIFIED <br />{!!metricData?.testdate ? getUTCTimeDateOverall(metricData?.testdate ?? "") : ""}</p>
                    )}
                </div>
            </div>
        );
    };

    const getMetricLabel = (metricData) => {
        switch (metricData.statsmatrixId) {
            case 1:
                return `${metricData.result} ${metricData.unit}`;
            case 2:
                return `${metricData?.jumpinfeet || 0}' ${metricData?.jumpininch || 0}"`;

            case 3:
                return `${metricData.result}`;
            default:
                return `${metricData.result} ${metricData.unit}`;
        }
    };

    return (
        <div
            className="modal fade common-modal stats-modal common-slide-modal show"
            id="StatsGraphModal"
            style={{display:"block", position:"initial", overflowY:"auto"}}
        >
            <div className="modal-dialog modal-dialog-centered m-0">
                <div className="modal-content">
                    <div className="modal-header" style={{ cursor: "pointer", padding: "20px 0px 20px 0px" }}>
                        <h5 className="modal-title">{props?.tabData?.tabName || ""}</h5>
                        <button type="button" className="close" onClick={props.onClose}>
                            <span aria-hidden="true" style={{ cursor: "pointer" }}>&times;</span>
                        </button>
                    </div>
                    {statmatrixdetails?.length > 0 ? (
                        <OwlCarousel
                            className="modal-body stats-graph-slider owl-carousel"
                            key={statmatrixdetails?.length}
                            loop={false}
                            margin={5}
                            items="1"
                            nav
                        >
                            {statmatrixdetails.map((item, index) => {
                                return (
                                    <div className="item" key={index}>
                                        <div
                                            className="stats-graph-box"
                                            style={{ marginTop: "21px", marginLeft: "10px" }}
                                        >
                                            <div className="graph-inner">
                                                <ul className="graph">{getSelectedTabYAxis()}</ul>
                                                <div className="graph-flex-bar">{showGraph(item)}</div>
                                            </div>
                                            <div className="graph-progress-bar">
                                                {showGraphLabel(item)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </OwlCarousel>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export const WebLinkProfileModal = ({onClose, webLinkData}) =>{
    return(
        <div
            className="modal fade common-modal form-modal stats-modal show"
            id="WebLinkModal"
            aria-modal="true"
            role="dialog"
            style={{display:"block", position:"initial", overflowY:"auto"}}
        >
            <div className="modal-dialog modal-dialog-centered editprofile-common-form m-0">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title"
                        style={{fontSize: "1.125rem", fontWeight:"500"}}
                        >{!!webLinkData?.tabName ? `${getCamelCase(webLinkData?.tabName)} - Web link` : ""}</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
          <span aria-hidden="true" onClick={() =>{
              onClose()
          }}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{border:"none"}}>
                        <div className="fieldBox mb-0">
                            <p style={{lineBreak:"anywhere"}}><a href={void 0}
                                  style={{cursor:"pointer", color: "#00b9ff"}}
                            onClick={()=>window.open((webLinkData?.weblink?.includes('http://') || webLinkData?.weblink?.includes('https://'))  ?
                                webLinkData?.weblink : `http://${webLinkData?.weblink}`,'_blank')}
                            >{!!webLinkData?.weblink ? webLinkData?.weblink : ""}</a></p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type={'button'}
                            className="btn green"
                            data-dismiss="modal"
                            data-toggle="modal"
                            onClick={() =>{onClose()
                            }}
                        >
                            Done
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}