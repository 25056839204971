import React, { useEffect, useRef, useState } from "react";
import '../header/assets/css/dashboard.scss'
import '../header/assets/css/stylesheet.scss'
import {AdminHeader} from "../header/adminHeader";
import {AdminFooter} from "../header/adminFooter";
import TeamLogo from '../../assets/img/TeamLogo.png'
import service from '../../services'
import {getCamelCase} from '../../components/common/getCamelCase'
import moment from "moment";
import {Tooltip} from '@material-ui/core'
import BackdropLoader from "../common/Loader";
import Pagination from "react-js-pagination";
import {getUserInfo} from "../../utils/constant";
import swal from 'sweetalert'
import {Dialog} from "@material-ui/core";
import {TagPop} from "../common/statsPopup";
import services from "../../services";
import {flameAPI, UpdateUserCollegeTable} from "../common/flameAPI";
import AdvisorHeader from "../header/advisorHeader";
import ENV from "../../config";

export const TagSchlClg = (props) =>{
    const athID = !!props?.match?.params?.clgID ? +props?.match?.params?.clgID : 0,
          athName =  !!props?.match?.params?.athName ? props?.match?.params?.athName : 0,
          [tagClgList, setTagClgList] = useState([]),
          [isLoading, setIsLoading] = useState(false),
          [tagValues, setTagValues] = useState({
              athleteid: athID,
              isfav: 1,
              sortby: 1,
              order: "asc",
              limit: 10,
              offset: 0
          }),
          [totalCount, setTotalCount] = useState(0),
          [pageNum, setPageNum] = useState(1),
          [currentPage, setCurrentPage] = useState(1),
          [tagPopOpen, setTagPopOpen] = useState(false),
          [collegeData, setCollegeData] = useState([]),
          [fields, setFields] = React.useState({
          collegeName: {
            id: 0,
            collegename: '',
             }
           }),
          [isError, setIsError] = useState(false)
    ;
    useEffect(()=>{
       getAthleteTagList(tagValues)
        loadColleges()
    },[])
    const getAthleteTagList = async (value) =>{
        setIsLoading(true)
        const res = await service.location.tagClgAPI(value)
        if(res?.status == 200 && res?.data?.response?.status == 200){
        const tagData = res?.data?.response?.data?.length > 0 ? res?.data?.response?.data : []
            setTagClgList(tagData)
            setTotalCount(!!res?.data?.response?.totalCount ? res?.data?.response?.totalCount : 0)
            setIsLoading(false)
        }else{
            setTagClgList([])
            setIsLoading(false)
        }
        },
    loadColleges =  async () => {
        const body = {
            collegeName: 0
        };
        const _res =  await services.location.getAllCollegeData(body);
        if(_res?.status == 200){
            let collegelist = _res?.data?.data;
            //sort the college list on basis of college name a-z.
            // if (collegelist.length > 0) {
            //     collegelist.sort(function(a, b){
            //         if(a.collegename < b.collegename) { return -1; }
            //         if(a.collegename > b.collegename) { return 1; }
            //         return 0;
            //     });
            // }
            setCollegeData(collegelist);
        }
    },
    onHandleChange = (e, field) =>{
        const cloneState = {...tagValues}
        if (field == "sortby"){
            cloneState[field] = parseInt(e?.target.value)
            cloneState['limit'] = 10
            cloneState['offset'] = 0
            setCurrentPage(1)
            setPageNum(1)
            getAthleteTagList(cloneState)
        }
        else if (field == "isfav"){
            cloneState[field] = parseInt(e)
            getAthleteTagList(cloneState)
        }
        else if(field == "limit"){
            cloneState[field] = parseInt(e?.target?.value)
            getAthleteTagList(cloneState)
        }
        setTagValues(cloneState)
        },
        handlePagination = (page_num) =>{
            const cloneState = {...tagValues },
                finalOffset = (page_num - 1) * cloneState['limit']//offset formula is page number * limit
            cloneState['offset'] = Math.abs(finalOffset)
            setCurrentPage(page_num)
            setTagValues(cloneState)
            getAthleteTagList(cloneState)
        },
        adminTagApi = async (data, type) =>{
            const body = type == "onTagClick" ?
                {
                    "userId": athID,
                    "title": "collegetaggedbysf",
                    "role": "ATHLETES",
                    "msgrecieverids":[],
                    "collegename": data?.collegename ?? ""
                }
                :
                {
                    "userId": athID,
                    "title": "collegetaggedbysf",
                    "role": "ATHLETES",
                    "msgrecieverids":[],
                    "collegename": data?.collegeName?.collegename ?? ""
                }
                const res = await service.location.adminToAdvisorNoty(body)
            if (res?.status != 200){
            }
        },
        tagUntagClg = async (isTag, data) =>{
        if (isTag){
          const finalObj={
              obj:{
                  collegeid: data?.collegeId,
                  userid: data?.userId,
                  id: data?.tagid ?? 0,
                  istagged: 1
              }
          }
          const _res = await service.location.tagUntagClg(finalObj)
            if (_res?.status == 200){
                const updateObj = {
                    collegeid: data?.collegeId ?? 0,
                    userid: data?.userId,
                    istag: 1
                }
                const resUpdate = await UpdateUserCollegeTable(updateObj)
                if (resUpdate){
                    swal({text: "College Tagged Successfully", icon: "success"})
                    getAthleteTagList(tagValues)
                    flameAPI("collegetagged", athID, getUserInfo()?.id,false, getUserInfo()?.role, true , data)
                   if (getUserInfo()?.role == "ADMIN") {
                       adminTagApi(data, "onTagClick")
                   }
                }
            }else{
                swal({text: "College Tagged Failed", icon: "error"})
            }
        }else{
            swal({
                text: `Do you want to untag the college for athlete ${athName}?`,
                icon: 'warning',
                buttons: {
                    confirm: { text: "Yes" },
                    cancel: "Cancel",
                },
            }).then(async (res)=>{
                if (res){
                    const _res = await service.location.deltagUntagClg(data?.tagid?? 0)
                    if (_res?.status == 200){
                        const updateObj = {
                            collegeid: data?.collegeId ?? 0,
                            userid: data?.userId,
                            istag: 0
                        }
                        const resUpdate = await UpdateUserCollegeTable(updateObj)
                        if (resUpdate) {
                            swal({text: "College Untagged Successfully", icon: "success"})
                            getAthleteTagList(tagValues)
                        }else{
                            swal({text: "All User College Update Failed", icon: "success"})
                        }
                    }else{
                        swal({text: "College Untagged Failed", icon: "error"})
                    }
                }
            })
        }
      },
        handleOnClgChange = (field, e) =>{
        setIsError(false)
            let fieldsT = fields;
            fieldsT[field] = e ? (e.target ? e.target.value : e) : e;
            setFields({
                ...fieldsT,
            });
        },
        onSaveTag = async (data) =>{
        if (!!data?.collegeName?.id){
            const finalObj={
                obj:{
                    collegeid: data?.collegeName?.id ?? "",
                    userid: athID,
                }
            }
            const _res = await service.location.tagUntagClg(finalObj)
            if (_res?.status == 200 && !!_res?.data?.errors){
                swal({text: !!_res?.data?.errors?.[0]?.message?.msg ?
                        _res?.data?.errors?.[0]?.message?.msg : "College Already Tagged"
                    , icon: "error"})
            }
        else if (_res?.status == 200 && !_res?.data?.errors){
                    const updateObj = {
                        collegeid: data?.collegeName?.id ?? 0,
                        userid: athID,
                        istag: 1
                    }
                    const resUpdate = await UpdateUserCollegeTable(updateObj)
                if (resUpdate){
                    getAthleteTagList(tagValues)
                    setTagPopOpen(false)
                    setFields({collegeName: {id: 0, collegename: ''}})
                    swal({text: "College Tagged Successfully", icon: "success"})
                    data['collegeId'] = data?.collegeName?.id ?? 0
                    flameAPI("collegetagged", athID, getUserInfo()?.id,false, getUserInfo()?.role, true, data)
                    if (getUserInfo()?.role == "ADMIN") {
                        adminTagApi(data, "onTagPop")
                    }
                }else{
                    swal({text: "College Tagged Failed", icon: "error"})
                }
            }else{
                swal({text: "API Failed", icon: "error"})
            }
        }else {
            setIsError(true)
        }
        },
        onPopCancel = () =>{
            setTagPopOpen(false)
            setIsError(false)
            setFields({collegeName: {id: 0, collegename: ''}})
        }
    return(
        <>
            <BackdropLoader open={isLoading}/>
            <div className="content">
                {getUserInfo()?.role == "ADVISORS"  && <AdvisorHeader title={!!athName ?  athName?.toUpperCase() : "ADVISOR"}/>}
                {getUserInfo()?.role == "ADMIN" && <AdminHeader title={!!athName ?  athName?.toUpperCase() : "ADMIN"}/>}
                <div className="dashboard">
                    <div className="filter-box tagged-filter">
                        <div className="search-box tab-width-auto">
                            <div className="tab-wrap">
                                <ul className="tabs">
                                    <li className={tagValues?.isfav == 1 ?  "current" : ""}
                                        onClick={()=>onHandleChange(1, "isfav")}
                                    >Favorite Colleges</li>
                                    <li className={tagValues?.isfav == 0 ?  "current" : ""}
                                        onClick={()=>onHandleChange(0, "isfav")}
                                    >Tagged Colleges</li>
                                </ul>
                            </div>
                        </div>
                        <div className="filters">
                            <h3 className="title" data-toggle="modal" data-target="#TagNewSchoolModal"
                            onClick={()=>setTagPopOpen(true)}
                            >Tag a new
                                college</h3>
                            <div className="sortFilter">
                                <label>
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12.163" height="15.204" viewBox="0 0 12.163 15.204">
                    <path id="bx-sort" d="M9.041,13.643H6L10.561,18.2V3H9.041ZM13.6,5.281V18.2h1.52V7.561h3.041L13.6,3Z"
                          transform="translate(-6 -3)" fill="#3e3e3e"></path>
                  </svg>
                </span>
                                    <span>Sort by</span>
                                </label>
                                <select value={tagValues?.sortby ?? 0}
                                onChange={(e)=>onHandleChange(e, "sortby")}
                                >
                                    <option value = { 0 }>Select</option>
                                    <option value = { 1 }>Rank</option>
                                    <option value = { 2 }>Category</option>
                                    <option value = { 3 }>Last Updated</option>
                                    <option value = { 4 }>Most Interested</option>
                                    <option value = { 5 }>Least Interested</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="white-box">
                        <div className="tab-contents tagged-tab-data">
                            <div id="tab1" className="tab-content current">
                                <div className="tagged-dataBox">
                                    {tagClgList?.length > 0 ? tagClgList?.map((tag, indx)=>{
                                        return(
                                            <div className="dataItem">
                                                <div className="dataFlex">
                                                    <div className="leftData">
                                                        {/*<div className="sort">*/}
                                                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="18.003"*/}
                                                        {/*         height="18.003" viewBox="0 0 18.003 18.003">*/}
                                                        {/*        <path id="move"*/}
                                                        {/*              d="M17.848,8.628,15.432,6.213a.527.527,0,0,0-.9.373v.7H10.717V3.471h.7a.527.527,0,0,0,.373-.9L9.374.154a.527.527,0,0,0-.746,0L6.213,2.57a.527.527,0,0,0,.373.9h.7V7.285H3.47v-.7a.527.527,0,0,0-.9-.373L.154,8.628a.527.527,0,0,0,0,.746L2.57,11.79a.527.527,0,0,0,.9-.373v-.7H7.285v3.815h-.7a.527.527,0,0,0-.373.9l2.416,2.416a.527.527,0,0,0,.746,0l2.416-2.416a.527.527,0,0,0-.373-.9h-.7V10.717h3.815v.7a.527.527,0,0,0,.9.373l2.416-2.416a.527.527,0,0,0,0-.746Z"*/}
                                                        {/*              transform="translate(0 0)" fill="#3e3e3e"/>*/}
                                                        {/*    </svg>*/}
                                                        {/*</div>*/}
                                                        <div className="count">#{tag?.collegerank ?? ""}</div>
                                                        <div className="data">
                                                            <div className="headFlex">
                                                                <div className="icon"><img
                                                                    src={!!tag?.collegelogo ? `${ENV?.DOWNLOAD_URL}/${tag?.collegelogo}` : TeamLogo} alt="teamLogo"/>
                                                                </div>
                                                                <div>
                                                                    <h5>{tag?.collegename ?? ''}</h5>
                                                                    <ul>
                                                                        <li><span>{tag?.collegedivisionname ?? ""}</span><span className="circle"></span>
                                                                        </li>
                                                                        <li><span>{`${getCamelCase(tag?.city ?? "")}${!!tag?.initials ?"," + " " + tag?.initials : ""}`}</span></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="stepperBox">
                                                                <div className= {(tag?.isfav == 1 ? 1 : tag?.target) ?  "item passed" : "item"}
                                                                     style={{cursor:"default"}}
                                                                >
                                                                    <span className="circle"></span>
                                                                    <span>Target</span>
                                                                </div>
                                                                <div className={tag?.contact == 1 ?  "item passed" : "item"}
                                                                style={{cursor:"default"}}
                                                                >
                                                                    <span className="circle"></span>
                                                                    <span>Contact</span>
                                                                </div>
                                                                <div className={tag?.interest == 1 ?  "item passed" : "item"}
                                                                     style={{cursor:"default"}}
                                                                >
                                                                    <span className="circle"></span>
                                                                    <span>Interest</span>
                                                                </div>
                                                                <div className={tag?.collegeactive == 1 ? "item passed" : "item"}
                                                                     style={{cursor:"default"}}
                                                                >
                                                                    <span className="circle"></span>
                                                                    <span>Active</span>
                                                                </div>
                                                                <div className={tag?.offer == 1 ? "item passed" : "item"} data-toggle="modal"
                                                                     data-target="#OfferModal"
                                                                     style={{cursor:"default"}}
                                                                >
                                                                    <span className="circle"></span>
                                                                    <span>Offer</span>
                                                                </div>
                                                                <div className={tag?.commit == 1 ? "item passed" : "item"} data-toggle="modal"
                                                                     data-target="#CommitmentModal">
                                                                    <span className="circle"></span>
                                                                    <span>Commit</span>
                                                                </div>
                                                                <div className={tag?.signed == 1 ? "item passed" : "item"} data-toggle="modal"
                                                                     data-target="#CommitmentModal">
                                                                    <span className="circle"></span>
                                                                    <span>Signed</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rightData">
                                                        <div className="actionBox">
                                                            <div className="badgeBox">
                                                                <a className={!!tag?.istagged && tag?.istagged == 1 ? "favourite" : ""} href={void 0}
                                                                onClick={()=>tagUntagClg(!!tag?.istagged && tag?.istagged == 1 ? false : true, tag)}
                                                                >
                                                                    <Tooltip
                                                                        placement={"top"}
                                                                    title={!!tag?.istagged && tag?.istagged == 1 ? "Untag College": "Tag College"}
                                                                    >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="27.533"
                                                                         height="27.533" viewBox="0 0 27.533 27.533">
                                                                        <g id="tag-black-shape"
                                                                           transform="translate(0.498 0.5)">
                                                                            <path id="Path_438" data-name="Path 438"
                                                                                  d="M25.887,14.1,13.365,1.594A5.424,5.424,0,0,0,11.579.464,5.411,5.411,0,0,0,9.529,0H2.244A2.155,2.155,0,0,0,.667.665,2.154,2.154,0,0,0,0,2.242V9.527a5.407,5.407,0,0,0,.464,2.049A5.219,5.219,0,0,0,1.6,13.345l12.522,12.54a2.145,2.145,0,0,0,1.576.648,2.243,2.243,0,0,0,1.594-.648l8.6-8.617a2.145,2.145,0,0,0,.648-1.576A2.244,2.244,0,0,0,25.887,14.1ZM7.191,7.189a2.161,2.161,0,0,1-1.585.657A2.243,2.243,0,0,1,3.365,5.6,2.243,2.243,0,0,1,5.606,3.363,2.243,2.243,0,0,1,7.848,5.6,2.159,2.159,0,0,1,7.191,7.189Z"
                                                                                  transform="translate(0 0)" fill="none"
                                                                                  stroke="#ff6624" stroke-width="1"/>
                                                                        </g>
                                                                    </svg>
                                                                    </Tooltip>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul className="UpdateFlexBox">
                                                        <li><label>Category :</label><span>{tag?.categoryName ?? ""}</span></li>
                                                        <li><label>Interest Level :</label><span>{tag?.interestlevelValue ?? ""}</span></li>
                                                        <li><label>Last Updated :</label><span>{!!tag?.lastUpdate ? moment(tag?.lastUpdate).format("MMM, DD YYYY") : ""}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    }) : "No College Found"}
                                </div>
                            </div>
                        </div>
                        <div className="pagination">
                            <div className="flex">
                                <div className="item perPage">
                                    <label>{totalCount} Records</label>
                                    <select value={tagValues?.limit}
                                    onChange={(e)=>onHandleChange(e, "limit")}
                                    >
                                        <option value={ 10 }>10</option>
                                        <option value = { 20 }>20</option>
                                        <option value = { 30 }>30</option>
                                        <option value = { 40 }>40</option>
                                        <option value = { 50 }>50</option>
                                    </select>
                                    <label>Records per page</label>
                                </div>
                                <div className="item pages">
                                    <Pagination
                                        innerClass='paging'
                                        itemClass=''
                                        linkClass=''
                                        activePage={currentPage}
                                        activeClass={"active"}
                                        itemsCountPerPage={tagValues?.limit || 10}
                                        totalItemsCount={totalCount}
                                        pageRangeDisplayed={5}
                                        onChange={handlePagination}
                                    />
                                </div>
                                <div className="item goto">
                                    <label>Go to page</label>
                                    <input type="number" value={pageNum}
                                           onChange={(e)=>((parseInt(e.target.value) <= 0) || (e.target.value == "")) ? "":
                                               setPageNum(parseInt(e?.target?.value))}
                                    />
                                        <button
                                            type={'button'}
                                            onClick={()=>handlePagination(pageNum)}
                                        >Go</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <AdminFooter/>
            </div>
            <Dialog open={tagPopOpen}
                    onClose={(event, reason)=>{
                        if (reason == "backdropClick" || reason == "escapeKeyDown"){
                            onPopCancel()
                        }
                    }}
            >
                <TagPop
                collegeData={collegeData}
                fields={fields}
                handleOnClgChange={handleOnClgChange}
                setTagOpen={()=>onPopCancel()}
                onSubmit = {()=>onSaveTag(fields)}
                isError = {isError}
                />
            </Dialog>
        </>
  )
}
