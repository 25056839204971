//for all notifications
export const notificationContentQuery = (body) => `
{
  allAdminnotifications(where:{adminid:${body.adminid},order:"id desc"},first:${body.first},last:${body.last}){
    totalCount
    Adminnotifications{
      id
      notificationcontent
      createdon
      isread
      athleteid
      adminnotificationathleteidmaprel{
        Athletes{
        athleteuseridmaprel{
            Userdata{
              sysgenid
            }
          }
          athletefirstname
          athletelastname
          onBoardingUserIdMap{
            Onboardings{
              profileimages
            }
          }
        }
      }
    }
  }
}
`
//for marking single notification as read
export const saveReadValue = (body) => `
mutation{
  saveAdminnotification(obj:{id:${body.id},isread:1}){
    id
    isread
  }
}
`
//for marking all notifications as read
export const markReadForAll = (body) => `
mutation{
  AdminnotificationUpdateAll(where:{adminid:${body.adminid}},data:{isread:1})
}
`
//for all unread notification content
export const notificationContentDyReadQuery = (body) => `
{
  allAdminnotifications(where:{adminid:${body.adminid}, isread:${body?.isread},order:"id desc"},first:${body.first},last:${body.last}){
    totalCount
    Adminnotifications{
      id
      notificationcontent
      createdon
      isread
      athleteid
      adminnotificationathleteidmaprel{
        Athletes{
          athletefirstname
          athletelastname
          onBoardingUserIdMap{
            Onboardings{
              profileimages
            }
          }
        }
      }
    }
  }
}
`
//for checking new notifications on bell icon
export const checkNewNotificationQuery = (body) => `
{
  allAdminnotifications(where:{adminid:${body.adminid},issee:0}){
    totalCount    
  }
}   
`
//for marking all notifications on bell icon
export const markNotificationQuery = (body) => `
  mutation{
    AdminnotificationUpdateAll(where:{adminid:${body.adminid}},data:{issee:1})
  }

`
/////advisor notification query

export const advisorNotificationContentQuery = (body) => `
{
  allAdvisornotifications(where:{advisorid:${body.advisorid},order:"id desc"},first:${body.first},last:${body.last}){
    totalCount
    Advisornotifications{
      id
      notificationcontent
      createdon
      isread
      athleteid
      advisornotificationadminidmaprel{
        Userdata{
          fullname
        }
      }
      advisornotificationathleteidmaprel{
        Athletes{
        athleteuseridmaprel{
            Userdata{
              sysgenid
            }
          }
          athletefirstname
          athletelastname
          onBoardingUserIdMap{
            Onboardings{
              profileimages
            }
          }
        }
      }
    }
  }
}
`
export const advisorUnreadNotificationContentQuery = (body) => `
{
  allAdvisornotifications(where:{advisorid:${body.advisorid}, isread:${body?.isread}, order:"id desc"},first:${body.first},last:${body.last}){
    totalCount
    Advisornotifications{
      id
      notificationcontent
      createdon
      isread
      athleteid
      advisornotificationadminidmaprel{
        Userdata{
          fullname
        }
      }
      advisornotificationathleteidmaprel{
        Athletes{
          athletefirstname
          athletelastname
          onBoardingUserIdMap{
            Onboardings{
              profileimages
            }
          }
        }
      }
    }
  }
}
`
//for marking all notifications on bell icon
export const advisorMarkNotificationQuery = (body) => `
  mutation{
    AdvisornotificationUpdateAll(where:{advisorid:${body.advisorid}},data:{issee:1})
  }

`
//for marking all notifications as read
export const advisorMarkReadForAll = (body) => `
mutation{
  AdvisornotificationUpdateAll(where:{advisorid:${body.advisorid}},data:{isread:1})
}
`
//for marking single notification as read
export const saveAdvisorReadValue = (body) => `
mutation{
  saveAdvisornotification(obj:{id:${body.id},isread:1}){
    id
    isread
  }
}
`
export const advisorCheckNewNotificationQuery = (body) => `
{
  allAdvisornotifications(where:{advisorid:${body.advisorid},issee:0}){
    totalCount    
  }
}   
`