import { React, useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import swal from 'sweetalert'
import services from "../../../services";
import "../assets/css/evaluation-form.scss";
import moment from "moment"
import FormControl from "@material-ui/core/FormControl";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { getUserInfo } from "../../../utils/constant";
import { mutationQueries } from "../../../MutationMethod";
import { gql } from "apollo-boost";
import Dialog from "@material-ui/core/Dialog";
import SaveNewDraft from "./SaveNewDraft";
import { validURL } from "../../../utils/validationUtils";

const getMetricFields = (tabData, onChangeHandler, fields, error, jumpList) => {
    switch (tabData?.statsmatrixid) {
        case 1:
            return (
                <>
                    <div className="half">
                        <label style={{ marginBottom: "4px", marginTop: "32px" }}>
                            {`${tabData?.name || ""} (Sec)`}
                        </label>
                        <input
                            type="text"
                            placeholder={tabData ? "e.x. " + tabData.placeholdertext : ""}
                            value={tabData?.value || ""}
                            onChange={(e) => onChangeHandler(e, "result", tabData)}
                        />
                        <p style={{ color: "red", textAlign: "left" }}>{tabData?.errors ? tabData?.errors : ""} </p>
                    </div>
                </>
            );
        case 2:
            return (
                <>
                    <div className="half">
                        <label style={{ marginBottom: "4px", marginTop: "32px" }}>
                            {`${tabData?.name || ""} (Ft)`}
                        </label>
                        <Select
                            value={tabData?.jumbidinfeet || ""}
                            id="heightinfeet"
                            onChange={(e) => onChangeHandler(e, "jumbidinfeet", tabData)}
                            style={{ width: "100%" }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {tabData?.tabname == "Vertical Jump" ? jumpList.feetList &&
                                jumpList.feetList.map((item, index) => (
                                    item.feetvalue <= 5 && item.feetvalue > 0 ?
                                        <MenuItem key={index} value={item.id}>
                                            {item.feetvalue}
                                        </MenuItem> : null
                                )) : jumpList.feetList &&
                            jumpList.feetList.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.feetvalue}
                                </MenuItem>
                            ))}
                        </Select>
                        <p style={{ color: "red", textAlign: "left" }}>
                            {tabData?.errors ? tabData?.errors : ""}{" "}
                        </p>
                    </div>
                    <div className="half">
                        <label style={{ marginBottom: "4px", marginTop: "32px" }}>
                            {`${tabData?.name || ""} (In)`}
                        </label>
                        <Select
                            value={tabData?.value || ""}
                            id="heightininches"
                            onChange={(e) => onChangeHandler(e, "jumbidininches", tabData)}
                            style={{ width: "100%" }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {jumpList.inchList &&
                                jumpList.inchList.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                        {item.inchvalue}
                                    </MenuItem>
                                ))}
                        </Select>
                        <p style={{ color: "red", textAlign: "left" }}>
                            {tabData?.errors ? tabData?.errors : ""}{" "}
                        </p>
                    </div>
                </>
            );
        case 3:
            return (
                <>
                    <div className="half">
                        <label style={{ marginBottom: "4px", marginTop: "32px" }}>
                            {`${tabData?.name || ""} (LBS)`}
                        </label>
                        <input
                            placeholder={tabData ? "e.x. " + tabData.placeholdertext : ""}
                            value={tabData?.value || ""}
                            type="text"
                            onChange={(e) => onChangeHandler(e, "lbs", tabData)}
                        />
                        <p style={{ color: "red", textAlign: "left" }}>{tabData?.errors ? tabData?.errors : ""} </p>
                    </div>
                    <div className="half">
                        <label style={{ marginBottom: "4px", marginTop: "32px" }}>
                            {`${tabData?.name || ""} (REPS)`}
                        </label>
                        <input
                            placeholder={tabData ? "e.x. " + tabData.placeholdertext : ""}
                            value={tabData?.value || ""}
                            type="text"
                            onChange={(e) => onChangeHandler(e, "reps", tabData)}
                        />
                        <p style={{ color: "red", textAlign: "left" }}>{tabData?.errors ? tabData?.errors : ""} </p>
                    </div>
                </>
            );
        default:
            return (
                <>
                    <div className="half">
                        <label style={{ marginBottom: "4px", marginTop: "32px" }}>
                            {`${tabData?.name || ""} (${tabData?.unit})`}
                        </label>
                        <input
                            placeholder={tabData ? tabData.placeholdertext : ""}
                            value={tabData?.value || ""}
                            type="text"
                            onChange={(e) => onChangeHandler(e, "result", tabData)}
                        />
                        <p style={{ color: "red", textAlign: "left" }}>{tabData?.errors ? tabData?.errors : ""} </p>
                    </div>
                </>
            );
    }
};

export default function AddEvaluationDraftForm({ handleModal, modalData, Athid, handleDraft, isDraft, bathID, throwID, handleDraftModal, evaluationReportId, publishedProjectedDivisions }) {
    const [field, setField] = useState({
        createdOn: null,
        fullName: undefined,
        projectionposition: "0",
        battingId: null,
        throwingId: null,
        inchId: "Select",
        feetId: "0",
        weight: null,
        athleticismlevel: "0",
        skilllevel: "0",
        acadamicrating: "0",
        scoutingsummary: "",
        areasforimprovement: "",
        additionalwebsitelink: "",
        reportviewedby: "SPORTSFORCE"
    });
    const [jumpList, setJumpList] = useState({
        feetList: [],
        inchList: [],
    });
    const [errorSwal, setErrorSwal] = useState(true);
    const [numFlag, setNumFlag] = useState(true);
    const [errorFlag, setErrorFlag] = useState(true);
    const [posFlag, setPosFlag] = useState(true);
    const [draftCount, setDraftCount] = useState(0);
    const [evaluationDraftData, setEvaluationDraftData] = useState([]);
    const [projectedPositionData, setProjectedPositionData] = useState([]),
        [keyStatData, setKeyStatData] = useState([]),
        [batData, setBatData] = useState([]),
        [feetData, setFeetData] = useState([]),
        [inchData, setInchData] = useState([]),
        [throwData, setThrowData] = useState([]),
        [errors, setErrors] = useState({}),
        [collegeDivisionData, setCollegeDivisionData] = useState([]),
        [openSaveDraftModal, setOpenSaveDraftModal] = useState(false),
        [advisorNameData, setAdvisorNameData] = useState([]);

    useEffect(async () => {
        getBatThrowData();
        getDraftData();
        const response = await services.location.getAllCollegeDivisionForDraft({ where: { active: 1, userid: Athid, /*advisorid: getUserInfo().id,*/ evaluationdraftid: modalData?.id } });
        if (response && response?.status == 200) {
            response?.data?.data?.allProjecteddivisionsfordrafts?.Projecteddivisionsfordrafts?.map(item => {
                item["collegeDivisionName"] = item?.projecteddivisionsfordraftdivisionidmaprel?.Collegedivisions?.[0]?.collegedivisionname || "";
            })
            setCollegeDivisionData(response?.data?.data?.allProjecteddivisionsfordrafts?.Projecteddivisionsfordrafts);
        }
        else {
            swal({ text: "No data found", icon: "error" });
            setCollegeDivisionData([])
        }
        const res = await services.location.getAllPrimaryPosition({ where: { active: 1 } })
        if (res && res?.status == 200 && res?.data?.data?.allPrimarypositions?.Primarypositions?.length > 0) {
            setProjectedPositionData(res?.data?.data?.allPrimarypositions?.Primarypositions);
            // let data = res?.data?.data?.allPrimarypositions?.Primarypositions?.length > 0 && projectionposition ? res?.data?.data?.allPrimarypositions?.Primarypositions?.filter(item => item.id == projectionposition) : [];
            // let newData = [];
            // let value = data?.length > 0 ? data?.map(item => {
            //     return item?.statsoverviewPrimarypositionidMap?.Statsoverviews?.length > 0 ? item?.statsoverviewPrimarypositionidMap?.Statsoverviews?.map(newDataValue => {
            //         newData.push({ name: newDataValue?.statsoverviewstatematrixmappingidrel?.Statmatrixmappings?.[0]?.tabname, value: "", id: newDataValue?.statsoverviewstatematrixmappingidrel?.Statmatrixmappings?.[0]?.id, placeholdertext: newDataValue?.statsoverviewstatematrixmappingidrel?.Statmatrixmappings?.[0]?.placeholdertext })
            //     }) : []
            // }) : [];
            // setKeyStatData(newData)
        }
        else {
            swal({ text: "No data found", icon: "error" });
            setProjectedPositionData([])
            setKeyStatData([])
        }
        if (modalData) {
            let fieldsT = field;
            fieldsT["id"] = modalData?.id || null
            fieldsT["createdOn"] = moment(new Date()).format("YYYY-MM-DD")
            if(getUserInfo().role === "ADMIN"){
                fieldsT["fullName"] = {
                    id: getUserInfo() && getUserInfo().id, fullName: getUserInfo() && getUserInfo().fullname.replace(/\s/g,'')
                };
            }
            else{
                fieldsT["fullName"] = {
                    id: getUserInfo() && getUserInfo().id, fullName: getUserInfo() && getUserInfo().firstname ? getUserInfo().firstname.concat(getUserInfo()?.lastname ? " " + getUserInfo().lastname : "") : ""
                };
            }
            fieldsT["projectionposition"] = modalData?.projectionposition ? modalData?.projectionposition.toString() : "0";
            fieldsT["athleticismlevel"] = modalData?.athleticismlevel ? modalData?.athleticismlevel : "0";
            fieldsT["skilllevel"] = !!modalData?.skilllevel ? modalData?.skilllevel : "0";
            fieldsT["acadamicrating"] = modalData?.acadamicrating ? modalData?.acadamicrating : "0";
            fieldsT["scoutingsummary"] = modalData?.scoutingsummary || "";
            fieldsT["additionalwebsitelink"] = modalData?.additionalwebsitelink ? modalData?.additionalwebsitelink : "";
            fieldsT["areasforimprovement"] = modalData?.areasforimprovement || "";
            fieldsT["reportviewedby"] = modalData?.reportviewedby ? modalData?.reportviewedby : null;
            fieldsT["inchId"] = modalData?.heightininch || modalData?.heightininch == 0 ? parseFloat(modalData?.heightininch) : "Select";
            fieldsT["feetId"] = modalData?.heightinfeet || "0";
            fieldsT["weight"] = modalData?.weight || "";
            fieldsT['battingId'] = bathID;
            fieldsT['throwingId'] = throwID;
            setField({ ...fieldsT });
            let data = modalData?.keystats ? JSON.parse(modalData?.keystats) : [];
            setKeyStatData(data)
        }
        else {
            let fieldsT = field;
            fieldsT["createdOn"] = moment(new Date()).format("YYYY-MM-DD")
            if(getUserInfo().role === "ADMIN"){
                fieldsT["fullName"] = {
                    id: getUserInfo() && getUserInfo().id, fullName: getUserInfo() && getUserInfo().fullname.replace(/\s/g,'')
                };
            }
            else{
                fieldsT["fullName"] = {
                    id: getUserInfo() && getUserInfo().id, fullName: getUserInfo() && getUserInfo().firstname ? getUserInfo().firstname.concat(getUserInfo()?.lastname ? " " + getUserInfo().lastname : "") : ""
                };
            }
            // fieldsT["projectionposition"] = athletePersonalInfo?.primarypositionid || "0";
            // fieldsT["feetId"] = athletePersonalInfo?.heightValue || "0";
            // fieldsT["inchId"] = athletePersonalInfo?.heightInInches || "Select";
            // fieldsT["weight"] = athletePersonalInfo?.weight || ""
            setField({ ...fieldsT });
            //     if(athletePersonalInfo?.primarypositionid){
            //     const res = await services.location.getAllPrimaryPosition({ where: { active: 1, id: athletePersonalInfo?.primarypositionid } })
            //     if (res && res?.status == 200 && res?.data?.data?.allPrimarypositions?.Primarypositions?.length > 0) {
            //         let data = res?.data?.data?.allPrimarypositions?.Primarypositions?.length > 0 ? res?.data?.data?.allPrimarypositions?.Primarypositions : [];
            //         let newData = [];
            //         let value = data?.length > 0 ? data?.map(item => {
            //             return item?.statsoverviewPrimarypositionidMap?.Statsoverviews?.length > 0 ? item?.statsoverviewPrimarypositionidMap?.Statsoverviews?.map(newDataValue => {
            //                 newData.push({ name: newDataValue?.statsoverviewstatematrixmappingidrel?.Statmatrixmappings?.[0]?.tabname, value: "", id: newDataValue?.statsoverviewstatematrixmappingidrel?.Statmatrixmappings?.[0]?.id, placeholdertext: newDataValue?.statsoverviewstatematrixmappingidrel?.Statmatrixmappings?.[0]?.placeholdertext })
            //             }) : []
            //         }) : [];
            //         setKeyStatData(newData)
            //     }
            // }
        }
    }, [])

    const getBatThrowData = async () => {
        const res = await services.location.getAllBatData({ obj: { active: 1 } });
        if (res && res?.status == 200 && res?.data?.data?.allBattingpreferences?.Battingpreferences?.length > 0) {
            setBatData(res?.data?.data?.allBattingpreferences?.Battingpreferences);
        }
        else {
            swal({ text: "No data found", icon: "error" });
            setBatData([]);
        }
        const response = await services.location.getAllThrowData({ obj: { active: 1 } });
        if (response && response?.status == 200 && response?.data?.data?.allThrowingpreferences?.Throwingpreferences?.length > 0) {
            setThrowData(response?.data?.data?.allThrowingpreferences?.Throwingpreferences);
        }
        else {
            swal({ text: "No data found", icon: "error" });
            setThrowData([]);
        }
        const _res = await services.location.getAllHeightFeetData({ obj: { active: 1 } });
        if (_res && _res?.status == 200 && _res?.data?.data?.allHeightinfeets?.Heightinfeets?.length > 0) {
            setFeetData(_res?.data?.data?.allHeightinfeets?.Heightinfeets);
        }
        else {
            swal({ text: "No data found", icon: "error" });
            setFeetData([]);
        }
        const _response = await services.location.getAllHeightInchesData({ obj: { active: 1 } });
        if (_response && _response?.status == 200 && _response?.data?.data?.allHeightininches?.Heightininches?.length > 0) {
            setInchData(_response?.data?.data?.allHeightininches?.Heightininches);
        }
        else {
            swal({ text: "No data found", icon: "error" });
            setInchData([]);
        }
        const resp = await services.location.getAllJumpData();
        if (resp && resp?.status == 200 && resp?.data?.data?.allFeet?.Feet?.length > 0 && resp?.data?.data?.allInches?.Inches?.length > 0) {
            setJumpList({
                ...jumpList,
                feetList: resp?.data?.data?.allFeet?.Feet,
                inchList: resp?.data?.data?.allInches?.Inches
            });
        }
        else {
            swal({ text: "No data found", icon: "error" });
            setJumpList([]);
        }
    }

    /* const getKeyStatData = async (id) => {
        const res = await services.location.getAllPrimaryPosition({ where: { active: 1, id: parseFloat(id) } });
        if (res && res?.status == 200 && res?.data?.data?.allPrimarypositions?.Primarypositions?.length > 0) {
            let newData = [];
            let value = res?.data?.data?.allPrimarypositions?.Primarypositions?.length > 0 ? res?.data?.data?.allPrimarypositions?.Primarypositions?.map(item => {
                return item?.statsoverviewPrimarypositionidMap?.Statsoverviews?.length > 0 ? item?.statsoverviewPrimarypositionidMap?.Statsoverviews?.map(newDataValue => {
                    newData.push({ name: newDataValue?.statsoverviewstatematrixmappingidrel?.Statmatrixmappings?.[0]?.tabname, value: "", errors: "", id: newDataValue?.statsoverviewstatematrixmappingidrel?.Statmatrixmappings?.[0]?.id, placeholdertext: newDataValue?.statsoverviewstatematrixmappingidrel?.Statmatrixmappings?.[0]?.placeholdertext, ...newDataValue?.statsoverviewstatematrixmappingidrel?.Statmatrixmappings?.[0] })
                }) : []
            }) : [];
            setKeyStatData(newData)
        }
        else {
            setKeyStatData([])
        }
    } */

    const getKeyStatData = async (id) => {
        const res = await services.location.getPrimaryPositionFields({ where: { primarypositionid: parseFloat(id) } });
        if (res && res?.status == 200 && res?.data?.data?.allPrimarypositionmappings?.Primarypositionmappings?.length > 0) {
            let newData = [];
            let value = res?.data?.data?.allPrimarypositionmappings?.Primarypositionmappings?.length > 0 ? res?.data?.data?.allPrimarypositionmappings?.Primarypositionmappings?.map(item => {
                return item?.statmatrixmappingmaprel?.Statmatrixmappings?.length > 0 ? item?.statmatrixmappingmaprel?.Statmatrixmappings?.map(newDataValue => {
                    newData.push({ 
                        ...newDataValue, 
                        name: item?.columnname, 
                        value: "", 
                        errors: "", 
                        id: newDataValue?.id, 
                        placeholdertext: item?.placeholdervalue
                    })
                }) : []
            }) : [];
            setKeyStatData(newData)
        }
        else {
            setKeyStatData([])
        }
    } 

    const handleChange = (fieldName, e) => {
        let fieldsT = field;
        let errorsT = errors;
        setErrors({
            errorsT: ""
        });
        if (fieldName === "projectionposition" && e.target.value !== "0") {
            getKeyStatData(e.target.value);
        }
        fieldsT[fieldName] = e.target.value;
        setField({
            ...fieldsT,
        });
    };

    const handleCollegeLevelData = (fieldName, e, data) => {
        let newData = collegeDivisionData?.length > 0 ? collegeDivisionData?.map(item => {
            if (item.id === data.id) {
                if (item[fieldName] == "yes")
                    item[fieldName] = "maybe";
                else if (item[fieldName] == "maybe")
                    item[fieldName] = null;
                else
                    item[fieldName] = "yes";
            }
            return item;
        }) : []
        setCollegeDivisionData(newData);
    }

    const handleCheckbox = (fieldName, value, e) => {
        let fieldsT = field;
        if (e.target.checked)
            fieldsT[fieldName] = value;
        else {
            fieldsT[fieldName] = null;
        }
        setField({
            ...fieldsT,
        });
    }

    const handleNameChange = async (field, e) => {
        let Search = e?.target?.value ? e.target.value.trim().replace(/\s\s+/g, "") : "";
        Search = Search.split(" ");
        const like1 = "%" + `${Search[0]}` + "%";
        const like2 = "%" + `${Search[1]}` + "%";
        const variables = {
            where: {
                active: 1,
                and: [
                    {
                        or: [
                            { firstname: { like: like1, options: "i" } },
                            { lastname: { like: like2, options: "i" } },
                            { lastname: { like: like1, options: "i" } },
                        ],
                    },
                ],
            },
        };
        if (e.target.value.toString().length > 2) {
            let res = await services.location.getAllAdvisor(variables);
            if (res?.status == 200) {
                let advisorNamelist = res?.data?.data?.allAdvisors?.Advisors?.length > 0 ? res?.data?.data?.allAdvisors?.Advisors?.map(item => {
                    item["fullName"] = item?.firstname ? item?.firstname.concat(item?.lastname ? " " + item?.lastname : "") : "";
                    return item;
                }) : [{
                    id: getUserInfo() && getUserInfo().id, fullName: getUserInfo() && getUserInfo().fullname.replace(/\s/g,'')
                }];
                setAdvisorNameData([...advisorNamelist]);
            } else {
                setAdvisorNameData([]);
            }
        }
        else {
            setAdvisorNameData([])
        }
    };

    const saveDraftModal = () => {
        setOpenSaveDraftModal(!openSaveDraftModal)
    }

    const handleValidation = (fieldname, e) => {
        let errorsT = errors;
        let formIsValid = true;
        let fieldValue = e ? (e.target ? e.target.value : e) : e;
        let updatedValue = fieldValue;

        if (fieldname === undefined || fieldname === "createdOn") {
            if (fieldname === undefined) {
                fieldValue = field["createdOn"] ? field["createdOn"] : "";
                updatedValue = fieldValue;
                delete errorsT["createdOn"];
            }
            if (!updatedValue) {
                formIsValid = false;
                errorsT["createdOn"] = "Please choose scouting report date";
            }
        }
        if (fieldname === undefined || fieldname === "fullName") {
            if (fieldname === undefined) {
                fieldValue = field["fullName"] ? field["fullName"] : "";
                updatedValue = fieldValue;
            }
            delete errorsT["fullName"];
            if (!updatedValue) {
                formIsValid = false;
                errorsT["fullName"] = "Please select evaluated by";
            }
            if (!updatedValue?.id) {
                formIsValid = false;
                errorsT["fullName"] = "Please select evaluated by from list";
            }

        }
        if (fieldname === undefined || fieldname === "projectionposition") {
            if (fieldname === undefined) {
                fieldValue = field["projectionposition"] && field["projectionposition"] !== "0" ? field["projectionposition"] : "";
                updatedValue = fieldValue;
            }
            delete errorsT["projectionposition"];
            if (!updatedValue) {
                formIsValid = false;
                errorsT["projectionposition"] = "Please select projection position";
            }
        }
        if (fieldname === undefined || fieldname === "athleticismlevel") {
            if (fieldname === undefined) {
                fieldValue = field["athleticismlevel"] && field["athleticismlevel"] !== "0" ? field["athleticismlevel"] : "";
                updatedValue = fieldValue;
            }
            delete errorsT["athleticismlevel"];
            if (!updatedValue) {
                formIsValid = false;
                errorsT["athleticismlevel"] = "Please select athleticism";
            }
        }
        if (fieldname === undefined || fieldname === "skilllevel") {
            if (fieldname === undefined) {
                fieldValue = field["skilllevel"] && field["skilllevel"] !== "0" ? field["skilllevel"] : "";
                updatedValue = fieldValue;
            }
            delete errorsT["skilllevel"];
            if (!updatedValue) {
                formIsValid = false;
                errorsT["skilllevel"] = "Please select skills";
            }
        }
        if (fieldname === undefined || fieldname === "additionalwebsitelink") {
            if (fieldname === undefined) {
                fieldValue = field["additionalwebsitelink"] ? field["additionalwebsitelink"] : "";
                updatedValue = fieldValue;
            }
            delete errorsT["additionalwebsitelink"];
            if (updatedValue) {
                if (!validURL(updatedValue)) {
                    formIsValid = false;
                    errorsT["additionalwebsitelink"] = "Please enter website link in correct format";
                }
            }
        }
        setErrors({
            ...errorsT,
        });
        return {
            formIsValid,
            updatedValue,
        };
    };

    const handleSubmit = () => {
        let effactiveAdvisorId = null;
        let effactiveAdminId = null;
        const { formIsValid } = handleValidation();
        if(errorFlag){
        if (numFlag) {
            if (posFlag) {
            if (errorSwal) {
                if (formIsValid) {
                    swal({
                        text: "Do you want to save this report now?",
                        icon: "success",
                        className: "publish-modal",
                        buttons: {
                            confirm: { text: "Save" },
                            catch: {
                                text: "Save Draft",
                                value: "draft",
                            },
                        }
                    }).then(async (value) => {
                        if (value === "draft") {
                            saveDraftModal();
                        }
                        else if (value) {
                            let inchValue = inchData?.filter(item => item.inchvalue == field?.inchId);
                            let obj = {
                                //id: field?.id,
                                id: evaluationReportId ? evaluationReportId : null,
                                active: 1,
                                createdon: field?.createdOn ? new Date(field?.createdOn) : null,
                                athleteid: Athid,
                                advisorid: field?.fullName?.id ? parseFloat(field?.fullName?.id) : null,
                                projectionposition: field?.projectionposition ? parseFloat(field?.projectionposition) : null,
                                athleticismlevel: field?.athleticismlevel ? field?.athleticismlevel.toString() : null,
                                skilllevel: field?.skilllevel ? field?.skilllevel.toString() : null,
                                acadamicrating: field?.acadamicrating && field?.acadamicrating !== "0" ? field?.acadamicrating.toString() : null,
                                keystats: keyStatData?.length > 0 ? JSON.stringify(keyStatData) : null,
                                scoutingsummary: field?.scoutingsummary || null,
                                areasforimprovement: field?.areasforimprovement || null,
                                additionalwebsitelink: field?.additionalwebsitelink || null,
                                heightinfeet: field?.feetId && field?.feetId !== "0" ? parseFloat(field?.feetId) : null,
                                heightininch: inchValue?.[0]?.id ? parseFloat(inchValue?.[0]?.id) : null,
                                weight: field?.weight ? field?.weight.toString() : null,
                                reportviewedby: field?.reportviewedby ? field?.reportviewedby : null
                            }
                            if(getUserInfo().role == "ADMIN" && getUserInfo().id == field?.fullName?.id){
                                obj['adminid'] = getUserInfo().id;
                                obj['advisorid'] = null;
                                effactiveAdvisorId = null;
                                effactiveAdminId = getUserInfo().id;
                            }
                            else if(getUserInfo().role == "ADMIN" && getUserInfo().id != field?.fullName?.id){
                                obj['adminid'] = getUserInfo().id;
                                effactiveAdvisorId = field?.fullName?.id ? parseFloat(field?.fullName?.id) : null;
                                effactiveAdminId = getUserInfo().id;
                            }
                            else if(getUserInfo().role !== "ADMIN"){
                                effactiveAdvisorId = field?.fullName?.id ? parseFloat(field?.fullName?.id) : null;
                                effactiveAdminId = null;
                            }
                            const res = await services.location.saveReportData(obj);
                            if (res && res?.status == 200) {
                                if (collegeDivisionData?.length > 0) {
                                    let query = "";
                                    collegeDivisionData.map((item, index) => {
                                        return item?.id ?
                                            (query += `id${index}: saveProjecteddivisions(
                            obj: {
                              advisorid: ${effactiveAdvisorId}
                              adminid: ${effactiveAdminId}
                              high: "${item.high}"
                              mid:"${item.mid}"
                              top: "${item.top}"
                              low: "${item.low}"
                              active:${1}
                              divisionid: ${parseFloat(item.divisionid)}
                              userid: ${parseFloat(item.userid)}
                              id: ${publishedProjectedDivisions[index]['id']}          
                            }
                          ){id}`) :
                                            (query += `id${index}: saveProjecteddivisions(
                          obj: {
                            advisorid: ${effactiveAdvisorId}
                            adminid: ${effactiveAdminId}
                            high: "${item.high}"
                            mid:"${item.mid}"
                            top: "${item.top}"
                            low: "${item.low}"
                            active:${1}
                            divisionid: ${parseFloat(item.divisionid)}
                            userid: ${parseFloat(item.userid)}            
                          }
                        ){id}`)
                                    });
                                    mutationQueries(gql`
                      mutation {
                        ${query}
                      }
                    `).then(res => {
                                    });
                                }
                                swal({ text: "Your report has been saved successfully", icon: "success" }).then(async (value) => {
                                    getDraftData();
                                    handleModal();
                                    handleDraftModal();
                                });
                            }
                            else {
                                swal({ title: "Evaluation report not saved", icon: "error" })
                            }
                        }
                    })
                }
                }
                else {
                    swal({ title: "Please enter value in range on key stats", icon: "warning" });
                }
            }
            else {
                swal({ title: "Please enter positive value in range on key stats", icon: "warning" });
            }
        }
            else {
                swal({ title: "Please enter numeric value in key stats", icon: "warning" });
            }
        }
        else{
            swal({ title: "Please remove all error of keystats", icon: "warning" });
        }
        }

        const handleCancelBtn = () => {
            swal({
                text: "Do you want to cancel the Evaluation?",
                icon: "warning",
                buttons: {
                    confirm: { text: "Ok" },
                    cancel: "Cancel",
                },
                dangerMode: true,
            }).then(value => {
                if (value) {
                    handleModal();
                }
            })
        }
        //earlier it was not there as that was ui is not updating
        const getDraftData = async () => {
            let draftDataWhere = {
                order: "id desc", 
                active: 1, 
                athleteid: parseFloat(Athid)
            };
            if(getUserInfo()['role'] === "ADMIN"){
                draftDataWhere.adminid = getUserInfo()['id']
            }
            else{
                draftDataWhere.advisorid = getUserInfo()['id']
            }
            const _res = await services.location.getEvaluationDraftData({ where: draftDataWhere })
            if (_res && _res?.status == 200 && _res?.data?.data?.allEvaluationdrafts?.Evaluationdrafts?.length > 0) {
                setDraftCount(_res?.data?.data?.allEvaluationdrafts?.totalCount);
                setEvaluationDraftData(_res?.data?.data?.allEvaluationdrafts?.Evaluationdrafts);
            }
            else {
                setDraftCount(0);
                setEvaluationDraftData([])
            }
        }

        const handleErrorValidation = (e, valueData, data) => { 
            let errFlag = true;
            let numberFlag = true;
            let posFlag = true;
            let eFlag = true;
            let newData = keyStatData?.length > 0 ? keyStatData?.map(item => {
                if(item.id === data.id){
                    //eFlag = false;
                    if (data?.statsmatrixid == 1) {
                        if (e.target.value) {
                            let regExp = new RegExp(/^\d+(\.\d)?\d*$/).test(e.target.value)
                            let value = parseFloat(e.target.value);
                            if (!regExp) {
                                item.errors = "Please enter numeric value";
                                numberFlag = false;
                            }
                            else{
                                item.errors = "";
                                numberFlag = true
                            }
                            if (value < 0) {
                                item.errors = "Please enter positive value";
                                posFlag = false;
                            }
                            else {
                                item.errors = "";
                                posFlag = true;
                            }
                            if (value >= 0) {
                                if (
                                    value < parseFloat(data.minimumvalue) ||
                                    value > parseFloat(data.maximumvalue)
                                ) {
                                    item.errors = `Please enter value in range ${data.minimumvalue}-${data.maximumvalue}`;
                                    errFlag = false;
                                }
                                else {
                                    item.errors = "";
                                    errFlag = true;
                                }
                            }
                        }
                    }
                    else if (data?.statsmatrixid == 3) {
                        if (e.target.value) {
                            let regExp = new RegExp(/^\d+(\.\d)?\d*$/).test(e.target.value)
                            let value = parseFloat(e.target.value);
                            if (!regExp) {
                                item.errors = "Please enter lbs in numeric value";
                                numberFlag = false;
                            }
                            else{
                                item.errors = "";
                                numberFlag = true; 
                            }
                            if (value < 0) {
                                item.errors = "Please enter positive value";
                                posFlag = false;
                            }
                            else {
                                posFlag = true;
                                item.errors = "";
                            }
                            if (value >= 0) {
                                if (
                                    value < parseFloat(data.minimumvalue) ||
                                    value > parseFloat(data.maximumvalue)
                                ) {
                                    errFlag = false;
                                    item.errors = `Please enter value in range ${data.minimumvalue}-${data.maximumvalue}`;
                                }
                                else {
                                    item.errors = "";
                                    errFlag = true;
                                }
                            }
                        }
                    } else {
                        if (e.target.value) {
                            let regExp = new RegExp(/^\d+(\.\d)?\d*$/).test(e.target.value)
                            let value = parseFloat(e.target.value);
                            if (!regExp) {
                                item.errors = "Please enter numeric value";
                                numberFlag = false;
                            }
                            else{
                                item.errors = "";
                                numberFlag = true;
                            }
                            if (value < 0) {
                                item.errors = "Please enter positive value";
                                posFlag = false;
                            }
                            else {
                                item.errors = "";
                                posFlag = true;
                            }
                            if (value >= 0) {
                                if (value < parseFloat(data.minimumvalue) || value > parseFloat(data.maximumvalue)) {
                                    item.errors = `Please enter value in range ${data.minimumvalue}-${data.maximumvalue}`;
                                    errFlag = false;
                                }
                                else {
                                    item.errors = "";
                                    errFlag = true;
                                }
                            }
                        }
                    }
                }
                else{
                    //eFlag = true;
                if (item.id === data.id) {
                    if (data?.statsmatrixid == 1) {
                        if (e.target.value) {
                            let regExp = new RegExp(/^\d+(\.\d)?\d*$/).test(e.target.value)
                            let value = parseFloat(e.target.value);
                            if (!regExp) {
                                item.errors = "Please enter numeric value";
                                numberFlag = false;
                            }
                            else{
                                item.errors = "";
                                numberFlag = true
                            }
                            if (value < 0) {
                                item.errors = "Please enter positive value";
                                posFlag = false;
                            }
                            else {
                                item.errors = "";
                                posFlag = true;
                            }
                            if (value >= 0) {
                                if (
                                    value < parseFloat(data.minimumvalue) ||
                                    value > parseFloat(data.maximumvalue)
                                ) {
                                    item.errors = `Please enter value in range ${data.minimumvalue}-${data.maximumvalue}`;
                                    errFlag = false;
                                }
                                else {
                                    item.errors = "";
                                    errFlag = true;
                                }
                            }
                        }
                    }
                    else if (data?.statsmatrixid == 3) {
                        if (e.target.value) {
                            let regExp = new RegExp(/^\d+(\.\d)?\d*$/).test(e.target.value)
                            let value = parseFloat(e.target.value);
                            if (!regExp) {
                                item.errors = "Please enter lbs in numeric value";
                                numberFlag = false;
                            }
                            else{
                                item.errors = "";
                                numberFlag = true; 
                            }
                            if (value < 0) {
                                item.errors = "Please enter positive value";
                                posFlag = false;
                            }
                            else {
                                posFlag = true;
                                item.errors = "";
                            }
                            if (value >= 0) {
                                if (
                                    value < parseFloat(data.minimumvalue) ||
                                    value > parseFloat(data.maximumvalue)
                                ) {
                                    errFlag = false;
                                    item.errors = `Please enter value in range ${data.minimumvalue}-${data.maximumvalue}`;
                                }
                                else {
                                    item.errors = "";
                                    errFlag = true;
                                }
                            }
                        }
                    } else {
                        if (e.target.value) {
                            let regExp = new RegExp(/^\d+(\.\d)?\d*$/).test(e.target.value)
                            let value = parseFloat(e.target.value);
                            if (!regExp) {
                                item.errors = "Please enter numeric value";
                                numberFlag = false;
                            }
                            else{
                                item.errors = "";
                                numberFlag = true;
                            }
                            if (value < 0) {
                                item.errors = "Please enter positive value";
                                posFlag = false;
                            }
                            else {
                                item.errors = "";
                                posFlag = true;
                            }
                            if (value >= 0) {
                                if (value < parseFloat(data.minimumvalue) || value > parseFloat(data.maximumvalue)) {
                                    item.errors = `Please enter value in range ${data.minimumvalue}-${data.maximumvalue}`;
                                    errFlag = false;
                                }
                                else {
                                    item.errors = "";
                                    errFlag = true;
                                }
                            }
                        }
                    }
                }
            }
            if(item.errors){
                eFlag = false;
            }
                return item;
            }) : [];
            setNumFlag(numberFlag);
            setErrorFlag(eFlag)
            setErrorSwal(errFlag);
            setKeyStatData(newData);
            setPosFlag(posFlag);
        }

        const onChangeHandler = (e, fieldName, data) => {
            let fieldsT = field;
            let newData = keyStatData?.length > 0 ? keyStatData?.map(item => {
                if (item.id === data.id) {
                    const updatedValue = e.target.value;
                    if (fieldName === "result" || fieldName === "lbs" || fieldName === "reps") {
                        const numArr = updatedValue.split(".");
                        if (numArr[1]) {
                            if (numArr[1].length <= 2) {
                                item.value = updatedValue;
                            }
                        } else {
                            item.value = updatedValue;
                        }
                    } else {
                        item.value = updatedValue;
                    }
                    if (fieldName == "jumbidininches" || fieldName == "jumbidinfeet") {
                        let feetValue = 0,
                            inchvalue = 0;
                        jumpList.feetList &&
                            jumpList.feetList.map((item) => {
                                if (item.id == fieldsT.jumbidinfeet) {
                                    feetValue = item.feetvalue;
                                }
                            });
                        jumpList.inchList &&
                            jumpList.inchList.map((item) => {
                                if (item.id == fieldsT.jumbidininches) {
                                    inchvalue = item.inchvalue;
                                }
                            });
                        item.value = feetValue * 30.48 + inchvalue * 2.54;
                    }
                }
                return item;
            }) : []
            setKeyStatData(newData);
            handleErrorValidation(e, newData, data);
        };

        return (
            <>
                <Dialog open={openSaveDraftModal}
                        onClose={(event, reason)=>{
                            if (reason == "backdropClick" || reason == "escapeKeyDown"){
                                handleModal()
                            }
                        }}
                >
                    <SaveNewDraft
                        handleModal={() => setOpenSaveDraftModal(!openSaveDraftModal)}
                        draftCount={draftCount}
                        draftData={evaluationDraftData}
                        getDraftData={() => getDraftData()}
                        Athid={Athid}
                        field={field}
                        keyStatData={keyStatData}
                        collegeDivisionData={collegeDivisionData}
                        closeEvaluationModal={() => handleModal()}
                        handleDraft={() => handleDraft()}
                        isDraft={isDraft}
                    />
                </Dialog>
                <div className="evaluation-sec evaluation-modal">
                    <div className="report-data">
                        <h1 className="sec-title">Evaluation Form <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => handleModal()}
                        >
                            <span aria-hidden="true">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.804"
                                    height="15.803"
                                    viewBox="0 0 15.804 15.803"
                                >
                                    <defs>
                                        <style></style>
                                    </defs>
                                    <g className="a" transform="translate(-6.776 -6.776)">
                                        <path
                                            d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                                            transform="translate(0 0)"
                                        />
                                    </g>
                                </svg>
                            </span>
                        </button>
                        </h1>
                        <div className="field-column-wrap">
                            <div className="field-box-item">
                                <h5>Scouting Report <sup>*</sup></h5>
                                <input
                                    type="date"
                                    name="date"
                                    id="date"
                                    value={field?.createdOn || new Date()}
                                    onChange={(e) => handleChange("createdOn", e)} />
                                <div className="error">{errors["createdOn"]}</div>
                            </div>
                            <div className="field-box-item">
                                <h5>Evaluated by <sup>*</sup></h5>
                                <Autocomplete
                                    id="combo-box-demo"
                                    className="search-input"
                                    freeSolo={true}
                                    options={advisorNameData}
                                    getOptionLabel={(option) =>
                                        option.fullName ? option.fullName : option
                                    }
                                    onChange={(e, data) => {
                                        handleChange("fullName", {
                                            target: { value: data ? data : "" },
                                        });
                                    }}
                                    value={field.fullName || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Name here"
                                            value={field.fullName || ""}
                                            onChange={(e) => {
                                                handleNameChange("fullName", {
                                                    target: {
                                                        value: e.target.value.replace(/[^a-zA-Z]/gi, ""),
                                                    },
                                                });
                                                handleChange("fullName", {
                                                    target: {
                                                        value: e.target.value.replace(/[^a-zA-Z]/gi, ""),
                                                    },
                                                });
                                            }}
                                        />
                                    )}
                                />
                                <div className="error">{errors["fullName"]}</div>
                            </div>
                            <div className="field-box-item">
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-outlined-label">Bat</InputLabel>
                                    <Select
                                        labelId="demo-outlined-label"
                                        id="demo-outlined"
                                        value={field?.battingId || "0"}
                                        onChange={(e) => handleChange("battingId", e)}
                                        label="Select"
                                        displayEmpty
                                        disabled={true}
                                    >
                                        <MenuItem value="0">
                                            <em>Select Bat</em>
                                        </MenuItem>
                                        {batData?.length > 0 &&
                                            batData.map(
                                                (item, index) => (
                                                    <MenuItem key={index} value={item.id}>
                                                        {item.battingpreferencevalue}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="field-box-item">
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-select-outlined-label">Throw</InputLabel>
                                    <Select
                                        labelId="demo-select-outlined-label"
                                        id="demo-select-outlined"
                                        value={field?.throwingId || "0"}
                                        onChange={(e) => handleChange("throwingId", e)}
                                        label="Select"
                                        displayEmpty
                                        disabled={true}
                                    >
                                        <MenuItem value="0">
                                            <em>Select Throw</em>
                                        </MenuItem>
                                        {throwData?.length > 0 &&
                                            throwData.map(
                                                (item, index) => (
                                                    <MenuItem key={index} value={item.id}>
                                                        {item.throwingpreferencevalue}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="field-box-item">
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-simple-select-outlined-label">Projected Position <sup>*</sup></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={field?.projectionposition || "0"}
                                        onChange={(e) => handleChange("projectionposition", e)}
                                        label="Select"
                                        displayEmpty
                                    >
                                        <MenuItem value="0">
                                            <em>Select position</em>
                                        </MenuItem>
                                        {projectedPositionData?.length > 0 &&
                                            projectedPositionData.map(
                                                (item, index) => (
                                                    <MenuItem key={index} value={item.id}>
                                                        {item.shortform}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                </FormControl>
                                <div className="error">{errors["projectionposition"]}</div>
                            </div>
                            <div className="field-box-item">
                                <h5>Key Stats
                                    <span className="icon">
                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <g id="Group_57168" data-name="Group 57168">
                                                <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                            </g>
                                        </svg>
                                    </span>
                                </h5>
                                <div className="field-box-item">
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="select-outlined-label">Height (ft)</InputLabel>
                                        <Select
                                            labelId="select-outlined-label"
                                            id="select-outlined"
                                            value={field?.feetId || "0"}
                                            onChange={(e) => handleChange("feetId", e)}
                                            label="Select"
                                            displayEmpty
                                        >
                                            <MenuItem value="0">
                                                <em>Select Feet</em>
                                            </MenuItem>
                                            {feetData?.length > 0 &&
                                                feetData.map(
                                                    (item, index) => (
                                                        <MenuItem key={index} value={item.heightvalue}>
                                                            {item.heightvalue}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="field-box-item">
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="select-outlined-label-data-inch">Height (In)</InputLabel>
                                        <Select
                                            labelId="select-outlined-label-data-inch"
                                            id="select-outlined-inch"
                                            value={field?.inchId || field?.inchId == 0 ? field?.inchId : "Select"}
                                            onChange={(e) => handleChange("inchId", e)}
                                            label="Select"
                                            displayEmpty
                                        >
                                            <MenuItem value="Select">
                                                <em>Select Inches</em>
                                            </MenuItem>
                                            {inchData?.length > 0 &&
                                                inchData.map(
                                                    (item, index) => (
                                                        <MenuItem key={index} value={item.id}>
                                                            {item.inchvalue}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="field-box-item">
                                    <label>Weight (LBS)</label>
                                    <div className="form-group mb-0">
                                        <TextField
                                            variant="outlined"
                                            id="weight"
                                            type="number"
                                            value={field.weight || ""}
                                            onChange={(e) => {
                                                handleChange("weight", e);
                                            }}
                                            placeholder="Weight in lbs"
                                            onInput={(e) => {
                                                e.target.value = Math.max(
                                                    0,
                                                    parseFloat(e.target.value)
                                                )
                                                    .toString()
                                                    .slice(0, 3);
                                            }}
                                        />
                                        <span
                                            style={{
                                                paddingLeft: "10px",
                                                lineHeight: "40px",
                                            }}
                                        >
                                            lbs
                                        </span>
                                    </div>
                                </div>
                                {
                                    keyStatData?.length > 0 ? keyStatData?.map(item => {
                                        return getMetricFields(
                                            item,
                                            onChangeHandler,
                                            field,
                                            errors,
                                            jumpList
                                        )
                                    }) : ""
                                }
                            </div>
                            <div className="field-box-item">
                                <h5>Athleticism <sup>*</sup>
                                    <span className="icon">
                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <g id="Group_57168" data-name="Group 57168">
                                                <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                            </g>
                                        </svg>
                                    </span>
                                </h5>
                                <select
                                    name="athleticism"
                                    value={field?.athleticismlevel || "0"}
                                    onChange={(e) => handleChange("athleticismlevel", e)}
                                >
                                    <option value={"0"}>Select</option>
                                    <option value={"0.5"}>0.5 Rating</option>
                                    <option value={"1"}>1 Rating</option>
                                    <option value={"1.5"}>1.5 Rating</option>
                                    <option value={"2"}>2 Rating</option>
                                    <option value={"2.5"}>2.5 Rating</option>
                                    <option value={"3"}>3 Rating</option>
                                    <option value={"3.5"}>3.5 Rating</option>
                                    <option value={"4"}>4 Rating</option>
                                    <option value={"4.5"}>4.5 Rating</option>
                                    <option value={"5"}>5 Rating</option>
                                </select>
                                <div className="error">{errors["athleticismlevel"]}</div>
                            </div>
                            <div className="field-box-item">
                                <h5>Skills <sup>*</sup>
                                    <span className="icon">
                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <g id="Group_57168" data-name="Group 57168">
                                                <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                            </g>
                                        </svg>
                                    </span>
                                </h5>
                                <select
                                    name="skills"
                                    value={field?.skilllevel || "0"}
                                    onChange={(e) => handleChange("skilllevel", e)}
                                >
                                    <option value={"0"}>Select</option>
                                    <option value={"0.5"}>0.5 Rating</option>
                                    <option value={"1"}>1 Rating</option>
                                    <option value={"1.5"}>1.5 Rating</option>
                                    <option value={"2"}>2 Rating</option>
                                    <option value={"2.5"}>2.5 Rating</option>
                                    <option value={"3"}>3 Rating</option>
                                    <option value={"3.5"}>3.5 Rating</option>
                                    <option value={"4"}>4 Rating</option>
                                    <option value={"4.5"}>4.5 Rating</option>
                                    <option value={"5"}>5 Rating</option>
                                </select>
                                <div className="error">{errors["skilllevel"]}</div>
                            </div>
                            <div className="field-box-item">
                                <h5>Academic
                                    <span className="icon">
                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <g id="Group_57168" data-name="Group 57168">
                                                <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                            </g>
                                        </svg>
                                    </span>
                                </h5>
                                <select
                                    name="acadamicrating"
                                    value={field?.acadamicrating || "0"}
                                    onChange={(e) => handleChange("acadamicrating", e)}
                                >
                                    <option value={"0"}>Select</option>
                                    <option value={"0.5"}>0.5 Rating</option>
                                    <option value={"1"}>1 Rating</option>
                                    <option value={"1.5"}>1.5 Rating</option>
                                    <option value={"2"}>2 Rating</option>
                                    <option value={"2.5"}>2.5 Rating</option>
                                    <option value={"3"}>3 Rating</option>
                                    <option value={"3.5"}>3.5 Rating</option>
                                    <option value={"4"}>4 Rating</option>
                                    <option value={"4.5"}>4.5 Rating</option>
                                    <option value={"5"}>5 Rating</option>
                                </select>
                            </div>
                        </div>
                        <div className="report-table-wrap">
                            <div className="top-block">
                                <h5>
                                    Projected Divisions
                                    <span className="icon">
                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <g id="Group_57168" data-name="Group 57168">
                                                <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="info-box">
                                        <span className="item">
                                            <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.71" height="9.704" viewBox="0 0 12.71 9.704">
                                                    <g id="Component_19_6" data-name="Component 19 – 6" transform="translate(0.357 0.354)">
                                                        <path id="check" d="M3.808,12.3l7.264-7.143L12,6.1,3.808,14.156,0,10.411l.96-.944Z" transform="translate(0 -5.156)" fill="#1cb79e" stroke="#1cb79e" strokeWidth="0.5"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                            Yes
                                        </span>
                                        <span className="item">
                                            <span className="icon">
                                                <svg id="Component_21_10" data-name="Component 21 – 10" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                    <circle id="Ellipse_86" data-name="Ellipse 86" cx="10" cy="10" r="10" fill="#fdb944"></circle>
                                                    <g id="question" transform="translate(-5.813 -3.875)">
                                                        <path id="Path_2280" data-name="Path 2280" d="M11.813,11.261h1.763a1.706,1.706,0,0,1,1.816-1.85,1.551,1.551,0,0,1,1.753,1.561c0,.849-.5,1.239-1.288,1.833a3.046,3.046,0,0,0-1.56,2.657l.009.619h1.745v-.475c0-.96.364-1.239,1.348-1.987a3.263,3.263,0,0,0,1.661-2.749c0-2.02-1.7-3-3.569-3-1.771,0-3.72.865-3.678,3.386ZM13.9,18.636a1.258,1.258,0,0,0,1.348,1.239,1.254,1.254,0,1,0,0-2.5A1.256,1.256,0,0,0,13.9,18.636Z" fill="#fff"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                            Maybe
                                        </span>
                                        <span className="item">
                                            <span className="icon">
                                                <svg id="Component_22_14" data-name="Component 22 – 14" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                    <g id="Ellipse_85" data-name="Ellipse 85" fill="#fff" stroke="#000" strokeWidth="1">
                                                        <circle cx="10" cy="10" r="10" stroke="none"></circle>
                                                        <circle cx="10" cy="10" r="9.5" fill="none"></circle>
                                                    </g>
                                                    <path id="close" d="M17,9.7,16.3,9,13,12.3,9.7,9,9,9.7,12.3,13,9,16.3l.7.7L13,13.7,16.3,17l.7-.7L13.7,13Z" transform="translate(-2.5 -2.5)" stroke="#000" strokeWidth="0.3"></path>
                                                </svg>
                                            </span>
                                            Unrated
                                        </span>
                                    </span>
                                </h5>
                                <p>(Ex. If athlete isn't ready to be rated then leave all boxes unrated)</p>
                                <div className="btn-wrap">
                                    <button className="btn">Scale</button>
                                </div>
                            </div>
                            <div className="report-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Division </td>
                                            <td>Top </td>
                                            <td>High </td>
                                            <td>Mid </td>
                                            <td>Low </td>
                                        </tr>
                                    </thead>
                                    {collegeDivisionData?.length > 0 ? collegeDivisionData?.map(item => {
                                        return <tbody>
                                            <tr>
                                                <td>{item?.collegeDivisionName}</td>
                                                <td onClick={(e) => { handleCollegeLevelData("top", e, item) }}>
                                                    {item?.top === "yes" ? <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                            <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                                <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                                <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            </g>
                                                        </svg>
                                                    </span> : item?.top === "maybe" ? <span className="icon">
                                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                    </span> : <span className="icon">
                                                        <svg id="Component_22_14" data-name="Component 22 – 14" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Ellipse_85" data-name="Ellipse 85" fill="#fff" stroke="#000" strokeWidth="1">
                                                                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                                                                <circle cx="10" cy="10" r="9.5" fill="none"></circle>
                                                            </g>
                                                            <path id="close" d="M17,9.7,16.3,9,13,12.3,9.7,9,9,9.7,12.3,13,9,16.3l.7.7L13,13.7,16.3,17l.7-.7L13.7,13Z" transform="translate(-2.5 -2.5)" stroke="#000" strokeWidth="0.3"></path>
                                                        </svg>
                                                    </span>}
                                                </td>
                                                <td onClick={(e) => { handleCollegeLevelData("high", e, item) }}>
                                                    {item?.high === "yes" ? <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                            <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                                <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                                <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            </g>
                                                        </svg>
                                                    </span> : item?.high === "maybe" ? <span className="icon">
                                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                    </span> : <span className="icon">
                                                        <svg id="Component_22_14" data-name="Component 22 – 14" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Ellipse_85" data-name="Ellipse 85" fill="#fff" stroke="#000" strokeWidth="1">
                                                                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                                                                <circle cx="10" cy="10" r="9.5" fill="none"></circle>
                                                            </g>
                                                            <path id="close" d="M17,9.7,16.3,9,13,12.3,9.7,9,9,9.7,12.3,13,9,16.3l.7.7L13,13.7,16.3,17l.7-.7L13.7,13Z" transform="translate(-2.5 -2.5)" stroke="#000" strokeWidth="0.3"></path>
                                                        </svg>
                                                    </span>}
                                                </td>
                                                <td onClick={(e) => { handleCollegeLevelData("mid", e, item) }}>
                                                    {item?.mid === "yes" ? <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                            <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                                <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                                <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            </g>
                                                        </svg>
                                                    </span> : item?.mid === "maybe" ? <span className="icon">
                                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                    </span> : <span className="icon">
                                                        <svg id="Component_22_14" data-name="Component 22 – 14" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Ellipse_85" data-name="Ellipse 85" fill="#fff" stroke="#000" strokeWidth="1">
                                                                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                                                                <circle cx="10" cy="10" r="9.5" fill="none"></circle>
                                                            </g>
                                                            <path id="close" d="M17,9.7,16.3,9,13,12.3,9.7,9,9,9.7,12.3,13,9,16.3l.7.7L13,13.7,16.3,17l.7-.7L13.7,13Z" transform="translate(-2.5 -2.5)" stroke="#000" strokeWidth="0.3"></path>
                                                        </svg>
                                                    </span>}
                                                </td>
                                                <td onClick={(e) => { handleCollegeLevelData("low", e, item) }}>
                                                    {item?.low === "yes" ? <span className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                            <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                                <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                                <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            </g>
                                                        </svg>
                                                    </span> : item?.low === "maybe" ? <span className="icon">
                                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                    </span> : <span className="icon">
                                                        <svg id="Component_22_14" data-name="Component 22 – 14" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Ellipse_85" data-name="Ellipse 85" fill="#fff" stroke="#000" strokeWidth="1">
                                                                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                                                                <circle cx="10" cy="10" r="9.5" fill="none"></circle>
                                                            </g>
                                                            <path id="close" d="M17,9.7,16.3,9,13,12.3,9.7,9,9,9.7,12.3,13,9,16.3l.7.7L13,13.7,16.3,17l.7-.7L13.7,13Z" transform="translate(-2.5 -2.5)" stroke="#000" strokeWidth="0.3"></path>
                                                        </svg>
                                                    </span>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    }) : <tbody><tr>No data found</tr></tbody>}
                                </table>
                            </div>
                        </div>
                        <div className="field-box-item">
                            <h5>Scouting Summary
                                <span className="icon">
                                    <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <g id="Group_57168" data-name="Group 57168">
                                            <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                        </g>
                                    </svg>
                                </span>
                            </h5>
                            <label>(Ex. Describe athletic, academic, performance, and intangibles)</label>
                            <textarea
                                placeholder="Andrew in an excellent 3B with plus athleticism..."
                                value={field?.scoutingsummary || ""}
                                maxLength="500"
                                onChange={(e) => {
                                    handleChange("scoutingsummary", e);
                                }}
                            ></textarea>
                        </div>
                        <div className="field-box-item">
                            <h5>Areas for improvement
                                <span className="icon">
                                    <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <g id="Group_57168" data-name="Group 57168">
                                            <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                        </g>
                                    </svg>
                                </span>
                            </h5>
                            <label>(Ex. Describe athletic, academic, performance, and intangibles)</label>
                            <textarea
                                placeholder="Andrew in an excellent 3B with plus athleticism..."
                                value={field?.areasforimprovement || ""}
                                onChange={(e) => {
                                    handleChange("areasforimprovement", e);
                                }}
                            ></textarea>
                        </div>
                        <div className="field-box-item">
                            <h5>Additional website link</h5>
                            <label>(Ex. schedule, stats, profile link, twitter)</label>
                            <input
                                type="text"
                                placeholder="https//www.sportsforce.com/andrew/schedule-stats-reports/"
                                value={field?.additionalwebsitelink || ""}
                                onChange={(e) => {
                                    handleChange("additionalwebsitelink", e);
                                }}
                            />
                        </div>
                        <div className="field-box-item">
                            <h5>Who can view this report?</h5>
                            <ul className="checkbox-group">
                                {/* <li>
                                <span className="checkbox"><input type="checkbox" checked={field?.reportviewedby.includes("COLLEGECOACH") ? true : false} onChange={(e) => handleCheckbox("reportviewedby", "COLLEGECOACH", e)} /><label>College Coach / Scout</label></span>
                            </li> */}
                                <li>
                                    <span className="checkbox"><input type="checkbox" checked={field?.reportviewedby === "SPORTSFORCE" ? true : false} onChange={(e) => handleCheckbox("reportviewedby", "SPORTSFORCE", e)} /><label>SportsForce Staff</label></span>
                                </li>
                            </ul>
                        </div>
                        <div className="btn-wrap">
                            <button className="btn blue" onClick={() => handleSubmit()}>Save</button>
                            <button className="btn" onClick={() => handleCancelBtn()}>Cancel</button>
                        </div>
                        <div className="blue-box-wrap">
                            <p>Athlete evaluations, projections, and measurements are ONLY visible to college coaches. <strong>Athletes can Not see this info.</strong></p>
                        </div>
                    </div>
                </div>
            </>
        );
    }