import axios from "axios";
import ENV from "./config";
import * as DashboardHeader from "./queryConstant/DashboardHeader/Query";
import * as Timeline from "./queryConstant/Timeline/Query";
import * as Report from "./queryConstant/Report/Query";
import * as StatsApproval from './queryConstant/StatsApproval'
import * as Notification from './queryConstant/Notification/Query'
import * as MessageQuery from "./components/Dashboard/Message/MessageQuery";
import * as ClgQuery from "./queryConstant/CollegeQuery/query";
import * as CommitQuery from './queryConstant/CommitRequest'
import * as AccountSettings from './queryConstant/AccountSettings/AccountSettingsQuery';
import * as CommonQuery from './components/common/CommonQuery';
import * as AdvisorQuery from './queryConstant/AdvisorQuery';
import History from './history'
//function for post request with authToken
//function for post request with authToken	
async function postData(url, body) {
    let postHeader = {};
    if (localStorage.getItem("authToken_Admin_Advisor")) {
        postHeader = {
            headers: {
                access_token: localStorage.getItem("authToken_Admin_Advisor"),
            },
        }
    }
    else {
        postHeader = {
            headers: {
                "x-api-key":process.env.REACT_APP_SECRET_KEY,
            },
        }
    }
    try {
        let _r = await axios.post(url, body, postHeader);
        if (_r?.data?.response?.status == 401) {
            if (_r?.data?.response?.msg == "Invalid access token.") {
                window.location.reload()
            }
            else {
                return _r;
            }
        }
        else {
            return _r;
        }
    } catch (e) { }
}

//function for get request with authToken
async function getData(url, body) {
    try {
        let postHeader = {};
        if(localStorage.getItem("authToken_Admin_Advisor")){
            postHeader = {
                access_token: localStorage.getItem("authToken_Admin_Advisor"),
                "x-api-key": process.env.REACT_APP_SECRET_KEY,
            }
        }
        else{
            postHeader = {
                "x-api-key": process.env.REACT_APP_SECRET_KEY,
            }
        }
        return axios({
            url: url,
            method: "get",
            body,
            headers: postHeader,
        });
    } catch (e) { }
}
//function for get request without authToken
async function getDataWithToken(url, body) {
    try {
        return axios({
            url: url,
            method: "get",
            body,
            headers: { "x-api-key": process.env.REACT_APP_SECRET_KEY, },
        });
    } catch (e) { }
}
//function for post request without authToken	
async function postDataWithToken(url, body) {
    try {
        let _r = await axios.post(url, body, {
            headers: {
                "x-api-key": process.env.REACT_APP_SECRET_KEY,
            }
        });
        return _r;
    } catch (e) { }
}
// declare a response interceptor
axios.interceptors.response.use(null, (error) => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;
    if (!expectedError) {
        console.log("rejected")
    }
    if (error?.response?.status === 401) {
        History.push("/");
    }
    return Promise.reject(error);
})

const location = {
    //function for account login
    login: async (body) => {
        const url =
            ENV.WEB_URI + `/login?email=${body.email}&password=${body?.password?.includes("#") ?
            body?.password?.replace(/#/g, "%23") : body?.password
        }`;
        let r = await postData(url, {email : body?.email, password : body?.password});
        return r;
    },
    searchAthlete: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Athletes/searchAthlete`;
        let r = await postData(url, body);
        return r;
    },
    userProfileApi: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: DashboardHeader.saveUserProfile,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getOverviewData: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Publicprofilefieldpreviews/statsMatrixDetailFromOverView`;
        let r = await postData(url, body);
        return r;
    },
    getAthleteData: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Publicprofilefieldpreviews/getAthleteProfileData`;
        let r = await postData(url, body);
        return r;
    },
    reqToAdvisor: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Athletes/requestForAdvisor`;
        let r = await postData(url, body);
        return r;
    },
    editProfileTimelineData: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Publicprofilefieldpreviews/getProfileViewStatus`;
        let r = await postData(url, body);
        return r;
    },
    updateProfileTimelineData: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Publicprofilefieldpreviews/updatePublicProfileStatus`;
        let r = await postData(url, body);
        return r;
    },
    sendPostOnEmail: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Userposts/sendPostOnEmail`;
        let r = await postData(url, body);
        return r;
    },
    sendPostOnText: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Userposts/sendPostOnText`;
        let r = await postData(url, body);
        return r;
    },
    getAllAthleteData: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: DashboardHeader.getAllAthletesDataQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    timelineData: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Userposts/timeLine`;
        let r = await postData(url, body);
        return r;
    },
    savePostLike: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Timeline.savePostLikeQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getVideoPhotoData: async (body) => {
        const url = ENV.WEB_URI + `/api/Uploadedvideoandphotos/getAllPhotosAndVideosData`;
        let r = await postData(url, body);
        return r;
    },
    getEvaluation: async (body) => {
        const url = ENV.WEB_URI + `/api/Evaluationreports/getEvaluationReport`;
        let r = await postData(url, body);
        return r;
    },
    getAllCollege: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Timeline.getAllCollegeQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllCoach: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Timeline.coachApiData,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllAboutData: async (body) => {
        const url = ENV.WEB_URI + `/api/Publicprofilefieldpreviews/allAbout`;
        let r = await postData(url, body);
        return r;
    },
    getRelationshipData: async (body) => {
        const url = ENV.WEB_URI + `/allRelation`;
        let r = await getData(url, body);
        return r;
    },
    getStatsTimelineData: async (body) => {
        const url = ENV.WEB_URI + `/api/Publicprofilefieldpreviews/statsMainHeaderForProfile`;
        let r = await postData(url, body);
        return r;
    },
    getStatsMatrixDetail: async (body) => {
        const url = ENV.WEB_URI + `/api/Publicprofilefieldpreviews/statsMatrixDetailForProfile`;
        let r = await postData(url, body);
        return r;
    },
    getAllPrimaryPosition: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.primaryPositionData,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getPrimaryPositionFields: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.primaryPositionFields,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllAdvisor: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.getAdvisorData,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllCollegeDivision: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.getCollegeDivisions,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllCollegeDivisionForDraft: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.getCollegeDivisionsForDraft,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    saveReportData: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.saveReport,
            variables: {
                obj: body,
            },
        };
        let r = await postData(url, query);
        return r;
    },
    createProjected: async (body) => {
        const url = ENV.WEB_URI + `/api/Projecteddivisions/createProjecteddivisions`;
        let r = await postData(url, body);
        return r;
    },
    getAllBatData: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.getAllBattingPreferencesQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllThrowData: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.getAllThrowingPreferencesQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllHeightFeetData: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.getAllHeightInFeetsQuery,
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    getAllHeightInchesData: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.getAllHeightInInchesQuery,
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    getEvaluationDraftData: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.evaluationDraftQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    saveEvaluationDraftData: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.saveEvaluationdraft,
            variables: {
                obj: body,
            },
        };
        let r = await postData(url, query);
        return r;
    },
    getAllJumpData: async () => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.getAllJumpData,
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    // sentNotification: async (body) => {
    //     const url = ENV.WEB_URI_GRAPHQL;
    //     const query = {
    //         query: Report.sentNotification,
    //         variables: {
    //             obj: body,
    //         },
    //     };
    //     let r = await postData(url, query);
    //     return r;
    // },
    deleteDraft: async (body) => {
        const url = ENV.WEB_URI + `/api/Evaluationreports/evaluationDraftDelete`;
        let r = await postData(url, body);
        return r;
    },
    saveFeaturedVideoToAdmin: async (body, isToken) => {
        const url = ENV.WEB_URI + `/api/Publicprofilefieldpreviews/requestFeaturedVideoToAdmin`;
        let r = await isToken ? postData(url, body) : postDataWithToken(url, body);
        return r;
    },
    getCountriesData: async (body) => {
        const url = ENV.WEB_URI + `/allCountry`;
        let r = await getDataWithToken(url, body);
        return r;
    },
    atheleteInfoList: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Advisors/athleteInfo`;
        let r = await postData(url, body);
        return r;
    },
    getAllSportsData: async () => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report.getAllSportsData,
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    atheleteInvitation: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Advisors/sendInvitationToAthlete`;
        let r = await postData(url, body);
        return r;
    },
    atheleteParentInvitation: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Advisors/sendInvitationToParent`;
        let r = await postData(url, body);
        return r;
    },
    adminInvitationToAll: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Advisors/inviteAll`;
        let r = await postData(url, body);
        return r;
    },
    activateOrDeactivateUser: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Advisors/activateOrdeactivateUser`;
        let r = await postData(url, body);
        return r;
    },
    sendEmail: async (body) => {
        const url = ENV.WEB_URI + `/api/Userdata/sendForgotMail`;
        let r = await postData(url, body);
        return r;
    },
    checkRole: async (body) => {
        const url = ENV.WEB_URI + `/api/Userdata/getUserRole`;
        let r = await postData(url, body);
        return r;
    },
    enterOTP: async (body) => {
        const url = ENV.WEB_URI + `/api/Userdata/matchForgotOtp`;
        let r = await postData(url, body);
        return r;
    },
    resetPassword: async (body) => {
        const url = ENV.WEB_URI + `/api/Userdata/resetPassword`;
        let r = await postData(url, body);
        return r;
    },
    getAllStatsData: async (body) => {
        const url = ENV.WEB_URI + `/api/Advisors/statsAprovalFilters`;
        let r = await postData(url, body);
        return r;

    },
    //api call for athlete all sports
    getStatsFilterSports: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: StatsApproval?.getAllAthleteSports,
            variables: !!body ? body : null
        };
        let r = await postData(url, query);
        return r;
    },
    //api call for grad year
    getStatsFilterGYear: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: StatsApproval?.getAllGraduationYear,
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //api call fir all team
    getStatsFilterTeam: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: StatsApproval?.getAllTeamName,
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //api call fir all team in where condition
    getStatsWhereTeam: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: StatsApproval?.getAllTeamNameDynamic,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    //api call for egt all advisor
    getStatsFilterAdvisor: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: StatsApproval?.getAllAdvisor,
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //api call for gp scale
    getStatsFilterGpscale: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: StatsApproval?.getAllGpScale,
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //api call for primary position
    getStatsFilterPri: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: StatsApproval?.getAllPrimaryPosBySportID,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    //api call is for stats approval
    onStatsApproval: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: StatsApproval?.onStatsApproval(body),
            // variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //api call is for stats disapproval
    onStatsDisApproval: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: StatsApproval?.onStatsDisApproval(body),
            // variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //api call is for stats and athlete program
    getAllProgramType: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: StatsApproval?.getAllProgramTypeQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    //api call is for stats disapproval
    onStatsDisApprovalRestApi: async (body) => {
        const url = ENV.WEB_URI + '/api/Statmatrixdetails/verifyRejectStatsAndNotify';
        let r = await postData(url, body);
        return r;
    },
    //api call is for login athlete
    onStatsProfileOpen: async (body) => {
        const url = ENV.WEB_URI + '/api/Advisors/athleteloginProfiledata';
        let r = await postData(url, body);
        return r;
    },
    //api call is for colleges
    getAllCollegeData: async (body) => {
        const url = ENV.WEB_URI + `/allCollegeList/${body.collegeName}`;
        let r = await getData(url, body);
        return r;
    },
    //api call is for login athlete
    requestCommitment: async (body) => {
        const url = ENV.WEB_URI + '/api/Commitsforverifications/RequestCommitment';
        let r = await postData(url, body);
        return r;
    },
    //api call is for dashboard count
    dashboardData: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: DashboardHeader.dashboardDataCountQuery
            // variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //api call is for notification count
    notificationData: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Notification.notificationContentQuery(body)
            // variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //api call is for notification count
    notificationDataDyRead: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Notification.notificationContentDyReadQuery(body)
            // variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //api call is for notification count
    advisorNotificationDataUnRead: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Notification.advisorUnreadNotificationContentQuery(body)
            // variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //api call is for notification count
    notificationAdvisorData: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Notification.advisorNotificationContentQuery(body)
            // variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //api call for admin dashboard percentage
    getPercentage: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Advisors/dashboardPercent`;
        let r = await postData(url, body);
        return r;
    },
    inviteToAllLink: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Athletes/inviteToAll`;
        let r = await postData(url, body);
        return r;
    },
    //for reading single notification on notification page
    readValueApi: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Notification?.saveReadValue(body),
            //variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //for reading single notification on notification page
    advisorReadValueApi: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Notification?.saveAdvisorReadValue(body),
            //variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //for reading all notifications on notification page
    markReadForAllApi: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Notification?.markReadForAll(body),
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //for reading all notifications on notification page
    advisorMarkReadForAll: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Notification?.advisorMarkReadForAll(body),
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //for checking new notifications on bell icon
    checkNewNotificationApi: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Notification?.checkNewNotificationQuery(body),
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //for checking new notifications on bell icon
    advisorNewNotificationApi: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Notification?.advisorCheckNewNotificationQuery(body),
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //for marking all notifications on bell icon
    markNotificationApi: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Notification?.markNotificationQuery(body),
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //for marking all notifications on bell icon advisor
    advisorNotificationUpdateAll: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Notification?.advisorMarkNotificationQuery(body),
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    // for making getting permalink for single user
    getPermalinkUrl: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: Report?.getPermalinkQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getMessagesReplyTotalCount: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: MessageQuery?.getMessageReplyCount,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAdvisiorMessages: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: MessageQuery?.getAdvisiorMessageQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllMessages: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: MessageQuery?.getMessageQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllMessageReplies: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: MessageQuery?.getMessageReplyQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    saveMessages: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: MessageQuery?.saveMessageApi,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    saveMessagereply: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: MessageQuery?.saveMessagereplyApi,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    saveUserActivity: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: MessageQuery?.AddUserActivity,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllAthleteDropDown: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: DashboardHeader.getAllAthleteDropDown,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllAdvisors: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: DashboardHeader.getAllAdvisors,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllCoaches: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: DashboardHeader.getAllCoaches,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    updateMessageReplies: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: DashboardHeader.updateMessageReply,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    deleteOrMovetoInbox: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Messages/deleteOrMovetoInbox`;
        let r = await postData(url, body);
        return r;
    },
    //this api is for notifcation when user click on like on any video / photo
    flameAPI: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Notificationsettingmasters/sendnotification`;
        let r = await postData(url, body);
        return r;
    },
    //this api is for video count increase when user click on like on any video / photo
    vidCount: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Viewvideos/IncreaseCount`;
        let r = await postData(url, body);
        return r;
    },
    //this api is for notifcation when user click on like on any video / photo
    tagClgAPI: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Collegetags/favOrTagcollege`;
        let r = await postData(url, body);
        return r;
    },
    //api req for tagging the clg
    tagUntagClg: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: ClgQuery.saveClgTagUnTag,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    //api req for deleting the data from the table
    deltagUntagClg: async (id) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: ClgQuery.delClgTag(id),
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    updateUserClg: async (data) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: ClgQuery.updateUserClg(data),
            variables: null
        };
        let r = await postData(url, query);
        return r;
    },
    //this api is for notifcation when user click on like on any video / photo
    newAthAddAPI: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Athletes/createAthlete`;
        let r = await postData(url, body);
        return r;
    },
    //this api is for request commitment
    requestCommitOnCS: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Commitsforverifications/getCommitmentReq`;
        let r = await postData(url, body);
        return r;
    },
    //this api is for request commitment
    requestAppRej: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Commitsforverifications/acceptOrRejectCommit`;
        let r = await postData(url, body);
        return r;
    },
    commitDetail: async (data) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: CommitQuery.commitDetail,
            variables: data
        };
        let r = await postData(url, query);
        return r;
    },
    //This is api is used for sending message
    sendMessageToAnyRole: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Messages/composeMessage`;
        let r = await postData(url, body);
        return r;
    },
    //This api is used to get user details
    getUserData: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: MessageQuery?.getUserData,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAdvisorAthleteMappings: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: DashboardHeader.getAdvisorAthletes,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    advisorChangePassword: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: AccountSettings.savePasswordChangeQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAdvisorNotificationSettings: async (body) => {
        const url = ENV.WEB_URI + `/api/Notificationadvisorsettings/notificationAdvisorSettingValue`;
        let r = await postData(url, body);
        return r;
    },
    saveNotificationSettings: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: AccountSettings.notificationSettingQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    saveAdvisorNotificationSettings: async (body) => {
        const url = ENV.WEB_URI + `/api/Notificationadvisorsettings/updateNotificationAdvisorStatus`;
        let r = await postData(url, body);
        return r;
    },
    sendNotificationToAdvisor: async (body) => {
        const url = ENV.WEB_URI + `/api/Notificationadvisorsettings/sendnotificationtoadvisor`;
        let r = await postData(url, body);
        return r;
    },
    saveCommitmentDetail: async (data) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: CommitQuery.saveCommitsforverification,
            variables: data
        };
        let r = await postData(url, query);
        return r;
    },
    atheleteParentInfoOnInvite: async (body) => {
        const url =
            ENV.WEB_URI + `/api/Athletes/getAthleteParentInfo`;
        let r = await postData(url, body);
        return r;
    },
    //this server req is for claim profile
    onSubmitClaimProfile: async (body) => {
        const url = ENV.WEB_URI + `/api/UserData/claimProfileCase2`;
        let r = await postData(url, body);
        return r;
    },
    adminToAdvisorNoty: async (body) => {
        const url = ENV.WEB_URI + `/api/Notificationadvisorsettings/sendnotificationtoadvisor`;
        let r = await postData(url, body);
        return r;
    },
    removeSelectedAthletes: async (body) => {
        const url = ENV.WEB_URI + `/api/advisors/removeSelectedAthletes`;
        let r = await postData(url, body);
        return r;
    },
    removeAllAthletes: async (body) => {
        const url = ENV.WEB_URI + `/api/Advisors/removeAllAthletes`;
        let r = await postData(url, body);
        return r;
    },
    assignAdvisorToSelectedAthletes: async (body) => {
        const url = ENV.WEB_URI + `/api/Advisors/mapSelectedAthletesToAdvisor`;
        let r = await postData(url, body);
        return r;
    },
    assignAdvisorToAllAthletes: async (body) => {
        const url = ENV.WEB_URI + `/api/Advisors/mapFilteredAthletesToAdvisor`;
        let r = await postData(url, body);
        return r;
    },
    getAllSubscriptionPlan: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: AccountSettings.allSubscriptionPlanQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    createNewAthlete: async (body) => {
        const url = ENV.WEB_URI + `/api/Userdata/createAthleteByAdmin`;
        let r = await postData(url, body);
        return r;
    },
    getAllSubscriptionPlanType: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: AccountSettings.allSubscriptionPlanTypeQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },    
    checkInviteStatus: async (body) => {
        const url = ENV.WEB_URI + `/api/Athletes/bulkOperationStatus`;
        let r = await getData(url, body);
        return r;
    },
    getAthleteEditInfo: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: CommonQuery.getAthleteInfoQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    cancelAthletePlan: async (body) => {
        const url = ENV.WEB_URI + `/api/Payments/cancelAthletePlans`;
        let r = await postData(url, body);
        return r;
    },
    removeAdvisorEditAth: async (body) => {
        const url = ENV.WEB_URI + `/api/Advisorathletemappings/removeAdvisor`;
        let r = await postData(url, body);
        return r;
    },
    verifyAthlete: async (body) => {
        const url = ENV.WEB_URI + `/api/Athletes/verifyUnverifySelectedAthletes`;
        let r = await postData(url, body);
        return r;
    },
    verifyAllAthlete: async (body) => {
        const url = ENV.WEB_URI + `/api/Advisors/verifyAllAthletes`;
        let r = await postData(url, body);
        return r;
    },
    advisorDataList: async (body) => {
        const url = ENV.WEB_URI + `/api/Advisors/advisorInfo`;
        let r = await postData(url, body);
        return r;
    },
    addNewAdvisor: async (body) => {
        const url = ENV.WEB_URI + `/api/Advisors/createAdvisor`;
        let r = await postData(url, body);
        return r;
    },
    getAdvisorDetail: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: AdvisorQuery.getAdvisorDetailsQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    saveAdvisorDetail: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: AdvisorQuery.saveAdvisorDetailQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    removeAdvisor: async (body) => {
        const url = ENV.WEB_URI + `/api/Advisors/removeAdvisor`;
        let r = await postData(url, body);
        return r;
    },
    getAthleteConnected: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: AdvisorQuery.getAdvisorAthleteDetailsQuery,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    inviteAdvisorSel: async (body) => {
        const url = ENV.WEB_URI + `/api/Advisors/inviteSelectedAdvisors`;
        let r = await postData(url, body);
        return r;
    },
    inviteAllAdvisor: async (body) => {
        const url = ENV.WEB_URI + `/api/Advisors/inviteAllAdvisors`;
        let r = await postData(url, body);
        return r;
    },
    checkAdvisorAssignAll: async (body) => {
        const url = ENV.WEB_URI + `/api/Advisors/checkAssignAdvisorStatus`;
        let r = await postData(url, body);
        return r;
    },
    getAllLeadSSource: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: AccountSettings.allLeadSource,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
    getAllAppointmentSetter: async (body) => {
        const url = ENV.WEB_URI_GRAPHQL;
        const query = {
            query: AccountSettings.allAppointmentSetter,
            variables: body
        };
        let r = await postData(url, query);
        return r;
    },
}

const locationComponent = {
    location
}

export default locationComponent;