import React from "react";
import DashboardHeader from "../header/DashboardHeader";
import {AdminHeader} from "../header/adminHeader";
import {AdminFooter} from "../header/adminFooter";
const AdvisorHomePage = (props) => {
  return (
      <div className={'content'}>
          <AdminHeader
              title={"Dashboard"}
          />
          <AdminFooter />
      </div>
  );
};

export default AdvisorHomePage;
