import {getAuthToken} from "../../utils/constant";

export const profileURLData = (url, type, loc_state = false) =>{
    const data = url?.split("/"),
        newData = data[data?.length - 1],
        finalData = newData?.split("-")
    if (type == 'token'){
        const rValue = !!getAuthToken() ? 1 : 0
        // +finalData[finalData?.length - 1]?.split("")?.[0]
        return rValue
    }
    else if (type == "athlete_id"){
        // let new_id = "",
        //     newID = finalData[finalData?.length - 1]?.split("")
        // newID?.forEach((id, idx) =>{
        //     if (idx > 1 && idx < 7){
        //         new_id = new_id+id
        //     }
        // })
        const new_id = finalData[finalData?.length - 1]
        return +new_id
    }
    //this value is 2 of the number
    else if (type == "profile"){
        const pValue = finalData[finalData?.length - 1]?.split("")?.[1]
        const value =  pValue == 0 ? false : true
        return value
    }
    //this value is last of the number
    else if (type == "public_profile"){
        if (loc_state){
            return false
        }else{
            const ppCode = finalData[finalData?.length - 1]?.split("")
            const value = ppCode?.[ppCode?.length - 1] == 0 ? false : true
            return value
        }
    }
    else if (type == "visitor"){
        const pCode = finalData[finalData?.length - 1]?.split("")?.[1] == 0 ? false : true,
            ppCode = finalData[finalData?.length - 1]?.split(""),
            ppCodeFinal = ppCode[ppCode?.length - 1] == 0 ? false : true
        if (pCode == false && ppCodeFinal == true){
            return true
        }else{
            return false
        }
    }
    else if (type == "report_id"){
        const new_id = +finalData[finalData?.length - 1],
            userid = JSON.parse(localStorage.getItem(`report_userid_${new_id}`))
        const reportID = !!userid ? userid : 0
        return reportID
    }

}