import React, {useEffect, useRef, useState} from 'react'
import "../../header/assets/css/stylesheet.scss";
import "../../header/assets/css/dashboard.scss";
import BackdropLoader from "../../common/Loader";
import {getUserInfo} from "../../../utils/constant";
import AdvisorHeader from "../../header/advisorHeader";
import {AdminHeader} from "../../header/adminHeader";
import {AdminFooter} from "../../header/adminFooter";
import InputLabel from "@material-ui/core/InputLabel";
import { Input, Select, FormControl, Dialog } from "@material-ui/core";
import {AdvisorStatusJSON} from "../../../utils/staticJSON";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import services from "../../../services";
import Pagination from "react-js-pagination";
import {AddNewAdvisorModal, InviteAdvisorPop, RemoveAdvisor} from "./advisorModals";
import ENV from "../../../config";
import {checkPhoneNumberValidation, formatPhoneNumber, NewValidName} from "../../../utils/validationUtils";
import {IsValidEmail} from "../../common/validation";
import toastr from 'toastr'
import  History from '../../../history'
import moment from "moment";
import {useDispatch} from "react-redux";
const initialFilter = {
    advisorName:"",
    limit: 10,
    offset: 0,
    order:"DESC",
    inviteStatus:[]
}
const initialState = {
    advisorName:"",
    limit: 10,
    offset: 0,
    order:"DESC",
    inviteStatus:[]
}
export const AdvisorInfo = () =>{
    const [isLoading, setIsLoading] = useState(false),
          [isFilter, setIsfilter] = useState(false),
          [fields, setFields] = useState({advisorName:"",
              limit: 10,
              offset: 0,
              order: "DESC",
              inviteStatus:[]}),
          [advisorApp, setAdvisorApp] = useState({advisorName:"",
            limit: 10,
            offset: 0,
            order: "DESC",
            inviteStatus:[]}),
          [advisorList, setAdvisorList] = useState([]),
          [currentPage, setCurrentPage] = useState(1),
          [totalCount, setTotalCount] = useState(0),
          [goNum, setGoNum] = useState(1),
          [isAddAdvisorPop, setIsAddAdvisorPop] = useState(false),
          [isNew, setIsNew] = useState(false),
          [show, setShow] = useState(false),
          [isSetting, setIsSetting] = useState(false),
          [allCountryList, setAllCountryList] = useState([]),
          [newAdvError, setNewAdvError] = useState({}),
          [newAdvData, setNewAdvData] = useState({phoneNumber:""}),
          [isAdvEdit, setIsAdvEdit] = useState(false),
          [isClickAECount, setIsClickAECount] = useState(1),
          [isSettingIndx, setIsSettingIndx] = useState(null),
          [isAdvRemove, setIsAdvRemove] = useState(false),
          [removeAdvData, setRemoveAdvData] = useState({}),
          [listData, setListData] = useState([""]),
          [inviteList, setInviteList] = useState([]),
          [checkAll, setCheckAll] = useState(false),
          [inviteStatus, setInviteStatus] = useState(false),
          [isInvitePop, setIsInvitePop] = useState(false),
          [isClickInviteAllPop, setIsClickInviteAllPop] = useState(false),
          domRef = useRef(),
          checkedRef = useRef(),
          settingRef = useRef(),
          dispatch = useDispatch();

    useState(()=>{
        var handler = event => {
            if (!domRef?.current?.contains(event?.target)) {
                setIsNew(false)
            }
            if (!checkedRef?.current?.contains(event?.target)) {
                setShow(false)
            }
            if (!settingRef?.current?.contains(event?.target)) {
                setIsSetting(false)
            }
        }
        document?.addEventListener("mousedown", handler)
    })

    useEffect(() => {
        if (inviteList.length === listData.length) {
            setCheckAll(true);
        }
    }, [inviteList]);
    useEffect(() => {
        setCheckAll(false);
        setInviteList([]);
    }, [listData]);
    useEffect(()=>{
        getAdvisorData(advisorApp)
        getAllCountry()
        refreshInviteStatus()
        dispatch({type:"nav_change", payload:{ status: true}})
    },[])

    const checkAllHandler = (e) => {
        if (e.target.checked) {
            const list = listData.map((item) => item.id);
            setInviteList(list);
            setCheckAll(true);
        } else {
            setInviteList([]);
            setCheckAll(false);
        }
    };

    const getAllCountry = async () =>{
        const response = await services.location.getCountriesData();
        if (response && response.status === 200 && response?.data?.data?.length > 0) {
            const allCountries = response.data.data ? response.data.data.map((item) => {
                if (item.countryFlagImage) {
                    item.countryFlagImage = `${ENV.DOWNLOAD_URL}/${item.countryFlagImage}`;
                }
                return item;
            }) : null;
            setAllCountryList(allCountries)
        }
        else {
            setAllCountryList([]);
        }
    }

    const getAdvisorData = async (data) =>{
        setIsLoading(true)
        const res = await services.location.advisorDataList(data)
        if (res?.status == 200 && res?.data?.response?.status == 200){
            setAdvisorList(res?.data?.response?.data?.length > 0 ? res?.data?.response?.data : [] )
            setListData(res?.data?.response?.data?.length > 0 ? res?.data?.response?.data : [])
            setTotalCount(res?.data?.response?.totalCount ?? 0)
            setIsLoading(false)
        }else{
            setTotalCount(0)
            setIsLoading(false)
            setAdvisorList([])
        }
    }

    const advisorHandle = (e, field) => {
        const clone = { ...fields };
        let newArr = [];
        if (field == "advisorName") {
            clone[field] = e?.target?.value;
        }
        clone["offset"] = 0;
        clone["limit"] = 10;
        setCurrentPage(1);
        setFields(clone);
        getAdvisorData(clone);
    }
   const advisorHandleMulti = (e, field) => {
       let cloneStats = { ...fields },
           cloneStatsFil = { ...advisorApp },
           filName = [],
           filVal = [],
           selVal = e?.target?.value?.length > 0 ? e?.target?.value : [];
      if (field == "inviteStatus") {
           cloneStats["offset"] = 0;
           cloneStats["limit"] = 10;
           if (selVal?.length > 0) {
               AdvisorStatusJSON?.map((el, idx) => {
                   return selVal.map((in_el, in_idx) => {
                       if (el?.id == in_el || el?.name == in_el) {
                           filName.push(el?.id ?? "null");
                           // filVal.push(el?.id)
                       }
                   });
               });
               cloneStats[field] = filName;
               cloneStatsFil["inviteStatus"] = selVal;
               setAdvisorApp(cloneStatsFil);
               setFields(cloneStats);
           } else {
               cloneStatsFil["inviteStatus"] = [];
               cloneStats[field] = [];
               setAdvisorApp(cloneStatsFil);
               setFields(cloneStats);
           }
       }
       setCurrentPage(1);
       getAdvisorData(cloneStats);
    }
    const clearFilter = () => {
        setFields(initialState);
        setAdvisorApp(initialFilter);
        getAdvisorData(initialState);
        setCurrentPage(1);
        setIsfilter(false)
    }
    const handlePagination = (page_num) => {
        const cloneStats = { ...fields },
            finalOffset = (page_num - 1) * cloneStats["limit"]; //offset formula is page number * limit
        cloneStats["offset"] = Math.abs(finalOffset);
        setCurrentPage(page_num);
        setFields(cloneStats);
        getAdvisorData(cloneStats);
    }
    const onHandlePerPage = (page_num) => {
        const cloneStats = { ...fields };
        cloneStats["limit"] = page_num;
        setFields(cloneStats);
        getAdvisorData(cloneStats);
    }
    const validateAthleteForm = () => {
        let status = true,
            cloneError = newAdvError;
        if (!newAdvData?.advisorFirstName) {
            cloneError['firstName'] = true
            cloneError['errorFirstName'] = "Please enter your First Name."

            setNewAdvError({ ...cloneError })
            return status = false
        }
        if (!!newAdvData?.advisorFirstName) {
            if (!NewValidName(newAdvData?.advisorFirstName)) {
                cloneError['firstName'] = true
                cloneError['errorFirstName'] = "Please enter a valid First Name"
                setNewAdvError({ ...cloneError })
                return status = false
            }
        }

        if (!newAdvData?.advisorLastName) {
            cloneError['lastName'] = true
            cloneError['errorLastName'] = "Please enter your Last Name."
            setNewAdvError({ ...cloneError })
            return status = false
        }
        if (!!newAdvData?.advisorLastName) {
            if (!NewValidName(newAdvData?.advisorLastName)) {
                cloneError['lastName'] = true
                cloneError['errorLastName'] = "Please enter a valid Last Name"
                setNewAdvError({ ...cloneError })
                return status = false
            }
        }

        if (!newAdvData?.advisorEmailId) {
            cloneError['emailId'] = true
            cloneError['errorEmailId'] = "Please enter advisor Email ID"
            setNewAdvError({ ...cloneError })
            return status == false
        }
        if (!!newAdvData?.advisorEmailId) {
            if (!IsValidEmail(!!newAdvData?.advisorEmailId ? newAdvData?.advisorEmailId : "")) {
                cloneError['emailId'] = true
                cloneError['errorEmailId'] = "Please enter a valid Email Id"
                setNewAdvError({ ...cloneError })
                return status == false
            }
        }
        if ('phoneNumber' in newAdvData) {
            if (!!newAdvData?.phoneNumber){
                if (!checkPhoneNumberValidation(newAdvData?.phoneNumber, !!newAdvData?.countryDetails?.countryShortName ?
                    newAdvData?.countryDetails?.countryShortName : "US")){
                    cloneError['mobileNumber'] = true
                    cloneError['errorMobileNumber'] = "Please enter valid advisor mobile number"
                    setNewAdvError({ ...cloneError })
                    return status == false
                }
            }else{
                cloneError['mobileNumber'] = true
                cloneError['errorMobileNumber'] = "Please enter advisor mobile number"
                setNewAdvError({ ...cloneError })
                return status == false
            }
        }
        return status
    }
    const onSubmitNewAdvisor = async (value)=>{
        const isValidate = validateAthleteForm();
        if (isValidate){
            setIsClickAECount(isClickAECount + 1)
            setIsLoading(true)
            const body ={
                advisorFirstName: newAdvData?.advisorFirstName,
                advisorLastName: newAdvData?.advisorLastName,
                advisorEmailId: newAdvData?.advisorEmailId,
                countrycodeId: newAdvData?.countryDetails?.value?.toString() ?? "1",
                // countryDetails: newAdvData?.advisorFirstName,
                phoneNumber: newAdvData?.phoneNumber,
                isinvite: value
        }
            const res = await services.location.addNewAdvisor(body)
            if (res?.status == 200 && res?.data?.response?.status == 200){
                toastr.success(!!res?.data?.response?.msg ?
                    res?.data?.response?.msg :'Advisor created successfully.')
            setIsAddAdvisorPop(false)
            setNewAdvData({phoneNumber : ""})
            setIsLoading(false)
            setIsClickAECount(1)
            getAdvisorData(fields)
            }else{
                setIsClickAECount(1)
                toastr.error(!!res?.data?.response?.msg ? res?.data?.response?.msg : "error" )
                setIsLoading(false)
            }
        }

        }
    const onHandleAdvisorChange = (e, field) =>{
        const cloneState = newAdvData
        if (e?.target?.name == "phoneNumber") {
            if (e.target.value?.length > 10) return
            cloneState[e?.target?.name] = formatPhoneNumber(e?.target?.value)
        }else{
            cloneState[e?.target.name] = e?.target?.value
        }
        setNewAdvData({ ...cloneState })
        setNewAdvError({})
    }
    const getDefaultCountry = (value) =>{
        let countryT = {}
        if(value?.countrycodeid){
            allCountryList.map(item => {
                if (item.id == value?.countrycodeid) {
                    countryT.value = item.id;
                    countryT.label = item.countryName + `(+${item.countryCode})`;
                    countryT.flag = item.countryFlagImage;
                    countryT.countryCode = item.countryCode;
                    countryT.countryShortName = item.countryShortName
                }
            })
        }
        else{
            allCountryList.map(item => {
                if (item.id == 1) {
                    countryT.value = item.id;
                    countryT.label = item.countryName + `(+${item.countryCode})`;
                    countryT.flag = item.countryFlagImage;
                    countryT.countryCode = item.countryCode;
                    countryT.countryShortName = item.countryShortName
                }
            })
        }
        return countryT
    }
    const handleCountryChange = (option) => {
        const cloneState = newAdvData;
        cloneState["countryDetails"] = option;
        cloneState['phoneNumber'] = ""
        setNewAdvData({ ...cloneState })
        setNewAdvError({})
    };
    const onClickAddEdit = async (isEdit, id) =>{
        if (isEdit){
            const finalObj = {
                obj:{
                    id: +id?.id
                }
            }
            setIsLoading(true)
            const resAdv = await services.location.getAdvisorDetail(finalObj)
            if (resAdv?.status == 200){
                setIsLoading(false)
                const cloneState = newAdvData,
                      resData = resAdv?.data?.data?.allAdvisors?.Advisors?.length > 0 ?
                          resAdv?.data?.data?.allAdvisors?.Advisors?.[0] : {}
                cloneState['id'] = resData?.id ?? 0
                cloneState['advisorFirstName'] = resData?.firstname ?? ""
                cloneState['advisorLastName'] =  resData?.lastname ?? ""
                cloneState['advisorEmailId'] =   resData?.emailid ?? ""
                cloneState['countrycodeId'] =  resData?.countrycodeid ?? 1
                cloneState['countryDetails'] = getDefaultCountry(resData)
                cloneState['phoneNumber'] =  resData?.phonenumber ?? ""
                setNewAdvData(cloneState)
                setIsAddAdvisorPop(true)
                setIsAdvEdit(true)
                setIsSetting(false)
            }else{
                setIsLoading(false)
                toastr.error('Error')
            }
        }else{
            const getValue = await getDefaultCountry(),
                cloneState = {...newAdvData }
            cloneState['countryDetails'] = getValue
            setNewAdvData(cloneState)
            setIsAddAdvisorPop(true)
            setIsAdvEdit(false)
            setIsLoading(false)
        }
    }
    const onSubmitEditAdv = async () => {
        const isValidate = validateAthleteForm();
        if (isValidate) {
            setIsClickAECount(isClickAECount + 1)
            const body = {
                obj: {
                    id: newAdvData?.id ?? 0,
                    firstname: newAdvData?.advisorFirstName,
                    lastname: newAdvData?.advisorLastName,
                    emailid: newAdvData?.advisorEmailId,
                    countrycodeid: newAdvData?.countryDetails?.value ?? 1,
                    phonenumber: newAdvData?.phoneNumber,
                }
            }
            const resEditAdv = await services.location.saveAdvisorDetail(body)
            if (resEditAdv?.status == 200){
                toastr.success('Advisor updated successfully.')
                setIsAddAdvisorPop(false)
                setNewAdvData({phoneNumber : ""})
                setIsLoading(false)
                setIsClickAECount(1)
                getAdvisorData(fields)
            }else{
                setIsLoading(false)
                setIsClickAECount(1)
                toastr.error("error")
            }
        }
    }
    const onClickSetting = (data, index) => {
            setIsSetting(!isSetting)
            setIsSettingIndx(index)
        }
    const onRemoveAdv = async () =>{
        setIsLoading(true)
        const resRem = await services.location.removeAdvisor({advisorid: removeAdvData?.id ?? ""})
         if (resRem?.status == 200 && resRem?.data?.response?.status == 200){
             toastr.success(!!resRem?.data?.response?.msg ? resRem?.data?.response?.msg : "Athlete remove successfully.")
             getAdvisorData(fields)
             setIsAdvRemove(false)
             setIsSetting(false)
             setIsLoading(false)
         }else{
          toastr.error('error')
             setIsLoading(false)
         }
     }
    const checkHandler = async (e, id) => {
        if (e.target.checked) {
            if (!inviteList.includes(id)) {
                await setInviteList((prev) => [...prev, id]);
            }
        } else {
            const index = inviteList.indexOf(id);
            const list = inviteList;
            list.splice(index, 1);
            await setInviteList(list);
            e.target.checked = false
            if (list.length !== listData.length) {
                setCheckAll(false);
            }
        }
    }
    const sendAllInvites = async () => {
        if (isClickInviteAllPop){
            if (listData.length > 0){
                setIsClickAECount(isClickAECount + 1)
                setIsInvitePop(false)
                setIsLoading(true)
                const body = JSON.parse(JSON.stringify(fields))
                delete body?.limit
                delete body?.offset
                delete body?.order
                body['createdBy'] = getUserInfo()?.id?.toString() ?? "0"
             const response = await services.location.inviteAllAdvisor(body)
                if (response?.status == 200){
                    toastr.success(!!response?.data?.response?.msg ?
                        response?.data?.response?.msg : "Message send successfully");
                    setShow(false)
                    setInviteList([])
                    setCheckAll(false)
                    setInviteStatus(true)//change done 28-2-2023
                    getAdvisorData(fields);
                    setIsLoading(false)
                    setIsClickAECount(1)
                    setIsClickInviteAllPop(false)
                } else{
                    setIsClickInviteAllPop(false)
                    toastr.success(!!response?.data?.response?.msg ?
                        response?.data?.response?.msg : "error");
                    setIsClickAECount(1)
                }
            }else{
                setIsClickAECount(1)
                setIsClickInviteAllPop(false)
                toastr.warning("Select atleast one advisor")
            }
        }else{
            if (inviteList.length > 0) {
                setIsClickAECount(isClickAECount + 1)
                setIsInvitePop(false)
                setIsLoading(true)
                const response = await services?.location?.inviteAdvisorSel({
                    advisorIds: inviteList,
                    createdBy: getUserInfo()?.id?.toString() ?? "0" //this key is added as to track how has send the req
                });
                if (response?.data?.response?.status === 200) {
                    toastr.success(!!response?.data?.response?.msg ?
                        response?.data?.response?.msg : "Invitation sent successfully.");
                    setShow(false)
                    setInviteList([])
                    setCheckAll(false)
                    setInviteStatus(true)//change done 28-2-2023
                    getAdvisorData(fields);
                    setIsLoading(false)
                    setIsClickAECount(1)
                    setIsClickInviteAllPop(false)
                    // getAtheleteListData(statsApp) //not needed
                }else{
                    toastr.success(!!response?.data?.response?.msg ?
                        response?.data?.response?.msg : "error");
                    setIsClickAECount(1)
                    setIsClickInviteAllPop(false)
                }
            }else {
                setIsClickAECount(1)
                setIsClickInviteAllPop(false)
                toastr.warning("Select atleast one advisor");
            }
        }
}
    const sendInvitation = async (ad) => {
        setIsLoading(true)
       const response = await services.location.inviteAdvisorSel(
           { advisorIds: [+ad?.id], createdBy: getUserInfo()?.id?.toString() ?? "0" })
        if (response?.data?.response?.status === 200) {
            toastr.success(!!response?.data?.response?.msg ?
                response?.data?.response?.msg : "Invitation sent successfully.");
            setShow(false)
            setInviteList([])
            setCheckAll(false)
            // setInviteStatus(true)//change done 28-2-2023
            getAdvisorData(fields);
            setIsLoading(false)
            setIsClickInviteAllPop(false)
            // getAtheleteListData(statsApp) //not needed
        }else{
            toastr.success(!!response?.data?.response?.msg ?
                response?.data?.response?.msg : "error");
            setIsLoading(false)
        }
    }
    const closeInvitePop = () => {
        setIsInvitePop(false)
        setShow(false)
        setInviteList([])
        setCheckAll(false)
        setIsClickAECount(1)
        setIsClickInviteAllPop(false)
    }
    const refreshInviteStatus = async () => {
        const _resCIS = await services?.location.checkInviteStatus();
        if(_resCIS?.data?.response?.invitealladvisors?.status == 200){
            setInviteStatus(false)
        }
        else{
            setInviteStatus(true)
        }
        // setLastInviteAllDate(_resCIS?.data?.response?.inviteallathletes?.updatedOn ? moment(_resCIS?.data?.response?.inviteallathletes?.updatedOn).format('MM/DD/YYYY HH:mm:ss') : "")
        getAdvisorData(fields);
    }
    const openInvitePopup = () =>{
        setIsClickInviteAllPop(true)
        setIsInvitePop(true)
        setIsClickAECount(1)
    }
    return(
        <>
            <BackdropLoader open={isLoading} />
            <div className={"content"}>
                {getUserInfo()?.role == "ADMIN" && <AdminHeader title={"Advisor Information"} />}
                <div className="dashboard">
                    <div className="filter-box">
                        <div className="search-box">
                            <input type="text" placeholder="Search by advisor name"
                                   value={fields?.advisorName ?? ""}
                                   onChange={(e) => advisorHandle(e, "advisorName")}
                            />
                        </div>
                        <div className="filters">
                            <h3 className="title togglebtn" data-toggle="modal" data-target="#AddNewadvisorModal"
                                id="dropdownMenuLink1" data-toggle="dropdown" style={{marginRight: "10px"}}
                                onClick={() => setIsNew(!isNew)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.386" height="18.386"
                                     viewBox="0 0 18.386 18.386">
                                    <path id="plus-round"
                                          d="M20.96,11.952H14.933V5.926a1.492,1.492,0,0,0-2.981,0v6.027H5.926a1.492,1.492,0,0,0,0,2.981h6.027V20.96a1.492,1.492,0,0,0,2.981,0V14.933H20.96a1.492,1.492,0,0,0,0-2.981Z"
                                          transform="translate(-4.25 -4.25)" fill="#3e3e3e" stroke="#fff"
                                          stroke-width="0.5"></path>
                                </svg>
                                {isNew &&
                                <ul
                                    ref={domRef}
                                    className={isNew ? "dropdownbox" : ""} aria-labelledby="dropdownMenuLink" >
                                    <li onClick={()=>onClickAddEdit(false)}>Add New Advisor</li>
                                </ul>
                                }
                            </h3>
                            <h3 className="title"
                                style={inviteStatus ? {opacity: "0.7",marginRight: "10px", cursor:"pointer"} : {marginRight: "10px", cursor:"pointer"}}
                            onClick={()=>listData.length > 0 ? !inviteStatus ?  openInvitePopup() : null :
                                toastr.warning('Select Atleast  One Advisor')}
                            >Invite All</h3>
                            <h3 className="title" style={{marginRight: "10px", cursor:"pointer"}}
                            onClick={()=>refreshInviteStatus()}
                            >Refresh</h3>
                            <h3 className="title" data-toggle="modal" data-target="#FilterModal"
                            onClick={()=>setIsfilter(true)}
                            >
              <span className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="12.082" height="12.083" viewBox="0 0 12.082 12.083">
                  <path id="filter"
                        d="M12.11,0H.972A.472.472,0,0,0,.5.472,4.61,4.61,0,0,0,2.046,3.914L4.113,5.752a1.682,1.682,0,0,1,.564,1.256v4.6A.472.472,0,0,0,5.411,12L8.2,10.147a.472.472,0,0,0,.21-.393V7.008a1.682,1.682,0,0,1,.564-1.256l2.067-1.837A4.61,4.61,0,0,0,12.582.472.472.472,0,0,0,12.11,0Zm-1.7,3.209L8.342,5.046a2.627,2.627,0,0,0-.881,1.961V9.5L5.621,10.728V7.008A2.627,2.627,0,0,0,4.74,5.046L2.673,3.209A3.666,3.666,0,0,1,1.475.944H11.608a3.665,3.665,0,0,1-1.2,2.265Zm0,0"
                        transform="translate(-0.5 0)" fill="#3e3e3e"/>
                </svg>
              </span>
                                Filters
                            </h3>
                            <div className={isFilter ? "flexbox show" : "flexbox"}>
                                <div className="item filter-close-btn"
                                onClick={()=>setIsfilter(false)}
                                >
                                    <span
                                        onClick={() => clearFilter()}
                                        style={{
                                            cursor: "pointer",
                                            float: "left",
                                            paddingTop: "10px",
                                            color: "red",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        Clear Filter
                                    </span>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button>
                                </div>
                                <div className="item">
                                    <FormControl>
                                        <InputLabel id="demo-multiple-checkbox-label">
                                            Invite Status
                                        </InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            placeholder={"Select Sports"}
                                            name={"selectedLocName"}
                                            value={advisorApp?.inviteStatus}
                                            onChange={(e) =>
                                                advisorHandleMulti(e, "inviteStatus")
                                            }
                                            input={<Input label="Tag" />}
                                            renderValue={(selected) => selected.join(", ")}
                                        >
                                            {AdvisorStatusJSON?.length > 0 &&
                                            AdvisorStatusJSON?.map((name, indx) => (
                                                <MenuItem key={indx} value={name?.name}>
                                                    <Checkbox
                                                        checked={
                                                            advisorApp?.inviteStatus?.indexOf(
                                                                name?.name
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText primary={name?.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="white-box">
                        <div className="table-responsive tableFixHead">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>
                                        <div className="checkbox-flex">
                                            <div className="checkbox-info">
                                                <input type="checkbox"
                                                    checked={listData?.length > 0 ? checkAll : false}
                                                    onChange={(e) => {
                                                     checkAllHandler(e);
                                                     }}
                                            /><label>&nbsp;</label>
                                            </div>
                                            <ul className="dropdown">
                                                <li className="">
                                                    <a className="dropdown-toggle" id="dropdownMenuLink"
                                                       data-toggle="dropdown" href="#" aria-expanded="false"
                                                       onClick={() => {
                                                           listData?.length > 0 && setShow(!show);
                                                       }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="4.486"
                                                             height="17.944" viewBox="0 0 4.486 17.944">
                                                            <path id="bx-dots-vertical-rounded"
                                                                  d="M17.243,12.729a2.243,2.243,0,1,0,2.243,2.243A2.25,2.25,0,0,0,17.243,12.729Zm0-6.729a2.243,2.243,0,1,0,2.243,2.243A2.25,2.25,0,0,0,17.243,6Zm0,13.458A2.243,2.243,0,1,0,19.486,21.7,2.25,2.25,0,0,0,17.243,19.458Z"
                                                                  transform="translate(-15 -6)" opacity="0.4"/>
                                                        </svg>
                                                    </a>
                                                    <ul
                                                        ref={checkedRef}
                                                        className="dropdown-menu" aria-labelledby="dropdownMenuLink"
                                                        style={{ position: "absolute", left: "-32px" }}
                                                        class={
                                                            show === true
                                                                ? "dropdown-menu show"
                                                                : "dropdown-menu"
                                                        }
                                                    >
                                                        <li data-toggle="modal"
                                                            // data-target="#AdvisorInvitationModal"
                                                            style={ inviteStatus ? {cursor: "default", opacity: "0.7"} : { zIndex: 9999 }}
                                                            onClick={() => {
                                                                if(!inviteStatus){
                                                                    setIsClickInviteAllPop(false)
                                                                    inviteList.length > 0 ?
                                                                        setIsInvitePop(true)
                                                                        : toastr.warning('Select Atleast  One Advisor')
                                                                }
                                                            }}
                                                        >Invite Advisor
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </th>
                                    <th>Advisor Name</th>
                                    <th>Advisor Email</th>
                                    <th className="text-center">Advisor Mobile Number</th>
                                    <th>Invite</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {advisorList?.length > 0 ?
                                advisorList?.map((ad, idx)=>{
                                    return(
                                        <tr>
                                            <td>
                                                <div className="checkbox-info">
                                                    {(inviteList.includes(ad.id)
                                                    ) ? (
                                                        <input
                                                            checked
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                checkHandler(e, ad.id);
                                                            }}
                                                            id={idx}
                                                        />
                                                    ) : (
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                checkHandler(e, ad.id);
                                                            }}
                                                            id={idx}
                                                        />
                                                    )}
                                                    <label>&nbsp;</label>
                                                </div>
                                            </td>
                                            <td>{ad?.firstname ?? ""}&nbsp;{ad?.lastname}</td>
                                            <td><a href={void 0} style={{cursor:"default"}}>{ad?.emailid ?? ""}</a></td>
                                            <td className="text-center">{ad?.phonenumber ?? ""}</td>
                                            <td><a className="text-underline" href={void 0}
                                                   onClick={()=> ad?.athleteInviteStatus ==  0 ?
                                                       !inviteStatus ? sendInvitation(ad) : null : null }
                                                   style={ad?.athleteInviteStatus ==  0 ?
                                                       !inviteStatus ? {cursor:"pointer", textDecoration:"none"} :
                                                       {cursor:"pointer", textDecoration:"none", opacity:"0.7"} :
                                                       {color:"#3e3e3e", textDecoration:"none", cursor:"default"}}>{ad?.athleteInviteStatus ==  0 ?
                                            "Invite" : ad?.athleteInviteStatus ==  1 ? "Invitation Sent" : ad?.athleteInviteStatus ==  2 ?
                                            "Logged-In" : ""}</a></td>
                                            <td>
                                                <ul className="dropdown flex-dropdown">
                                                    {/*<li>*/}
                                                    {/*    <a className="view-athlete-btn" href="#">*/}
                                                    {/*        <svg data-toggle="tooltip" data-placement="bottom"*/}
                                                    {/*             title="Go to profile" xmlns="http://www.w3.org/2000/svg"*/}
                                                    {/*             width="18" height="18" viewBox="0 0 18 18">*/}
                                                    {/*            <path id="arrow-right"*/}
                                                    {/*                  d="M19.766,10.75A9,9,0,1,0,26.348,13.6a9.094,9.094,0,0,0-6.581-2.852Zm4.323,9.482-2.457,2.513a.689.689,0,0,1-.986-.961l1.24-1.268H16.012a.764.764,0,1,1,0-1.529h5.845l-1.264-1.262a.689.689,0,0,1,.974-.973l2.517,2.513A.687.687,0,0,1,24.09,20.232Z"*/}
                                                    {/*                  transform="translate(-10.755 -10.75)" fill="#1cb79e"/>*/}
                                                    {/*        </svg>*/}
                                                    {/*    </a>*/}
                                                    {/*</li>*/}
                                                    <li>
                                                        <a className="dropdown-toggle" id="dropdownMenuLink"
                                                           data-toggle="dropdown" href={void 0} aria-expanded="false"
                                                           onClick={() => onClickSetting(ad, idx)}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                 viewBox="0 0 18 18">
                                                                <g id="gear-fill" transform="translate(0 -0.001)">
                                                                    <path id="Path_2289" data-name="Path 2289"
                                                                          d="M10.581,1.182a1.648,1.648,0,0,0-3.161,0l-.113.383a1.647,1.647,0,0,1-2.367.981l-.349-.192A1.648,1.648,0,0,0,2.355,4.589l.19.35a1.647,1.647,0,0,1-.982,2.368l-.383.113a1.648,1.648,0,0,0,0,3.161l.383.113a1.647,1.647,0,0,1,.981,2.368l-.192.349a1.648,1.648,0,0,0,2.235,2.235l.35-.19a1.647,1.647,0,0,1,2.369.981l.113.383a1.648,1.648,0,0,0,3.161,0l.113-.383a1.647,1.647,0,0,1,2.368-.981l.349.192a1.648,1.648,0,0,0,2.235-2.235l-.19-.35a1.647,1.647,0,0,1,.981-2.368l.383-.113a1.648,1.648,0,0,0,0-3.161l-.383-.113a1.647,1.647,0,0,1-.981-2.368l.192-.349a1.648,1.648,0,0,0-2.235-2.235l-.35.19a1.647,1.647,0,0,1-2.368-.981l-.113-.383ZM9,12.3A3.3,3.3,0,1,0,5.707,9,3.3,3.3,0,0,0,9,12.3Z"
                                                                          transform="translate(0 0)" fill="#3e3e3e"
                                                                          fill-rule="evenodd"/>
                                                                </g>
                                                            </svg>
                                                        </a>
                                                        <ul
                                                            // className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuLink"

                                                            ref={isSetting == true && isSettingIndx == idx ? settingRef : null}
                                                            className={
                                                                (isSetting == true && isSettingIndx == idx)
                                                                    ? "dropdown-menu show"
                                                                    : "dropdown-menu"
                                                            }
                                                        >
                                                            <li
                                                            onClick={()=>{
                                                                setIsSetting(false)
                                                                History?.push(`/advisor/athletelisting/${ad?.id}`)
                                                            }}
                                                            >View Athletes</li>
                                                            <li data-toggle="modal" data-target="#EditAdvisorModal"
                                                            onClick={()=>onClickAddEdit(true, ad)}
                                                            >Edit Advisor
                                                            </li>
                                                            <li
                                                                // data-toggle="modal" data-target="#RemoveAdvisorModal"
                                                            onClick={()=>{
                                                                setRemoveAdvData(ad)
                                                                setIsAdvRemove(true)
                                                                setIsSetting(false)
                                                            }}
                                                            >Remove
                                                                Advisor
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>

                                    )
                                }) :
                                    <tr>
                                        <td colSpan={"5"}>No Record Found</td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        </div>

                        <div className="pagination">
                            <div className="flex">
                                <div className="item perPage">
                                    <label>{totalCount ?? 0} Records</label>
                                    <select
                                        value={fields?.limit}
                                        onChange={(e) =>
                                            onHandlePerPage(parseInt(e?.target?.value))
                                        }
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                    </select>
                                    <label>Records per page</label>
                                </div>
                                <div className="item pages">
                                    <Pagination
                                        innerClass="paging"
                                        itemClass=""
                                        linkClass=""
                                        activePage={currentPage}
                                        activeClass={"active"}
                                        itemsCountPerPage={fields?.limit || 10}
                                        totalItemsCount={totalCount}
                                        pageRangeDisplayed={5}
                                        onChange={handlePagination}
                                    />
                                </div>
                                <div className="item goto">
                                    <label>Go to page</label>
                                    <input
                                        value={goNum}
                                        onChange={(e) =>
                                            parseInt(e.target.value) <= 0 || e.target.value == ""
                                                ? ""
                                                : setGoNum(parseInt(e?.target?.value))
                                        }
                                        type="number"
                                    />
                                    <button
                                        type={"button"}
                                        onClick={() => handlePagination(goNum)}
                                    >
                                        Go
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AdminFooter/>
            </div>
            <Dialog open={isAddAdvisorPop}
                    onClose={(event, reason)=> {
                        if (reason == "backdropClick" || reason == "escapeKeyDown") {
                            setIsAddAdvisorPop(false)
                            setNewAdvData({phoneNumber: ""})
                            setNewAdvError({})
                            setIsClickAECount(1)
                        }
                    }}
            >
                <AddNewAdvisorModal
                isClickAECount={isClickAECount}
                isAdvEdit={isAdvEdit}
                onClose={()=>{
                    setIsAddAdvisorPop(false)
                    setNewAdvData({phoneNumber: ""})
                    setNewAdvError({})
                    setIsClickAECount(1)
                }}
                onSubmit={onSubmitNewAdvisor}
                onHandleAdvisorChange={onHandleAdvisorChange}
                allCountryList={allCountryList}
                newAdvData={newAdvData}
                // getDefaultCountry={getDefaultCountry}
                handleCountryChange={handleCountryChange}
                newAdvError={newAdvError}
                onSubmitEditAdv={onSubmitEditAdv}
                />
            </Dialog>
            <Dialog open={isAdvRemove}
                    onClose={(event, reason)=> {
                        if (reason == "backdropClick" || reason == "escapeKeyDown") {
                            setIsAdvRemove(false)
                            setIsSetting(false)
                        }
                    }}
            >
                <RemoveAdvisor
                removeData={removeAdvData}
                onRemove={()=>onRemoveAdv()}
                onClose={()=>setIsAdvRemove(false)}
                />
            </Dialog>

            <Dialog open={isInvitePop}
                    onClose={(event, reason)=> {
                        if (reason == "backdropClick" || reason == "escapeKeyDown") {
                            closeInvitePop()
                        }
                    }}
            >
                <InviteAdvisorPop
                isClickInviteAllPop={isClickInviteAllPop}
                onClose={closeInvitePop}
                isClickAECount={isClickAECount}
                onSubmitInvite={sendAllInvites}
                />
            </Dialog>
        </>
    )
}