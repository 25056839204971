import React, { useState, useEffect } from "react";
import services from '../../services';
import logoVPng from "../../assets/img/newLogoV.png";
import "../../assets/css/stylesheet.scss";
import "../../assets/css/signIn.scss";
import toastr from 'toastr';
import {
    IconButton,
    InputAdornment,
    TextField,
  } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { IsValidEmail } from "../../utils/validationUtils";
import swal from "sweetalert";
import CryptoJS from "crypto-js";

const initialData = {
    email: "",
    password: "",
    showPassword: false,
    rememberPassword: false,
  };

//Login component used for login the application
export const Login = (props) => {
    const [login, setLogin] = useState({ ...initialData }),
          [errors, setErrors] = useState({}),
        [loading, setLoading] = useState(false);
    useEffect(() => {
        const isRem = localStorage.getItem('rememberMe')
        if(isRem){
            localStorage?.removeItem('user_Admin_Advisor')
            localStorage?.removeItem('loginType')
            localStorage?.removeItem('authToken_Admin_Advisor')

            let credentials = JSON.parse(localStorage.getItem('credentials'));
            let bytes = CryptoJS.AES.decrypt(credentials.password, "password"),
                passString = (bytes && bytes.toString(CryptoJS.enc.Utf8)) || "";
            credentials.password = JSON.parse(passString || null);
            const rememberPassword = isRem;
            const email = credentials.email;
            const password = credentials.password;
            setLogin({email,rememberPassword, password});
        }
        else{
            localStorage?.clear()
        }
    },[])
        //handle change for input field taking value from the user
    const onHandleChange = async (field, e) => {
            const { updatedValue } = handleValidation(field, e);
            let fieldsT = login;
            fieldsT[field] = updatedValue;
            setLogin({ ...fieldsT })
        },

    handleValidation = (fieldname, e) => {
            let errorsT = errors;
            let formIsValid = true;
            let fieldValue = e ? (e.target ? e.target.value : e) : e;
            let updatedValue = fieldValue;
            //email validations
            if (fieldname === undefined || fieldname === "email") {
              if (fieldname === undefined) {
                fieldValue = login["email"] ? login["email"] : "";
                updatedValue = fieldValue;
              }
              delete errorsT["email"];
              if (!updatedValue) {
                formIsValid = false;
                errorsT["email"] = "Email is required";
              } else if (!IsValidEmail(updatedValue)) {
                formIsValid = false;
                errorsT["email"] = "Please enter Email in correct format";
              }
            }
            //password validations
            if (fieldname === undefined || fieldname === "password") {
              if (fieldname === undefined) {
                fieldValue = login["password"] ? login["password"] : "";
                updatedValue = fieldValue;
              }
              delete errorsT["password"];
              if (!updatedValue) {
                formIsValid = false;
                errorsT["password"] = "Password is required";
              } else if (!(updatedValue.length >= 6)) {
                formIsValid = false;
                errorsT["password"] = "Invalid Password";
              }
            }
            setErrors({
              ...errorsT,
            });
            return {
              formIsValid,
              updatedValue,
            };
          },
        //function call for login api with user input credential
    onClickLogin = async (e) => {
            e.preventDefault()
            const { formIsValid, updatedValue } = handleValidation();
            //checking validation for required filed
            if (formIsValid) {
                setLoading(true)
                //api for login
                const _res = await services.location.login(login);
                if (_res?.data?.statusCode === 401) {
                    setLoading(false)
                    swal({text: !!_res?.data?.message ? _res?.data?.message :
                            "Invalid Email ID or Password", icon:"error"})
                }
                else if (_res?.status === 200) {
                    if((_res?.data?.[0]?.role =="ADVISORS" || _res?.data?.[0]?.role =="ADMIN")){
                        setLoading(false)
                        //below code toastr is commented as pr jira task 225
                        // toastr.success("Login Successfully", "Success")
                        localStorage.setItem('authToken_Admin_Advisor', _res?.data?.[0]?.token)
                        localStorage.setItem('user_Admin_Advisor', JSON.stringify(_res?.data?.[0]))
                        localStorage.setItem('loginType', _res?.data?.[0]?.role ?? "error")
                        // if(localStorage.getItem("authToken"))
                        //     props.history.push('/homepage')
                        if(_res?.data?.[0]?.ispassword == 1){
                            if(_res?.data?.[0]?.role == "ADMIN"){
                                props.history.push('/dashboard')
                            }else {
                                props.history.push('/advisor/myAthlete')
                            }
                            const {email, rememberPassword} = login;
                            if(rememberPassword){
                                let password = CryptoJS.AES.encrypt(
                                    JSON.stringify(login.password),
                                    "password"
                                ).toString();
                                localStorage.setItem('rememberMe',rememberPassword);
                                localStorage.setItem('credentials', JSON.stringify({ email, password}));
                            }
                            else{
                                localStorage.removeItem('rememberMe');
                                localStorage.removeItem('credentials');
                            }
                        }else{
                            props?.history?.push(`/changePassword/${!!_res?.data?.[0]?.email ? _res?.data?.[0]?.email :
                                !!_res?.data?.[0]?.emailid ? _res?.data?.[0]?.emailid : "error" }`)
                        }
                    }else{
                        swal({text: "Access Denied", icon:"error"})
                        // toastr.error("Only Admin/Advisor can login", 'Error')
                        setLoading(false)
                    }
                } else {
                    setLoading(false)
                    toastr.error("Server Error", 'Error')
                }
            } else {
                setLoading(false)
                // toastr.error("Field Required", "Error", { preventDuplicates: true })
            }
        },
        //function call for pressing the enter key
    onKeyDown = (event) => {
            if (event.keyCode === 13 && event.code === "NumpadEnter") {
                event.preventDefault();
                event.stopPropagation();
                onClickLogin(event);
            }
        },
    handleShowPasswordClick = () => {
            setLogin({
              ...login,
              showPassword: !login.showPassword,
            });
          }, 
    handleMouseDownPassword = (event) => {
            event.preventDefault();
          },
    handleChechbox = (e) => {
        // if(e.target.type === "checkbox"){
        //   localStorage.setItem("isRememberKey", e.target.checked)
        // }
        if (e.target.type === "checkbox" && !e.target.checked) {
            setLogin((fields) => ({ ...fields, rememberPassword: !fields.rememberPassword }));
        }
        else {
            setLogin((fields) => ({ ...fields, rememberPassword: !fields.rememberPassword }));
        }
    };
    return (
        <div className="signIn">
            <div className="container">
                <div className="flex">
                    <div className="logo">
                        <img src={logoVPng} alt="Logo | SportsForce" />
                    </div>
                    <div className="form">
                        <h2>Login to Sportsforce</h2>
                        <form action="" className="form">
                            <div className="user-box">
                                <label className="label" htmlFor="">Email</label>
                                <input className="text-field"
                                       placeholder='Enter Email*'
                                       type="text" id="email"
                                       value={login?.email ?? ""}
                                       name='email'
                                       onChange={(e) => onHandleChange("email", e)} />
                                <div className="error">{errors["email"]}</div>
                            </div>
                            <div className="user-box">
                                <label className="label" htmlFor="">Password</label>
                                <TextField
                                    variant="outlined"
                                    id="password"
                                    placeholder="Enter Password*"
                                    type={login.showPassword ? "text" : "password"}
                                    className="text-field"
                                    fullWidth
                                    name="password"
                                    value={login?.password || ""}
                                    onChange={(e) => onHandleChange("password", e)}
                                    onBlur={(e) => onHandleChange("password", e)}
                                    onKeyDown={(e) => onKeyDown(e)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleShowPasswordClick()}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {login.showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                />
                                <div className="error">{errors["password"]}</div>
                            </div>
                            <div className={'row user-box'}>
                                <div className={'col-md-7'}>
                                    <input
                                        type="checkbox"
                                        id="rememberPassword"
                                        name="checkbox"
                                        checked={login?.rememberPassword}
                                        onChange={(e) => handleChechbox(e)}
                                    />
                                    <label htmlFor="rememberPassword">Remember me</label>
                                </div>
                                <div className={'col-md-5'} style={{marginTop:"5px"}}>
                                    <p style={{textAlign:"right",cursor:"pointer", color : "#c0321f"}} onClick={()=>props?.history.push('/forgotPassword')}>Forgot Password?</p>
                                </div>
                            </div>
                            <button className="btn primary" onClick={(e) => onClickLogin(e)}>
                                {loading ? "Loading.." : "Submit"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}