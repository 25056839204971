import React, { useState } from 'react'
import "../header/assets/css/stylesheet.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import MuiAutoComplete from './AutoComplete';

export const AssignAdvisorPopup = ({ onClose, changeText, newAdvisorField, onHandleNameChange, onHandleChange, onSubmitModal, advisorData, error, selectedAssignAdvisor, athleteIds, isAdvisorOkDisabled, handleIsAdvisorOkDisabled, isAdvisorAssign, onClickRemove }) => {
    const [clickState, setClickState] = useState(1)
    return (
        <div className="modal verifymodal" id="AssignAdvisor1Modal" 
        style={{ display: 'block', position: 'initial' }}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        {changeText ? <h5 className="modal-title">Assign advisor</h5> :
                            selectedAssignAdvisor ? <h5 className="modal-title">Assign advisor to <span className="name">All Athletes</span> </h5> :
                                <h5 className="modal-title">Assign advisor to <span className="name">{athleteIds?.athleteFullName}</span> </h5>}
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" onClick={() => onClose()}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group flexbox">
                            <div className="half">
                                <label>Advisor First Name <sup>*</sup></label>
                                <div className="autocomplete-class">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        className="search-input"
                                        freeSolo={true}
                                        options={advisorData}
                                        getOptionLabel={(option) =>
                                            option.firstname ? option.firstname : option
                                        }
                                        onChange={(e, data) => {
                                            onHandleChange("firstName", {
                                                target: { value: data ? data : "" },
                                            });
                                            // onHandleNameChange("firstName", {
                                            //     target: {
                                            //         value: data ? data?.firstname : ""
                                            //     }
                                            // });
                                        }}
                                        value={newAdvisorField.firstName || ""}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Advisor first name"
                                                value={newAdvisorField.firstName || ""}
                                                onChange={(e) => {
                                                    onHandleNameChange("firstName", {
                                                        target: {
                                                            value: e.target.value ? e.target.value : ""
                                                        }
                                                    });
                                                    onHandleChange("firstName", {
                                                        target: {
                                                            value: e.target.value ? e.target.value : ""
                                                        }
                                                    });
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="error">{error["errorFirstName"]}</div>
                            </div>
                            <div className="half">
                                <label>Advisor Last Name <sup>*</sup></label>
                                <div className="autocomplete-class">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        className="search-input"
                                        freeSolo={true}
                                        options={advisorData}
                                        getOptionLabel={(option) =>
                                            option.lastname ? option.lastname : option

                                        }
                                        onChange={(e, data) => {
                                            onHandleChange("lastName", {
                                                target: { value: data ? data : "" },
                                            });
                                            onHandleNameChange("lastName", {
                                                target: {
                                                    value: data ? data?.lastname : ""
                                                }
                                            });
                                        }}
                                        value={newAdvisorField.lastName || ""}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Advisor last name"
                                                value={newAdvisorField.lastName || ""}
                                                onChange={(e) => {
                                                    onHandleNameChange("lastName", {
                                                        target: {
                                                            value: e.target.value ? e.target.value : ""
                                                        }
                                                    });
                                                    onHandleChange("lastName", {
                                                        target: {
                                                            value: e.target.value ? e.target.value : ""
                                                        }
                                                    });
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="error">{error["errorLastName"]}</div>
                            </div>
                        </div>
                        <div className="form-group flexbox">
                            <div className="half">
                                <label>Advisor Email ID <sup>*</sup></label>
                                <div className="autocomplete-class">
                                    <Autocomplete
                                        id="combo-box-demo"
                                        className="search-input"
                                        freeSolo={true}
                                        options={advisorData}
                                        getOptionLabel={(option) =>
                                            option.emailid ? option.emailid : option
                                        }
                                        onChange={(e, data) => {
                                            onHandleChange("emailId", {
                                                target: { value: data ? data : "" },
                                            });
                                        }}
                                        value={newAdvisorField.emailId || ""}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Advisor email id"
                                                value={newAdvisorField.emailid || ""}
                                                onChange={(e) => {
                                                    onHandleNameChange("emailId", {
                                                        target: {
                                                            value: e.target.value,
                                                        },
                                                    });
                                                    onHandleChange("emailId", {
                                                        target: {
                                                            value: e.target.value ? e.target.value : ""
                                                        }
                                                    });
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="error">{error["errorEmailID"]}</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                            onClick={() => { handleIsAdvisorOkDisabled(true); onSubmitModal() }}
                            className="btn blue"
                            disabled={isAdvisorOkDisabled}
                            data-dismiss="modal">{isAdvisorAssign ? "Update" : "Assign"}</button>
                        {isAdvisorAssign &&
                        <button type="button"
                                disabled={clickState == 1 ? false : true}
                                className="btn cancel"
                                data-dismiss="modal"
                                onClick={() =>{
                                    setClickState(clickState + 1)
                                    onClickRemove()
                                }}
                        >Remove
                        </button>}
                    </div>
                </form>
            </div >
        </div >


    )
}

export const AssignAllAdvisorPopup = ({ onClose, onHandleChange, onSubmitModal }) => {
    const [isAdvisorAllOkDisabled, setIsAdvisorAllOkDisabled] = useState(false);
    return (
        <div className="modal verifymodal" id="AssignAdvisor2Modal" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Assign advisor to <span className="name">All Athletes</span></h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" onClick={() => onClose()}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group flexbox">
                            <div className="half">
                                <label>Advisor First Name <sup>*</sup></label>
                                <input type="text"
                                    name={'advisorfirstname'}
                                    onChange={(e) => onHandleChange(e)}
                                    placeholder="Advisor first name" />
                            </div>
                            <div className="half">
                                <label>Advisor Last Name <sup>*</sup></label>
                                <input type="text"
                                    name={'advisorlastname'}
                                    onChange={(e) => onHandleChange(e)}
                                    placeholder="Advisor last name" />
                            </div>
                        </div>
                        <div className="form-group flexbox">
                            <div className="half">
                                <label>Advisor Email ID <sup>*</sup></label>
                                <input type="email"
                                    name={'advisorEmailId'}
                                    onChange={(e) => onHandleChange(e)}
                                    placeholder="Advisor email id" />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                            onClick={(e) => { setIsAdvisorAllOkDisabled(true); onSubmitModal(e) }}
                            className="btn blue"
                            disabled={isAdvisorAllOkDisabled}
                            data-dismiss="modal"
                        >Assign</button>
                    </div>
                </form>
            </div>
        </div>
    )

}

export const AssignAdvisorToAthletePopup = ({ onClose, onHandleChange, onSubmitModal }) => {
    const [isAdvisorToAthleteOkDisabled, setIsAdvisorToAthleteOkDisabled] = useState(false);
    return (
        <div className="modal verifymodal" id="AssignAdvisor3Modal" style={{ display: "block" }} >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Assign advisor to <span className="name">James Smith</span></h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" onClick={() => onClose()}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group flexbox">
                            <div className="half">
                                <label>Advisor First Name <sup>*</sup></label>
                                <input type="text"
                                    name={'advisorfirstname'}
                                    onChange={(e) => onHandleChange(e)}
                                    placeholder="Advisor first name" />
                            </div>
                            <div className="half">
                                <label>Advisor Last Name <sup>*</sup></label>
                                <input type="text"
                                    name={'advisorlastname'}
                                    onChange={(e) => onHandleChange(e)}
                                    placeholder="Advisor last name" />
                            </div>
                        </div>
                        <div className="form-group flexbox">
                            <div className="half">
                                <label>Advisor Email ID <sup>*</sup></label>
                                <input type="email"
                                    name={'advisorEmailId'}
                                    onChange={(e) => onHandleChange(e)}
                                    placeholder="Advisor email id" />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                            onClick={(e) => { setIsAdvisorToAthleteOkDisabled(true); onSubmitModal(e) }}
                            className="btn blue"
                            disabled={isAdvisorToAthleteOkDisabled}
                            data-dismiss="modal"
                        >Assign</button>
                    </div>
                </form>
            </div>
        </div >
    )

}

export const NewAccountPopup = ({ onClose, onSubmitModal }) => {
    const [isNewAdvisorDisabled, setIsNewAdvisorDisabled] = useState(false);
    return (
        <div class="modal verifymodal" id="AdvisorNewAccountModal" style={{ display: "block" }}>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <form class="modal-content">
                    <div class="modal-header border-none">
                        <h5 class="modal-title">New Account</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" onClick={() => onClose()}>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <p>Do you want to create a new advisor account?</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button"
                            class="btn blue"
                            data-dismiss="modal"
                            disabled={isNewAdvisorDisabled}
                            onClick={() => {setIsNewAdvisorDisabled(true); onSubmitModal()}}
                        >Yes</button>
                        <button type="button"
                            class="btn cancel"
                            data-dismiss="modal"
                            onClick={() => onClose()}
                        >No</button>
                    </div>
                </form>
            </div>
        </div>
    )
}