import { AsYouType, parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
export const IsValidEmail = (value) => {
    //this below code is done becaoue the regex only take alphabet in small letter
    const newValue = !!value ? value?.toLowerCase() : ""
    return new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)$/
    ).test(newValue);
};
export const alphaNumericValidation = (value) => {
    // return new RegExp(/^[a-z0-9]+$/i).test(value);
    return new RegExp(/[^A-Za-z0-9]+/g).test(value);
}
export const numericValidation = (value) => {
    return new RegExp(/^\d+(\.\d)?\d*$/).test(value);
}
//validation for phone number
export const checkPhoneNumberValidation = (number, countryShortForm = "US") => {
    try {
        const phoneNumber = parsePhoneNumber(number, countryShortForm);
        if (phoneNumber && phoneNumber.nationalNumber) {
            const formattedNumber = new AsYouType(countryShortForm).input(
                phoneNumber.nationalNumber
            );
            const newphoneNumber = parsePhoneNumber(
                formattedNumber,
                countryShortForm
            );
            const status = newphoneNumber.isValid();
            return status
        }
        return false;
    } catch (error) {
        return false;
    }
};