import '../components/header/assets/css/stylesheet.scss'
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

export const WrapperComponent = (props) =>{
    const store = useSelector(store => store)
    const location = useLocation()
    const splitRoute = location?.pathname?.split("/")
    const splitPath = splitRoute?.[splitRoute?.length - 1];
    return(
        <div
            // style={splitPath == "notification" ? {height:"auto"} : {}}
            className={store?.navStatus?.navState ?  'main' : "main sidebar-hidden"}
        >
            {props?.children}
        </div>
    )
}