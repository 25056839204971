import React from "react";
import { useState, useEffect } from "react";
import "./css/notification.css";
import { Menu, MenuItem, Button } from "@material-ui/core";
import { useHistory, Prompt, useLocation } from "react-router-dom";
import messagePopup from "../../../utils/messagePopup";
import BackdropLoader from "../../common/Loader";
import {useSelector} from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import services from "../../../../src/services";
import { getUserInfo } from "../../../../src/utils/constant";
import toastr from 'toastr';
import swal from "sweetalert";
import AdvisorHeader from "../../header/advisorHeader";

const ITEM_HEIGHT = 48;

const AdvisorComposeMessage = (props) => {
  const location = useLocation();
  let history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [anchorE1, setAnchorE1] = React.useState(null);
  const store = useSelector(store => store);
  const open = Boolean(anchorE1),
    [message, setMessage] = useState(undefined),
    [allAthlete, setAllAthlete] = useState([]),
    [selectedAthletes, setSelectedAthletes] = useState([]),
    [isBlocking, setIsBlocking] = useState(false),
    [loading, setLoading] = useState(false);

    const showModal = async(nextLocation) => {
      const willSave = await swal({
          text: "Changes you made, may not be saved",
          icon: 'warning',
          buttons: {
              confirm: { text: "Stay" },
              danger: "Leave",
          },
      })
      if(willSave === "danger"){
          setConfirmedNavigation(true);
      }
      setLastLocation(nextLocation);
    };
  
    const handleBlockedRoute = (nextLocation) => {
      if (!confirmedNavigation && nextLocation.pathname !== history.location.pathname) {
        showModal(nextLocation);
        return false;
      }
      return true;
    };
  
    useEffect(() => {
      if (confirmedNavigation && lastLocation) {
        history.push(lastLocation.pathname);
      }
    }, [confirmedNavigation, lastLocation, history]);

    useEffect(async () => {
      await services.location.getAdvisorAthleteMappings({ where: { advisorid: getUserInfo()?.id, active: 1 } }).then(res =>{
        if(res?.status == 200){ 
          let athleteData = res?.data?.data?.allAdvisorathletemappings?.Advisorathletemappings.filter(item => {
            return item?.advisorathletemappingathleteidmaprel?.Athletes[0]?.athleteuseridmaprel?.Userdata[0]?.issoftdeleted != 1 && item?.advisorathletemappingathleteidmaprel?.Athletes[0]?.active != 0;
          })
          athleteData.map(item => { 
            item["id"] = item?.advisorathletemappingathleteidmaprel?.Athletes[0]['id']
            item["athleteFullName"] = item?.advisorathletemappingathleteidmaprel?.Athletes[0]['athletefirstname']+" "+item?.advisorathletemappingathleteidmaprel?.Athletes[0]['athletelastname']
            item["sportsName"] = item?.advisorathletemappingathleteidmaprel?.Athletes[0]['athleteathletesportsmaprel']['Athletesports'][0]['sportname']
            item["graduationYear"] = item?.advisorathletemappingathleteidmaprel?.Athletes[0]['athleteathletegraduationyearmaprel']['Graduationyears'][0]['yearvalue']
          })
          if(athleteData.length){
            setAllAthlete(athleteData)
          }
        }
      })
      setTimeout(()=>{
        window.scrollTo(0, 0)
      }, 800)
    }, [])  
  
  /*
  const handleAllAthleteChange = async (values) => {
    let searchText = values.target.value;
    if(searchText){
      await services.location.searchAthlete({ athleteName: searchText, role: getUserInfo()?.role ?? "" }).then(res =>{
        if(res?.data?.response?.status == 200){
          setAllAthlete(res?.data?.response?.data)
        }
      })
    }
  }
  */  
  
  const changeSelectedAthlete = async (values) => {
    let selVal = values?.target?.value?.length ? values?.target?.value : [];
    //const ids = selVal.map(o => o.id)
    //const filtered = selVal.filter(({id}, index) => !ids.includes(id, index + 1))
    setSelectedAthletes(selVal)
  }  
  
  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorE1(null);
  };
  
  const handleAthleteMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/advisor/composeMessage" });
  }

  const handleSupportMenu = () => {
    setAnchorE1(null)
    history.push({ pathname: "/message/support/composeMessage" })
  }
  
  const sendClickBtn = async (e) => {
    e.preventDefault();
    setIsBlocking(true);
    if (selectedAthletes.length) {
        if (message) {
          let userIds = [];
          selectedAthletes.map(item => {
            userIds.push(item.id)
          })
          await services.location.sendMessageToAnyRole({ 
              msgsenderrole: getUserInfo()?.role,
              msgrecieverrole: "ATHLETES",
              msgsenderid: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).userid),
              msg: message.replace (/"/g, ""),
              userids: userIds,
              parentid: 0
          }).then(async (messageRes) => {
              setIsBlocking(false);
              if (messageRes?.data?.response?.status == 200) {
                toastr.success("Message has been sent successfully").css({"width":"auto"})
                history.push('/message/advisor/athlete');
              } else {
                messagePopup("", "Message has not been sent. Try agai!", "error");
              }
          })
        }
        else {
          messagePopup("", "Please enter message", "warning")
        }
    }
    else {
      messagePopup("", "Please specify recipient", "warning")
    }
  }

  return (
    <div className="pageContent">
      <BackdropLoader open={loading} />
      <div className="container">
        <div className="notification-with-sidebar">
          <nav className="notification-sidebar">
            <ul>
              <li className="button-link dropdown">
                <Button
                  className="btn lightblue dropdown-toggle"
                  onClick={e => handleClick(e)}
                >
                  Compose
                </Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button"
                  }}
                  anchorEl={anchorE1}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch"
                    }
                  }}
                >
                  <MenuItem>
                    <strong>Compose for</strong>
                  </MenuItem>
                  <MenuItem onClick={handleSupportMenu}>Support</MenuItem>
                  <MenuItem onClick={handleAthleteMenu}>Athlete</MenuItem>
                </Menu>
              </li>
              <li
                className={window.location.href.includes('/message/support/composeMessage') ? "active" : ""}
                onClick={() => history.push({ pathname: "/message/support/composeMessage" })}
              >
                <div className="link-item">
                  <span>Support</span> <span>{(parseInt(store?.adminMessages?.advisorSupportUnreadMessages)) ? (parseInt(store?.adminMessages?.advisorAthleteUnreadMessages)) : ""}</span>
                </div>
              </li>
              <li onClick={() => history.push({ pathname: "/message/advisor/athlete" })}>
                <div className="link-item">
                  <span>Athlete</span> <span>{(parseInt(store?.adminMessages?.advisorAthleteUnreadMessages)) ? (parseInt(store?.adminMessages?.advisorAthleteUnreadMessages)) : ""}</span>
                </div>
              </li>
              <li onClick={() => history.push({ pathname: "/message/advisor/archive" })}>
                <div className="link-item">
                  <span>Archive</span><span>{(parseInt(store?.adminMessages?.advisorArchiveUnreadMessages)) ? (parseInt(store?.adminMessages?.advisorArchiveUnreadMessages)) : ""}</span>
                </div>
              </li>
            </ul>
          </nav>
          <div className="notification-detail-box">
            <div className="notification-search-box">
              <div className="search-box">
                <h1>New Message</h1>
              </div>
            </div>
            <div className="notification-messageDetail">
              <div className="message-hereInfo newMessage">
                <div className="message-replyInfo">
                  <form>
                  <Prompt
                    when={isBlocking}
                    // message={(location) => {
                    //     if(location.pathname !== history.location.pathname){
                    //       return "Do you want to leave this page";
                    //     }
                    // }
                    // }
                    message={handleBlockedRoute}
                  />
                    <div className="form-group flexbox d-block">
                      <label>
                        Select Athlete
                      </label>
                      <div className="autocomplete-class">
                        <Autocomplete
                          id="combo-box-demo"
                          multiple={true}
                          freeSolo={true}
                          options={allAthlete}
                          getOptionLabel={(option) => {
                              return option.athleteFullName ? option.athleteFullName +" ["+option.graduationYear+", "+option?.sportsName+"]" : option.toString()
                            }
                          }
                          onChange={(e, data) => {
                            changeSelectedAthlete({
                              target: { value: data ? data : "" },
                            });
                          }}
                          value={selectedAthletes || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Enter Athlete Name"
                              value={selectedAthletes || ""}
                              onChange={(e) => {
                                // handleAllAthleteChange({
                                //   target: {
                                //     value: e.target.value ? e.target.value : "",
                                //   },
                                // });
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {/*
                    <div className="form-group flexbox">
                      <label>To:</label>
                      {selectedAthletes.map(item => <span className="toName">{item.athleteEmailId}</span> )}
                    </div>
                    <div className="form-group flexbox">
                      <label>Subject:</label>
                      <input
                        type="text"
                        value={subject ? subject : ""}
                        disabled={!sendMsg ? true : false}
                        onChange={(e) => { setSubject(e.target.value); setIsBlocking(true); }} />
                    </div> */}
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        placeholder="Enter Your message here..."
                        value={message ? message : ""}
                        onChange={(e) => { setMessage(e.target.value); setIsBlocking(true); }}>
                      </textarea>
                    </div>
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn blue"
                        onClick={(e) => { sendClickBtn(e) }}>Send</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvisorComposeMessage;