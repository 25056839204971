export const getAllAthleteSports = `{
  allAthletesports{
    Athletesports{
      id
      active
      sportname
      sportforgender
    }
  }                                                                                                                                                                                                                                         
}`
export const getAllGraduationYear = `
{
  allGraduationyears{
    Graduationyears{
      id
      active
      yearvalue
    }
  } 
}
`
export const getAllTeamName = `{
  allTeams{
    Teams{
      id
      active
      teamname
      isverified
    }
  } 
}`
export const getAllTeamNameDynamic = `
query allTeams($where: JSON){
  allTeams(where : $where){
    Teams{
      id
      active
      teamname
      isverified
    }
  } 
}`
export const getAllAdvisor = `{
  allAdvisors{
    Advisors{
      id
      acive
      firstname
      lastname
    }
  } 
}`
export const getAllGpScale = `{
  allGpascales{
    Gpascales{
      id
      gpascalevalue
    }
  } 
}`
export const getAllPrimaryPosBySportID = `
query allPrimarypositions($where: JSON){
  allPrimarypositions(where: $where){
    Primarypositions{
      id
      malesportsid
      femalesportsid
      selectionfield
      shortform
  } 
}
}`
export const getAllProgramTypeQuery = `
query allSubscriptionplans($where: JSON){
  allSubscriptionplans(where: $where){
    Subscriptionplans{
      id
     plantype
     planname
  } 
}
}
`

export const onStatsApproval = (body) =>
`mutation{
        StatmatrixdetailsUpdateAll(where:{userid:${body?.userid}},
        data:{verified:${body?.verified},verifiedby:"${!!body?.verifiedBy ? body?.verifiedBy : null}",
        verifiedbrandlogo: "${!!body?.verifiedbrandlogo ? body?.verifiedbrandlogo : ""}",
        isscoutingrequestupdateOn:${body?.isscoutingrequestupdateOn} 
        })
}`
export const onStatsDisApproval = (body) =>
`mutation{
  StatmatrixdetailsUpdateAll(where:{userid:${body?.userid}},
  data:{verified:0, verifiedby:"Not Verified", isscoutingrequestsent: ${body?.isscoutingrequestsent}})
}`

