import React from "react";
import {Dialog} from "@material-ui/core";
import {typeConvert} from "./getThumbnail";
export const VideoPop = ({isTestSingle, setIsTestSingle, vidID, isLink = true, isToken}) =>{
    return(
        <>
            <Dialog
                open={isTestSingle}
                maxWidth={'sm'}
                className={'video-modal'}
                onClose={(event, reason)=>(reason == "backdropClick" ||
                        reason == "escapeKeyDown"
                    ) &&
                    setIsTestSingle()
                }
            >
                <>
                    <div className="modal-dialog modal-dialog-centered" style={{margin:0}}>
                        <div className="modal-content">
                            <div className="modal-body youtube_embed_frame" id="">
                                {isLink ?
                                    <iframe id="" height="315" src={!!vidID ? `${vidID}?&autoplay=1&mute=1` : "https://www.youtube.com/embed/aqz-KE-bpKQ"} title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen></iframe>
                                    :
                                    <video
                                        autoPlay={true}
                                        controls
                                        controlsList={isToken == 1 ? "" : "nodownload"}
                                    >
                                        <source src={typeConvert(vidID, "vidPop")} alt="video/youtube" />
                                    </video>
                                }
                            </div>
                        </div>
                    </div>
                </>
            </Dialog>
        </>
    )
}