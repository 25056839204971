import Routes from "../src/navigation/Routes";
import './App.css';

function App() {
  return (
    <Routes />
  );
}

export default App;
