export const getInitial = (LS, isFullName) =>{
const cloneLS = !!LS ?  JSON.parse(LS) : {}
    if(LS&& isFullName){
        if(cloneLS?.fullname){
        const lsSplit = cloneLS?.fullname?.split(" "),
            first = lsSplit?.[0]?.[0]?? "S",
            last = lsSplit?.[1]?.[0]?? "F";
        return `${first}${last}`;
        }
        else{
            return `${cloneLS?.firstname?.[0] ?? "S"}${cloneLS?.lastname?.[0] ?? "F"}`
        }
    }
}