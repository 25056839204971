
const VerifiedModal = (props) => {
  return (
    <div
      className={`modal fade getVerifiedModal show verifiedModalCustomBackdrop`}
      id="getVerifiedModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >   
      <div className="modalBackdrop backdropActive" onClick={props.onClose}></div>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose}
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.804"
                  height="15.803"
                  viewBox="0 0 15.804 15.803"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <g className="a" transform="translate(-6.776 -6.776)">
                    <path
                      d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                      transform="translate(0 0)"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>
          <div className="modal-body">
            <h1 className="heading">
              <p>What is a verified profile?</p>
            </h1>
            <p className="info">
              <i>We verify profiles to ensure that the SportsForce community can be trusted by all athletes, college coaches, and professional scouts.</i>
            </p>
            <p className="info">
            <i>Make sure your profile has a profile photo, grad year, and your position. Verified profiles will receive higher search results to maximize your exposure.</i>
            </p>  
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedModal;
