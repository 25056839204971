import thumbnail from '../../assets/img/video-to-link-thumbnail.jpg'
import hudlThumbnail from '../../assets/img/hudl-video-thumb.jpg'
export const getThumbnail = (vidURL, data, page) =>{
    if(vidURL?.includes('youtube')){
        const dataSplit = vidURL?.split('/'),
            url = dataSplit[dataSplit?.length - 1]
        return `https://img.youtube.com/vi/${url}/mqdefault.jpg`
    }
    else if (vidURL?.includes('vimeo')){
        if (page == "Vid/Photo" ||
            page == "ProfileView" ||
            page == "Profile" ||
            page == "ProfileVP" ||
            page == "ProfileABT" ||
            page == "Profile_Timeline"
        ){
            if(!!data?.thumbnail){
                return  data?.thumbnail
            }else{
                return thumbnail
            }
        }
        else if (page == "Save/Update_StatsVP"){
            if (data?.vidThumbnail){
                return data?.vidThumbnail
            }else {
                return thumbnail
            }
        }
        else if (page == "Show_Stats_Profile" || page == "Show_Stats_PR"){
            if (data?.thumbnail){
                return data?.thumbnail
            }else {
                return thumbnail
            }
        }
        else if(page == "Dashboard/MyFeed" || page == "Dashboard/Global" || page == "Timeline"){
            if (data?.thumbnail){
                return  data?.thumbnail
            }else{
                return thumbnail
            }
        }
        return thumbnail
    }
    else if (vidURL?.includes('hudl')){
        return hudlThumbnail
    }else {
        return thumbnail
    }
}

export const typeConvert = (data, page) =>{
    var repStr = ""
    if ( page == "Timeline_Photo" || page == "Share_Pop_URL" || page == "Share_Pop_Text_Mail" || page == "Athlete_Info" ||
        page == "Athlete_About" || page == "vidPop" || page == "Show_Stats_Profile" || page == "Profile_Timeline"){
        const dataLower = data?.toLowerCase()
        if (dataLower?.includes('3g2') || dataLower?.includes('3gp') || dataLower?.includes('avi') ||
            dataLower?.includes('flv') || dataLower?.includes('m4v') || dataLower?.includes('mkv') ||
            dataLower?.includes('mov') || dataLower?.includes('mp4') || dataLower?.includes('mpg') ||
            dataLower?.includes('mts') || dataLower?.includes('m2ts') || dataLower?.includes('mpeg') ||
            dataLower?.includes('wmv')){
            const splitData = data?.split("."),
                videoExt = splitData?.[splitData?.length - 1]
            repStr = data?.replace(videoExt,'mp4')
        }else{
            repStr = data
        }
    }else{
        repStr = data
    }
    return repStr
}
