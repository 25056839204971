import React, {useState} from "react";
import PhoneNumber from "../../common/PhoneNumber";

export const AddNewAdvisorModal = ({isClickAECount, isAdvEdit, onClose, onSubmit, onSubmitEditAdv, onHandleAdvisorChange, allCountryList, newAdvData, handleCountryChange, newAdvError}) =>{
    return(
        <div className="modal verifymodal" id="AddNewadvisorModal" style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered modal-lg m-0" role="document">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">{isAdvEdit ? "Edit" : "Add new"} advisor</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group flexbox">
                            <div className="half">
                                <label>Advisor First Name <sup>*</sup></label>
                                <input type="text" placeholder="Advisor first name"
                                       name ={"advisorFirstName"}
                                       value={newAdvData?.advisorFirstName ?? ""}
                                onChange={(e)=>onHandleAdvisorChange(e)}
                                />
                                {newAdvError?.firstName && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAdvError?.errorFirstName ?? ""}
                                            </span>}
                            </div>
                            <div className="half">
                                <label>Advisor Last Name <sup>*</sup></label>
                                <input type="text" placeholder="Advisor last name"
                                       name={"advisorLastName"}
                                       value={newAdvData?.advisorLastName ?? ""}
                                onChange={(e)=>onHandleAdvisorChange(e)}
                                />
                                {newAdvError?.lastName && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAdvError?.errorLastName ?? ""}
                                            </span>}
                            </div>
                        </div>
                        <div className="form-group flexbox phone">
                            <div className="half">
                                <label>Advisor Email ID <sup>*</sup></label>
                                <input type="email" placeholder="Advisor email id"
                                       name={'advisorEmailId'}
                                       disabled={isAdvEdit ? true : false}
                                       value={newAdvData?.advisorEmailId ?? ""}
                                onChange={(e)=>onHandleAdvisorChange(e)}
                                />
                                {newAdvError?.emailId && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAdvError?.errorEmailId ?? ""}
                                            </span>}
                            </div>
                            <div className="half">
                                <div className="form-group phone">
                                    <label>Athlete Mobile Number <sup>*</sup></label>
                                    <PhoneNumber
                                        dropdownName="countryCode"
                                        dropdownOnChange={handleCountryChange}
                                        dropdownValue={newAdvData?.countryDetails ?? 1}
                                        dropDownOptions={
                                            allCountryList?.map((item) => ({
                                                value: item.id,
                                                label: item.countryName + `(+${item.countryCode})`,
                                                flag: item.countryFlagImage,
                                                countryCode: item.countryCode,
                                                countryShortName: item.countryShortName,
                                            }))
                                        }
                                        formatOptionLabel={(option, { context, selectValue }) => {
                                            return context === "menu" ? (
                                                <>
                                                    <img
                                                        src={option.flag}
                                                        alt=""
                                                        style={{ width: "40px", marginRight: "15px" }}
                                                    />
                                                    <span>{option.label}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <img
                                                        src={option.flag}
                                                        alt=""
                                                        style={{ width: "40px", marginRight: "15px" }}
                                                    />
                                                    <span>+{option.countryCode}</span>
                                                </>
                                            );
                                        }}
                                        inputPlaceholder="Phone Number"
                                        inputType="phonenumber"
                                        inputValue={newAdvData?.phoneNumber || ""}
                                        inputOnChange={(e) => {
                                            onHandleAdvisorChange({
                                                target: {
                                                    value: e.target.value.replace(/[^0-9]/gi, ""),
                                                    name: "phoneNumber"
                                                },
                                            });
                                        }}
                                    />
                                    {newAdvError?.mobileNumber && <span style={{ color: "red", fontSize: "12px" }}>
                                                {newAdvError?.errorMobileNumber ?? ""}
                                            </span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn blue" data-dismiss="modal"
                        onClick={()=>isAdvEdit ? onSubmitEditAdv() : onSubmit("0")}
                         disabled={isClickAECount == 1 ? false : true}
                        >{isAdvEdit ? "Update" : "Save"}</button>
                        <button type="button" className={isAdvEdit ? "btn cancel" : "btn save-invitebtn"} data-dismiss="modal"
                        onClick={()=>isAdvEdit ? onClose() : onSubmit("1")}
                                disabled={isClickAECount == 1 ? false : true}
                        >{isAdvEdit ? "Cancel" : "Save & Invite"}</button>
                    </div>
                </form>
            </div>
            </div>
            )
}

export const RemoveAdvisor = ({onRemove, onClose, removeData}) =>{
    return(
        <div className="modal verifymodal" id="RemoveAdvisorModal" style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Remove advisor <span className="name">{removeData?.firstname ?? ""}&nbsp;{removeData?.lastname}</span></h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <p>Do you want to remove the selected advisor?</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn blue" data-dismiss="modal"
                        onClick={()=>onRemove()}
                        >Yes</button>
                        <button type="button" className="btn cancel" data-dismiss="modal"
                        onClick={()=>onClose()}
                        >Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export const InviteAdvisorPop = ({isClickAECount, onClose, onSubmitInvite}) =>{
    return(
        <div className="modal verifymodal" id="AdvisorInvitationModal"
             style={{display:"block", position:"initial", overflowY:"auto"}}>
            <div className="modal-dialog modal-dialog-centered m-0" role="document">
                <form className="modal-content">
                    <div className="modal-header border-none">
                        <h5 className="modal-title">Advisor Invitation</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={()=>onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <p>Invite will be sent to only those advisor who have not logged into their profiles.
                            </p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn blue" data-dismiss="modal"
                        disabled={isClickAECount == 1 ? false : true}
                                onClick={()=>onSubmitInvite()}
                        >Ok</button>
                        <button type="button" className="btn cancel" data-dismiss="modal"
                        onClick={()=>onClose()}
                        >Cancel</button>
                    </div>
                </form>
            </div>

        </div>
    )
}