import React from "react";
import { useState, useEffect } from "react";
import "./css/notification.css";
import { Menu, MenuItem, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import messagePopup from "../../../utils/messagePopup";
import { AdminHeader } from '../../header/adminHeader';
import BackdropLoader from "../../common/Loader";
import {useSelector} from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import services from "../../../../src/services";
const ITEM_HEIGHT = 48;

const ComposeMessage = (props) => {
  let history = useHistory();
  const [anchorE1, setAnchorE1] = React.useState(null);
  const store = useSelector(store => store);
  const open = Boolean(anchorE1),
    [subject, setSubject] = useState(undefined),
    [message, setMessage] = useState(undefined),
    [coachData, setCoachData] = useState([]),
    [toDetails, setToDetails] = useState([]),
    [loading, setLoading] = useState(true);
  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };
  const handleCoachMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/coach/composeMessage" });
  }
  const handleAthleteMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/athlete/composeMessage" });
  }
  const handleAdvisorMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/advisor/composeMessage" });
  }


  const sendClickBtn = async (e) => {
    e.preventDefault();
    if (toDetails?.coachemail) {
      if (subject) {
        if (message) {
          await services.location.saveMessages({
            obj: {
              active: 1,
              message: message,
              isread: 0,
              messagesendbyadmin: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id),
              messagesendtocoachid: toDetails.id,
              userrole: "ADMIN",
              subject: subject,
            }
          }).then(async (res) => {
            if (res?.status == 200) {
              await services.location.saveMessagereply({
                obj: {
                  msgid: res?.data?.data?.saveMessage?.id,
                  active: 1,
                  msg: message,
                  isread: 0,
                  msgsenderadminid: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id),
                  msgreceivercoachid: toDetails.id,
                  subject: subject,
                }
              }).then(async (replyRes) => {
                await services.location.saveUserActivity({
                  obj: {
                    messageid: parseFloat(res?.data?.data?.saveMessage?.id),
                    userid: toDetails.id,
                    adminid: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id),
                  }
                }).then(activityRes => {
                  messagePopup("", "Message send successfully", "success");
                  history.push('/message/athlete');
                })
              })
            }
            else {

            }
          });
        }
        else {
          messagePopup("", "Please enter message", "warning")
        }
      }
      else {
        messagePopup("", "Please enter subject", "warning")
      }
    }
    else {
      messagePopup("", "Please specify atleast one recipient", "warning")
    }
  }

  const handleCoachChange = (values) => {
    setToDetails(values.target.value)
  }

  useEffect(async () => {
    await services.location.getAllCoaches({ obj: { active: 1, coachemail:{neq:null}, coachfirstname:{neq:null}, coachfirstname:{neq:""} } }).then(res => {
      if (res?.data?.data?.allCoachandteamdetails?.Coachandteamdetails?.length) {
        setCoachData(res?.data?.data?.allCoachandteamdetails?.Coachandteamdetails);
        setLoading(false);
      }
    })
  }, []);

  return (
    <>
      <BackdropLoader open={loading} />
      <div className="content">
        <AdminHeader
          title={"Message"}
        />
      <div className="notification-with-sidebar">
        <nav className="notification-sidebar">
          <ul>
            <li className="button-link dropdown">
              <Button
                className="btn lightblue dropdown-toggle"
                onClick={e => handleClick(e)}
              >
                Compose
              </Button>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button"
                }}
                anchorEl={anchorE1}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch"
                  }
                }}
              >
                <MenuItem>
                  <strong>Compose for</strong>
                </MenuItem>
                <MenuItem onClick={handleAthleteMenu}>Athlete</MenuItem>
                <MenuItem onClick={handleAdvisorMenu}>Advisor</MenuItem>
                <MenuItem onClick={handleCoachMenu}>Coach</MenuItem>
              </Menu>
            </li>
            <li onClick={() => history.push({ pathname: "/message/athlete" })}>
              <div className="link-item">
                <span>Athlete</span> <span>{(parseInt(store?.adminMessages?.athleteUnreadMessages)) ? (parseInt(store?.adminMessages?.athleteUnreadMessages)) : ""}</span>
              </div>
            </li>
            <li onClick={() => history.push({ pathname: "/message/advisor" })}>
              <div className="link-item">
                <span>Advisor</span> <span>{(parseInt(store?.adminMessages?.advisiorUnreadMessages)) ? (parseInt(store?.adminMessages?.advisiorUnreadMessages)) : ""}</span>
              </div>
            </li>
            <li onClick={() => history.push({ pathname: "/message/coach" })}>
              <div className="link-item">
                <span>Coaches</span> <span>{(parseInt(store?.adminMessages?.coachesUnreadMessages)) ? (parseInt(store?.adminMessages?.coachesUnreadMessages)) : ""}</span>
              </div>
            </li>
            <li onClick={() => history.push({ pathname: "/message/trash" })}>
              <div className="link-item">
                <span>Trash</span>
              </div>
            </li>
          </ul>
        </nav>
        <div className="notification-detail-box">
          <div className="notification-search-box">
            <div className="search-box">
              <h1>New Message</h1>
            </div>
          </div>
          <div className="notification-messageDetail">
            <div className="message-hereInfo newMessage">
              <div className="message-replyInfo">
                <form>
                  <div className="form-group flexbox d-block">
                  <label>
                        Select Athlete
                      </label>
                      <div className="autocomplete-class">
                        <Autocomplete
                          id="combo-box-demo"
                          freeSolo={true}
                          options={coachData}
                          getOptionLabel={(option) =>
                            option.coachfirstname ? option.coachfirstname + " " + option.coachlastname : option
                          }
                          onChange={(e, data) => {
                            handleCoachChange({
                              target: { value: data ? data : "" },
                            });
                          }}
                          value={""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Enter Athlete Name"
                              value={""}
                              onChange={(e) => {/*
                                handleSchoolChange("highschoolname", {
                                  target: {
                                    value: e.target.value ? e.target.value : "",
                                  },
                                }); */
                              }}
                            />
                          )}
                        />
                      </div>
                  </div>
                  <div className="form-group flexbox">
                    <label>To:</label>
                    { toDetails.coachemail ? <span className="toName">{ toDetails.coachemail }</span> : ''}
                  </div>
                  <div className="form-group flexbox">
                    <label>Subject:</label>
                    <input
                      type="text"
                      value={subject ? subject : ""}
                      disabled={!toDetails.coachemail ? true : false}
                      onChange={(e) => { setSubject(e.target.value) }} />
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      placeholder="Enter Your message here..."
                      value={message ? message : ""}
                      disabled={!toDetails.coachemail ? true : false}
                      onChange={(e) => setMessage(e.target.value)}>
                    </textarea>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn blue"
                      disabled={!toDetails.coachemail ? true : false}
                      onClick={(e) => { sendClickBtn(e) }}>Send</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default ComposeMessage;