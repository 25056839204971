import React from "react";
import { useState, useEffect } from "react";
import "./css/notification.css";
import { Menu, MenuItem, Button } from "@material-ui/core";
import { useHistory, Prompt, useLocation } from "react-router-dom";
import messagePopup from "../../../utils/messagePopup";
import { AdminHeader } from '../../header/adminHeader';
import BackdropLoader from "../../common/Loader";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import services from "../../../../src/services";
import { getUserInfo } from "../../../../src/utils/constant"
import toastr from 'toastr';
import swal from "sweetalert";
const ITEM_HEIGHT = 48;

const ComposeMessage = (props) => {
  const location = useLocation();
  let history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [anchorE1, setAnchorE1] = React.useState(null);
  const store = useSelector(store => store);
  const open = Boolean(anchorE1),
    [subject, setSubject] = useState(undefined),
    [message, setMessage] = useState(undefined),
    [toName, setToName] = useState({}),
    [sendMsg, setMsg] = useState(true),
    [loading, setLoading] = useState(true),
    [isBlocking, setIsBlocking] = useState(false),
    [athleteData, setAthleteData] = useState([]),
    [allAthlete, setAllAthlete] = useState([]),
    [selectedAthletes, setSelectedAthletes] = useState([]);

    const showModal = async(nextLocation) => {
      const willSave = await swal({
          text: "Changes you made, may not be saved",
          icon: 'warning',
          buttons: {
              confirm: { text: "Stay" },
              danger: "Leave",
          },
      })
      if(willSave === "danger"){
          setConfirmedNavigation(true);
      }
      setLastLocation(nextLocation);
    };
  
    const handleBlockedRoute = (nextLocation) => {
      if (!confirmedNavigation && nextLocation.pathname !== history.location.pathname) {
        showModal(nextLocation);
        return false;
      }
      return true;
    };
  
    useEffect(() => {
      if (confirmedNavigation && lastLocation) {
        history.push(lastLocation.pathname);
      }
    }, [confirmedNavigation, lastLocation, history]);  

  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };
  const handleCoachMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/coach/composeMessage" });
  }
  const handleAtheleteMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/athlete/composeMessage" });
  }
  const handleAdvisorMenu = () => {
    setAnchorE1(null);
    history.push({ pathname: "/message/support/advisor/composeMessage" });
  }

  const sendClickBtn = async (e) => {
    e.preventDefault();
    setIsBlocking(true);
    let messageId = "";
    if (selectedAthletes.length) {
      /*if (subject) {*/
        if (message) {
          let userIds = [];
          selectedAthletes.map(item => {
            userIds.push(item.id)
          })
          
          await services.location.sendMessageToAnyRole({ 
              msgsenderrole: "ADMIN",
              msgrecieverrole: "ATHLETES",
              msgsenderid: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id),
              msg: message.replace (/"/g, ""),
              userids: userIds,
              parentid: 0
          }).then(async (messageRes) => {
              setIsBlocking(false);
              if (messageRes?.data?.response?.status == 200) {
                toastr.success("Message has been sent successfully").css({"width":"auto"})
                history.push('/message/athlete');
                await services.location.flameAPI({ 
                  msgrecieverids: userIds,
                  athleteId: 0,
                  role: "ADMIN",
                  title: "messagefromteam",
                  userId: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id)
                }).then(async (flameRes) => { })
              } else {
                messagePopup("", "Message has not been sent. Try agai!", "error");
              }
          })
          /*
          await services.location.getAllMessages({where:{
            or: [{and: [{messagesendbyadmin: {neq: null}},{messagesendto: toName.id}]},{and:[{messagesendtoadmin:{neq:null}}, {messagesendby:toName.id}]}]
          }}).then(async (res) =>{
            if(res?.data?.data?.allMessages?.totalCount){
              messageId =  res?.data?.data?.allMessages?.Messages[0]?.id;
              await services.location.saveMessages({
                obj: {
                  id: messageId,
                  readbyotherrole: 1,
                  deletebyotherrole: 0,
                  archivebyotherrole: 0,
                  deletebyathlete:0,
                  archivebyathlete:0
                }
              }).then(async (res) => {
                await services.location.saveMessagereply({
                  obj: {
                    msgid: messageId,
                    active: 1,
                    msg: message,
                    readbyotherrole: 1,
                    msgsenderadminid: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id),
                    msgreceiverid: toName.id,
                    //subject: subject,
                  }
                })
              });
            }
            else{
              await services.location.saveMessages({
                obj: {
                  active: 1,
                  message: message,
                  readbyotherrole: 1,
                  messagesendbyadmin: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id),
                  messagesendto: toName.id,
                  userrole: "ADMIN",
                  //subject: subject,
                }
              }).then(async (mesRes) => {
                if (mesRes?.status == 200) {
                  messageId = mesRes?.data?.data?.saveMessage?.id;
                  await services.location.saveMessagereply({
                    obj: {
                      msgid: messageId,
                      active: 1,
                      msg: message,
                      readbyotherrole: 1,
                      msgsenderadminid: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id),
                      msgreceiverid: toName.id,
                      //subject: subject,
                    }
                  })
                }
              });
            }
            await services.location.saveUserActivity({
              obj: {
                messageid: messageId,
                userid: toName.id,
                adminid: parseFloat(JSON.parse(localStorage.getItem('user_Admin_Advisor')).id),
              }
            }).then(activityRes => {
              messagePopup("", "Message send successfully", "success");
              history.push('/message/athlete');
            })
          })
          */
        }
        else {
          messagePopup("", "Please enter message", "warning")
        }
      /*}
      else {
        messagePopup("", "Please enter subject", "warning")
      }*/
    }
    else {
      messagePopup("", "Please specify recipient", "warning")
    }
  }

  const handleAthleteChange = (values) => {
    setIsBlocking(true);
    setToName(values.target.value)
  }

  const handleAllAthleteChange = async (values) => {
    let searchText = values.target.value;
    if(searchText){
      await services.location.searchAthlete({ athleteName: searchText, active: 1, role: getUserInfo()?.role ?? "" }).then(res =>{
        if(res?.data?.response?.status == 200){
          setAllAthlete(res?.data?.response?.data)
        }
      })
    }
  }

  const changeSelectedAthlete = async (values) => {
    let selVal = values?.target?.value?.length ? values?.target?.value : [];
    const ids = selVal.map(o => o.id)
    const filtered = selVal.filter(({id}, index) => !ids.includes(id, index + 1))
    setSelectedAthletes(filtered)
  }

  useEffect(async () => {
    /*
    await services.location.getAllAthleteDropDown({ obj: { active: 1 } }).then(res => {
      if (res?.data?.data?.allAthletes?.Athletes?.length) {
        res?.data?.data?.allAthletes?.Athletes.map(item => {
          item['gradeyear'] = item?.athleteathletegraduationyearmaprel?.Graduationyears?.length ? item?.athleteathletegraduationyearmaprel?.Graduationyears[0]['yearvalue'] : "";
          item['sportname'] = item?.athleteathletesportsmaprel?.Athletesports?.length ? item?.athleteathletesportsmaprel?.Athletesports[0]['sportname'] : "";
          return item;
        })
        setAthleteData(res?.data?.data?.allAthletes?.Athletes);
        setLoading(false);
      }
    })
    */
    setLoading(false);
  }, []);

  return (
    <>
      <BackdropLoader open={loading} />
      <div className="content">
        <AdminHeader
          title={"Message"}
        />
        <div className="notification-with-sidebar">
          <nav className="notification-sidebar">
            <ul>
              <li className="button-link dropdown">
                <Button
                  className="btn lightblue dropdown-toggle"
                  onClick={e => handleClick(e)}
                >
                  Compose
                </Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button"
                  }}
                  anchorEl={anchorE1}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch"
                    }
                  }}
                >
                  <MenuItem>
                    <strong>Compose for</strong>
                  </MenuItem>
                  <MenuItem onClick={handleAtheleteMenu}>Athlete</MenuItem>
                  <MenuItem onClick={handleAdvisorMenu}>Advisor</MenuItem>
                  {/* <MenuItem onClick={handleCoachMenu}>Coach</MenuItem> */}
                </Menu>
              </li>
              <li onClick={() => history.push({ pathname: "/message/athlete" })}>
                <div className="link-item">
                  <span>Athlete</span> <span>{(parseInt(store?.adminMessages?.athleteUnreadMessages)) ? (parseInt(store?.adminMessages?.athleteUnreadMessages)) : ""}</span>
                </div>
              </li>
              <li onClick={() => history.push({ pathname: "/message/advisor" })}>
                <div className="link-item">
                  <span>Advisor</span><span>{(parseInt(store?.adminMessages?.advisiorUnreadMessages)) ? (parseInt(store?.adminMessages?.advisiorUnreadMessages)) : ""}</span>
                </div>
              </li>
              {/* <li onClick={() => history.push({ pathname: "/message/coach" })}>
                <div className="link-item">
                  <span>Coaches</span> <span>{(parseInt(store?.adminMessages?.coachesUnreadMessages)) ? (parseInt(store?.adminMessages?.coachesUnreadMessages)) : ""}</span>
                </div>
              </li> */}
              <li onClick={() => history.push({ pathname: "/message/archive" })}>
                <div className="link-item">
                  <span>Archive</span><span>{(parseInt(store?.adminMessages?.archiveUnreadMessages)) ? (parseInt(store?.adminMessages?.archiveUnreadMessages)) : ""}</span>
                </div>
              </li>
            </ul>
          </nav>
          <div className="notification-detail-box">
            <div className="notification-search-box">
              <div className="search-box">
                <h1>New Message</h1>
              </div>
            </div>
            <div className="notification-messageDetail">
              <div className="message-hereInfo newMessage">
                <div className="message-replyInfo">
                  <form>
                  <Prompt
                    when={isBlocking}
                    // message={(location) => {
                    //     if(location.pathname !== history.location.pathname){
                    //       return "Do you want to leave this page";
                    //     }
                    // }
                    // }
                    message={handleBlockedRoute}
                  />
                    <div className="form-group flexbox d-block">
                      <label>
                        Select Athlete
                      </label>
                      <div className="autocomplete-class">
                        {/* <Autocomplete
                          id="combo-box-demo"
                          freeSolo={true}
                          options={athleteData}
                          getOptionLabel={(option) =>
                            option.athletefirstname ? option.athletefirstname + " " + option.athletelastname  + " [" + option.gradeyear + ", " + option.sportname + "]" : option
                          }
                          onChange={(e, data) => {
                            handleAthleteChange({
                              target: { value: data ? data : "" },
                            });
                          }}
                          value={""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Enter Athlete Name"
                              value={""}
                            />
                          )}
                        /> */}

                        <Autocomplete
                          id="combo-box-demo"
                          multiple={true}
                          freeSolo={true}
                          options={allAthlete}
                          getOptionLabel={(option) => {
                              return option.athleteFullName ? option.athleteFullName+" ["+option.yearValue+", "+option.sportName+"]" : option.toString()
                            }
                          }
                          onChange={(e, data) => {
                            changeSelectedAthlete({
                              target: { value: data ? data : "" },
                            });
                          }}
                          value={selectedAthletes || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Enter Athlete Name"
                              value={selectedAthletes || ""}
                              onChange={(e) => {
                                handleAllAthleteChange({
                                  target: {
                                    value: e.target.value ? e.target.value : "",
                                  },
                                });
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {/*
                    <div className="form-group flexbox">
                      <label>To:</label>
                      {selectedAthletes.map(item => <span className="toName">{item.athleteEmailId}</span> )}
                    </div>
                    <div className="form-group flexbox">
                      <label>Subject:</label>
                      <input
                        type="text"
                        value={subject ? subject : ""}
                        disabled={!sendMsg ? true : false}
                        onChange={(e) => { setSubject(e.target.value); setIsBlocking(true); }} />
                    </div> */}
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        placeholder="Enter Your message here..."
                        value={message ? message : ""}
                        disabled={!sendMsg ? true : false}
                        onChange={(e) => { setMessage(e.target.value); setIsBlocking(true); }}>
                      </textarea>
                    </div>
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn blue"
                        disabled={!sendMsg ? true : false}
                        onClick={(e) => { sendClickBtn(e) }}>Send</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComposeMessage;