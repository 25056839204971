export const saveUserProfile = `
mutation saveUserprofileview($obj: UserprofileviewInput!){
saveUserprofileview(obj:$obj){
    id
}
}
`

export const dashboardDataCountQuery = `
query{
  allColleges(where :{active:1}){
    totalCount
  }
  allAthletes{
    totalCount
  }
  allCoachandteamdetails{
   totalCount
  }
  allAdvisors(where :{acive:1}){
    totalCount
  }
  allVisitors{
    Visitors{
      totalvisitorcount
    }
  }
}
`


export const getAllAthletesDataQuery = `
query allAthletes($obj: JSON){
  allAthletes(where: $obj){
    Athletes{
      athletecountrycodeid
      athletecountryflag
      athleteemailid
      athletefirstname
      athletegraduationyear
      athletelastname
      athleteoutsideusorcanada
      athletephonenumber
      phonenumberrelation
      stateid
      isathletemobilenumber
      athletestateidmaprel{
        States{
          statename
        }
      }
      homecountryid
      athleteathletecountrycodeidmaprel{
        Countries{
          id
          countryname
        }
      }

      address
      addresscountinue
      homezipcode
      homeoutsideusorcanada
      athletesports
      athletezipcode
      city
      clubteamname
      gender
      graduationmonthvalue
      id
      memberofbaseballclubteam
      verifieduser
      advisorid
      athleteSports
      athleteuseridmaprel{
        Userdata{
          email
          emailverified
          username
          role
        }
      }
      athleteathletesportsmaprel{
        Athletesports{
          sportname
        }
      }
      athleteathletegraduationyearmaprel{
        Graduationyears{
          yearvalue
        }
      }
      onBoardingUserIdMap{
        Onboardings{
          id
          primaryposition
          dateofbirth
          onboardingprimarypositionmaprel{
            Primarypositions{
              selectionfield
              id
            }
          }
          secondaryposition
          onboardingsecondarypositionmaprel{
            Secondarypositions{
              selectionfield
              id
            }
          }
          otherposition
          heightinfeet
          heightininches
          weight
          weightgpa
          weightinkilogram
          battingprefencevalue
          throwingprefencevalue
          gpascale
          unweightedgpa
          gpascalevalue
          twitteraccountname
          usetwitterforschool
          instagramaccountname
          profileimages
        }
      }
      followersFollowerIdMap(where: {isfollowed: 1}){
        totalCount
      }
      followersFollowingIdMap(where: {isfollowed: 1}){
        totalCount
      }
    }
  }
} 
`;

export const getAllAthleteDropDown = `
query allAthletes($obj: JSON){
  allAthletes(where: $obj){
    Athletes{
      id
      athleteemailid
      athletefirstname
      athletelastname
      athleteathletegraduationyearmaprel{
        Graduationyears{
          yearvalue
        }
      }
      athleteathletesportsmaprel{
        Athletesports{
          sportname
        }
      }
    }
  }
} 
`;

export const getAllAdvisors = `
query allAdvisors($obj: JSON){
  allAdvisors(where: $obj){
    Advisors{
      id
      userid
      firstname
      lastname
      emailid
    }
  }
} 
`;

export const getAllCoaches = `
query allCoachandteamdetails($obj: JSON){
  allCoachandteamdetails(where: $obj){
    Coachandteamdetails{
      id
      coachfirstname
      coachlastname
      coachemail
    }
  }
} 
`;

export const updateMessageReply = `
mutation messagereplyUpdateAll($where:JSON, $data:JSON){
  MessagereplyUpdateAll(where: $where, data: $data)
}
`;

export const getAdvisorAthletes = `
query allAdvisorathletemappings($where:JSON){
  allAdvisorathletemappings(where:$where){
    Advisorathletemappings{
      advisorathletemappingathleteidmaprel{
        Athletes{
          active
          athletefirstname
          athletelastname
          id
          athleteathletesportsmaprel{
            Athletesports{
              sportname
            }
          }
          athleteathletegraduationyearmaprel{
            Graduationyears{
              yearvalue
            }
          }
          athleteuseridmaprel{
            Userdata{
              issoftdeleted
              active
            }
          }
        }
      }
    }
  }
}`;