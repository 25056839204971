import { AsYouType, parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
export const IsValidEmail = (value) => {
  //this below code is done becaoue the regex only take alphabet in small letter
  const newValue = !!value ? value?.toLowerCase() : ""
  return new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)$/
  ).test(newValue);
};

export const IsValidName = (value) => {
  const status = new RegExp("^[a-zA-Z]*$").test(value);
  return status
};

export const NewValidName = (value) => {
  const status = new RegExp("^[a-zA-Z-. ]*$").test(value);
  return status
};

export const validURL = (str) => {
  var pattern = new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm);
  return !!pattern.test(str);
}

export const checkPhoneNumberValidation = (number, countryShortForm = "US") => {
  try {
    const phoneNumber = parsePhoneNumber(number, countryShortForm);
    if (phoneNumber && phoneNumber.nationalNumber) {
      const formattedNumber = new AsYouType(countryShortForm).input(
        phoneNumber.nationalNumber
      );
      const newphoneNumber = parsePhoneNumber(
        formattedNumber,
        countryShortForm
      );
      const status = newphoneNumber.isValid();
      return status
    }
    return false;
  } catch (error) {
    return false;
  }
};

// This function is to format phone number into desired format like 000-000-0000
export const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value;
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength <= 5) return phoneNumber;
  if (phoneNumberLength <= 6) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3,6)}`;
  }
  if (phoneNumberLength <= 7) {
    return `${phoneNumber.slice(0, 2)}-${phoneNumber.slice(2,7)}`;
  }
  if (phoneNumberLength <= 8) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3,5)}-${phoneNumber.slice(5, 8)}`;
  }
  if (phoneNumberLength <= 9) {
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 9)}`;
  }
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`;
}