import React, { useEffect, useState } from "react";
import { IsValidEmail } from '../common/validation'
import { Dialog } from "@material-ui/core";
import { EnterOTP } from './enterOTP'
import './assets/css/forgotPassword.scss'
import logoVPng from './assets/img/newlogoForgot.png'
import services from "../../services";
import { Link } from "react-router-dom";
import toastr from 'toastr'
import swal from 'sweetalert'
import BackdropLoader from "../common/Loader";
export const ForgotPassword = (props) => {
    const [userEmail, setUserEmail] = useState("");
    const [error, setError] = useState({});
    const [openOtpModal, setOpenOtpModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState("");
    const inputHandler = (event) => {
        setUserEmail(event.target.value);
        setError({ ...error, emailError: false, registeredEmailError: false });
    };
    const onClose = () => {
        setOpenOtpModal(false);
    };
    // const openModal = async (res) => {
    //     if (res.data.response.status === 201) {
    //         setError({ ...error, registeredEmailError: true });
    //     } else {
    //         setOpenOtpModal(true);
    //         setError({})
    //     }
    // }
    const onSubmitHandler = async (event) => {
        event.preventDefault();
        if (!IsValidEmail(userEmail)) {
            setError({ ...error, emailError: true });
            return;
        }
        setLoading(true)
        const _resCheck = await services?.location?.checkRole({ "email": userEmail })
        if (_resCheck?.status == 200 && _resCheck?.data?.response?.status == 200) {
            setLoading(false);
            if (_resCheck?.data?.response?.data?.role == "ADMIN" || _resCheck?.data?.response?.data?.role == "ADVISORS") {
                const body = {
                    emailId: userEmail,
                    forverification: 0 //this condition is for email header
                }
                const _res = await services.location.sendEmail(body)
                if (_res?.status == 200) {
                setPhone(_res?.data?.response?.data?.phonenumber ?? "")
                    if (_res.data.response.status === 201) {
                        setError({ ...error, registeredEmailError: true });
                        setLoading(false)
                    } else {
                        setOpenOtpModal(true);
                        setError({})
                    }
                } else {
                }
            } else {
                // toastr.error('Access Denied')
                swal({ text: "Access Denied", icon: "error" });
                setLoading(false)
            }
        }
        else if (_resCheck?.status == 200 && _resCheck?.data?.response?.status == 401) {//last moment change 25/11/2022
            toastr.error(" Please enter your registered email id", "", {
                progressBar: true, timeOut: 2000,
                closeButton: true, positionClass: "toast-top-full-width",
                showEasing: "swing", fadeIn: 40000,
                preventDuplicates: true,
            })
            setLoading(false)
        }
    }
    return (
        <>
            <BackdropLoader open={loading} />
            <section className="forgot-pass-sec">
                <div className="container">
                    <div className="forgot-box">
                        <div className="back-link-box">
                            <Link
                                to={'/'}
                                //styling this as to fix the bug
                                style={{ width: "24%" }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="9.337"
                                    height="16.828"
                                    viewBox="0 0 9.337 16.828"
                                >
                                    <path
                                        id="Path_117"
                                        data-name="Path 117"
                                        d="M6.922,0,0,7l6.922,7"
                                        transform="translate(1 1.414)"
                                        fill="none"
                                        stroke="#c0321f"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    />
                                </svg>
                                Sign In
                            </Link>
                        </div>
                        <div className="detail-text-box">
                            <div className="logo-box">
                                <img src={logoVPng} alt="" />
                            </div>
                            {sessionStorage.getItem("ispasswordStatus") ? <h1>Verify your email ID</h1> : <h1>Forgot Your Password ?</h1>}
                            {sessionStorage.getItem("ispasswordStatus") ? <p>Please enter the passcode sent on your Email ID.</p> : <p>
                                You 'll receive a mail on your registered email address containing a temporary passcode to reset your password.
                            </p>}
                        </div>
                        <form className="form-box">
                            <div className="form-group">
                                <input
                                    type="email"
                                    placeholder="Registered email address"
                                    onChange={inputHandler}
                                    value={userEmail}
                                />
                            </div>
                            {error["emailError"] && (
                                <p style={{ color: "red", textAlign: "left" }}>Please enter your registered email id.</p>
                            )}
                            {error["registeredEmailError"] && (
                                <p style={{ color: "red", textAlign: "left" }}>
                                    Please enter a registered email id
                                </p>
                            )}
                            <div className="form-group">
                                <button className="btn" type="submit" onClick={onSubmitHandler}>
                                    Send me the passcode
                                </button>
                            </div>
                        </form>
                        <div className="bottom-text-box">
                            {sessionStorage.getItem("ispasswordStatus") ?
                                "" :
                                <p>
                                    {/* Don't have an account? <a href="/#signupstep1">Sign Up</a> */}
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <Dialog open={openOtpModal}
                            onClose={(event, reason)=>{
                                if (reason == "backdropClick" || reason == "escapeKeyDown"){
                                    onClose()
                                }
                            }}
                    >
                        <EnterOTP closeButton={onClose} userEmailId={userEmail} phone={phone} props={props} />
                    </Dialog>
                </div>
            </section>
        </>
    )
}