export const savePasswordChangeQuery = `
mutation changePassword($id :JSON,$oldPassword:String ,$newPassword:String){
    UserdataChangePassword(id:$id ,oldPassword:$oldPassword ,newPassword:$newPassword)  
  }
`;

export const notificationSettingQuery = `
mutation saveNotificationadvisorsetting($obj:NotificationadvisorsettingInput!){
  saveNotificationadvisorsetting(obj:$obj){
      id
    }
  }
`;

export const allSubscriptionPlanQuery = `
query allSubscriptionplans($where:JSON){
  allSubscriptionplans(where:$where){
    Subscriptionplans{
      id
      planname
    }
  }
}        
`;

export const allSubscriptionPlanTypeQuery = `
query allSubscriptionplantypes($where:JSON){
  allSubscriptionplantypes(where:$where){
    Subscriptionplantypes{
      id
      plantype
    }
  }
}        
`;

export const allLeadSource = `
query allLeadsources($where:JSON){
  allLeadsources(where: $where){
    Leadsources{
      id
      name
    }
  }
}
`
export const allAppointmentSetter = `
query allAppointmentsetters($where:JSON){
  allAppointmentsetters(where: $where){
    Appointmentsetters{
      id
      name
    }
  }
}
`


  
  