import {React, useState} from "react";
import { getUserInfo } from "../../../utils/constant";
import { mutationQueries } from "../../../MutationMethod";
import { gql } from "apollo-boost";
import swal from 'sweetalert';
import moment from "moment";
import services from "../../../services";
import "../assets/css/evaluation-form.scss";
export default function SaveNewDraft({handleModal, draftCount, draftData, getDraftData, Athid, field, keyStatData,
                                         collegeDivisionData, closeEvaluationModal, isDraft, handleDraft}) {
  const athid = Athid;
  const [draftName, setDraftName] = useState(undefined),
  [errors, setErrors] = useState({});
  const handleChange = e => {
    setErrors({
        errorsT : ""
    });
      setDraftName(e.target.value)
  }
  const handleValidation = (fieldname, e) => {
    let errorsT = errors;
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    let updatedValue = fieldValue;
    if (fieldname === undefined || fieldname === "draftName") {
      if (fieldname === undefined) {
        fieldValue = draftName ? draftName : "";
        updatedValue = fieldValue;
        delete errorsT["draftName"];
      }
      if (!updatedValue) {
        formIsValid = false;
        errorsT["draftName"] = "Please enter draft name";
      }
    }
    setErrors({
      ...errorsT,
    });
    return {
      formIsValid,
      updatedValue,
    };
  }
  const handleSubmit = async () => {
    const { formIsValid } = handleValidation();
    if(formIsValid){
    if(draftData?.length < 10){
        let effactiveAdvisorId = null;
        let effactiveAdminId = null;
        if(getUserInfo().role == "ADMIN" && getUserInfo().id == field?.fullName?.id){
            effactiveAdvisorId = null;
            effactiveAdminId = getUserInfo().id;
        }
        else if(getUserInfo().role == "ADMIN" && getUserInfo().id != field?.fullName?.id){
            effactiveAdvisorId = field?.fullName?.id ? parseFloat(field?.fullName?.id) : null;
            effactiveAdminId = getUserInfo().id;
        }
        else if(getUserInfo().role !== "ADMIN"){
            effactiveAdvisorId = field?.fullName?.id ? parseFloat(field?.fullName?.id) : null;
            effactiveAdminId = null;
        }
        let obj = {
            // id: field?.id,
            draftname: draftName,
            isdraft: 1,
            active: 1,
            // createdon:  field?.createdOn ? new Date(field?.createdOn) : null,
            createdon : moment(new Date()),
            athleteid: Athid,
            adminid: effactiveAdminId,
            advisorid: effactiveAdvisorId,
            projectionposition: field?.projectionposition ? parseFloat(field?.projectionposition) : null,
            athleticismlevel: field?.athleticismlevel ? field?.athleticismlevel.toString() : null,
            skilllevel: field?.skilllevel ? field?.skilllevel.toString() : null,
            acadamicrating: field?.acadamicrating && field?.acadamicrating !== "0" ? field?.acadamicrating.toString() : null,
            keystats: keyStatData?.length > 0 ? JSON.stringify(keyStatData) : null,
            scoutingsummary: field?.scoutingsummary || null,
            areasforimprovement: field?.areasforimprovement || null,
            additionalwebsitelink: field?.additionalwebsitelink || null,
            heightinfeet: field?.feetId && field?.feetId !== "0" ? parseFloat(field?.feetId) : null,
            heightininch: field?.inchId && field?.inchId !== "Select" ? parseFloat(field?.inchId) : null,
            weight: field?.weight ? field?.weight.toString() : null,
            reportviewedby: field?.reportviewedby ? field?.reportviewedby : null
        }
        const res = await services.location.saveEvaluationDraftData(obj);
        if (res && res?.status == 200) {
          const evaluationdraftid = res?.data?.data?.saveEvaluationdraft?.id;
            if (collegeDivisionData?.length > 0) {
                let query = "";
                collegeDivisionData.map((item, index) => {
                    return item?.id ?
                        (query += `id${index}: saveProjecteddivisionsfordraft(
                    obj: {
                      advisorid: ${effactiveAdvisorId}
                      adminid: ${effactiveAdminId}
                      high: "${item.high}"
                      mid:"${item.mid}"
                      top: "${item.top}"
                      low: "${item.low}"
                      active:${1}
                      divisionid: ${parseFloat(item.divisionid)}
                      userid: ${parseFloat(item.userid)}
                      evaluationdraftid:${evaluationdraftid}          
                    }
                  ){id}`) :
                        (query += `id${index}: saveProjecteddivisionsfordraft(
                  obj: {
                    advisorid: ${effactiveAdvisorId}
                    adminid: ${effactiveAdminId}
                    high: "${item.high}"
                    mid:"${item.mid}"
                    top: "${item.top}"
                    low: "${item.low}"
                    active:${1}
                    divisionid: ${parseFloat(item.divisionid)}
                    userid: ${parseFloat(item.userid)}
                    evaluationdraftid:${evaluationdraftid}            
                  }
                ){id}`)
                });
                mutationQueries(gql`
              mutation {
                ${query}
              }
            `).then(res => {
                });
            }
            swal({ text: "Your draft has been saved !", icon: "success" });
            getDraftData();
            handleModal();
            closeEvaluationModal();
            if(!!isDraft){
                handleDraft()
            }
        }
        else {
            swal({ title: "Evaluation draft report not saved", icon: "error" })
            handleModal();
            closeEvaluationModal();
            if(!!isDraft){
                handleDraft()
            }
        }
    }
    else{
        handleModal();
        swal({text: "You can save up to 10 Evaluation Drafts for an Athlete, delete one of them to proceed further",  icon:"warning"})
    }
    }
  }
  return (
    <>
      <div class="modal openModal common-modal" id="SaveNewDraftModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Save as new draft</h5>
                    <button type="button" class="close" data-dismiss="modal" onClick={() => handleModal()} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Draft name <sup style={{color:"red"}}>*</sup></label>
                        <input type="text" placeholder="Enter draft name here" name="draftName" id="draftName" value={draftName || ""} onChange={(e)=>handleChange(e)} />
                        <div className="error">{errors["draftName"]}</div>
                    </div>
                    <div class="draft-table-wrap">
                        <div class="head-box">
                            <div class="item">{`Total Drafts (${draftCount})`}</div>
                        </div> 
                        <div className="table-scrollbar">
                        <table class="table">
                            {draftData?.length > 0 ? draftData?.map(item=>{
                                return <tr>
                                 <td>{item.createdon ? moment(item.createdon).format("MMMM DD, YYYY") : ""}</td>
                                 <td>{item.draftname || ""}</td>
                                 <td>{item.createdon ? moment(item.createdon).format("h:mm a") : ""}</td>
                             </tr>
                            })
                            : <tr>No data found</tr>}
                        </table>
                        </div> 
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn lightblue" data-target="#EvaluationSaveDraftModal" onClick={()=>handleSubmit()}>Save Draft</button>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}