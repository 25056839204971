import {React, useEffect, useState} from "react";
import { getUserInfo } from "../../../utils/constant";
import { mutationQueries } from "../../../MutationMethod";
import { gql } from "apollo-boost";
import swal from 'sweetalert';
import moment from "moment";
import services from "../../../services";
import Dialog from "@material-ui/core/Dialog";
import AddEvaluationDraftForm from "./AddEvaluationDraftForm";
import "../assets/css/evaluation-form.scss";
export default function DraftData({handleModal, handleDraftModal, Athid, getEvaluationData, bathID, throwID, evaluationReportId, publishedProjectedDivisions}) {
  const [draftCount, setDraftCount] = useState(0);
  const [openDraftModal, setOpenDraftModal] = useState(0);
  const [evaluationDraftData, setEvaluationDraftData] = useState([]);
  const [reportDraftData, setReportDraftData] = useState([]);
  const getDraftData = async () => {
    let evaluationWhere = {
        order: "id desc",
        active: 1,
        athleteid: parseFloat(Athid),
    };
    if(getUserInfo().role === "ADMIN"){
        evaluationWhere.adminid = parseFloat(getUserInfo().id)
    }
    else{
        evaluationWhere.advisorid = parseFloat(getUserInfo().id)
    }
    const _res = await services.location.getEvaluationDraftData({ where: evaluationWhere })
    if (_res && _res?.status == 200 && _res?.data?.data?.allEvaluationdrafts?.Evaluationdrafts?.length > 0) {
    setDraftCount(_res?.data?.data?.allEvaluationdrafts?.totalCount);
    setEvaluationDraftData(_res?.data?.data?.allEvaluationdrafts?.Evaluationdrafts);
    }
    else {
        setDraftCount(0);
        setEvaluationDraftData([])
    }
    }

    useEffect(()=>{
      getDraftData();
    },[]);

const handleDeleteAllDraft = async (e) => {
    if(evaluationDraftData?.length > 0){
    swal({text:"Do you want to delete all available evaluation drafts?", icon:"warning",  buttons: {
        confirm: { text: "Ok" },
        cancel: "Cancel",
      }}).then(async willDelete=>{
          if(willDelete){
            let userInfo = await getUserInfo()
            const res = await services.location.deleteDraft({
               "athleteid":parseFloat(Athid),
               "advisorid": userInfo.role === 'ADVISORS' ? parseFloat(userInfo.id) : 0,
               "draftId": 0,
               "adminid":userInfo.role === 'ADMIN' ? parseFloat(userInfo.id) : 0
          })
          if (res && res?.status == 200) {
           swal({ text: "All available drafts have been deleted !", icon: "success" });
           getDraftData();
           getEvaluationData();
           handleModal();
          }
          else{
           swal({ text: "Delete draft data failed", icon: "error" });
           handleModal();
          }
        }
    })
}
}

 const deleteDraftData = async (e, item) => {
     swal({text:"Do you want to delete this evaluation draft?", icon:"warning",  buttons: {
        confirm: { text: "Ok" },
        cancel: "Cancel",
      },}).then(async willDelete=>{
         if(willDelete){
            const res = await services.location.deleteDraft({
                "athleteid":parseFloat(Athid),
            "advisorid": parseFloat(getUserInfo().id),
            "draftId":item.id
           })
           if (res && res?.status == 200) {
            swal({ text: "Your draft has been deleted !", icon: "success" });
            getDraftData();
            handleModal();
            getEvaluationData();
           }
           else{
            swal({ text: "Delete draft data failed", icon: "error" });
            handleModal();
           }
         }
     })
 }

 const openReportDraftData = (item) => {
 setReportDraftData(item);
 setOpenDraftModal(!openDraftModal)
 }

  return (
    <>
    <Dialog open={openDraftModal}
            onClose={(event, reason)=>{
                if (reason == "backdropClick" || reason == "escapeKeyDown"){
                    setOpenDraftModal(!openDraftModal)
                }
            }}
    >
                <AddEvaluationDraftForm
                    handleModal={() => setOpenDraftModal(!openDraftModal)}
                    handleDraft={()=>handleModal()}
                    handleDraftModal={()=>handleDraftModal()}
                    isDraft={true}
                    modalData={reportDraftData}
                    getDraftData={getDraftData}
                    Athid={Athid}
                    bathID = {bathID}
                    throwID = {throwID}
                    evaluationReportId = {evaluationReportId}
                    publishedProjectedDivisions={publishedProjectedDivisions}
                    // projectionposition={profileAbout ? profileAbout?.projectionposition : null}
                    // athletePersonalInfo={props?.athletePersonalInfo}
                />
            </Dialog>
      <div class="modal openModal common-modal" id="TotalDraftAvailableModal" style={{display:"block", position:"initial", overflowY:"auto"}}>
        <div class="modal-dialog modal-dialog-centered m-0" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Draft</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>handleModal()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body border-none">
                    <div class="draft-table-wrap">
                        <div class="head-box">
                            <div class="item">{`Total Drafts (${draftCount})`}</div>
                            <div class="item">
                                <button style={draftCount == 0 ? {opacity:"0.7"}:{}} class="trash-btn" data-dismiss="modal" data-toggle="modal" data-target="#TrashEvaluationDraftModal" onClick={()=>{handleDeleteAllDraft()}}>
                                    <span class="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <path id="delete" d="M16.905,3.375H1.156A1.1,1.1,0,0,1,.365,3.05a1.063,1.063,0,0,1-.334-.791,1.107,1.107,0,0,1,.325-.8,1.071,1.071,0,0,1,.8-.334H6.781A1.1,1.1,0,0,1,7.106.335,1.073,1.073,0,0,1,7.906,0h2.25a1.073,1.073,0,0,1,.8.334,1.1,1.1,0,0,1,.325.791h5.625a1.081,1.081,0,0,1,.791.334,1.091,1.091,0,0,1,.334.8,1.062,1.062,0,0,1-.334.791,1.1,1.1,0,0,1-.791.325Zm0,12.375A2.25,2.25,0,0,1,14.655,18H3.405a2.169,2.169,0,0,1-1.591-.659,2.165,2.165,0,0,1-.659-1.591V4.5H16.9V15.75ZM5.655,7.875A1.14,1.14,0,0,0,4.522,6.751a1.062,1.062,0,0,0-.791.334,1.1,1.1,0,0,0-.325.791v6.75A1.111,1.111,0,0,0,4.522,15.75a1.107,1.107,0,0,0,.8-.325,1.071,1.071,0,0,0,.334-.8V7.876Zm4.5,0A1.14,1.14,0,0,0,9.022,6.751a1.062,1.062,0,0,0-.791.334,1.1,1.1,0,0,0-.325.791v6.75A1.111,1.111,0,0,0,9.022,15.75a1.107,1.107,0,0,0,.8-.325,1.071,1.071,0,0,0,.334-.8V7.876Zm4.5,0a1.14,1.14,0,0,0-1.134-1.125,1.062,1.062,0,0,0-.791.334,1.1,1.1,0,0,0-.325.791v6.75a1.111,1.111,0,0,0,1.116,1.125,1.107,1.107,0,0,0,.8-.325,1.071,1.071,0,0,0,.334-.8V7.876Z" transform="translate(-0.031 -0.001)" fill="#c0321f"/>
                                        </svg>
                                    </span> Delete all
                                </button>
                            </div>
                        </div>  
                        <table class="table">
                            <tr>
                                <td>Date</td>
                                <td>Draft Name</td>
                                <td>Time</td>
                                <td class="text-right">Action</td>
                            </tr>
                            {evaluationDraftData?.length > 0 ? evaluationDraftData?.map(item=>{
                                return <tr>
                                 <td>{item.createdon ? moment(item.createdon).format("MMMM DD, YYYY") : ""}</td>
                                 <td style={{cursor: "pointer",textDecoration:'underline',color:'#c0321f'}} onClick={()=>openReportDraftData(item)}>{item.draftname || ""}</td>
                                 <td>{item.createdon ? moment(item.createdon).format("h:mm a") : ""}</td>
                                <td class="text-right">
                                    <button class="trash-btn" onClick={(e)=>deleteDraftData(e, item)}>
                                        <span class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                <path id="delete" d="M16.905,3.375H1.156A1.1,1.1,0,0,1,.365,3.05a1.063,1.063,0,0,1-.334-.791,1.107,1.107,0,0,1,.325-.8,1.071,1.071,0,0,1,.8-.334H6.781A1.1,1.1,0,0,1,7.106.335,1.073,1.073,0,0,1,7.906,0h2.25a1.073,1.073,0,0,1,.8.334,1.1,1.1,0,0,1,.325.791h5.625a1.081,1.081,0,0,1,.791.334,1.091,1.091,0,0,1,.334.8,1.062,1.062,0,0,1-.334.791,1.1,1.1,0,0,1-.791.325Zm0,12.375A2.25,2.25,0,0,1,14.655,18H3.405a2.169,2.169,0,0,1-1.591-.659,2.165,2.165,0,0,1-.659-1.591V4.5H16.9V15.75ZM5.655,7.875A1.14,1.14,0,0,0,4.522,6.751a1.062,1.062,0,0,0-.791.334,1.1,1.1,0,0,0-.325.791v6.75A1.111,1.111,0,0,0,4.522,15.75a1.107,1.107,0,0,0,.8-.325,1.071,1.071,0,0,0,.334-.8V7.876Zm4.5,0A1.14,1.14,0,0,0,9.022,6.751a1.062,1.062,0,0,0-.791.334,1.1,1.1,0,0,0-.325.791v6.75A1.111,1.111,0,0,0,9.022,15.75a1.107,1.107,0,0,0,.8-.325,1.071,1.071,0,0,0,.334-.8V7.876Zm4.5,0a1.14,1.14,0,0,0-1.134-1.125,1.062,1.062,0,0,0-.791.334,1.1,1.1,0,0,0-.325.791v6.75a1.111,1.111,0,0,0,1.116,1.125,1.107,1.107,0,0,0,.8-.325,1.071,1.071,0,0,0,.334-.8V7.876Z" transform="translate(-0.031 -0.001)" fill="#c0321f"/>
                                            </svg>
                                        </span>
                                    </button>
                                </td>
                                </tr>
                            }) : <tr>No data found</tr>}
                       </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </>
  );
}