import React, { useEffect, useState, useRef } from "react";
import {useHistory, useLocation} from "react-router-dom";
import { Link, withRouter } from 'react-router-dom';
import "../../../assets/css/stylesheet.css";
import "../assets/css/athleteProfileFinalDark.scss";
import ENV from "../../../config";
import moment from 'moment';
import Dialog from "@material-ui/core/Dialog";
import SharePost from "./ShareReportPost";
import swal from "sweetalert";
import services from "../../../services";
import BaseballImage from "../assets/img/baseball-position.jpg";
import AddEvaluationForm from "./AddEvaluationForm";
import { getUserInfo } from "../../../utils/constant";
import DraftData from "./DraftData";
import {profileURLData} from "../../common/getProfileURLData";
export const Report = withRouter((props) => {
    let history = useHistory();
    const [openModal, setOpenModal] = useState(false)
    const [openDraftModal, setOpenDraftModal] = useState(false)
    const [isShare, setIsShare] = useState(false)
    const [profileAbout, setProfileAbout] = useState(null);
    const [draftCount, setDraftCount] = useState(0);
    const [profileProjectData, setProfileProjectData] = useState([]);
    const location = useLocation()
    const Athid = profileURLData(location?.pathname, 'report_id'),
            // +props?.match?.params?.id,
        pubViewID = JSON.parse(localStorage.getItem('pubViewID')),
        isToken = profileURLData(location?.pathname, 'token')
            // props?.props?.match?.params?.isToken == 1 ? 1 : 0;
    const fakeInput = useRef(null);

    const getEvaluationData = async () => {
        const res = await services.location.getEvaluation({ 
            athleteId: Athid, 
            isAccessToken: isToken, 
            role: getUserInfo()['role'],
            adminid: getUserInfo()['role'] === "ADMIN" ? getUserInfo()['id'] : 0,
            advisorid: getUserInfo()['role'] === "ADMIN" ? 0 : getUserInfo()['id'],
        })
        
        if (res && res?.status == 200) {
            res?.data?.response?.data?.map(item => {
                item["keyStatValue"] = JSON.parse(item.keystats)?.length > 0 ? JSON.parse(item.keystats)?.map(newItem => {
                    return { name: newItem.name + " : " + newItem.value }
                }) : []
                return item;
            })
            setProfileAbout(res?.data?.response?.data?.[0]);
            setDraftCount(res?.data?.response?.draftCount);
            setProfileProjectData(res?.data?.response?.projectedDivisions);
        }
        else {
            setDraftCount(0);
            setProfileProjectData([]);
            setProfileAbout(null)
        }
    }

    useEffect(() => {
        getEvaluationData()
    }, [])

    useEffect(() => {
        getEvaluationData();
        fakeInput.current.focus();
        fakeInput.current.select();
    }, [pubViewID])

    //this is added as ui is not updating when user save the draft
    useEffect(() => {
        getEvaluationData();
    }, [openModal, openDraftModal])

    const calculateAge = (dateOfBirth) => {
        var dob = new Date(dateOfBirth);
        var dobYear = dob.getYear();
        var dobMonth = dob.getMonth();

        var now = new Date();
        var currentYear = now.getYear();
        var currentMonth = now.getMonth();

        var age = {};
        var ageString = "";

        //get years
        let yearAge = currentYear - dobYear;

        //get months
        if (currentMonth >= dobMonth)
            //get months when current month is greater
            var monthAge = currentMonth - dobMonth;
        else {
            yearAge--;
            var monthAge = 12 + currentMonth - dobMonth;
        }

        age = {
            years: yearAge,
            months: monthAge
        };
        if ((age.years > 0) && (age.months > 0))
            ageString = age.years + " years, " + age.months + " months";
        else if ((age.years > 0) && (age.months == 0))
            ageString = age.years + " years";
        else if ((age.years == 0) && (age.months > 0))
            ageString = age.months + " months";
        return ageString;
    }

    const openModalDialog = async () => {
        const res = await services.location.createProjected({ userid: Athid, advisorid: 1 });
        setOpenModal(!openModal)
    }
    const handleOpenDraftModal = () => {
        setOpenDraftModal(!openDraftModal);
    }
    const routeToAthleteMessage = () => {
        // props.history.push(`/message/advisor/composeMessage/${parseFloat(profileAbout?.advisorid)}`)
    }
    return (
        <div className="report-data">
            <input className="fakeInput" type="text" ref={fakeInput} />
            <Dialog open={isShare}
                    onClose={(event, reason)=>{
                        if (reason == "backdropClick" || reason == "escapeKeyDown"){
                            setIsShare(!isShare)
                        }
                    }}
            >
                <SharePost
                    handleModal={() => setIsShare(!isShare)}
                    modalData={profileAbout}
                />
            </Dialog>
            <Dialog open={openModal}
                    onClose={(event, reason)=>{
                        if (reason == "backdropClick" || reason == "escapeKeyDown"){
                            setOpenModal(!openModal)
                        }
                    }}
            >
                <AddEvaluationForm
                    handleModal={() => setOpenModal(!openModal)}
                    modalData={profileAbout}
                    getEvaluationData={getEvaluationData}
                    Athid={Athid}
                    projectionposition={profileAbout ? profileAbout?.projectionposition : null}
                    athletePersonalInfo={props?.athletePersonalInfo}
                    bathID={!!props?.athletePersonalInfo?.battingPrefId
                        ? props?.athletePersonalInfo?.battingPrefId
                        : ""}
                    throwID={!!props?.athletePersonalInfo?.throwingPrefId
                        ? props?.athletePersonalInfo?.throwingPrefId
                        : ""}
                />
            </Dialog>
            <Dialog open={openDraftModal}
                    onClose={(event, reason)=>{
                        if (reason == "backdropClick" || reason == "escapeKeyDown"){
                            setOpenDraftModal(!openDraftModal)
                        }
                    }}
            >
                <DraftData
                    handleModal={() => setOpenDraftModal(!openDraftModal)}
                    handleDraftModal={() => handleOpenDraftModal()}
                    getEvaluationData={getEvaluationData}
                    Athid={Athid}
                    draftCount={draftCount}
                    bathID={!!props?.athletePersonalInfo?.battingPrefId
                        ? props?.athletePersonalInfo?.battingPrefId
                        : ""}
                    throwID={!!props?.athletePersonalInfo?.throwingPrefId
                        ? props?.athletePersonalInfo?.throwingPrefId
                        : ""}
                    evaluationReportId={profileAbout?.id ? profileAbout?.id : null }
                    publishedProjectedDivisions={profileProjectData?.length ? profileProjectData : []}    
                />
            </Dialog>
            {JSON?.parse(localStorage.getItem("user_Admin_Advisor")) && JSON?.parse(localStorage.getItem("user_Admin_Advisor"))?.role !== "ATHLETES" ?
                (JSON?.parse(localStorage.getItem("user_Admin_Advisor"))?.role === "ADVISORS"
                    || getUserInfo()?.role == "ADMIN" //this is added as both admin and advisor can access the report
                ) ?
                    profileAbout ? (
                        <div>
                            <div className="report-profile-info">
                                <h3 className="title">Scouting Reports</h3>
                                <div className="updateEvaluationPara">
                                    <div className="detail-box">
                                        <p>College coaches rely on up-to-date evaluations. Keeping evaluations fresh greatly increases your athletes chances.</p>

                                    </div>
                                    <button className="btn blue" onClick={() => openModalDialog()}>
                                        {profileAbout ? "Update Evaluation" : "Add Evaluation"}
                                    </button>
                                    <span className="draftText" onClick={() => { handleOpenDraftModal() }}>{`Draft(s) Available (${draftCount || 0})`}</span>
                                </div>
                                <div className="scouting-info-box">
                                    <h4>Evaluated by  
                                        <span>
                                            {profileAbout?.advisorid ? profileAbout?.advisorFirstName ? " " + profileAbout?.advisorFirstName.concat(profileAbout?.advisorLastName ? " " + profileAbout?.advisorLastName : "") : "" : profileAbout?.adminid ? " "+getUserInfo()?.fullname?.replace(/\s/g, '') : ""}
                                            {/*profileAbout?.advisorFirstName ? profileAbout?.advisorFirstName.concat(profileAbout?.advisorLastName ? " " + profileAbout?.advisorLastName : "") : ""*/}
                                        </span>
                                    </h4>
                                    <p>Last Updated: {profileAbout?.updatedOn ? moment(profileAbout?.updatedOn).format('MM/DD/YYYY') : ""}</p>
                                </div>
                                <div className="profile-info">
                                    <div className="pick-box">
                                        {profileAbout?.profileImages ?
                                            <img src={`${ENV.DOWNLOAD_URL + "/" + profileAbout?.profileImages || ""}`} alt="" /> :
                                            <span className="initialLetter">
                                                {`${profileAbout?.advisorFirstName?.split("")[0] || ""}${profileAbout?.advisorLastName?.split("")[0] || 'sf'}`}
                                            </span>
                                        }
                                    </div>
                                    <div className="detail-box">
                                        <Link onClick={() => { }}>
                                            <h4 className="name">{profileAbout?.advisorFirstName ? profileAbout?.advisorFirstName.concat(profileAbout?.advisorLastName ? " " + profileAbout?.advisorLastName : "") : ""}</h4>
                                        </Link>
                                        <p>SportsForce Team</p>
                                    </div>
                                </div>
                                <ul>
                                    <li><strong>Report:</strong> {profileAbout?.createdOn ? moment(profileAbout?.createdOn).format('MM/DD/YYYY') : ""}</li>
                                    <li><strong>Athlete:</strong> {profileAbout?.athleteFirstName ? profileAbout?.athleteFirstName.concat(profileAbout?.athleteLastName ? " " + profileAbout?.athleteLastName : "") : ""}</li>
                                    <li><strong>Grad Year:</strong> {profileAbout?.yearValue ? profileAbout?.yearValue : ""}</li>
                                    <li><strong>Age:</strong> {profileAbout?.dateOfBirth ? calculateAge(profileAbout?.dateOfBirth) : ""}</li>
                                    <li><strong>Bat / Throw: </strong>{!!props?.athletePersonalInfo?.battingPreferenceValue
                                        ? props?.athletePersonalInfo?.battingPreferenceValue
                                        : ""}
                                        {!!props?.athletePersonalInfo?.battingPreferenceValue &&
                                            !!props?.athletePersonalInfo?.throwingPreferenceValue
                                            ? " / "
                                            : ""}
                                        {!!props?.athletePersonalInfo?.throwingPreferenceValue
                                            ? props?.athletePersonalInfo?.throwingPreferenceValue
                                            : ""}</li>
                                    <li><strong>Projected Position:</strong> {profileAbout?.projectionposition ? profileAbout?.selectionField.concat(profileAbout?.shortform ? ` (${profileAbout?.shortform})` : "") : ""}</li>
                                </ul>
                                <div className="report-flexbox">
                                    <div className="item">
                                        <div className="report-range-item">
                                            <div className="range-top-bar">
                                                <h4>
                                                    Athleticism
                                                    <span className="icon">
                                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <g id="Group_57168" data-name="Group 57168">
                                                                <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </h4>
                                                <div className="range-info">
                                                    <div className="item">Rating</div>
                                                    <div className="item">{profileAbout?.athleticismlevel ? profileAbout?.athleticismlevel : 0}</div>
                                                </div>
                                            </div>
                                            <div className="range-progress-bar">
                                                <div className="progressbar small">
                                                    <div className="progress" style={{
                                                        width: `${profileAbout?.athleticismlevel ?
                                                            parseFloat(profileAbout?.athleticismlevel) === 0.5 ? 14.5
                                                                : parseFloat(profileAbout?.athleticismlevel) === 1 ? 23.3
                                                                    : parseFloat(profileAbout?.athleticismlevel) === 1.5 ? 33.3
                                                                        : parseFloat(profileAbout?.athleticismlevel) === 2 ? 42.3
                                                                            : parseFloat(profileAbout?.athleticismlevel) === 2.5 ? 52.3
                                                                                : parseFloat(profileAbout?.athleticismlevel) === 3 ? 61.3
                                                                                    : parseFloat(profileAbout?.athleticismlevel) === 3.5 ? 71.9
                                                                                        : parseFloat(profileAbout?.athleticismlevel) === 4 ? 80.5
                                                                                            : parseFloat(profileAbout?.athleticismlevel) === 4.5 ? 90.9
                                                                                                : parseFloat(profileAbout?.athleticismlevel) === 5 ? 100
                                                                                                    : 3.9 : 3.9}%`
                                                    }}></div>
                                                    {/* <div className="progress" style={{ width: `${profileAbout?.athleticismlevel ? ((parseFloat(profileAbout?.athleticismlevel) / 5) * 100) + 0.9 : 3.9}%` }}></div> */}
                                                </div>
                                                <div className="range-point-number">
                                                    <div className="item">0</div>
                                                    <div className="item">1</div>
                                                    <div className="item">2</div>
                                                    <div className="item">3</div>
                                                    <div className="item">4</div>
                                                    <div className="item">5</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="report-range-item">
                                            <div className="range-top-bar">
                                                <h4>
                                                    Skill Level
                                                    <span className="icon">
                                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <g id="Group_57168" data-name="Group 57168">
                                                                <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </h4>
                                                <div className="range-info">
                                                    <div className="item">Rating</div>
                                                    <div className="item">{profileAbout?.skillLevel ? profileAbout?.skillLevel : 0}</div>
                                                </div>
                                            </div>
                                            <div className="range-progress-bar">
                                                <div className="progressbar small">
                                                    <div className="progress" style={{
                                                        width: `${profileAbout?.skillLevel ?
                                                            parseFloat(profileAbout?.skillLevel) === 0.5 ? 14.5
                                                                : parseFloat(profileAbout?.skillLevel) === 1 ? 23.3
                                                                    : parseFloat(profileAbout?.skillLevel) === 1.5 ? 33.3
                                                                        : parseFloat(profileAbout?.skillLevel) === 2 ? 42.3
                                                                            : parseFloat(profileAbout?.skillLevel) === 2.5 ? 52.3
                                                                                : parseFloat(profileAbout?.skillLevel) === 3 ? 61.3
                                                                                    : parseFloat(profileAbout?.skillLevel) === 3.5 ? 71.9
                                                                                        : parseFloat(profileAbout?.skillLevel) === 4 ? 80.5
                                                                                            : parseFloat(profileAbout?.skillLevel) === 4.5 ? 90.9
                                                                                                : parseFloat(profileAbout?.skillLevel) === 5 ? 100
                                                                                                    : 3.9 : 3.9}%`
                                                    }}></div>
                                                </div>
                                                <div className="range-point-number">
                                                    <div className="item">0</div>
                                                    <div className="item">1</div>
                                                    <div className="item">2</div>
                                                    <div className="item">3</div>
                                                    <div className="item">4</div>
                                                    <div className="item">5</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="report-range-item">
                                            <div className="range-top-bar">
                                                <h4>
                                                    Academic Rating
                                                    <span className="icon">
                                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <g id="Group_57168" data-name="Group 57168">
                                                                <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </h4>
                                                <div className="range-info">
                                                    <div className="item">Rating</div>
                                                    <div className="item">{profileAbout?.acadamicrating ? profileAbout?.acadamicrating : 0}</div>
                                                </div>
                                            </div>
                                            <div className="range-progress-bar">
                                                <div className="progressbar small">
                                                    <div className="progress" style={{
                                                        width: `${profileAbout?.acadamicrating ?
                                                            parseFloat(profileAbout?.acadamicrating) === 0.5 ? 14.5
                                                                : parseFloat(profileAbout?.acadamicrating) === 1 ? 23.3
                                                                    : parseFloat(profileAbout?.acadamicrating) === 1.5 ? 33.3
                                                                        : parseFloat(profileAbout?.acadamicrating) === 2 ? 42.3
                                                                            : parseFloat(profileAbout?.acadamicrating) === 2.5 ? 52.3
                                                                                : parseFloat(profileAbout?.acadamicrating) === 3 ? 61.3
                                                                                    : parseFloat(profileAbout?.acadamicrating) === 3.5 ? 71.9
                                                                                        : parseFloat(profileAbout?.acadamicrating) === 4 ? 80.5
                                                                                            : parseFloat(profileAbout?.acadamicrating) === 4.5 ? 90.9
                                                                                                : parseFloat(profileAbout?.acadamicrating) === 5 ? 100
                                                                                                    : 3.9 : 3.9}%`
                                                    }}></div>
                                                </div>
                                                <div className="range-point-number">
                                                    <div className="item">0</div>
                                                    <div className="item">1</div>
                                                    <div className="item">2</div>
                                                    <div className="item">3</div>
                                                    <div className="item">4</div>
                                                    <div className="item">5</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="report-diagram">
                                        <div className="diagram-box">
                                            <img src={BaseballImage} alt="" />
                                            <ul className="range-point">
                                                <li className={profileAbout?.shortform === "C" ? "active" : ""}>C</li>
                                                <li className={(profileAbout?.shortform === "P" ||
                                                    profileAbout?.shortform === "RHP" ||
                                                    profileAbout?.shortform === "LHP") ? "active" : ""}>P</li>
                                                <li className={profileAbout?.shortform === "3B" ? "active" : ""}>3B</li>
                                                <li className={profileAbout?.shortform === "SS" ? "active" : ""}>SS</li>
                                                <li className={profileAbout?.shortform === "2B" ? "active" : ""}>2B</li>
                                                <li className={profileAbout?.shortform === "1B" ? "active" : ""}>1B</li>
                                                <li className={profileAbout?.shortform === "LF" ? "active" : ""}>LF</li>
                                                <li className={profileAbout?.shortform === "CF" ? "active" : ""}>CF</li>
                                                <li className={profileAbout?.shortform === "RF" ? "active" : ""}>RF</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="projected-division-wrap">
                                    <div className="top-info">
                                        <h5>
                                            Projected Divisions
                                            <span className="icon">
                                                <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                    <g id="Group_57168" data-name="Group 57168">
                                                        <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </h5>
                                        <div className="info-box">
                                            <div className="item">
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                        <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                            <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                            <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                Yes
                                            </div>
                                            <div className="item">
                                                <span className="icon">
                                                    <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                </span>
                                                Maybe
                                            </div>
                                            <button className="btn green">Scale</button>
                                        </div>
                                    </div>
                                    <div className="division-table">
                                        <table className="table">
                                            <tr>
                                                <td>Divisions</td>
                                                <td>Top</td>
                                                <td>High</td>
                                                <td>Mid</td>
                                                <td>Low</td>
                                            </tr>
                                            {profileProjectData?.length > 0 ?
                                                profileProjectData?.map(item => {
                                                    return <tr>
                                                        <td>{item?.collegedivisionname}</td>
                                                        <td>{item?.top === "yes" ? <span className="icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                                <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                                    <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                                    <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                </g>
                                                            </svg>
                                                        </span> : item?.top === "maybe" ? <span className="icon">
                                                            <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                        </span> : ""}</td>
                                                        <td>
                                                            {item?.high === "yes" ? <span className="icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                                    <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                                        <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                                        <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                    </g>
                                                                </svg>
                                                            </span> : item?.high === "maybe" ? <span className="icon">
                                                                <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                            </span> : ""}
                                                        </td>
                                                        <td>
                                                            {item?.mid === "yes" ? <span className="icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                                    <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                                        <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                                        <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                    </g>
                                                                </svg>
                                                            </span> : item?.mid === "maybe" ? <span className="icon">
                                                                <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                            </span> : ""}
                                                        </td>
                                                        <td>
                                                            {item?.low === "yes" ? <span className="icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                                                    <g id="Group_2026" data-name="Group 2026" transform="translate(-471 -2209)">
                                                                        <rect id="Rectangle_626" data-name="Rectangle 626" width="23" height="23" rx="11.5" transform="translate(471 2209)" fill="#1cb79e" />
                                                                        <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.221,9,9.507,17.416,6,13.59" transform="translate(471.125 2207.292)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                    </g>
                                                                </svg>
                                                            </span> : item?.low === "maybe" ? <span className="icon">
                                                                <svg id="question" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18"><g id="Group_57168" data-name="Group 57168"><path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#fdb944"></path></g></svg>
                                                            </span> : ""}
                                                        </td>
                                                    </tr>
                                                })
                                                : <tr>
                                                    <td>No data found</td>
                                                </tr>}
                                        </table>
                                    </div>
                                </div>
                                <h5>Key Stats</h5>
                                <ul className="key-type-list">
                                    <li>Height : {profileAbout?.heightinfeet
                                        ? profileAbout?.heightinfeet + "'"
                                        : ""}
                                        &nbsp;
                                        {profileAbout?.heightininch
                                            ? profileAbout?.heightininch == 0 ? `${profileAbout?.heightininch}"` : `${parseFloat(profileAbout?.heightininch) - 1}"`
                                            : ""}</li>
                                    <li>Weight : {profileAbout?.weight ? profileAbout?.weight : ""}</li>
                                    {profileAbout?.keyStatValue ? profileAbout?.keyStatValue.map(item => {
                                        return <li>{item.name}</li>
                                    })
                                        : ""}
                                </ul>
                            </div>
                            <div className="field-box-item">
                                <h5>
                                    Scouting Summary
                                    <span className="icon">
                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <g id="Group_57168" data-name="Group 57168">
                                                <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                            </g>
                                        </svg>
                                    </span>
                                </h5>
                                <label>(Ex. Describe athletic, academic, performance, and intangibles)</label>
                                <textarea value={`${profileAbout?.scoutingsummary ? profileAbout?.scoutingsummary : ""}`}></textarea>
                            </div>
                            <div className="field-box-item">
                                <h5>
                                    Areas for improvement
                                    <span className="icon">
                                        <svg id="question" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <g id="Group_57168" data-name="Group 57168">
                                                <path id="Path_2272" data-name="Path 2272" d="M9,0a9,9,0,1,0,9,9A8.995,8.995,0,0,0,9,0ZM8.734,13.212a.912.912,0,0,1,0-1.824.912.912,0,0,1,0,1.824ZM10.084,8.6a1.488,1.488,0,0,0-.663,1.481c0,.225-.118.486-.7.486-.486,0-.652-.178-.652-.794a2.169,2.169,0,0,1,.794-1.8c.391-.332,1.054-.7,1.054-1.339,0-.545-.474-.806-1.066-.806-1.208,0-.948.912-1.587.912a.679.679,0,0,1-.711-.675c0-.64.734-1.587,2.334-1.587,1.516,0,2.523.841,2.523,1.955A3.032,3.032,0,0,1,10.084,8.6Z" fill="#3e3e3e" />
                                            </g>
                                        </svg>
                                    </span>
                                </h5>
                                <label>(Ex. Describe athletic, academic, performance, and intangibles)</label>
                                <textarea value={`${profileAbout?.areasforimprovement ? profileAbout?.areasforimprovement : ""}`}></textarea>
                            </div>
                            <div className="field-box-item">
                                <h5>Additional website link</h5>
                                <label>(Ex. Schedule, stats, profile link, twitter)</label>
                                {profileAbout?.additionalwebsiteLink ? <a href={`${profileAbout?.additionalwebsiteLink}`} target={"_blank"}>{profileAbout?.additionalwebsiteLink ? profileAbout?.additionalwebsiteLink : ""}</a>: <a></a>}
                            </div>
                            <div className="btn-wrap">
                                {/* <button className="btn green">
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.139" height="18" viewBox="0 0 16.139 18">
                                    <path id="admin-users" d="M13.037,11.68c-2.53,0-3.043-3.834-3.043-3.834-.3-2,.613-4.246,3.009-4.246s3.321,2.251,3.02,4.246C16.024,7.846,15.567,11.68,13.037,11.68Zm0,2.864,3.032-2.028a5.365,5.365,0,0,1,5.038,5.049v2.775A31.434,31.434,0,0,1,13.037,21.6a30.767,30.767,0,0,1-8.069-1.259V17.565A5.107,5.107,0,0,1,9.95,12.572Z" transform="translate(-4.968 -3.6)" fill="#fff"/>
                                </svg>                                                                
                            </span>
                            Add to My Recruits
                        </button> */}
                                <button className="btn blue" onClick={() => routeToAthleteMessage()} disabled={profileAbout?.advisorid ? false : true}>
                                    <span className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15.602" viewBox="0 0 18 15.602">
                                            <path id="send" d="M18.4,10.09l-.006,0L2.376,3.444a.884.884,0,0,0-.833.081.924.924,0,0,0-.418.772V8.547a.9.9,0,0,0,.732.884l8.736,1.615a.15.15,0,0,1,0,.295L1.857,12.956a.9.9,0,0,0-.732.883v4.25a.884.884,0,0,0,.4.739.9.9,0,0,0,.5.15.921.921,0,0,0,.357-.073L18.392,12.3l.007,0a1.2,1.2,0,0,0,0-2.205Z" transform="translate(-1.125 -3.375)" fill="#fff" />
                                        </svg>
                                    </span>
                                    Discuss Athlete
                                </button>
                                <button className="btn yellow" onClick={() => setIsShare(!isShare)}>
                                    <span className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14.996" viewBox="0 0 18 14.996">
                                            <path id="Icon_ionic-ios-share-alt" data-name="Icon ionic-ios-share-alt" d="M22.342,13.049,15.73,6.862a.338.338,0,0,0-.262-.112c-.206.009-.469.155-.469.375v3.1a.2.2,0,0,1-.169.192C8.255,11.427,5.485,16.348,4.505,21.456c-.037.2.234.389.361.23A11.952,11.952,0,0,1,14.8,16.653a.232.232,0,0,1,.2.225v3.046a.4.4,0,0,0,.68.248l6.66-6.294a.517.517,0,0,0,.164-.394A.628.628,0,0,0,22.342,13.049Z" transform="translate(-4.502 -6.75)" fill="#fff" />
                                        </svg>
                                    </span>
                                    Share
                                </button>
                            </div>
                        </div>) :
                        <div className="report-profile-info">
                            <h3 className="title">Scouting Reports</h3>
                            <div className="updateEvaluationPara">
                                <div className="detail-box">
                                    <p>College coaches rely on up-to-date evaluations. Keeping evaluations fresh greatly increases your athletes chances.</p>

                                </div>
                                <button className="btn blue" onClick={() => openModalDialog()}>
                                    {profileAbout ? "Update Evaluation" : "Add Evaluation"}
                                </button>
                                <span className="draftText" onClick={() => { handleOpenDraftModal() }}>{`Draft(s) Available (${draftCount || 0})`}</span>
                            </div>
                            <div className="item" style={{ textAlign: "center" }}>
                                <span>No reports yet</span>
                            </div>
                        </div>
                    :
                    <div>
                        {swal({ text: "Coming Soon", icon: "" })}
                        <span>Coming Soon</span>
                    </div>
                : <div>
                    {swal({ text: "Coming Soon", icon: "" })}
                    <span>Coming Soon</span>
                </div>
            }
        </div>
    );
})