export const primaryPositionData = `query allPrimaryPosition($where: JSON){
  allPrimarypositions(where:$where){
      Primarypositions{
         id
         selectionfield
        shortform
        statsoverviewPrimarypositionidMap{
          Statsoverviews{
            statsoverviewstatematrixmappingidrel{
              Statmatrixmappings{
                id
                tabname
                placeholdertext
                abbreviation
                datafield
                datatype
                maximumvalue
                metricsslider
                minimumvalue
                overview
                resultlebelname
                statsmatrixid
                unit
                whichoneisbest
              }
            }
          }
        }
      }
  }
}`;

export const getAdvisorData = `
query allAdvisors($where: JSON){
  allAdvisors(where:$where){
    Advisors{
      id
      firstname
      lastname
      emailid
      userid
    }
  }
}
`;

export const getCollegeDivisions = `
query allProjecteddivisions($where: JSON){
  allProjecteddivisions(where:$where){
      Projecteddivisions{
        id
        high
        mid
        top
        low
        advisorid
        userid
        divisionid
        projecteddivisionsdivisionidmaprel{
          Collegedivisions{
            collegedivisionname
          }
        }
      }
  }
}`;

export const getCollegeDivisionsForDraft = `
query allProjecteddivisionsfordrafts($where:JSON){
  allProjecteddivisionsfordrafts(where:$where){
    Projecteddivisionsfordrafts{
      id
      high
      mid
      top
      low
      advisorid
      userid
      divisionid
      projecteddivisionsfordraftdivisionidmaprel{
        Collegedivisions{
          collegedivisionname
        }
      }
    }
  }
}`;

export const saveReport = `
mutation saveEvaluationreport($obj: EvaluationreportInput!){
  saveEvaluationreport(obj:$obj){
    id
  }
}
`;

export const getAllBattingPreferencesQuery = `
  query allBattingpreferences($obj: JSON){
        allBattingpreferences(where: $obj){
            Battingpreferences{
                battingpreferencevalue
                id
                active
          }
        }
      }
  `;

  export const getAllThrowingPreferencesQuery = `
  query allThrowingpreferences($obj: JSON){
        allThrowingpreferences(where: $obj){
            Throwingpreferences{
                throwingpreferencevalue
                id
                active
          }
        }
      }
  `;

  export const getAllHeightInFeetsQuery = `
query {
    allHeightinfeets{
          Heightinfeets{
           id
           heightvalue      
        }
    }
  }
  `;

export const getAllHeightInInchesQuery = `
  query{
    allHeightininches{
      Heightininches{
        id
        inchvalue
      }
    }
  }
  `;

  export const evaluationDraftQuery = `
  query allEvaluationdrafts($where:JSON){
    allEvaluationdrafts(where:$where){
      totalCount
      Evaluationdrafts{
        id
        active
        draftname
        createdon
        acadamicrating
        additionalwebsitelink
        advisorid
        adminid
        areasforimprovement
        athleteid
        athleticismlevel
        createdon
        heightinfeet
        heightininch
        isdraft
        keystats
        projectedlevelrange
        projectionposition
        reportviewedby
        scoutingsummary
        skilllevel
        weight
        updatedon
      }
    }
  }
  `;

export const saveEvaluationdraft =  `mutation saveEvaluationdraft($obj: EvaluationdraftInput!){
    saveEvaluationdraft(obj:$obj){
      id
    }
  }`;

  export const getAllJumpData = `
  {
    allFeet(where:{active:1}){
      Feet{
        id
        feetvalue
      }
    }
    allInches(where:{active:1}){
      Inches{
        id
        inchvalue
      }
    }
  }
  `;
  export const getAllSportsData = `
  {
      allAthletesports{
      Athletesports{
        sportname 
        sportforgender 
      }
    }
  }
  `;
  //get permalink query
export const getPermalinkQuery = `
query allAthletes($obj: JSON){
  allAthletes(where: $obj){
    Athletes{
      permalinkurl
      }
  }
} 
`;

export const primaryPositionFields = `query allPrimarypositionmappings($where:JSON){
  allPrimarypositionmappings(where:$where){
    Primarypositionmappings{
      id
      primarypositionid
      columnname
      columnkey
      placeholdervalue
      minrangevalue
      maxrangevalue
      statmatrixmapping
      statmatrixmappingmaprel{
        Statmatrixmappings{
          datafield
          abbreviation
          id
          maximumvalue
          minimumvalue
          metricsslider
          overview
          placeholdertext
          tabname
          unit
          whichoneisbest
        }
      }
    }
  }
}`;

  //  export const getAllSportsData = `
  // {
  //     allAthletesports{
  //     Athletesports{
  //       sportname 
  //       sportforgender 
  //     }
  //   }
  // }
  // `;

// export const sentNotification =  `
// mutation saveNotification($obj: NotificationInput!){
//     saveNotification(obj:$obj){
//       id
//     }
//   }`