export function unixTimestampToDate(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  return date + " " + month + " " + year;
}

//func independent of time zone
export const getUTCTimeDateDash = (value) =>{
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date = (new Date(`${value}`)),
      day = date?.getUTCDate(),
      month = months[date.getMonth()],
      year = date?.getUTCFullYear();
  return `${month} ${day}, ${year}`
}

//func independent of time zone
export const getUTCTimeDateOverall = (value) =>{
  let date = (new Date(`${value}`)),
      day = date?.getUTCDate(),
      month = date?.getUTCMonth()+1,
      year = date?.getUTCFullYear();
  return `${month?.toString()?.length > 1 ? `${month}` : `0${month}`}/${day}/${year}`
}

// Both gives same time
export function unixTimestampToTime(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp);
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  return hour + ":" + min + ":" + sec;
}
// mm/dd/yyyy formatted Date
export const getSlashFormattedDate = (date) => {
  const tempdate = new Date(date);
  let currentdate = tempdate.getDate();
  let month = tempdate.getMonth() + 1;
  let year = tempdate.getFullYear();
  currentdate = currentdate < 10 ? `0${currentdate}` : currentdate;
  month = month < 10 ? `0${month}` : month;
  return `${month}/${currentdate}/${year}`;
};

// yyyy-mm-dd formatted Date
export const getColonSlotFormatedDate = (date) => {
  const tempdate = new Date(date);
  let currentdate = tempdate.getDate();
  let month = tempdate.getMonth() + 1;
  let year = tempdate.getFullYear();
  currentdate = currentdate < 10 ? `0${currentdate}` : currentdate;
  month = month < 10 ? `0${month}` : month;
  return `${year}-${month}-${currentdate}`;
};

//mm--dd--yyyy
export const getColonFormatedDate = (date) => {
  const tempdate = new Date(date);
  let currentdate = tempdate.getDate();
  let month = tempdate.getMonth() + 1;
  let year = tempdate.getFullYear();
  currentdate = currentdate < 10 ? `0${currentdate}` : currentdate;
  month = month < 10 ? `0${month}` : month;
  return `${month}-${currentdate}-${year}`;
};
// mm dd, yyyy
export function postDateFormat(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  return month + " " + date + ", " + year;
}

// AM PM formate
export function postTimeFormat(date) {
  const timeString12hr = new Date(date)
  .toLocaleTimeString({},
    {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
  );
  return timeString12hr;
}

export function timeFormatter(date) {
  const time = new Date(date)
  .toLocaleString().split(",")[1];
  return time;
}
export default {
  unixTimestampToDate,
  unixTimestampToTime,
  getSlashFormattedDate,
  getColonSlotFormatedDate,
  postDateFormat,
  postTimeFormat,
};
